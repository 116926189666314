import timeTracking from '@/store/modules/addresses/address/time-tracking';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    addressPending: false,
    addressDetailPending: true,
    storeAddressPending: false,
    address: {},
    singleActions: {},
    opReceivablesAmont: 0,
    opPayablesAmount: 0,
    opPayablesPaidAmount: 0,
    opCreditnotesAmount: 0,
    opReceivablesPaidAmount: 0,
    activities: [],
    fetchZipPlaceAutoCompletionPending: false,
    fetchStreetAutoCompletionPending: false,
    fetchCustomerNumberPreviewPending: false,
    customerNumberValidationPending: false,
    accountNumberValidationPending: false,
    bicNumberValidationPending: false,
    checkAbaNetAvailablePending: false,
  },
  actions,
  mutations,
  getters,
  modules: {
    timeTracking,
  },
};
