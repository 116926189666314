<template>
  <v-list>
    <v-list-item>
      <v-list-item-subtitle>
        {{ $tc('texts.setting_browser_specific') }}
      </v-list-item-subtitle>
    </v-list-item>
    <v-list-item-group v-model="store" mandatory>
      <v-list-item value="auto">
        <template #default="{ active }">
          <v-list-item-icon>
            <v-icon v-if="active">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('texts.theme_device') }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-list-item value="dark">
        <template #default="{ active }">
          <v-list-item-icon>
            <v-icon v-if="active">mdi-check</v-icon>
            <v-icon v-else-if="isDarkTheme" class="auto">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('texts.theme_dark') }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-list-item value="light">
        <template #default="{ active }">
          <v-list-item-icon>
            <v-icon v-if="active">mdi-check</v-icon>
            <v-icon v-else-if="!isDarkTheme" class="auto">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('texts.theme_light') }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script lang="ts" setup>
import { useTheme } from '@/composables/theme';

const { store, isDarkTheme } = useTheme();

</script>
<style lang="scss" scoped>
.auto {
  opacity: .2;
}
</style>
