import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialLoading: true,
    initialError: false,
    accountingYears: [],
    currentAccountingYear: {},
    keyFigures: {
      calculated: {
        asset_coverage_ratio1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_coverage_ratio2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        cash_ratio: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        current_ratio: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        equity_ratio: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        foreign_financing_ratio: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        quick_ratio: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        return_on_equity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        return_on_sales: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        total_capital_profitability: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      calculated_year: {
        asset_coverage_ratio1: 0,
        asset_coverage_ratio2: 0,
        cash_ratio: 0,
        current_ratio: 0,
        ebit: 0,
        ebitda: 0,
        equity_ratio: 0,
        foreign_financing_ratio: 0,
        quick_ratio: 0,
        return_on_equity: 0,
        return_on_sales: 0,
        total_capital_profitability: 0,
      },
      ranges: {
        asset_coverage_ratio1: [0, 0],
        asset_coverage_ratio2: [0, 0],
        cash_ratio: [0, 0],
        current_ratio: [0, 0],
        notes: '',
        quick_ratio: [0, 0],
        return_on_equity: [0, 0],
        return_on_sales: [0, 0],
        total_capital_profitability: [0, 0],
      },
    },
    keyFiguresLoading: false,
  },
  actions,
  mutations,
};
