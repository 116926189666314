import * as types from './types';

export default {
  // import document json
  [types.IMPORT_DOCUMENT_JSON](state) {
    state.isImportingDocumentJson = true;
    state.hasImportedDocumentJson = false;
    state.importDocumentJsonError = null;
  },
  [types.IMPORT_DOCUMENT_JSON_SUCCESS](state) {
    state.isImportingDocumentJson = false;
    state.hasImportedDocumentJson = true;
    state.importDocumentJsonError = null;
  },
  [types.IMPORT_DOCUMENT_JSON_FAILURE](state) {
    state.isImportingDocumentJson = false;
    state.importDocumentJsonError = null;
  },

  // fetch document queue
  [types.FETCH_DOCUMENT_QUEUE](state) {
    state.isFetchingDocumentQueue = true;
  },
  [types.FETCH_DOCUMENT_QUEUE_SUCCESS](state, data) {
    state.documentQueue = data;
    state.isFetchingDocumentQueue = false;
  },
  [types.FETCH_DOCUMENT_QUEUE_FAILURE](state) {
    state.isFetchingDocumentQueue = false;
  },
};
