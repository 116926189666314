import type { RouteConfig } from 'vue-router/types/router';

const EmailDelivery = () => import('@/pages/settings/customer/SettingsEmailDelivery.vue');
const PassThrough = () => import('@/views/PassThrough.vue');

export const emailRoutes: Array<RouteConfig> = [{
  path: 'email',
  component: PassThrough,
  children: [
    {
      path: 'delivery',
      name: 'email-delivery',
      component: EmailDelivery,
      meta: {
        title: 'email_sender',
      },
    },
  ],
}];
