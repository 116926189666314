import { useI18n } from '@/helpers/i18n';
import { usePermissionsStore } from '@/stores/definitions/permissions';

export enum EmployeeView {
  DETAILS = 'details',
  CONTRACTS = 'contracts',
  TIME_CORRECTIONS = 'time-corrections',
  CLOSURES = 'closures',
}

export enum ReportView {
  IN_AND_OUT = 'in-and-out',
  ACTIVITIES = 'activities',
  ABSENCES = 'absences',
  HOLIDAYS = 'holidays',
  TOTALS = 'totals',
  SHORT_WORK = 'short-work',
}

type TabDefinition = {
  title: string;
  name: string;
  params: {
    reportView: ReportView;
    employeeView?: never;
  } | {
    reportView?: never;
    employeeView: EmployeeView;
  };
  show?: boolean;
}

export const useEmployeeTabs = () => {
  const i18n = useI18n();
  const permission = usePermissionsStore();

  const name = 'employee';

  const employeeTabs: Array<TabDefinition> = [
    {
      title: i18n.tc('texts.details'),
      name,
      params: {
        employeeView: EmployeeView.DETAILS,
      },
      show: true,
    },
    {
      title: i18n.tc('texts.employee_contract'),
      name,
      params: {
        employeeView: EmployeeView.CONTRACTS,
      },
      show: permission.isAdmin,
    },
    {
      title: i18n.tc('texts.workhours_time_bookings'),
      name,
      params: {
        employeeView: EmployeeView.TIME_CORRECTIONS,
      },
      show: permission.isAdmin,
    },
    {
      title: i18n.tc('texts.employee_closures'),
      name,
      params: {
        employeeView: EmployeeView.CLOSURES,
      },
      show: permission.isAdmin,
    },
  ].filter((tab) => tab.show);

  const reportTabs: Array<TabDefinition> = [
    {
      title: i18n.tc('texts.timetracking_in_and_out'),
      name,
      params: {
        reportView: ReportView.IN_AND_OUT,
      },
    },
    {
      title: i18n.tc('texts.activity_group_work'),
      name,
      params: {
        reportView: ReportView.ACTIVITIES,
      },
    },
    {
      title: i18n.tc('texts.activity_group_absent'),
      name,
      params: {
        reportView: ReportView.ABSENCES,
      },
    },
    {
      title: i18n.tc('texts.activity_group_holiday'),
      name,
      params: {
        reportView: ReportView.HOLIDAYS,
      },
    },
    {
      title: i18n.tc('texts.in_total'),
      name,
      params: {
        reportView: ReportView.TOTALS,
      },
    },
    {
      title: i18n.tc('texts.is_shorttimework_short'),
      name,
      params: {
        reportView: ReportView.SHORT_WORK,
      },
    },
  ];

  return {
    employeeTabs,
    reportTabs,
  };
};
