import * as types from '@/store/modules/user-interface/invoice-items-list/types';
import { deepMerge } from '@/mixins/deep-merge';

export default {

  /*
   * Basic setter / reset mutations
   */
  [types.UPDATE_TABLE_SETTING](state, setting) {
    state.tableSettings = {
      ...state.tableSettings,
      ...setting,
    };
  },
  [types.SET_INVOICE_ITEMS](state, invoiceItems) {
    state.invoiceItems = invoiceItems;
  },
  [types.RESET_GHOST_ITEMS](state) {
    state.ghostItems = [];
  },

  /*
   * Append Items
   */
  [types.ADD_INVOICE_ITEM](state, invoiceItem) {
    state.invoiceItems.push(invoiceItem);
  },
  [types.ADD_GHOST_ITEM](state, invoiceItem) {
    state.ghostItems.push(invoiceItem);
  },

  /*
   * Remove Items
   */
  [types.REMOVE_INVOICE_ITEM](state, indexToRemove) {
    state.invoiceItems.splice(indexToRemove, 1);
  },
  [types.REMOVE_NESTED_INVOICE_ITEM](state, { groupIndex, invoiceItemIndex }) {
    state.invoiceItems[groupIndex].group_items.splice(invoiceItemIndex, 1);
  },
  [types.REMOVE_GHOST_ITEM](state, indexToRemove) {
    state.ghostItems.splice(indexToRemove, 1);
  },

  /*
   * Update single value
   */
  [types.UPDATE_INVOICE_ITEM_VALUE](state, { key, value, indexToUpdate }) {
    state.invoiceItems.splice(indexToUpdate, 1, { ...state.invoiceItems[indexToUpdate], [key]: value });
  },
  [types.UPDATE_GROUP_INVOICE_ITEM_VALUE](state, {
    key, value, groupIndex, invoiceItemIndex,
  }) {
    state.invoiceItems.splice(groupIndex, 1, {
      ...state.invoiceItems[groupIndex],
      group_items: Object.assign(
        [],
        state.invoiceItems[groupIndex].group_items,
        {
          [invoiceItemIndex]: {
            ...state.invoiceItems[groupIndex].group_items[invoiceItemIndex],
            [key]: value,
          },
        },
      ),
    });
  },
  [types.UPDATE_GHOST_ITEM_VALUE](state, { key, value, indexToUpdate }) {
    state.ghostItems.splice(indexToUpdate, 1, { ...state.ghostItems[indexToUpdate], [key]: value });
  },

  /*
   * Update multiple values
   */
  [types.UPDATE_INVOICE_ITEM_VALUES](state, { updateObject, indexToUpdate }) {
    state.invoiceItems.splice(
      indexToUpdate,
      1,
      deepMerge(state.invoiceItems[indexToUpdate], updateObject),
    );
  },
  [types.UPDATE_GROUP_INVOICE_ITEM_VALUES](state, { updateObject, groupIndex, invoiceItemIndex }) {
    state.invoiceItems.splice(groupIndex, 1, {
      ...state.invoiceItems[groupIndex],
      group_items: Object.assign(
        [],
        state.invoiceItems[groupIndex].group_items,
        { [invoiceItemIndex]: deepMerge(state.invoiceItems[groupIndex].group_items[invoiceItemIndex], updateObject) },
      ),
    });
  },
  [types.UPDATE_GHOST_ITEM_VALUES](state, { updateObject, indexToUpdate }) {
    state.ghostItems.splice(
      indexToUpdate,
      1,
      deepMerge(state.ghostItems[indexToUpdate], updateObject),
    );
  },

  /*
   * Special Mutations
   */
  [types.UNGROUP_GROUP](state, { groupIndex, invoiceItems }) {
    state.invoiceItems.splice(groupIndex, 1, ...invoiceItems);
  },

};
