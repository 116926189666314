import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    initialError: false,
    importBankfilePending: false,
    fetchBankfilePending: false,
    storeBankfilePending: false,
    deleteBankfilePending: false,
    bankfile: {},
  },
  actions,
  mutations,
};
