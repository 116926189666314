const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const AbaBankCallback = () => import('@/pages/ababank/AbaBankCallback.vue');
const AbaBankOnboarding = () => import('@/pages/ababank/AbaBankOnboarding.vue');

export default {
  path: '/ababank',
  name: 'ababank',
  component: PassThroughKeyed,
  redirect: { name: 'error' },
  children: [
    {
      path: 'onboarding',
      name: 'ababank-onboarding-callback',
      component: AbaBankOnboarding,
    },
    {
      path: 'cb',
      name: 'ababank-callback',
      component: AbaBankCallback,
    },
  ],
};
