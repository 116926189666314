import { useUserStore } from '@/stores/users/user';

function roleExist(userRoles, roleToCheck) {
  return !!userRoles.find(
    (singleRole) => singleRole.toLowerCase() === roleToCheck.toLowerCase(),
  );
}

export default function hasRole(to, from, next, role) {
  const userStore = useUserStore();
  next(roleExist(userStore.entity.roles, role) ? true : { name: 'error-no-permission' });
}
