import axios from 'axios';
import * as types from './types';

const actions = {
  async archiveProductGroup({ rootState, commit, dispatch }, id) {
    commit(types.ARCHIVE_PRODUCT_GROUP_PENDING);

    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/products/groups/${id}/archive`;
      const response = await axios.post(url);
      commit(types.ARCHIVE_PRODUCT_GROUP_FULFILLED, response);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      dispatch('products/fetchPaginationProductsGroup', { archive: false }, { root: true });
    } catch (error) {
      dispatch('messages/showMessage', { text: error.message, color: 'error' }, { root: true });
      commit(types.ARCHIVE_PRODUCT_GROUP_ERROR, error);
    }
  },
  async restoreProductGroup({ rootState, commit, dispatch }, id) {
    commit(types.RESTORE_PRODUCT_GROUP_PENDING);

    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/products/groups/${id}/restore`;
      const response = await axios.post(url);
      commit(types.RESTORE_PRODUCT_GROUP_FULFILLED, response);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      dispatch('products/fetchPaginationProductsGroup', { archive: true }, { root: true });
    } catch (error) {
      dispatch('messages/showMessage', { text: error.message, color: 'error' }, { root: true });
      commit(types.RESTORE_PRODUCT_GROUP_ERROR, error);
    }
  },
};

export default actions;
