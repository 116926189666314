const Journal = () => import('@/pages/accounting/journal/Journal.vue');

export default [
  {
    path: 'journal/:yearId?',
    name: 'journal',
    component: Journal,
    meta: {
      title: 'journal',
    },
  },
];
