import axios from 'axios';
import * as types from './types';

export default {
  fetchComments({ rootState, commit, state }, { id, type, meta }) {
    commit(types.FETCH_COMMENTS, { id, type });

    const metaValue = meta || state.meta;
    const params = {
      direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
      per_page: metaValue.itemsPerPage,
      sort: metaValue.sortBy[0],
      page: metaValue.page,
    };
    // TODO: api v2
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/comments/${type}/${id}`, { params }).then((payload) => {
      commit(types.FETCH_COMMENTS_FULFILLED, payload);
    }).catch((error) => {
      commit(types.FETCH_COMMENTS_ERROR, error);
    });
  },

  postComment({ rootState, commit }, { id, type, data }) {
    commit(types.POST_COMMENT, { id, ...data });
    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/comments/${type}/${id}`, data).then((payload) => {
      commit(types.POST_COMMENT_FULFILLED, payload);
    }).catch((error) => {
      commit(types.POST_COMMENT_ERROR, error);
    });
  },

  deleteComment({ rootState, state, commit }, { id }) {
    if (!state.isDeletingIds.includes(id)) {
      commit(types.DELETE_COMMENT, { id });
      return axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/comments/${id}`).then(() => {
        commit(types.DELETE_COMMENT_FULFILLED, { id });
      }).catch((error) => {
        commit(types.DELETE_COMMENT_ERROR, { error, id });
      });
    }
    return Promise.reject(new Error('can not delet comment'));
  },

  resetComments({ commit }) {
    commit(types.RESET_COMMENT);
  },

  updateMetaAfterCommentPost({ state, commit }) {
    // add second page, if a user adds more than one page full of comments.
    if (state.ids.length % state.meta.itemsPerPage === 1
      && (state.ids.length / state.meta.itemsPerPage) > 1
      && state.meta.maxPage === 1
    ) {
      const meta = {
        maxPage: state.meta.maxPage + 1,
      };
      commit(types.UPDATE_COMMENT_META, { meta });
    }
  },
};
