import type { RouteConfig } from 'vue-router/types/router';

const EmployeeGroupForm = () => import('@/pages/time-tracking/employee-groups/EmployeeGroupForm.vue');
const EmployeeGroupsList = () => import('@/pages/time-tracking/employee-groups/EmployeeGroupsList.vue');

export const employeeGroupsRoutes: Array<RouteConfig> = [
  {
    path: 'employee-groups',
    name: 'employee-groups',
    component: EmployeeGroupsList,
    meta: {
      title: 'employee_groups',
    },
  },
  {
    path: 'employee-groups/employee-group',
    name: 'employee-group-create',
    component: EmployeeGroupForm,
  },
  {
    path: 'employee-groups/employee-group/:employeeGroupUuid',
    name: 'employee-group-edit',
    component: EmployeeGroupForm,
  },
];
