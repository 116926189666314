import initialState from './state';
import * as types from './types';

export default {
  /*
   * FETCH
   */
  [types.FETCH_ADDRESS_TIME_TRACKING](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
    state.meta = {
      ...initialState.meta,
    };
  },
  [types.FETCH_ADDRESS_TIME_TRACKING_SUCCESS](state, { data, meta }) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;
    state.data = data;

    state.meta = {
      sortDesc: [meta.direction === 'DESC'],
      itemsPerPage: parseInt(meta.per_page, 10),
      sortBy: [meta.sort],
      page: parseInt(meta.page, 10),
      search: meta.search,
      extendSearch: meta.extend_search,
      maxPage: parseInt(meta.max_page, 10),
      totalItems: meta.max_page * meta.per_page,
    };
  },
  [types.FETCH_ADDRESS_TIME_TRACKING_FAILURE](state, error) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },
  [types.FETCH_ADDRESS_TIME_TRACKING_CANCEL](state) {
    state.isLoading = false;
  },
};
