<template>
  <v-menu
    :value="inboundCall"
    absolute
    offset-y
    :close-on-click="false"
    :close-on-content-click="false"
    content-class="v-menu--leansync-notification list-tiles--leansync"
    min-width="250px"
    max-width="3000px"
  >
    <v-card>
      <v-list three-line>
        <template v-for="(caller, index) in callers">
          <v-list-item
            :key="index"
            @click="openAddressDetail({ index: index, addressId: `${caller.addressId}` });"
          >
            <v-list-item-action>
              <v-icon>mdi-phone-message</v-icon>
            </v-list-item-action>
            <v-list-item-content v-if="caller.companyName">
              <v-list-item-title>{{ caller.companyName }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="caller.personName !== null"
                class="text--primary"
              >
                {{ caller.personName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-else
                class="text--primary"
              >
                {{ caller.phoneNumber }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{ caller.time }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title v-if="caller.personName">{{ caller.personName }}</v-list-item-title>
              <v-list-item-title v-else>{{ caller.phoneNumber }}</v-list-item-title>
              <v-list-item-subtitle>{{ caller.time }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action @click.stop>
              <v-btn
                text
                icon
                small
                @click="removeCaller(index)"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${index}-divider`" />
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('leansync');

export default {
  computed: {
    ...mapState([
      'connected',
      'callPending',
      'inboundCall',
      'callers',
    ]),
  },
  methods: {
    ...mapActions([
      'removeCaller',
    ]),
    openAddressDetail(payload) {
      if (payload.addressId !== 'null') {
        this.$router.push({ name: 'address-edit', params: { id: payload.addressId } }).catch(() => {});
        this.removeCaller(payload.index);
      }
    },
  },
};
</script>
