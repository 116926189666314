import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');
const QuotesList = () => import('@/pages/customers/quotes/QuotesList.vue');

const quotesRoutes: RouteConfig[] = [{
  path: 'quotes',
  alias: ['quote'],
  component: PassThrough,
  children: [
    {
      path: ':group?',
      name: 'quotes-list',
      meta: {
        title: 'quotes',
      },
      component: QuotesList,
      beforeEnter(to, _, next) {
        if (!to.params.group) {
          next({ name: 'quotes-list', params: { group: 'open' } });
        } else {
          next();
        }
      },
    },
  ],
}];

export default quotesRoutes;
