import type { RouteConfig } from 'vue-router/types/router';

const merchantCategoryCodeRoutes: RouteConfig[] = [{
  path: 'merchant-category-codes',
  name: 'merchant-category-codes',
  component: () => import('@/pages/settings/accounting/merchant-category-code/MerchantCategoryCodeList.vue'),
}, {
  path: 'merchant-category-code/:uuid?',
  name: 'merchant-category-code',
  props: true,
  component: () => import('@/pages/settings/accounting/merchant-category-code/MerchantCategoryCodeForm.vue'),
}];

export default merchantCategoryCodeRoutes;
