import { DocumentType } from '@/components/Document/types/document-type';

const AddressActivities = () => import('@/pages/addresses/detail/Activities.vue');
const AddressDetail = () => import('@/pages/addresses/detail/AddressDetail.vue');
const AddressEdit = () => import('@/pages/addresses/address/Address.vue');
const Addresses = () => import('@/pages/addresses/Addresses.vue');
const AddressInvoices = () => import('@/pages/addresses/detail/Invoices.vue');
const AddressesList = () => import('@/pages/addresses/AddressesList.vue');
const AddressesArchive = () => import('@/pages/addresses/AddressesArchive.vue');
const AddressPayments = () => import('@/pages/addresses/detail/Payments.vue');
const AddressReceipts = () => import('@/pages/addresses/detail/Receipts.vue');
const AddressTimeTracking = () => import('@/pages/addresses/detail/AddressTimeTracking.vue');
const AddressSupplierCreditNotes = () => import('@/pages/addresses/detail/AddressSupplierCreditNotes.vue');
const PassThrough = () => import('@/views/PassThrough.vue');

export default {
  path: '/addresses',
  component: PassThrough,
  name: 'addresses',
  redirect: { name: 'addresses-default' },
  children: [

    {
      path: '',
      component: Addresses,
      redirect: { name: 'addresses-list' },
      meta: {
        title: 'addresses',
      },
      name: 'addresses-default',
      children: [
        {
          path: 'list-deprecated',
          component: AddressesList,
          name: 'address-list',
          meta: {
            title: 'addresses',
            list: 'list',
          },
        },
        {
          path: 'archived-deprecated',
          name: 'address-archive',
          component: AddressesArchive,
          meta: {
            title: 'client_archive',
            list: 'archived',
          },
        },
        {
          path: 'list',
          name: 'addresses-list',
          component: () => import('@/pages/addresses/AddressesElasticList.vue'),
        },
        {
          path: 'archived',
          name: 'addresses-archive',
          component: () => import('@/pages/addresses/AddressesElasticArchive.vue'),
        },
      ],
    },
    {
      path: 'create',
      name: 'address-create',
      component: AddressEdit,
      meta: {
        title: 'new_address',
      },
    },
    {
      path: 'edit/:id',
      name: 'address-edit',
      component: AddressEdit,
      meta: {
        title: 'edit_client',
      },
    },
    {
      path: ':id',
      name: 'address',
      component: AddressDetail,
      meta: {
        title: 'address',
      },
      children: [
        {
          path: 'activities',
          name: 'address-activities',
          component: AddressActivities,
        },
        {
          path: 'payments',
          name: 'address-payments',
          component: AddressPayments,
          meta: {
            paymentListType: 'address',
          },
        },
        {
          path: 'receipts',
          name: 'address-receipts',
          component: AddressReceipts,
          meta: {
            list: 'address',
          },
        },
        {
          path: 'supplier-credit-notes',
          name: 'address-supplier-credit-notes',
          component: AddressSupplierCreditNotes,
        },
        {
          path: 'time-tracking',
          name: 'address-time-tracking',
          component: AddressTimeTracking,
        },
        {
          path: ':type',
          component: AddressInvoices,
          name: 'address-type',
          meta: {
            list: 'address',
          },
          beforeEnter(to, from, next) {
            if (Object.values(DocumentType).includes(to.params.type)) {
              next();
            } else {
              next('error-page');
            }
          },
        },
      ],
    },
  ],
};
