import * as types from './types';

export default {
  // Setup deepcloud
  [types.SETUP_DEEPCLOUD_BANKACCOUNTS_PENDING](state) {
    state.setupDeepCloudBankAccountsPending = true;
  },
  [types.SETUP_DEEPCLOUD_BANKACCOUNTS_FULFILLED](state) {
    state.setupDeepCloudBankAccountsPending = false;
  },
  [types.SETUP_DEEPCLOUD_BANKACCOUNTS_ERROR](state) {
    state.setupDeepCloudBankAccountsPending = false;
  },

  // Login to deepcloud
  [types.DEEPCLOUD_LOGIN_PENDING](state) {
    state.deepCloudLoginPending = true;
  },
  [types.DEEPCLOUD_LOGIN_FULFILLED](state) {
    state.deepCloudLoginPending = false;
  },
  [types.DEEPCLOUD_LOGIN_ERROR](state) {
    state.deepCloudLoginPending = false;
  },

  // Sync deepcloud
  [types.DEEPCLOUD_SYNC_PENDING](state) {
    state.deepCloudSyncPending = true;
  },
  [types.DEEPCLOUD_SYNC_FULFILLED](state) {
    state.deepCloudSyncPending = false;
  },
  [types.DEEPCLOUD_SYNC_ERROR](state) {
    state.deepCloudSyncPending = false;
  },

  // Remove deepcloud connection
  [types.REMOVE_DEEPCLOUD_CONNECTION_PENDING](state) {
    state.removeDeepCloudConnectionPending = true;
  },
  [types.REMOVE_DEEPCLOUD_CONNECTION_FULFILLED](state) {
    state.removeDeepCloudConnectionPending = false;
  },
  [types.REMOVE_DEEPCLOUD_CONNECTION_ERROR](state) {
    state.removeDeepCloudConnectionPending = false;
  },
};
