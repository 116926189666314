import { useGetEntity } from '@/stores/composables/use-get-entity';
import type { ReadHandler } from '@/stores/interfaces/crud';

export const useRequireEntity = <R = never>(
  handler: ReadHandler<R>,
  initialState?: R,
) => {
  const getEntity = useGetEntity(handler, initialState);

  async function requireEntity() {
    if (!getEntity.isLoading.value && !getEntity.hasLoaded.value) {
      await getEntity.getEntity();
    }

    return true;
  }

  return {
    ...getEntity,
    requireEntity,
  };
};
