import type { Payload } from '@/api/interfaces/payload';
import { useEntities } from '@/stores/composables/use-entities';
import { useLoadable } from '@/stores/composables/use-loadable';
import { useStatusCode } from '@/stores/composables/use-status-code';
import type { AxiosPromise } from 'axios';

export const useGetEntitiesV2 = <TArgs extends unknown[], TResponse>(
  handler: (...args: TArgs) => { promise: AxiosPromise<Payload<Array<TResponse>>>; abortController: AbortController },
) => {
  const { isLoading, hasLoaded, abortController, cancel } = useLoadable();
  const { entities } = useEntities<TResponse>();
  const { statusCode } = useStatusCode();

  async function getEntities(...args: TArgs) {
    cancel();

    const { abortController: controller, promise } = handler(...args);
    abortController.value = controller;

    try {
      isLoading.value = true;

      const response = await promise;
      entities.value = response.data.data;
      statusCode.value = response.status;
      hasLoaded.value = true;
      isLoading.value = false;

      return response;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    hasLoaded,
    entities,
    statusCode,
    cancel,
    getEntities,
  };
};
