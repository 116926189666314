import axios from 'axios';

const FETCH_DEEPV_TEMPLATES_PENDING = 'FETCH_DEEPV_TEMPLATES_PENDING';
const FETCH_DEEPV_TEMPLATES_FULFILLED = 'FETCH_DEEPV_TEMPLATES_FULFILLED';
const FETCH_DEEPV_TEMPLATES_ERROR = 'FETCH_DEEPV_TEMPLATES_ERROR';

const FETCH_DEEPV_DOCUMENTS_PENDING = 'FETCH_DEEPV_DOCUMENTS_PENDING';
const FETCH_DEEPV_DOCUMENTS_FULFILLED = 'FETCH_DEEPV_DOCUMENTS_FULFILLED';
const FETCH_DEEPV_DOCUMENTS_ERROR = 'FETCH_DEEPV_DOCUMENTS_ERROR';

const DELETE_DEEPV_PENDING = 'DELETE_DEEPV_PENDING';
const DELETE_DEEPV_FULFILLED = 'DELETE_DEEPV_FULFILLED';
const DELETE_DEEPV_ERROR = 'DELETE_DEEPV_ERROR';

const STORE_DEEPV_PENDING = 'STORE_DEEPV_PENDING';
const STORE_DEEPV_FULFILLED = 'STORE_DEEPV_FULFILLED';
const STORE_DEEPV_ERROR = 'STORE_DEEPV_ERROR';

const RESET_DEEPV = 'RESET_DEEPV';

export default {
  namespaced: true,
  state: {
    fetchDeepVTemplatesPending: false,
    fetchDeepVDocumentsPending: false,
    storeDeepVPending: false,
    deleteDeepVPending: false,
    noDeepVData: false,
    deepVTemplates: [],
    deepVDocuments: [],
    createdDeepV: {},
  },
  mutations: {
    // FETCH_DEEPV_TEMPLATES
    [FETCH_DEEPV_TEMPLATES_PENDING](state) {
      state.fetchDeepVTemplatesPending = true;
    },
    [FETCH_DEEPV_TEMPLATES_FULFILLED](state, { data }) {
      state.deepVTemplates = [...data];
      state.fetchDeepVTemplatesPending = false;
    },
    [FETCH_DEEPV_TEMPLATES_ERROR](state) {
      state.fetchDeepVTemplatesPending = false;
    },
    // FETCH_DEEPV_DOCUMENTS
    [FETCH_DEEPV_DOCUMENTS_PENDING](state) {
      state.fetchDeepVDocumentsPending = true;
    },
    [FETCH_DEEPV_DOCUMENTS_FULFILLED](state, { data }) {
      state.deepVDocuments = [...data];
      state.fetchDeepVDocumentsPending = false;
    },
    [FETCH_DEEPV_DOCUMENTS_ERROR](state) {
      state.fetchDeepVDocumentsPending = false;
    },
    // STORE_DEEPV
    [STORE_DEEPV_PENDING](state) {
      state.storeDeepVPending = true;
    },
    [STORE_DEEPV_FULFILLED](state, { data }) {
      state.createdDeepV = {};
      if (data.id == null) {
        state.noDeepVData = true;
      }
      state.createdDeepV = data;
      state.storeDeepVPending = false;
    },
    [STORE_DEEPV_ERROR](state) {
      state.storeDeepVPending = false;
      state.storeDeepVError = true;
    },
    // DELETE_DEEPV
    [DELETE_DEEPV_PENDING](state) {
      state.deleteDeepVPending = true;
    },
    [DELETE_DEEPV_FULFILLED](state) {
      state.deleteDeepVPending = false;
    },
    [DELETE_DEEPV_ERROR](state) {
      state.deleteDeepVPending = false;
    },
    // RESET_DEEPV
    [RESET_DEEPV](state) {
      state.createdDeepV = {};
      state.noDeepVData = false;
    },
  },
  actions: {
    async fetchDeepVTemplates({ rootState, commit }) {
      commit(FETCH_DEEPV_TEMPLATES_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/deepv/templates`);
        commit(FETCH_DEEPV_TEMPLATES_FULFILLED, response);
      } catch (error) {
        commit(FETCH_DEEPV_TEMPLATES_ERROR, error);
        console.error(error);
      }
    },
    async fetchDeepVDocuments({ rootState, commit }) {
      commit(FETCH_DEEPV_DOCUMENTS_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/deepv/documents`);
        commit(FETCH_DEEPV_DOCUMENTS_FULFILLED, response);
      } catch (error) {
        commit(FETCH_DEEPV_DOCUMENTS_ERROR, error);
        console.error(error);
      }
    },
    async deleteDeepV({ rootState, commit, dispatch }, id) {
      commit(DELETE_DEEPV_PENDING);
      try {
        const response = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/deepv/documents/${id}`);
        commit(DELETE_DEEPV_FULFILLED, response);
        dispatch('fetchDeepVDocuments');
      } catch (error) {
        commit(DELETE_DEEPV_ERROR, error);
        console.error(error);
      }
    },
    async storeDeepV({ rootState, commit, dispatch }, data) {
      commit(STORE_DEEPV_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/deepv/documents`, data);
        commit(STORE_DEEPV_FULFILLED, response);
        dispatch('fetchDeepVDocuments');
      } catch (error) {
        commit(STORE_DEEPV_ERROR, error);
        if (error.data && error.data.data.error && error.data.data.error) {
          dispatch('messages/showMessage', { text: error.data.data.error, color: 'error' }, { root: true });
        }
      }
    },
    resetCreatedDeepV({ commit }) {
      commit(RESET_DEEPV);
    },
  },
};
