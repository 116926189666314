import { acceptHMRUpdate, defineStore } from 'pinia';
import { getModules } from '@/api/definitions/modules';
import { useRequireEntities } from '@/stores/composables/use-require-entities';
import type { Account } from '@/api/interfaces/user/account';
import type { Module } from '@/api/interfaces/definitions/module';
import { computed } from 'vue';
import store from '@/store';

const paidSubscriptions: Array<Module> = [
  'Basic',
  'Pro',
  'ProFree',
  'EnterpriseM',
  'EnterpriseL',
  'EnterpriseXL',
  'EnterpriseXXL',
  'EnterpriseInfinity',
];

const swiss21Subscriptions: Array<Module> = [
  'Starter',
  'Basic',
  'Pro',
  'ProFree',
  'EnterpriseM',
  'EnterpriseL',
  'EnterpriseXL',
  'EnterpriseXXL',
  'EnterpriseInfinity',
];

const affectedModules: Array<Module> = [
  'AdvancedAccounting',
  'AbaNet',
  'Sumup',
  'AbaClock',
];

const account = computed<Account>(() => store.state.definitions.account.account);
const isDeepBoxAccount = computed(() => account.value.is_deepbox_account);

export const useModulesStore = defineStore('modules', () => {
  const requireEntities = useRequireEntities(getModules);

  function hasModulesOr(modules: Array<Module>) {
    if (!modules.length) {
      return false;
    }
    if (isDeepBoxAccount.value) { return true; }
    if (requireEntities.entities.value) {
      if (requireEntities.entities.value.some((activeModule) => paidSubscriptions.includes(activeModule))) {
        return true;
      }
      return !!modules.find((module) => requireEntities.entities.value.includes(module));
    }
    return false;
  }

  function hasModule(module: Module) {
    if (requireEntities.entities.value) {
      if (affectedModules.includes(module)
        && requireEntities.entities.value.some((activeModule) => paidSubscriptions.includes(activeModule))
      ) {
        return true;
      }
      return requireEntities.entities.value.includes(module);
    }
    return false;
  }

  const isAmidAccount = computed(() => requireEntities.entities.value.includes('AMIDAccount'));

  const isNewSwiss21Subscription = computed(() => requireEntities.entities.value.find(
    (item) => swiss21Subscriptions.includes(item),
  ));

  const selectedSwiss21Subscription = computed(() => {
    if (!isNewSwiss21Subscription.value) {
      return undefined;
    }

    let selected = '';
    swiss21Subscriptions.reverse().forEach((item) => {
      if (!selected && requireEntities.entities.value.includes(item)) {
        selected = item;
      }
      return item;
    });
    return selected;
  });

  const isBehindPaywall = computed(() => !hasModulesOr(['AdvancedAccounting', 'AMIDAccount']));

  const hasPaidSubscription = computed(() => {
    if (isDeepBoxAccount.value) {
      return true;
    }
    if (!requireEntities.entities.value) {
      return false;
    }
    const paidSubscriptionsOrAmid = [...paidSubscriptions, 'AMIDAccount'];

    return requireEntities.entities.value.some((activeModule) => paidSubscriptionsOrAmid.includes(activeModule));
  });

  const hasProSubscription = computed(() => paidSubscriptions.slice(1).some((module) => hasModule(module)));

  return {
    ...requireEntities,
    isBehindPaywall,
    hasPaidSubscription,
    hasModulesOr,
    hasModule,
    isAmidAccount,
    isNewSwiss21Subscription,
    selectedSwiss21Subscription,
    hasProSubscription,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModulesStore, import.meta.hot));
}
