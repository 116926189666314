import type { TopLevelRoutePermission } from '@/api/interfaces/definitions/permission';
import type { User } from '@/api/interfaces/user';
import { useMoment } from '@/helpers/moment';
import type { NavigationItem } from '@/helpers/navigation/navigation-items';
import { NAVIGATION_ITEMS } from '@/helpers/navigation/navigation-items';
import { usePermissionsStore } from '@/stores/definitions/permissions';
import { useUserStore } from '@/stores/users/user';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import type { RouteRecord } from 'vue-router';
import { useI18n } from '@/helpers/i18n';

interface NavigationItemParams {
  employeeUuid?: Uuid | null;
  hasFullAccess: boolean;
}

const i18n = useI18n();

const employeeNavigationItem: NavigationItem = {
  title: computed(() => i18n.tc('texts.employees')),
  route: 'employee',
  params: {},
};

const timeTrackingNavigationItem: NavigationItem = {
  title: computed(() => i18n.tc('texts.time_and_activity_tracking')),
  route: 'time-tracking',
  params: {},
};

function navigationItemByEmployee(navigationItem: NavigationItem, employeeUuid?: Uuid | null): NavigationItem {
  const moment = useMoment();
  let { params } = navigationItem;

  if (employeeUuid) {
    params = { employeeUuid };
  }

  return {
    ...navigationItem,
    params: {
      ...params,
      date: moment().format('YYYY-MM-DD'),
    },
  };
}

export const useNavigation = (routes: RouteRecord[]) => {
  const permissionsStore = usePermissionsStore();
  const userStore = useUserStore();
  const employeeUuid = computed<User['employee_uuid'] | null>(() => userStore.entity?.employee_uuid ?? null);

  const navigationByEmployee = (params: NavigationItemParams) => computed(
    () => NAVIGATION_ITEMS.map((navigationItem) => {
      if (navigationItem.route === 'time') {
        const timeTrackingChildren = [];

        if (!params.hasFullAccess) {
          timeTrackingChildren.unshift(
            navigationItemByEmployee(employeeNavigationItem, params.employeeUuid),
          );
        }

        if (params.employeeUuid) {
          timeTrackingChildren.unshift(
            navigationItemByEmployee(timeTrackingNavigationItem, params.employeeUuid),
          );
        } else {
          timeTrackingChildren.unshift(
            navigationItemByEmployee({
              ...timeTrackingNavigationItem,
              route: 'time-tracking-default',
            }, params.employeeUuid),
          );
        }

        return {
          ...navigationItem,
          children: [
            ...timeTrackingChildren,
            ...navigationItem.children ?? [],
          ],
        };
      }

      return navigationItem;
    }),
  );

  const permittedNavigation = (
    navigationItems: Array<NavigationItem>,
    topLevelRoute?: TopLevelRoutePermission,
  ): ComputedRef<Array<NavigationItem>> => computed(() => navigationItems
    .filter((navigationItem) => {
      if (topLevelRoute) {
        return permissionsStore.hasTopLevelRoutePermission(topLevelRoute)
          && permissionsStore.hasRoutePermission(navigationItem.route);
      }
      return permissionsStore.hasTopLevelRoutePermission(navigationItem.route as TopLevelRoutePermission)
        && permissionsStore.hasRoutePermission(navigationItem.route);
    })
    .map((navigationItem) => {
      if (navigationItem.children) {
        return {
          ...navigationItem,
          children: permittedNavigation(
            navigationItem.children,
            topLevelRoute ?? navigationItem.route as TopLevelRoutePermission,
          ).value,
        } as NavigationItem;
      }
      return navigationItem;
    }));

  const activatedNavigation = (
    navigationItems: NavigationItem[],
    activeRoutes: RouteRecord[],
  ): NavigationItem[] => (activeRoutes.length ? navigationItems
    .map<NavigationItem>((navigationItem) => {
      if (navigationItem.route === activeRoutes[0].name && navigationItem.children) {
        return {
          ...navigationItem,
          active: ref(true),
          children: activeRoutes.length > 1
            ? activatedNavigation(navigationItem.children, activeRoutes.slice(1))
            : navigationItem.children,
        };
      }
      return navigationItem;
    }) : navigationItems);

  return computed<Array<NavigationItem>>(() => activatedNavigation(
    permittedNavigation(
      navigationByEmployee({
        employeeUuid: employeeUuid.value,
        hasFullAccess: permissionsStore.hasFullAccess,
      }).value,
    ).value,
    routes,
  ));
};
