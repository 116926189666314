import { useEntity } from '@/stores/composables/use-entity';
import { useLoadable } from '@/stores/composables/use-loadable';
import { useStatusCode } from '@/stores/composables/use-status-code';
import type { ReadHandler } from '@/stores/interfaces/crud';

export const useGetEntity = <R = never>(
  handler: ReadHandler<R>,
  initialState?: R,
) => {
  const { isLoading, hasLoaded, abortController, cancel } = useLoadable();
  const { entity } = useEntity<R>(initialState);
  const { statusCode } = useStatusCode();

  async function getEntity() {
    isLoading.value = true;
    cancel();
    abortController.value = new AbortController();

    try {
      const response = await handler(abortController.value);
      entity.value = response.data.data;
      statusCode.value = response.status;
      hasLoaded.value = true;
      return response;
    } finally {
      // cancel();
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    hasLoaded,
    entity,
    statusCode,
    cancel,
    getEntity,
  };
};
