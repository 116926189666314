<template>
  <v-slide-y-transition>
    <v-snackbar
      v-if="paidSubscription"
      :timeout="-1"
      :value="true"
      app
      color="info"
      content-class="black--text"
      multi-line
      top
    >
      {{ $t('texts.swiss21_needs_paid_subscription_or_amid') }}
    </v-snackbar>
  </v-slide-y-transition>
</template>
<script lang="ts" setup>
import { useNotificationStore } from '@/stores/user-interface/notification';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const { paidSubscription } = storeToRefs(useNotificationStore());

function disablePaidSubscription() {
  setTimeout(() => {
    paidSubscription.value = false;
  }, 5000);
}

watch(
  () => paidSubscription.value,
  () => {
    if (paidSubscription.value) {
      disablePaidSubscription();
    }
  },
  {
    immediate: true,
  },
);

</script>
