import actions from '@/store/modules/accounting/opening-balance/actions';
import mutations from '@/store/modules/accounting/opening-balance/mutations';
import initialState from '@/store/modules/accounting/opening-balance/state';

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
};
