import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialLoading: true,
    initialError: false,
    importInvoicePending: false,
    storeImportedInvoicePending: false,
    fetchAddressPending: false,
    fetchSingleActionsPending: false,
    addresses: [],
    importedInvoice: {},
    invoiceQueue: [],
    address: {},
    duplicateAddresses: [],
    nextNumber: '',
    singleActions: {},
    batchEdit: {
      list: [],
    },
  },
  actions,
  mutations,
};
