import axios from 'axios';
import Vue from 'vue';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }, id) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await dispatch('fetchBankAccount', id);
      await dispatch('fetchBankInformation', bankAccount.data.iban);

      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchBankAccount({ rootState, commit }, id) {
    commit(types.FETCH_BANK_ACCOUNT_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/finances/banking/accounts/${id}`);
      commit(types.FETCH_BANK_ACCOUNT_FULFILLED, response);
      return response;
    } catch (error) {
      commit(types.FETCH_BANK_ACCOUNT_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storeBankAccount({ rootState, commit, dispatch }, form) {
    commit(types.STORE_BANK_ACCOUNT_PENDING);
    try {
      if (form.is_inactive) {
        try {
          await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/bankaccounts/checkusage/${form.id}`);
        } catch (error) {
          commit(types.STORE_BANK_ACCOUNT_ERROR, error);
          if (error.data && error.data.message) {
            dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
          } else {
            dispatch('messages/showMessage', { key: 'bank_account_save_failed', color: 'error' }, { root: true });
          }
          return Promise.reject(error);
        }
      }
      const method = form.id ? 'PATCH' : 'POST';
      const url = form.id
        ? `/accounts/${rootState.definitions.account.account.uuid}/finances/banking/accounts/${form.id}`
        : `/accounts/${rootState.definitions.account.account.uuid}/finances/banking/accounts`;
      const response = await axios({ url, method, data: form });
      await dispatch('definitions/fetchBankAccounts', null, { root: true });
      commit(types.STORE_BANK_ACCOUNT_FULFILLED, response);
      dispatch('messages/showMessage', {
        key: form.id ? 'updated_bank_account' : 'created_bank_account',
        color: 'success',
      }, { root: true });
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.STORE_BANK_ACCOUNT_ERROR, error);
      if (error.data.errors && Object.keys(error.data.errors).length > 0) {
        dispatch('messages/showMessage', {
          text: error.data.errors[Object.keys(error.data.errors)[0]][0],
          color: 'error',
        }, { root: true });
      } else {
        dispatch('messages/showMessage', { key: 'bank_account_save_failed', color: 'error' }, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async deleteBankAccount({ rootState, commit, dispatch }, id) {
    commit(types.DELETE_BANK_ACCOUNT_PENDING);
    try {
      const response = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/finances/banking/accounts/${id}`);
      commit(types.DELETE_BANK_ACCOUNT_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'bank_account_deleted_success', color: 'success' }, { root: true });
      await dispatch('finances/banking/fetchList', null, { root: true });
      return true;
    } catch (error) {
      commit(types.DELETE_BANK_ACCOUNT_ERROR, error);
      if (error.data.text) {
        dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      } else {
        dispatch('messages/showMessage', { key: 'bank_account_deleted_failed', color: 'error' }, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async fetchBankInformation({ rootState, commit, dispatch }, iban) {
    commit(types.FETCH_BANK_INFORMATION_PENDING);
    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/discovery`;
      const response = await axios.get(`${baseUrl}/${iban}`);
      let payload = {};
      if (response.status >= 200 && response.status < 400) {
        const additional = await axios.get(`${baseUrl}/institute/${iban}`);
        payload = Object.assign(additional.data, response.data);
      }
      commit(types.FETCH_BANK_INFORMATION_FULFILLED, payload);
      return Promise.resolve(payload);
    } catch (error) {
      commit(types.FETCH_BANK_INFORMATION_ERROR);
      if (error.data.message === 'QRIBAN_SUPPLIED') {
        dispatch('messages/showMessage', { key: 'input_is_qriban', color: 'error' }, { root: true });
      } else if (error.data && Object.keys(error.data.errors).length > 0) {
        dispatch('messages/showMessage', {
          text: error.data.errors[Object.keys(error.data.errors)[0]][0],
          color: 'error',
        }, { root: true });
      } else {
        dispatch('messages/showMessage', { key: 'bank_account_info_faild', color: 'error' }, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async loginEbanking({
    rootState, state, commit, dispatch,
  }, form) {
    if (state.loginEbankingConfirmationSuccessful) {
      return undefined;
    }
    commit(types.LOGIN_EBANKING_PENDING);
    try {
      let response = {};
      const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/login`;
      if (state.loginEbankingSuccessful || state.loginEbankingChangePassword || state.loginEbankingChoose) {
        const headers = {
          'X-Session-Id': state.ebankingLogin.sessionId,
        };
        form.transportData = state.ebankingLogin.transportData;
        response = await axios.post(url, form, { headers });
      } else {
        response = await axios.post(url, form);
      }
      if (response.data.state === 'NOT_SUPPORTED' || response.data.state === 'KEYFILE') {
        commit(types.LOGIN_EBANKING_ERROR, response);
        dispatch('messages/showMessage', { key: 'ebanking_login_not_supported', color: 'error' }, { root: true });
        return Promise.reject(response);
      }
      // Login step completed
      commit(types.LOGIN_EBANKING_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      if (!state.loginEbankingSuccessful) {
        if (error.data.status === 403) {
          dispatch('messages/showMessage', {
            key: 'bank_account_contract_password_error',
            color: 'error',
          }, { root: true });
        } else {
          dispatch('messages/showMessage', { key: 'bank_account_login_failed', color: 'error' }, { root: true });
        }
      }
      if (state.loginEbankingSuccessful && !state.loginEbankingConfirmationSuccessful) {
        dispatch('messages/showMessage', { key: 'bank_account_confirm_failed', color: 'error' }, { root: true });
      }
      commit(types.LOGIN_EBANKING_ERROR, error);
      return Promise.reject(error);
    }
  },
  setSession({ commit }, data) {
    commit(types.LOGIN_EBANKING_FULFILLED, data);
    return Promise.resolve(true);
  },
  async blinkLogin({ rootState, commit }, payload) {
    commit(types.BLINK_LOGIN_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/blink/login`, payload);
      commit(types.BLINK_LOGIN_FULFILLED, response);
      window.location = response.data.url;
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      return true;
    } catch (error) {
      commit(types.BLINK_LOGIN_ERROR, error);
      return Promise.reject(error);
    }
  },
  async oauthLogin({ rootState, commit, dispatch }, payload) {
    commit(types.OAUTH_LOGIN_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/oauthlogin`, payload);
      commit(types.OAUTH_LOGIN_FULFILLED, response);
      window.location = response.data.url;
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      return true;
    } catch (error) {
      commit(types.OAUTH_LOGIN_ERROR, error);
      dispatch('messages/showMessage', { text: 'OAuth Login fehlgeschlagen', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async oauthLogout({ rootState, commit, dispatch }, bankId) {
    commit(types.OAUTH_LOGOUT_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/oauthlogout`, { bankId });
      commit(types.OAUTH_LOGOUT_FULFILLED, response);
      dispatch('finances/banking/fetchList', {}, { root: true });
      return true;
    } catch (error) {
      commit(types.OAUTH_LOGOUT_ERROR, bankId);
      return Promise.reject(error);
    }
  },
  async storeOauthAuthorization({ rootState, commit, dispatch }, payload) {
    commit(types.STORE_OAUTH_AUTHORIZATION_PENDING);
    try {
      // csrf is awesome
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
      const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/accounts/${payload.id}/authorization`, payload);
      commit(types.STORE_OAUTH_AUTHORIZATION_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.STORE_OAUTH_AUTHORIZATION_ERROR, error);
      dispatch('messages/showMessage', { text: 'OAuth Authorization fehlgeschlagen', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async storeOauthCallbackBlink({ rootState, commit, dispatch }, payload) {
    commit(types.STORE_OAUTH_CALLBACK_BLINK_PENDING);
    try {
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
      const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/blink/callback`, payload);
      commit(types.STORE_OAUTH_CALLBACK_BLINK_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.STORE_OAUTH_CALLBACK_BLINK_ERROR, error);
      dispatch('messages/showMessage', { text: 'OAuth Authorization fehlgeschlagen', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async pushEbankingPayments({
    rootState, state, commit, dispatch,
  }, form) {
    commit(types.PUSH_EBANKING_PAYMENTS_PENDING);
    try {
      const headers = {
        'X-Session-Id': state.ebankingLogin.sessionId,
      };
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/payments/${form.id}`, form, { headers });
      if (response.data && response.data.state && response.data.state !== 'SUCCESS') {
        Vue.prototype.$bus.$emit('EBANKING_CONFIRM_PAYMENTS', { response: response.data, id: form.id });
        return true;
      }
      if (response.status === 204) {
        commit(types.PUSH_EBANKING_PAYMENTS_FULFILLED, { code: response.status });
        dispatch('messages/showMessage', { key: 'no_payments_found_to_upload', color: 'success' }, { root: true });
      } else {
        commit(types.PUSH_EBANKING_PAYMENTS_FULFILLED, response.data);
        dispatch('messages/showMessage', {
          key: 'banking_uploaded_payments_successful',
          color: 'success',
        }, { root: true });
      }
      return true;
    } catch (error) {
      commit(types.PUSH_EBANKING_PAYMENTS_ERROR, error);
      dispatch('messages/showMessage', { key: 'payments_upload_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchEbankingTransactions({
    rootState, state, commit, dispatch,
  }, form) {
    commit(types.FETCH_EBANKING_TRANSACTIONS_PENDING);
    try {
      const headers = {
        'X-Session-Id': state.ebankingLogin.sessionId,
      };
      const config = { params: form, headers };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/transactions/${form.id}`, config);
      if (response.status === 204) {
        commit(types.FETCH_EBANKING_TRANSACTIONS_FULFILLED, { code: response.status });
        dispatch('messages/showMessage', {
          key: 'banking_fetched_transactions_empty',
          color: 'success',
        }, { root: true });
      } else {
        commit(types.FETCH_EBANKING_TRANSACTIONS_FULFILLED, response.data);
        dispatch('messages/showMessage', {
          key: 'banking_fetched_transactions_successful',
          color: 'success',
        }, { root: true });
      }
      return true;
    } catch (error) {
      commit(types.FETCH_EBANKING_TRANSACTIONS_ERROR, error);
      dispatch('messages/showMessage', { key: 'banking_fetched_transactions_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async syncEbanking({ commit, dispatch }, form) {
    // TODO combine with deepcloud syncs (needs specific login before)
    commit(types.SYNC_EBANKING_PENDING, form.id);
    try {
      await dispatch('pushEbankingPayments', form);
      await dispatch('fetchEbankingTransactions', form);
      commit(types.SYNC_EBANKING_FULFILLED, form.id);
      return true;
    } catch (error) {
      commit(types.SYNC_EBANKING_ERROR, form.id);
      return Promise.reject(error);
    }
  },
  async confirmEbankingPayments({
    rootState, state, commit, dispatch,
  }, { form, id }) {
    commit(types.CONFIRM_EBANKING_PAYMENTS_PENDING);
    try {
      const headers = {
        'X-Session-Id': state.ebankingLogin.sessionId,
      };
      const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/payments/${id}/confirm`;
      const response = await axios.post(url, form, { headers });
      commit(types.CONFIRM_EBANKING_PAYMENTS_FULFILLED, response);
      dispatch('messages/showMessage', {
        key: 'banking_uploaded_payments_successful',
        color: 'success',
      }, { root: true });
      return true;
    } catch (error) {
      commit(types.CONFIRM_EBANKING_PAYMENTS_ERROR, error);
      dispatch('messages/showMessage', { key: 'payments_upload_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async logoutEbanking({
    rootState, state, commit, dispatch,
  }) {
    commit(types.LOGOUT_EBANKING_PENDING);
    try {
      const headers = {
        'X-Session-Id': state.ebankingLogin.sessionId,
      };
      const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/logout`;
      const response = await axios.post(url, null, { headers });
      commit(types.LOGOUT_EBANKING_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'banking_ebanking_logged_out', color: 'success' }, { root: true });
      return true;
    } catch (error) {
      commit(types.LOGOUT_EBANKING_ERROR, error);
      dispatch('messages/showMessage', { key: 'banking_ebanking_logged_out_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  resetEbanking({ commit }) {
    commit(types.RESET_EBANKING);
  },
  async validateBicNumber({ commit }, { bicNumber }) {
    commit(types.VALIDATE_BIC_NUMBER_PENDING);
    try {
      await axios.get(`/bankaccounts/checkbic/${bicNumber}`);
      commit(types.VALIDATE_BIC_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_BIC_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  async checkAccountNumber({ commit }, value) {
    commit(types.CHECK_ACCOUNT_NUMBER_PENDING);
    try {
      const response = await axios.get(`/bankaccounts/checknumbertype/${value}`);
      commit(types.CHECK_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve(response.data.type);
    } catch (error) {
      commit(types.CHECK_ACCOUNT_NUMBER_ERROR);
      return Promise.reject(error);
    }
  },
  async validateAccountNumber({ commit }, { accountNumber, isEsr }) {
    commit(types.VALIDATE_ACCOUNT_NUMBER_PENDING);
    try {
      await axios.get(`/bankaccounts/checknumber/${accountNumber}`, { params: { isEsr: !!isEsr } });
      commit(types.VALIDATE_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  async validateQRAccountNumber({ commit }, { number }) {
    commit(types.VALIDATE_QR_ACCOUNT_NUMBER_PENDING);
    try {
      await axios.get(`/bankaccounts/checkqrnumber/${number}?isBesr=false`);
      commit(types.VALIDATE_QR_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_QR_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  async validateQRBesrId({ commit }, { besrId }) {
    commit(types.VALIDATE_QR_BESR_ID_PENDING);
    try {
      await axios.get(`/bankaccounts/checkqrnumber/${besrId}?isBesr=true`);
      commit(types.VALIDATE_QR_BESR_ID_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_QR_BESR_ID_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  timeout() {
    return new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
  },
};
export default actions;
