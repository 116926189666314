import axios from 'axios';
import * as types from './types';

export default {
  searchForProductGroups({ rootState, commit }, { search, abortController }) {
    const controller = abortController || new AbortController();
    commit(types.SEARCH_PRODUCT_GROUP);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/product_groups`, {
      signal: controller.signal,
      params: {
        search: [search],
        per_page: 50,
        direction: 'ASC',
      },
    }).then((response) => {
      commit(types.SEARCH_PRODUCT_GROUP_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.SEARCH_PRODUCT_GROUP_CANCEL);
        return Promise.reject(error);
      }
      commit(types.SEARCH_PRODUCT_GROUP_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
