import * as types from './types';

const mutations = {
  [types.FETCH_PRODUCT_GROUP_PENDING](state) {
    state.productGroupPending = true;
  },
  [types.FETCH_PRODUCT_GROUP_FULFILLED](state, { data }) {
    state.productGroup = data;
    state.productGroupPending = false;
  },
  [types.FETCH_PRODUCT_GROUP_ERROR](state) {
    state.productGroupPending = false;
  },
  [types.STORE_PRODUCT_GROUP_PENDING](state) {
    state.productGroupStorePending = true;
  },
  [types.STORE_PRODUCT_GROUP_FULFILLED](state) {
    state.productGroupStorePending = false;
  },
  [types.STORE_PRODUCT_GROUP_ERROR](state) {
    state.productGroupStorePending = false;
  },
  [types.VALIDATE_GROUP_NUMBER_PENDING](state) {
    state.productGroupNumberValidationPending = true;
  },
  [types.VALIDATE_GROUP_NUMBER_FULFILLED](state) {
    state.productGroupNumberValidationPending = false;
  },
  [types.RESET_PRODUCT_GROUP](state) {
    state.productGroup = {};
  },
  [types.ARCHIVE_PRODUCT_GROUP_PENDING](state) {
    state.productGroupArchivePending = true;
  },
  [types.ARCHIVE_PRODUCT_GROUP_FULFILLED](state) {
    state.productGroupArchivePending = false;
  },
  [types.ARCHIVE_PRODUCT_GROUP_ERROR](state) {
    state.productGroupArchivePending = false;
  },
  [types.RESTORE_PRODUCT_GROUP_PENDING](state) {
    state.productGroupRestorePending = true;
  },
  [types.RESTORE_PRODUCT_GROUP_FULFILLED](state) {
    state.productGroupRestorePending = false;
  },
  [types.RESTORE_PRODUCT_GROUP_ERROR](state) {
    state.productGroupRestorePending = false;
  },
};

export default mutations;
