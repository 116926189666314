/* global moment */
import { setLanguage } from '@/helpers/i18n';
import axios from 'axios';
import { Validator } from 'vee-validate';
import * as types from './types';

export default {
  fetchAccount({ commit }, { abortController, accountUuid }) {
    commit(types.FETCH_ACCOUNT);
    const controller = abortController || new AbortController();
    return axios.get(`/accounts/${accountUuid}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_FAILURE, error);
      return Promise.reject(error);
    });
  },
  storeAccount({ commit }, { form }) {
    commit(types.STORE_ACCOUNT);
    return axios.patch(`/settings/accounts/${form.uuid}`, form)
      .then(
        (response) => {
          commit(types.STORE_ACCOUNT_SUCCESS, response);
          return Promise.resolve(response.data);
        },
      ).catch(
        (error) => {
          commit(types.STORE_ACCOUNT_FAILURE);
          return Promise.reject(error);
        },
      );
  },
  deleteAccount({ state }, { form }) {
    return axios.delete(`/settings/accounts/${state.account.uuid}`, form);
  },
  getDefaultAccount({ rootState }) {
    if (rootState.user.user.accounts.length === 0) {
      return {};
    }
    let selectedAccount = {};
    const sessionUuid = sessionStorage.getItem('accountUuid');
    const storageUuid = localStorage.getItem('accountUuid');
    const sessionAppInstanceUuid = sessionStorage.getItem('appInstanceUuid');
    const storageAppInstanceUuid = localStorage.getItem('appInstanceUuid');
    const predefinedUuid = sessionUuid || storageUuid;
    const predefinedAppInstanceUuid = sessionAppInstanceUuid || storageAppInstanceUuid;
    let appInstanceAccount;
    let predefinedAccount;
    if (predefinedAppInstanceUuid) {
      appInstanceAccount = rootState.user.user.accounts
        .find((account) => account.app_instance_uuid === predefinedAppInstanceUuid);
      sessionStorage.removeItem('appInstanceUuid');
    }
    if (predefinedUuid && predefiedUuid !== 'undefined') {
      predefinedAccount = rootState.user.user.accounts.find((account) => account.uuid === predefinedUuid);
    }
    selectedAccount = appInstanceAccount
      || predefinedAccount
      || rootState.user.user.accounts.find((account) => account.current_selected === true)
      || rootState.user.user.accounts.find((account) => account.is_owner === true)
      || rootState.user.user.accounts[0];
    setLanguage(selectedAccount.locale);
    Validator.localize(selectedAccount.locale);
    moment.locale(selectedAccount.locale);
    sessionStorage.setItem('accountUuid', selectedAccount.uuid);
    sessionStorage.setItem('appInstanceUuid', selectedAccount.app_instance_uuid);
    localStorage.setItem('accountUuid', selectedAccount.uuid);
    localStorage.setItem('appInstanceUuid', selectedAccount.app_instance_uuid);
    return selectedAccount;
  },

  fetchAccountInvoice({ rootState, commit }, abortController) {
    commit(types.FETCH_ACCOUNT_INVOICE);
    const controller = abortController || new AbortController();
    return axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/invoices`, {
      signal: controller.signal,
    }).then(
      (response) => {
        commit(types.FETCH_ACCOUNT_INVOICE_SUCCESS, response);
        return Promise.resolve(response.data);
      },
    ).catch(
      (error) => {
        if (axios.isCancel(error)) {
          commit(types.FETCH_ACCOUNT_INVOICE_CANCEL);
          return Promise.reject(error);
        }
        commit(types.FETCH_ACCOUNT_INVOICE_FAILURE);
        return Promise.reject(error);
      },
    );
  },
  storeAccountInvoice({ state, commit }, { invoice }) {
    commit(types.STORE_ACCOUNT_INVOICE);
    return axios.patch(`/settings/accounts/${state.account.uuid}/invoices`, invoice)
      .then((response) => {
        commit(types.STORE_ACCOUNT_INVOICE_SUCCESS, response);
        return Promise.resolve(response.data);
      }).catch(
        (error) => {
          commit(types.STORE_ACCOUNT_INVOICE_FAILURE);
          return Promise.reject(error);
        },
      );
  },

  fetchAccountInvoicePrices({ state, commit }, abortController) {
    commit(types.FETCH_ACCOUNT_INVOICE_PRICES);
    const controller = abortController || new AbortController();
    return axios.get(`/settings/accounts/${state.account.uuid}/invoices/prices`, {
      signal: controller.signal,
    }).then(
      (data) => {
        commit(types.FETCH_ACCOUNT_INVOICE_PRICES_SUCCESS, data);
        return Promise.resolve(data.data);
      },
    ).catch(
      (error) => {
        if (axios.isCancel(error)) {
          commit(types.FETCH_ACCOUNT_INVOICE_PRICES_CANCEL);
          return Promise.reject(error);
        }
        commit(types.FETCH_ACCOUNT_INVOICE_PRICES_FAILURE);
        return Promise.reject(error);
      },
    );
  },
  storeAccountInvoicePrices({ state, commit }, { invoice }) {
    commit(types.STORE_ACCOUNT_INVOICE_PRICES);
    return axios.patch(`/settings/accounts/${state.account.uuid}/invoices/prices`, invoice)
      .then((response) => {
        commit(types.STORE_ACCOUNT_INVOICE_PRICES_SUCCESS, response);
        return Promise.resolve(response.data);
      }).catch(
        (error) => {
          commit(types.STORE_ACCOUNT_INVOICE_PRICES_FAILURE);
          return Promise.reject(error);
        },
      );
  },
  fetchQuoteNumber({ rootState, commit }, abortController) {
    commit(types.FETCH_ACCOUNT_QUOTE);
    const controller = abortController || new AbortController();
    return axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/quotes`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_QUOTE_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_QUOTE_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_QUOTE_FAILURE);
      return Promise.reject(error);
    });
  },
  storeQuoteNumber({ rootState, commit }, { quote }) {
    commit(types.STORE_ACCOUNT_QUOTE);
    return axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/quotes`, quote)
      .then((response) => {
        commit(types.STORE_ACCOUNT_QUOTE_SUCCESS, response);
        return Promise.resolve(response.data);
      }).catch(
        (error) => {
          commit(types.STORE_ACCOUNT_QUOTE_FAILURE);
          return Promise.reject(error);
        },
      );
  },

  fetchDocuments({ rootState, commit }, abortController) {
    commit(types.FETCH_ACCOUNT_DOCUMENTS, true);
    const controller = abortController || new AbortController();
    return axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/documents`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_DOCUMENTS_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_DOCUMENTS_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_DOCUMENTS_FAILURE);
      return Promise.reject(error);
    });
  },
  storeDocuments({ rootState, commit }, { document }) {
    commit(types.STORE_ACCOUNT_DOCUMENTS);
    return axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/documents`, document)
      .then((response) => {
        commit(types.STORE_ACCOUNT_DOCUMENTS_SUCCESS, response);
        return Promise.resolve(response.data);
      }).catch(
        (error) => {
          commit(types.STORE_ACCOUNT_DOCUMENTS_FAILURE);
          return Promise.reject(error);
        },
      );
  },

  async fetchDesign({ state, commit, rootState }, abortController) {
    if (state.design && Object.keys(state.design).length !== 0) {
      // already Fetched Design Data
      return state.design;
    }
    // else fetch design settings from Backend
    try {
      commit(types.FETCH_ACCOUNT_DESIGN);
      const controller = abortController || new AbortController();
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/invoices/design`, {
        signal: controller.signal,
      });
      commit(types.FETCH_ACCOUNT_DESIGN_SUCCESS, data);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_ACCOUNT_DESIGN_FAILURE);
      return Promise.reject(error);
    }
  },
  async storeDesign({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_ACCOUNT_DESIGN);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/invoices/design`, payload);
      commit(types.STORE_ACCOUNT_DESIGN_SUCCESS, data);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_ACCOUNT_DESIGN_FAILURE);
      return Promise.reject(error);
    }
  },
};
