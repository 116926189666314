export const FETCH_PRODUCT_GROUP = 'FETCH_PRODUCT_GROUP';
export const FETCH_PRODUCT_GROUP_SUCCESS = 'FETCH_PRODUCT_GROUP_SUCCESS';
export const FETCH_PRODUCT_GROUP_FAILURE = 'FETCH_PRODUCT_GROUP_FAILURE';
export const FETCH_PRODUCT_GROUP_CANCEL = 'FETCH_PRODUCT_GROUP_CANCEL';

export const STORE_PRODUCT_GROUP = 'STORE_PRODUCT_GROUP';
export const STORE_PRODUCT_GROUP_SUCCESS = 'STORE_PRODUCT_GROUP_SUCCESS';
export const STORE_PRODUCT_GROUP_FAILURE = 'STORE_PRODUCT_GROUP_FAILURE';

export const VALIDATE_PRODUCT_GROUP_NUMBER = 'VALIDATE_PRODUCT_GROUP_NUMBER';
export const FINISHED_VALIDATING_PRODUCT_GROUP_NUMBER = 'FINISHED_VALIDATING_PRODUCT_GROUP_NUMBER';
