export const FETCH_JOURNAL = 'FETCH_JOURNAL';
export const FETCH_JOURNAL_SUCCESS = 'FETCH_JOURNAL_SUCCESS';
export const FETCH_JOURNAL_FAILURE = 'FETCH_JOURNAL_FAILURE';
export const FETCH_JOURNAL_CANCEL = 'FETCH_JOURNAL_CANCEL';

export const FETCH_MORE_JOURNAL = 'FETCH_MORE_JOURNAL';
export const FETCH_MORE_JOURNAL_SUCCESS = 'FETCH_MORE_JOURNAL_SUCCESS';
export const FETCH_MORE_JOURNAL_FAILURE = 'FETCH_MORE_JOURNAL_FAILURE';
export const FETCH_MORE_JOURNAL_CANCEL = 'FETCH_MORE_JOURNAL_CANCEL';

// FETCH PDF EXPORT
export const FETCH_JOURNAL_PDF_EXPORT = 'FETCH_JOURNAL_PDF_EXPORT';
export const FETCH_JOURNAL_PDF_EXPORT_SUCCESS = 'FETCH_JOURNAL_PDF_EXPORT_SUCCESS';
export const FETCH_JOURNAL_PDF_EXPORT_FAILURE = 'FETCH_JOURNAL_PDF_EXPORT_FAILURE';
export const FETCH_JOURNAL_PDF_EXPORT_CANCEL = 'FETCH_JOURNAL_PDF_EXPORT_CANCEL';

// FETCH XLS EXPORT
export const FETCH_JOURNAL_XLS_EXPORT = 'FETCH_JOURNAL_XLS_EXPORT';
export const FETCH_JOURNAL_XLS_EXPORT_SUCCESS = 'FETCH_JOURNAL_XLS_EXPORT_SUCCESS';
export const FETCH_JOURNAL_XLS_EXPORT_FAILURE = 'FETCH_JOURNAL_XLS_EXPORT_FAILURE';
export const FETCH_JOURNAL_XLS_EXPORT_CANCEL = 'FETCH_JOURNAL_XLS_EXPORT_CANCEL';

export const SET_SEARCH = 'SET_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';

// OLD

// initial data load
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

// asset fetches
export const FETCH_ACCOUNTS_FULFILLED = 'FETCH_ACCOUNTS_FULFILLED';
export const FETCH_TAXES_FULFILLED = 'FETCH_TAXES_FULFILLED';
export const FETCH_JOURNAL_FULFILLED = 'FETCH_JOURNAL_FULFILLED';

// load reload entries
export const FETCH_ENTRIES_PENDING = 'FETCH_ENTRIES_PENDING';
export const FETCH_ENTRIES_FULFILLED = 'FETCH_ENTRIES_FULFILLED';
export const FETCH_ENTRIES_ERROR = 'FETCH_ENTRIES_ERROR';

// fetch journal export
export const FETCH_JOURNAL_EXPORT_PENDING = 'FETCH_JOURNAL_EXPORT_PENDING';
export const FETCH_JOURNAL_EXPORT_FULFILLED = 'FETCH_JOURNAL_EXPORT_FULFILLED';
export const FETCH_JOURNAL_EXPORT_ERROR = 'FETCH_JOURNAL_EXPORT_ERROR';

// update params
export const UPDATE_PARAMS = 'CHANGE_PARAMS';

// fetch next entries
export const FETCH_NEXT_ENTRIES_PENDING = 'FETCH_NEXT_ENTRIES_PENDING';
export const FETCH_NEXT_ENTRIES_FULFILLED = 'FETCH_NEXT_ENTRIES_FULFILLED';

// store new entry
export const STORE_ENTRY_PENDING = 'STORE_ENTRY_PENDING';
export const STORE_ENTRY_FULFILLED = 'STORE_ENTRY_FULFILLED';
export const STORE_ENTRY_ERROR = 'STORE_ENTRY_ERROR';

// Sync Entry to abacus
export const SYNC_ENTRY_PENDING = 'SYNC_ENTRY_PENDING';
export const SYNC_ENTRY_FULFILLED = 'SYNC_ENTRY_FULFILLED';
export const SYNC_ENTRY_ERROR = 'SYNC_ENTRY_ERROR';

// store bulk
export const STORE_BULK_PENDING = 'STORE_BULK_PENDING';
export const STORE_BULK_FULFILLED = 'STORE_BULK_FULFILLED';
export const STORE_BULK_ERROR = 'STORE_BULK_ERROR';
