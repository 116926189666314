export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  incomeStatement: [],

  isLoadingIncomeStatementPdfExport: false,
  isLoadingIncomeStatementXlsExport: false,

  meta: {
    success: [],
    availableLevels: [],
  },
};
