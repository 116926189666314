import * as types from '@/store/modules/accounting/account-plan/types';

export default {
  [types.FETCH_ACCOUNT_PLAN](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
  },
  [types.FETCH_ACCOUNT_PLAN_SUCCESS](state, response) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;
    if (response.status === 204) {
      state.accountPlan = {
        structure: [],
      };
    } else {
      state.accountPlan = response.data;
    }
  },
  [types.FETCH_ACCOUNT_PLAN_CANCEL](state) {
    state.isLoading = false;
  },
  [types.FETCH_ACCOUNT_PLAN_FAILURE](state, { error }) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },

  // import account plan json
  [types.IMPORT_ACC_PLAN_JSON](state) {
    state.isImportingAccountPlanJson = true;
  },
  [types.IMPORT_ACC_PLAN_JSON_SUCCESS](state) {
    state.isImportingAccountPlanJson = false;
  },
  [types.IMPORT_ACC_PLAN_JSON_CANCEL](state) {
    state.isImportingAccountPlanJson = false;
  },
  [types.IMPORT_ACC_PLAN_JSON_FAILURE](state) {
    state.isImportingAccountPlanJson = false;
  },

  // import account plan amid
  [types.IMPORT_ACC_PLAN_AMID](state) {
    state.isImportingAccountPlanAmid = true;
  },
  [types.IMPORT_ACC_PLAN_AMID_SUCCESS](state) {
    state.isImportingAccountPlanAmid = false;
  },
  [types.IMPORT_ACC_PLAN_AMID_CANCEL](state) {
    state.isImportingAccountPlanAmid = false;
  },
  [types.IMPORT_ACC_PLAN_AMID_FAILURE](state) {
    state.isImportingAccountPlanAmid = false;
  },

  // generate account plan from template
  [types.GENERATE_ACC_PLAN_FROM_TEMPLATE](state) {
    state.isGeneratingAccountPlanFromTemplate = true;
  },
  [types.GENERATE_ACC_PLAN_FROM_TEMPLATE_SUCCESS](state) {
    state.isGeneratingAccountPlanFromTemplate = false;
  },
  [types.GENERATE_ACC_PLAN_FROM_TEMPLATE_FAILURE](state) {
    state.isGeneratingAccountPlanFromTemplate = false;
  },

  // delete account plan
  [types.DELETE_ACCOUNT_PLAN](state) {
    state.isDeletingAccountPlan = true;
  },
  [types.DELETE_ACCOUNT_PLAN_SUCCESS](state) {
    state.accountPlan = {
      structure: [],
    };
    state.isDeletingAccountPlan = false;
  },
  [types.DELETE_ACCOUNT_PLAN_FAILURE](state) {
    state.isDeletingAccountPlan = false;
  },

  // fetch classifications (flat array without accounts)
  [types.FETCH_CLASSIFICATIONS](state) {
    state.isLoadingClassifications = true;
  },
  [types.FETCH_CLASSIFICATIONS_SUCCESS](state, response) {
    state.isLoadingClassifications = false;
    state.classifications = response.data;
  },
  [types.FETCH_CLASSIFICATIONS_FAILURE](state) {
    state.isLoadingClassifications = false;
  },
  [types.FETCH_CLASSIFICATIONS_CANCEL](state) {
    state.isLoadingClassifications = false;
  },

  [types.FETCH_ACCOUNT_PLAN_MASTER](state) {
    state.isLoadingAccountPlanMaster = true;
  },
  [types.FETCH_ACCOUNT_PLAN_MASTER_SUCCESS](state, response) {
    state.isLoadingAccountPlanMaster = false;
    state.accountPlanMaster = response.data;
  },
  [types.FETCH_ACCOUNT_PLAN_MASTER_CANCEL](state) {
    state.isLoadingAccountPlanMaster = false;
  },
  [types.FETCH_ACCOUNT_PLAN_MASTER_FAILURE](state) {
    state.isLoadingAccountPlanMaster = false;
  },

  // fetch PDF account plan sheet
  [types.FETCH_ACCOUNT_PLAN_PDF_EXPORT](state) {
    state.isLoadingAccountPlanPdfExport = true;
  },
  [types.FETCH_ACCOUNT_PLAN_PDF_EXPORT_SUCCESS](state) {
    state.isLoadingAccountPlanPdfExport = false;
  },
  [types.FETCH_ACCOUNT_PLAN_PDF_EXPORT_FAILURE](state) {
    state.isLoadingAccountPlanPdfExport = false;
  },
  [types.FETCH_ACCOUNT_PLAN_PDF_EXPORT_CANCEL](state) {
    state.isLoadingAccountPlanPdfExport = false;
  },

  // fetch XLS account plan sheet
  [types.FETCH_ACCOUNT_PLAN_XLS_EXPORT](state) {
    state.isLoadingAccountPlanXlsExport = true;
  },
  [types.FETCH_ACCOUNT_PLAN_XLS_EXPORT_SUCCESS](state) {
    state.isLoadingAccountPlanXlsExport = false;
  },
  [types.FETCH_ACCOUNT_PLAN_XLS_EXPORT_FAILURE](state) {
    state.isLoadingAccountPlanXlsExport = false;
  },
  [types.FETCH_ACCOUNT_PLAN_XLS_EXPORT_CANCEL](state) {
    state.isLoadingAccountPlanXlsExport = false;
  },
};
