import store from '@/store';
import axios from 'axios';
import Vue from 'vue';

const baseUrl = `${import.meta.env.VITE_URL}/webapi`;
const ignoreList = ['inbox/count_unread'];

export default function init() {
  // store token time
  axios.interceptors.request.use(
    (config) => {
      const isInIgnoreList = ignoreList.some((ignoreUrl) => config.url.endsWith(ignoreUrl));
      if (!isInIgnoreList) {
        store.dispatch('definitions/storeTokenTime', new Date()).then(() => {});
      }
      return config;
    },
  );

  axios.interceptors.request.use(
    (config) => {
      let url = baseUrl + config.url;
      if (config.url.startsWith('/api/')) {
        const { uuid } = store.state.definitions.account.account;
        url = `${import.meta.env.VITE_URL}/accounts/${uuid}/${config.url.slice(5)}`;
      }

      if (config.url.startsWith('http')) {
        if (config.url.startsWith(import.meta.env.VITE_URL)) {
          url = config.url;
        } else {
          return config;
        }
      }

      const headers = {
        'Content-Type': 'application/json',
      };
      if (Vue.$keycloak.token) {
        headers.Authorization = `Bearer ${Vue.$keycloak.token}`;
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          ...headers,
        },
        url,
      };
    },
  );

  // prepare filename
  axios.interceptors.response.use(
    (response) => {
      if (response.data instanceof Blob) {
        if (
          response.headers['content-disposition']
          && response.headers['content-disposition'].includes('filename')
        ) {
          // Get Filename from Content-Disposition
          const regex = /filename[^;=\n]*=['"]?([^;\r\n"']*)['"]?/;
          const contentDispositionFilename = response.headers['content-disposition'].match(regex)[1];
          if (contentDispositionFilename) {
            response.filename = contentDispositionFilename;
          }
        }
        return response;
      }
      if (response.data) {
        return response.data;
      }
      return response;
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    },
  );
}
