import type { InternalAxiosRequestConfig } from 'axios';

export const requestBasePathInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { href, origin } = new URL(config.url || '', config.baseURL);

  return {
    ...config,
    baseURL: origin,
    url: href,
  };
};
