export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  openingBalance: [],

  meta: {
    availableLevels: [],
    provisionally: false,
  },

  isLoadingOpeningBalancePdfExport: false,
  isLoadingOpeningBalanceXlsExport: false,
};
