import axios from 'axios';
import qs from 'qs';
import * as types from './types';

export default {
  resetPaginationProductsList({ commit }) {
    commit(types.RESET_PRODUCTS_PAGINATION_LIST);
  },
  async fetchInitial({ commit, dispatch }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await Promise.all([
        dispatch('fetchProducts'),
        dispatch('fetchProductGroups'),
      ]);
      commit(types.FETCH_INITIAL_FULFILLED);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
    }
  },
  async fetchProducts({ rootState, commit }, listType) {
    commit(types.FETCH_PRODUCTS_PENDING);
    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/products`;
      const url = listType === 'archived' ? `${baseUrl}?archive=true` : baseUrl;
      commit(types.FETCH_PRODUCTS_FULFILLED, await axios.get(url));
    } catch (error) {
      commit(types.FETCH_PRODUCTS_ERROR, error);
      console.error(error);
    }
  },
  async fetchPaginationInitial({ commit, dispatch }, { archive }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await dispatch('fetchProductTags', { meta: { itemsPerPage: 'all' }, metaKey: 'allTags' });
      await dispatch('fetchPaginationProductsGroup', {
        archive,
        meta: { itemsPerPage: 'all' },
        metaKey: 'allGroups',
      });
      await dispatch('fetchPaginationProducts', { archive });
      commit(types.FETCH_INITIAL_FULFILLED);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
    }
  },
  async fetchPaginationProducts({ rootState, commit, state }, { archive, meta }) {
    commit(types.FETCH_PRODUCTS_PENDING);
    try {
      let params = {};
      const metaKey = archive ? 'productsarchived' : 'productslist';
      const metaValue = meta || state.meta[metaKey];

      let extendSearch = {
        tags_OR: [],
        product_group_OR: [],
      };

      if (metaValue && Object.prototype.hasOwnProperty.call(metaValue, 'extendSearch')) {
        extendSearch = {
          ...extendSearch,
          ...metaValue.extendSearch,
        };
      }

      const search = [];
      if (metaValue) {
        metaValue.search.forEach((filterValue) => {
          const hasTag = state.tags.some(
            (tag) => tag.tag === filterValue,
          );
          if (hasTag) {
            extendSearch.tags_OR.push(filterValue);
          }

          const hasProduct = state.productGroups.find(
            (productGroup) => productGroup.description === filterValue,
          );
          if (hasProduct) {
            extendSearch.product_group_OR.push(hasProduct.group);
          }

          if (!hasTag && !hasProduct) {
            search.push(filterValue);
          }
        });

        extendSearch.tags_OR = extendSearch.tags_OR.join(',');
        extendSearch.product_group_OR = extendSearch.product_group_OR.join(',');

        params = {
          direction: metaValue.sortDesc && metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy[0],
          page: metaValue.page,
          search,
          extend_search: extendSearch,
        };
      }

      let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/products`;
      url = archive ? `${url}/archived` : url;

      const data = await axios.get(url, {
        params,
        paramsSerializer: (item) => qs.stringify(item, { arrayFormat: 'brackets' }),
      });
      if (data.meta) {
        if (metaValue) {
          data.meta.search = metaValue.search; // keep current search params
        }
        commit(types.FETCH_TABLE_META, { meta: data.meta, key: metaKey });
      }
      commit(types.FETCH_PAGINATION_PRODUCTS_FULFILLED, data);
    } catch (error) {
      commit(types.FETCH_PRODUCTS_ERROR, error);
      console.error(error);
    }
  },
  async fetchProductGroups({ commit, rootState }, listType) {
    commit(types.FETCH_PRODUCT_GROUPS_PENDING);
    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/products/groups`;
      const url = listType === 'archived' ? `${baseUrl}?archive=true` : baseUrl;
      commit(types.FETCH_PRODUCT_GROUPS_FULFILLED, await axios.get(url));
    } catch (error) {
      commit(types.FETCH_PRODUCT_GROUPS_ERROR, error);
      console.error(error);
    }
  },
  async fetchPaginationGroupInitial({ commit, dispatch }, { archive }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await dispatch('fetchPaginationProductsGroup', { archive });
      commit(types.FETCH_INITIAL_FULFILLED);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
    }
  },
  async fetchPaginationProductsGroup({ rootState, commit, state }, { archive, meta, metaKey }) {
    commit(types.FETCH_PRODUCT_GROUPS_PENDING);
    try {
      let params = {};
      const metaKeyValue = metaKey || (archive ? 'productGrouparchived' : 'productGrouplist');
      const metaValue = meta || state.meta[metaKeyValue];
      if (metaValue) {
        params = {
          direction: metaValue.sortDesc && metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy ? metaValue.sortBy[0] : null,
          page: metaValue.page,
          search: metaValue.search,
        };
      }
      let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/product_groups`;
      url = archive ? `${url}/archived` : url;
      const data = await axios.get(url, { params });
      if (data.meta) {
        commit(types.FETCH_TABLE_META, { meta: data.meta, key: metaKeyValue });
      }
      commit(types.FETCH_PRODUCT_GROUPS_FULFILLED, data);
    } catch (error) {
      commit(types.FETCH_PRODUCT_GROUPS_ERROR, error);
      console.error(error);
    }
  },
  async fetchProductTags({ rootState, state, commit }, { meta, metaKey }) {
    commit(types.FETCH_TAG_PENDING, true);
    try {
      let params = {};
      const metaValue = meta || state.meta[metaKey];
      if (metaValue) {
        params = {
          direction: metaValue.sortDesc && metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy ? metaValue.sortBy[0] : null,
          page: metaValue.page,
          search: metaValue.search,
        };
      }
      const url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/settings/tags/products`;
      const data = await axios.get(url, { params });
      if (data.meta) {
        commit(types.FETCH_TABLE_META, { meta: data.meta, key: metaKey });
      }
      commit(types.FETCH_TAG, data);
      commit(types.FETCH_TAG_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_TAG_PENDING, false);
      return Promise.reject(error);
    }
  },
  async deleteTag({ commit, dispatch, rootState }, { id, meta, metaKey }) {
    commit(types.FETCH_TAG_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/tags/products/${id}`;
      await axios.delete(url);
      dispatch('fetchProductTags', { meta, metaKey });
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_TAG_PENDING, false);
      return Promise.reject(error);
    }
  },
  async updateTag({ rootState, commit, dispatch }, { tag, metaKey }) {
    commit(types.FETCH_TAG_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/tags/products/${tag.id}`;
      await axios.patch(url, tag);
      await dispatch('fetchProductTags', { metaKey });
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_TAG_PENDING, false);
      return Promise.reject(error);
    }
  },

  searchForProduct({ rootState, commit }, { search, abortController }) {
    const controller = abortController || new AbortController();

    commit(types.SEARCH_PRODUCT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/products`, {
      params: {
        search,
        limit: 50,
      },
      signal: controller.signal,
    }).then((response) => {
      commit(types.SEARCH_PRODUCT_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.SEARCH_PRODUCT_CANCEL);
        return Promise.reject(error);
      }
      commit(types.SEARCH_PRODUCT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchEmptySearchProducts({ rootState, commit }, { abortController }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_EMPTY_SEARCH_PRODUCTS);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/products`, {
      params: {
        limit: 30,
      },
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_EMPTY_SEARCH_PRODUCTS_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_EMPTY_SEARCH_PRODUCTS_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_EMPTY_SEARCH_PRODUCTS_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
