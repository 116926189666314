import type { AxiosPromise } from 'axios';
import type { Payload } from '@/api/interfaces/payload';
import httpClient from '@/helpers/client/http-client';
import type { JournalYear } from '@/api/interfaces/accounting/journal-year';

export function getJournalYears(
  abortController: AbortController = new AbortController(),
): AxiosPromise<Payload<Array<JournalYear>>> {
  return httpClient.get('/definitions/journal-years', {
    signal: abortController.signal,
  });
}
