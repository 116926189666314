<template>
  <v-menu
    v-model="showAppSwitch"
    fixed
    max-width="216px"
    min-width="216px"
    nudge-top="-48"
  >
    <template #activator="{ on }">
      <v-btn
        :class="btnClass"
        :disabled="fetchAppSwitchPending"
        :loading="fetchAppSwitchPending"
        fab
        icon
        :color="isDarkTheme ? 'white' : 'black'"
        @click="loadApps()"
        v-on="on"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>
    <v-list class="appswitch">
      <template v-for="(app, index) in appList">
        <v-divider
          v-if="index !== 0"
          :key="`appswitch-divider-${index}`"
        />
        <v-list-item
          :key="index"
          :href="app.url"
          :target="app.external ? '_blank' : ''"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <img
                :alt="app.name"
                :height="app.height"
                :src="isDarkTheme && app.logoDarkMode ? app.logoDarkMode : app.logo"
                class="d-block"
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { useTheme } from '@/composables/theme';

const { mapGetters: mapDefinitionsAccountGetters } = createNamespacedHelpers('definitions/account');
const { mapState: mapSwiss21State, mapActions: mapSwiss21Actions } = createNamespacedHelpers('swiss21');

export default {
  props: {
    btnClass: {
      type: [String, Array, Object],
      default: '',
    },
  },
  setup() {
    const { isDarkTheme } = useTheme();

    return { isDarkTheme };
  },
  data() {
    return {
      showAppSwitch: false,
      swiss21LogoPath: 'images/swiss21',
      deepBoxLogoPath: 'images/deepbox',
      apps: {
        portal: {
          logo: 'logo_swiss21.svg',
          logoDarkMode: 'logo_swiss21-dark-mode.svg',
          height: 36,
          class: 'swiss21-logo',
          external: false,
        },
        smartwe: {
          logo: 'lettermark-swiss21-crm.svg',
          height: 14,
          class: 'swiss21__app-smartwe',
          external: true,
        },
        peppershop_shop: {
          logo: 'lettermark-swiss21-shop.svg',
          height: 14,
          class: 'swiss21__app-commerce-shop',
          external: true,
        },
        peppershop_pos: {
          logo: 'lettermark-swiss21-pos.svg',
          height: 14,
          class: 'swiss21__app-commerce-kasse',
          external: true,
        },
        peppershop_ext: {
          logo: 'lettermark-swiss21-peppershop_ext.svg',
          height: 14,
          class: 'swiss21__app-peppershop-ext',
          external: true,
        },
        abasalary: {
          logo: 'lettermark-swiss21-abasalary.svg',
          height: 14,
          class: 'swiss21__app-abasalary',
          external: true,
        },
      },
    };
  },
  computed: {
    ...mapDefinitionsAccountGetters([
      'isDeepBoxAccount',
      'isSwiss21Account',
    ]),
    ...mapSwiss21State([
      'fetchAppSwitchPending',
      'swiss21Apps',
    ]),
    swiss21AppList() {
      return this.swiss21Apps
        .map((item) => {
          const app = this.apps[item.app_name];
          if (!app) {
            return undefined;
          }
          return {
            ...item,
            logo: `/${this.swiss21LogoPath}/${app.logo}`,
            logoDarkMode: app.logoDarkMode ? `/${this.swiss21LogoPath}/${app.logoDarkMode}` : undefined,
            height: app.height,
            class: `swiss21 ${app.class}`,
            external: app.external,
          };
        })
        .filter((item) => item !== undefined);
    },
    ninjaBoxAppList() {
      return [
        {
          url: import.meta.env.VITE_DEEPBOX_APP_URL,
          logo: `/${this.deepBoxLogoPath}/logo_deepbox.svg`,
          logoDarkMode: `/${this.deepBoxLogoPath}/deepbox-dark-mode.svg`,
          height: 22,
          class: 'appswitch__app-deepbox',
          external: true,
        },
      ];
    },
    appList() {
      if (this.isSwiss21Account) {
        return this.swiss21AppList;
      }
      if (this.isDeepBoxAccount) {
        return this.ninjaBoxAppList;
      }
      return [];
    },
  },
  methods: {
    ...mapSwiss21Actions([
      'fetchAppSwitch',
    ]),
    loadApps() {
      if (this.showAppSwitch) {
        return;
      }
      if (this.isSwiss21Account) {
        this.fetchAppSwitch();
      }
    },
  },
};
</script>
