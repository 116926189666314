import INVOICE_ITEM_KIND from '@/enums/invoice-item-kind';
import * as types from '@/store/modules/user-interface/invoice-items-list/types';

function createHashFromInvoiceItem(invoiceItem) {
  return btoa(JSON.stringify([
    invoiceItem.product_key,
    invoiceItem.notes,
    invoiceItem.is_amount_discount,
    parseFloat(invoiceItem.discount),
    parseFloat(invoiceItem.cost),
    invoiceItem.tax_rate1,
    invoiceItem.employee_activity_id,
  ]));
}

function combineProductsOnSameLevel(invoiceItems, groupId = null) {
  const idsToUpdate = [];
  const idsToUpdateMap = [];
  const idsToDelete = [];

  invoiceItems.forEach((invoiceItem) => {
    const id = invoiceItem.id || invoiceItem.uuid;

    if (invoiceItem.kind === INVOICE_ITEM_KIND.PRODUCT && !invoiceItem.related_invoice_id) {
      const hash = createHashFromInvoiceItem(invoiceItem);

      if (!idsToUpdateMap.includes(hash)) {
        idsToUpdateMap.push(hash);
        idsToUpdate.push({
          id,
          groupId,
          qty: parseFloat(invoiceItem.qty),
          needsUpdate: false,
        });
      } else {
        // update qty
        const index = idsToUpdateMap.indexOf(hash);
        idsToUpdate[index].qty += parseFloat(invoiceItem.qty);
        idsToUpdate[index].needsUpdate = true;

        idsToDelete.push({
          id,
          groupId,
        });
      }
    } else if (invoiceItem.kind === INVOICE_ITEM_KIND.GROUP) {
      const response = combineProductsOnSameLevel(invoiceItem.group_items, id);
      idsToDelete.push(...response.idsToDelete);
      idsToUpdate.push(...response.idsToUpdate);
      idsToUpdateMap.push(...Array(response.idsToUpdate.length).fill(null));
    }
  });

  return {
    idsToUpdate: idsToUpdate.filter((item) => item.needsUpdate),
    idsToDelete,
  };
}

export default {
  updateTableSetting({ commit }, setting) {
    commit(types.UPDATE_TABLE_SETTING, setting);
  },

  setInvoiceItems({ commit }, invoiceItems) {
    commit(types.SET_INVOICE_ITEMS, invoiceItems);
  },

  addInvoiceItem({ commit }, invoiceItem) {
    commit(types.ADD_INVOICE_ITEM, invoiceItem);
  },

  resetGhostItems({ commit }) {
    commit(types.RESET_GHOST_ITEMS);
  },

  addGhostItem({ commit }, invoiceItem) {
    commit(types.ADD_GHOST_ITEM, invoiceItem);
  },

  removeInvoiceItem({ commit, getters }, invoiceItemId) {
    commit(types.REMOVE_INVOICE_ITEM, getters.getIds.indexOf(invoiceItemId));
  },
  removeNestedInvoiceItem({ commit, getters }, { invoiceItemId, groupId }) {
    commit(types.REMOVE_NESTED_INVOICE_ITEM, {
      groupIndex: getters.getIds.indexOf(groupId),
      invoiceItemIndex: getters.getGroupInvoiceItemsIdsByGroupId(groupId).indexOf(invoiceItemId),
    });
  },
  removeGhostItem({ commit, getters }, ghostItemId) {
    commit(types.REMOVE_GHOST_ITEM, getters.getGhostItemIds.indexOf(ghostItemId));
  },
  setInvoiceItemValue({ commit, getters }, { key, value, invoiceItemId }) {
    commit(types.UPDATE_INVOICE_ITEM_VALUE, { key, value, indexToUpdate: getters.getIds.indexOf(invoiceItemId) });
  },
  setGhostItemValue({ commit, getters }, { key, value, ghostItemId }) {
    commit(types.UPDATE_GHOST_ITEM_VALUE, {
      key,
      value,
      indexToUpdate: getters.getGhostItemIds.indexOf(ghostItemId),
    });
  },
  setGhostItemValues({ commit, getters }, { updateObject, ghostItemId }) {
    commit(types.UPDATE_GHOST_ITEM_VALUES, {
      updateObject,
      indexToUpdate: getters.getGhostItemIds.indexOf(ghostItemId),
    });
  },
  setInvoiceItemValues({ commit, getters }, { updateObject, invoiceItemId }) {
    commit(types.UPDATE_INVOICE_ITEM_VALUES, { updateObject, indexToUpdate: getters.getIds.indexOf(invoiceItemId) });
  },

  setGroupInvoiceItemValue({ commit, getters }, {
    key, value, invoiceItemId, groupId,
  }) {
    commit(types.UPDATE_GROUP_INVOICE_ITEM_VALUE, {
      key,
      value,
      groupIndex: getters.getIds.indexOf(groupId),
      invoiceItemIndex: getters.getGroupInvoiceItemsIdsByGroupId(groupId).indexOf(invoiceItemId),
    });
  },
  setGroupInvoiceItemValues({ commit, getters }, {
    updateObject, invoiceItemId, groupId,
  }) {
    commit(types.UPDATE_GROUP_INVOICE_ITEM_VALUES, {
      updateObject,
      groupIndex: getters.getIds.indexOf(groupId),
      invoiceItemIndex: getters.getGroupInvoiceItemsIdsByGroupId(groupId).indexOf(invoiceItemId),
    });
  },
  ungroupGroup({ commit, getters }, groupId) {
    commit(types.UNGROUP_GROUP, {
      groupIndex: getters.getIds.indexOf(groupId),
      invoiceItems: getters.getGroupItems(groupId),
    });
  },

  combineInvoiceItems({ state, dispatch }) {
    const { idsToUpdate, idsToDelete } = combineProductsOnSameLevel(state.invoiceItems);
    idsToUpdate.forEach(({ qty, id, groupId }) => {
      if (!groupId) {
        dispatch('setInvoiceItemValue', { key: 'qty', value: qty, invoiceItemId: id });
      } else {
        dispatch('setGroupInvoiceItemValue', {
          key: 'qty', value: qty, invoiceItemId: id, groupId,
        });
      }
    });
    idsToDelete.forEach(({ id, groupId }) => {
      if (!groupId) {
        dispatch('removeInvoiceItem', id);
      } else {
        dispatch('removeNestedInvoiceItem', { invoiceItemId: id, groupId });
      }
    });
  },

  addGhostItemToInvoiceItems({ dispatch, getters }, ghostItemId) {
    dispatch('addInvoiceItem', getters.getGhostItem(ghostItemId));
    dispatch('removeGhostItem', ghostItemId);
  },
};
