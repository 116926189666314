import type { ActivityType, ActivityTypeRequest } from '@/api/interfaces/time/settings/activity-types';
import apiClient from '@/helpers/client/api-client';
import {
  type AbortControllerInjectorReturn,
  useAbortControllerInjector,
} from '@/stores/composables/use-abort-controller-injector';

export function getTimeActivityTypes(): AbortControllerInjectorReturn<Array<ActivityType>> {
  return useAbortControllerInjector((abortController) => apiClient.get(
    '/time/v2/settings/activity-types',
    {
      signal: abortController.signal,
    },
  ));
}

export function getTimeActivityType(uuid: Uuid): AbortControllerInjectorReturn<ActivityType> {
  return useAbortControllerInjector((abortController) => apiClient.get(
    `/time/v2/settings/activity-types/${uuid}`,
    {
      signal: abortController.signal,
    },
  ));
}

export function createTimeActivityType(data: ActivityTypeRequest) {
  return apiClient.post('/time/v2/settings/activity-types', data);
}

export function updateTimeActivityType(uuid: Uuid, data: ActivityTypeRequest) {
  return apiClient.patch(`/time/v2/settings/activity-types/${uuid}`, data);
}

export function deleteTimeActivityType(uuid: Uuid) {
  return apiClient.delete(`/time/v2/settings/activity-types/${uuid}`);
}
