export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_TOP_CLIENTS_PENDING = 'FETCH_TOP_CLIENTS_PENDING';
export const FETCH_TOP_CLIENTS = 'FETCH_TOP_CLIENTS';

export const FETCH_TOP_PRODUCTS_PENDING = 'FETCH_TOP_PRODUCTS_PENDING';
export const FETCH_TOP_PRODUCTS = 'FETCH_TOP_PRODUCTS';

export const FETCH_BALANCE_PENDING = 'FETCH_BALANCE_PENDING';

export const FETCH_CUSTOMER_BALANCE_PENDING = 'FETCH_CUSTOMER_PENDING';
export const FETCH_CUSTOMER_BALANCE = 'FETCH_CUSTOMER_BALANCE';

export const FETCH_SUPPLIER_BALANCE_PENDING = 'FETCH_SUPPLIER_BALANCE_PENDING';
export const FETCH_SUPPLIER_BALANCE = 'FETCH_SUPPLIER_BALANCE';

export const FETCH_TOTALS_PENDING = 'FETCH_TOTALS_PENDING';
export const FETCH_TOTALS = 'FETCH_TOTALS';

export const FETCH_FORM = 'FETCH_FORM';

export const FETCH_DATA_PENDING = 'FETCH_DATA_PENDING';
export const FETCH_DATA = 'FETCH_DATA';

export const FETCH_ACTIVITIES_PENDING = 'FETCH_ACTIVITIES_PENDING';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';

export const FETCH_FIRST_STEPS_PENDING = 'FETCH_FIRST_STEPS_PENDING';
export const FETCH_FIRST_STEPS = 'FETCH_FIRST_STEPS';

export const FETCH_DASHBOARD_SETTINGS_PENDING = 'FETCH_DASHBOARD_SETTINGS_PENDING';
export const FETCH_DASHBOARD_SETTINGS = 'FETCH_DASHBOARD_SETTINGS';

export const FETCH_DASHBOARD_STATUS_TOTAL_PENDING = 'FETCH_DASHBOARD_STATUS_TOTAL_PENDING';
export const FETCH_DASHBOARD_STATUS_TOTAL = 'FETCH_DASHBOARD_STATUS_TOTAL';

export const FETCH_DASHBOARD_POS_DATA_PENDING = 'FETCH_DASHBOARD_POS_DATA_PENDING';
export const FETCH_DASHBOARD_POS_DATA = 'FETCH_DASHBOARD_POS_DATA';

export const FETCH_DASHBOARD_SALES_PENDING = 'FETCH_DASHBOARD_SALES_PENDING';
export const FETCH_DASHBOARD_SALES = 'FETCH_DASHBOARD_SALES';

export const STORE_DASHBOARD_SETTINGS_PENDING = 'FETCH_DASHBOARD_WIDGETS_PENDING';
export const STORE_DASHBOARD_SETTINGS = 'FETCH_DASHBOARD_WIDGETS';

export const FETCH_LIQUIDITY_SALES_PENDING = 'FETCH_LIQUIDITY_SALES_PENDING';
export const FETCH_LIQUIDITY_SALES = 'FETCH_LIQUIDITY_SALES';
