import { OUT_OF_SCOPE } from '@/helpers/utils';
import { keycloakInstance } from '@/plugins/keycloak';

export function useKeycloak() {
  if (keycloakInstance) {
    return keycloakInstance;
  }

  console.warn(`[Keycloak] out of ${OUT_OF_SCOPE}`);
  return undefined as never;
}
