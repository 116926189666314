import axios from 'axios';
import * as types from './types';

export default {

  fetchVatControlReport({ rootState, commit }, { year, period }) {
    commit(types.FETCH_VAT_CONTROL_REPORT, { year, period });

    const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/accounting/vat-reporting`;
    return axios.get(`${baseUrl}/years/${year}/periods/${period.id}/control-report`).then((payload) => {
      commit(types.FETCH_VAT_CONTROL_REPORT_FULFILLED, payload);
    }).catch((error) => {
      commit(types.FETCH_VAT_CONTROL_REPORT_ERROR, error);
    });
  },

  fetchVatControlReportExport({ rootState, commit }, { year, period, type }) {
    commit(types.FETCH_VAT_CONTROL_REPORT_EXPORT);
    const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/accounting/vat-reporting`;
    return axios({
      url: `${baseUrl}/years/${year}/periods/${period.id}/control-report`,
      method: 'GET',
      responseType: 'blob',
      params: {
        export: type,
      },
    }).then((payload) => {
      commit(types.FETCH_VAT_CONTROL_REPORT_EXPORT_FULFILLED);
      return payload;
    }).catch((error) => {
      commit(types.FETCH_VAT_CONTROL_REPORT_EXPORT_ERROR, error);
    });
  },
};
