import { useEntities } from '@/stores/composables/use-entities';
import { useLoadable } from '@/stores/composables/use-loadable';
import { useStatusCode } from '@/stores/composables/use-status-code';
import type { ListHandler } from '@/stores/interfaces/crud';

export const useGetEntities = <R>(handler: ListHandler<R>) => {
  const { isLoading, hasLoaded, cancel, abortController } = useLoadable();
  const { entities } = useEntities<R>();
  const { statusCode } = useStatusCode();

  async function getEntities() {
    isLoading.value = true;
    abortController.value = new AbortController();

    try {
      const response = await handler(abortController.value);
      entities.value = response.data.data;
      statusCode.value = response.status;
      hasLoaded.value = true;
      return response;
    } finally {
      isLoading.value = false;
      cancel();
    }
  }

  return {
    isLoading,
    hasLoaded,
    entities,
    statusCode,
    cancel,
    getEntities,
  };
};
