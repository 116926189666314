export default {
  permissions: {
    is_admin_group: false,
    dashboard: false,
    addresses: false,
    customers: false,
    vendors: false,
    payments: false,
    products: false,
    time: false,
    accounting: false,
    settings: false,
    abasalary: false,
    is_owner: false,
    deepv: false,
    more: false,
  },
  isLoadingPermission: false,
};
