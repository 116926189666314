import type { RouteConfig } from 'vue-router/types/router';

const PaymentsBankfilesList = () => import('@/pages/finances/payments/PaymentsBankfilesList.vue');
const IncomingForm = () => import('@/pages/finances/payments/payment/FormIncoming.vue');
const OutgoingForm = () => import('@/pages/finances/payments/payment/FormOutgoing.vue');
const PassThrough = () => import('@/views/PassThrough.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const PaymentsIndex = () => import('@/pages/finances/payments/PaymentsIndex.vue');
const PaymentsList = () => import('@/pages/finances/payments/PaymentsList.vue');

export const payments: Array<RouteConfig> = [
  {
    path: 'payments',
    redirect: { name: 'payments-list', params: { group: 'open' } },
    component: PassThrough,
    children: [
      {
        path: 'in',
        name: 'payments-in',
        component: PassThroughKeyed,
        children: [
          {
            path: 'create',
            name: 'payments-in-create',
            component: IncomingForm,
            meta: {
              paymentListType: 'descendant',
            },
          },
          {
            path: 'edit/:id',
            name: 'payments-in-edit',
            component: IncomingForm,
            meta: {
              paymentListType: 'descendant',
            },
          },
        ],
      },
      {
        path: 'out',
        name: 'payments-out',
        component: PassThroughKeyed,
        children: [
          {
            path: 'create',
            name: 'payments-out-create',
            component: OutgoingForm,
            meta: {
              paymentListType: 'descendant',
            },
          },
          {
            path: 'edit/:id',
            name: 'payments-out-edit',
            component: OutgoingForm,
            meta: {
              paymentListType: 'descendant',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'payments',
    redirect: { name: 'payments-list', params: { group: 'open' } },
    component: PaymentsIndex,
    children: [
      {
        path: 'bankfiles',
        name: 'payments-bankfiles-list',
        component: PaymentsBankfilesList,
        meta: {
          title: 'payments',
        },
      },

      {
        path: ':group?',
        name: 'payments-list',
        component: PaymentsList,
        meta: {
          title: 'payments',
        },
        beforeEnter(to, _, next) {
          if (!to.params.group) {
            next({ name: 'payments-list', params: { group: 'open' } });
          } else {
            next();
          }
        },
      },
    ],
  },

];
