import { getTaxTimelines } from '@/api/tax/tax-timelines';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useGetEntitiesV3 } from '../composables/use-get-entities-v3';

export const useTaxTimelinesStore = defineStore('taxTimelines', () => ({
  ...useGetEntitiesV3(getTaxTimelines),
}));

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaxTimelinesStore, import.meta.hot));
}
