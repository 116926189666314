export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  isBookingExchangeDiff: false,
  bookingExchangeDiffError: null,

  accountStatement: [],
  accountInfo: {
    balance_forward_currency_code: '',
    balance_forward_name: '',
    balance_forward_value: null,
    can_edit_balance_forward: false,
    credit_range: '',
    current_equivalent: null,
    current_equivalent_currency_code: '',
    current_equivalent_title: '',
    debit_range: '',
    effective_value: null,
    effective_value_currency_code: '',
    provisionally: false,
    raw_balance_forward_value: null,
    saldo_range: '',
    saldo_range_currency_code: '',
    saldo_range_end_date: null,
    saldo_range_exchange: null,
    saldo_range_start_date: null,
    too_many_open_year: false,
  },
  meta: {
    page: 1,
    per_page: 20,
    max_page: null,
    extend_search: null,
  },

  isSearching: false,
  search: {
    form: {
      account_number: '',
      amount: '',
      booking_number: '',
      booking_text: '',
      contra_account_number: '',
      create_user_name: '',
      created_at: '',
      credit_value: '',
      debit_value: '',
      partner: '',
      public_notes: '',
      reference: '',
      tax_rate_id: '',
    },

    valutaRangeValue: '',

    valutaRange: {
      date_from: null,
      date_to: null,
    },

    bookingDateRangeValue: '',

    bookingDateRange: {
      date_from: null,
      date_to: null,
    },

    selectedOperator: {
      amount: 2,
      booking_number: 2,
      credit_value: 2,
      debit_value: 2,
    },
  },

  isLoadingAccountStatementPdfExport: false,
  isLoadingAccountStatementXlsExport: false,
  isLoadingAccountStatementExport: false,
};
