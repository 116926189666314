import colors from 'vuetify/lib/util/colors';

export default {
  background: {
    base: '#121212',
  },
  surface: {
    base: '#1E1E1E',
  },
  'text-theme': {
    base: '#FFFFFF',
  },
  primary: {
    base: '#128ef2',
    lighten5: '#E3F2FD',
    lighten4: '#BBDEFB',
    lighten3: '#90CAF9',
    lighten2: '#64B5F6',
    lighten1: '#42A5F5',
    darken1: '#1E88E5',
    darken2: '#1976D2',
    darken3: '#1565C0',
    darken4: '#0D47A1',
  },

  'deep-box': '#987AF1',

  secondary: '#6c6c6c',
  accent: '#128ef2',
  error: '#ff4949',
  warning: '#ffc82c',
  info: '#a9c7ea',
  success: '#13ce66',
  presuccess: '#71e1a3',
  black: '#282828',
  grey: colors.grey,
};
