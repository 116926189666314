import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }, id) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      const payment = await dispatch('fetchPayment', id);
      // fetch payment requirements
      if (payment.invoice_id) {
        await Promise.all([
          dispatch('addresses/address/fetchAddress', payment.address_id, { root: true }),
          dispatch('customers/invoice/fetchSingleInvoice', payment.invoice_id, { root: true }),
          dispatch('fetchActions', id),
        ]);
      } else if (payment.is_imported || payment.payment_status_id === 3) {
        await dispatch('fetchActions', id);
      }
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchPayment({ rootState, commit }, id) {
    commit(types.FETCH_PAYMENT_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/payments/${id}`);
      commit(types.FETCH_PAYMENT_FULFILLED, response);
      return response.data;
    } catch (error) {
      commit(types.FETCH_PAYMENT_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storePayment({ rootState, commit, dispatch }, form) {
    commit(types.STORE_PAYMENT_PENDING);
    const method = form.id ? 'PATCH' : 'POST';
    const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/payments`;
    const url = form.id ? `${baseUrl}/${form.id}` : baseUrl;
    const payload = { ...form };
    if (form.file) {
      payload.file = null;
      payload.file = await dispatch('getBase64', form.file[0]);
      payload.file_name = form.file[0].name;
    }
    try {
      const response = await axios({ url, method, data: payload });
      commit(types.STORE_PAYMENT_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'payment_store_successful', color: 'success' }, { root: true });
      return Promise.resolve(response.data);
    } catch (error) {
      if (error.data && error.data.errors) {
        const errorKey = Object.keys(error.data.errors)[0];
        dispatch('messages/showMessage', { text: error.data.errors[errorKey][0], color: 'error' }, { root: true });
      } else {
        dispatch('messages/showMessage', { key: 'payment_store_error', color: 'error' }, { root: true });
      }
      commit(types.STORE_PAYMENT_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchActions({ rootState, commit }, id) {
    commit(types.FETCH_ACTIONS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/payments/singleactions/${id}`);
      commit(types.FETCH_ACTIONS_FULFILLED, response);
      return true;
    } catch (error) {
      commit(types.FETCH_ACTIONS_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storeAction({ rootState, commit, dispatch }, params) {
    commit(types.STORE_ACTION_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/payments/action`, params);
      commit(types.STORE_ACTION_FULFILLED);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      return true;
    } catch (error) {
      commit(types.STORE_ACTION_ERROR, error);
      // TODO: harmonize error message object key
      const message = error.data.data || error.data.message;
      dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  getBase64(context, file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
};

export default actions;
