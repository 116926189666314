import colors from 'vuetify/lib/util/colors';

export default {
  background: {
    base: '#f8f9fa',
  },
  surface: {
    base: '#ffffff',
  },
  'text-theme-red': {
    base: '#000000',
  },
  primary: {
    base: '#128ef2',
    lighten5: '#E3F2FD',
    lighten4: '#BBDEFB',
    lighten3: '#90CAF9',
    lighten2: '#64B5F6',
    lighten1: '#42A5F5',
    darken1: '#1E88E5',
    darken2: '#1976D2',
    darken3: '#1565C0',
    darken4: '#0D47A1',
  },
  secondary: {
    base: '#dedede',
    lighten1: '#EBEBEB',
    lighten2: '#F7F7F7',
    lighten3: '#FFFFFF',

    darken1: '#C4C4C4',
    darken2: '#9E9E9E',
    darken3: '#5E5E5E',
    darken4: '#262626',
  },

  'deep-box': '#987AF1',

  accent: '#128ef2',
  error: '#ff4949',
  warning: '#ffc82c',
  info: '#a9c7ea',
  success: '#13ce66',
  presuccess: '#71e1a3',
  black: '#282828',
  grey: colors.grey,
};
