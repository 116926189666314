import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');
const RecurringInvoicesList = () => import('@/pages/customers/recurring-invoices/RecurringInvoicesList.vue');

const recurringInvoicesRoutes: RouteConfig[] = [{
  path: 'recurring-invoices',
  alias: ['recurring_invoice'],
  component: PassThrough,
  children: [
    {
      path: ':group?',
      name: 'recurring-invoices-list',
      meta: {
        title: 'recurring_invoices',
      },
      component: RecurringInvoicesList,
      beforeEnter(to, _, next) {
        if (!to.params.group) {
          next({ name: 'recurring-invoices-list', params: { group: 'open' } });
        } else {
          next();
        }
      },
    },
  ],
}];

export default recurringInvoicesRoutes;
