export default {
  isLoadingClassification: false,
  hasLoadedClassification: false,
  classificationError: null,

  isPostingClassification: false,
  postingError: null,

  isPatchingClassification: false,
  patchingError: null,

  isDeletingClassification: false,
  deletingError: null,

  classification: null,
};
