import type { Payload } from '@/api/interfaces/payload';
import type { AxiosPromise } from 'axios';

export interface AbortControllerInjectorReturn<T> {
  promise: AxiosPromise<Payload<T>>;
  abortController: AbortController;
}

export const useAbortControllerInjector = <R>(
  promise: (abortController: AbortController) => R,
) => {
  const abortController = new AbortController();

  return {
    promise: promise(abortController),
    abortController,
  };
};
