<template>
  <v-navigation-drawer
    v-show="navigationItems.length"
    v-model="props.navigation.open"
    :class="{
      'pr-3': $vuetify.breakpoint.mdAndUp,
    }"
    :mini-variant="props.navigation.mini"
    :mini-variant-width="60"
    :width="270"
    app
    class="app-navigation"
    clipped
    floating
  >
    <v-toolbar
      v-if="$vuetify.breakpoint.xsOnly"
      class="app-navigation__toolbar"
      flat
    >
      <v-spacer />
      <toolbar-actions
        :account="props.account"
        :inbox-count="props.inboxCount"
        :is-account-ready="props.isAccountReady"
        :logout="logout"
        :permissions="props.permissions"
      />
    </v-toolbar>

    <v-list
      :style="{
        'padding-top': $vuetify.breakpoint.sm || $vuetify.breakpoint.md
          ? `${$vuetify.application.top}px`
          : 'unset',
      }"
      class="pr-0 pl-4"
      dense
      nav
    >
      <div class="pt-6">
        <template v-for="(level1) in navigationItems">
          <!-- only first level -->
          <v-list-item
            v-if="!level1.children"
            :key="level1.route"
            :exact="!!level1.exact"
            :to="{ name: level1.route, params: level1.params || undefined }"
            active-class="primary--text"
            class="pl-3"
          >
            <v-list-item-icon>
              <span :class="level1.icon"></span>
            </v-list-item-icon>
            <v-list-item-title>
              {{ level1.title.value }}
            </v-list-item-title>
          </v-list-item>

          <!-- prepare group for lvl2 -->
          <v-list-group
            v-else-if="level1.active"
            :key="level1.route"
            v-model="level1.active.value"
            :class="{ 'has-no-active-child': matchedRoutes[0]?.name !== level1.route }"
            no-action
            class="app-navigation__level2 pl-1"
          >
            <template #activator>
              <v-list-item
                :class="{ 'primary--text': matchedRoutes[0]?.name === level1.route }"
                class="px-0 list-item"
              >
                <v-list-item-icon>
                  <span :class="level1.icon"></span>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ level1.title.value }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <!-- only second level -->
            <template v-for="(level2) in level1.children">
              <v-list-item
                v-if="!level2.children"
                :key="level2.route"
                :exact="!!level2.exact"
                :to="{ name: level2.route, params: level2.params || undefined }"
                class="app-navigation__level3--item"
              >
                <v-list-item-title>
                  {{ level2.title.value }}
                </v-list-item-title>
              </v-list-item>

              <!-- prepare group for lvl3 -->
              <v-list-group
                v-else-if="level2.active"
                :key="level2.route"
                v-model="level2.active.value"
                :class="{ 'has-no-active-child': matchedRoutes.length > 1 && matchedRoutes[1].name !== level2.route }"
                no-action
                sub-group
                class="app-navigation__level4"
              >
                <template #activator>
                  <v-list-item-content
                    :class="{ 'primary--text': matchedRoutes.length > 1 && matchedRoutes[1].name === level2.route }"
                  >
                    <v-list-item-title>{{ level2.title.value }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(level3) in level2.children"
                  :key="level3.route"
                  :exact="!!level3.exact"
                  :to="{ name: level3.route, params: level3.params || undefined }"
                  class="app-navigation__level4--item"
                >
                  <v-list-item-title>
                    {{ level3.title.value }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list-group>
        </template>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import type { Permissions } from '@/api/interfaces/definitions/permission';
import type { Account } from '@/api/interfaces/user/account';
import { useNavigation } from '@/helpers/navigation/navigation';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import ToolbarActions from './ToolbarActions.vue';

interface Props {
  logout: () => void;
  permissions: Permissions;
  account: Account;
  navigation: {
    open: boolean;
    mini: boolean;
  };
  inboxCount?: number;
  isAccountReady?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isAccountReady: false,
  inboxCount: 0,
});

const matchedRoutes = computed(() => useRoute().matched);
const navigationItems = useNavigation(matchedRoutes.value);
</script>

<style lang="scss" scoped>
@import "vuetify/src/styles/styles";

// Toolbar
.app-navigation__toolbar {
  .theme--light & {
    border-bottom: 1px solid #e9ecef !important;
  }

  .theme--dark & {
    border-bottom: 1px solid #1e1e1e !important;
  }
}

.app-navigation {
  z-index: 6;

  @media only screen and (min-width: 2100px) {
    left: calc((100vw - 2100px) / 2);
  }

  :deep([class^="swiss21-"]),
  :deep([class*=" swiss21-"]) {
    font-size: 16px;
    padding: 4px;
  }

  &__level3 {
    &--item {
      padding-left: 48px !important;
    }
  }

  &__level4 {
    :deep(.v-list-group__header) {
      flex-direction: row-reverse;
      .v-list-group__header__prepend-icon {
        margin-right: 0 !important;
      }
      .v-list-item__content {
        padding-left: 26px;
      }
    }
  }

  :deep(.list-item) {
    margin: 2px 0 !important;
  }

  :deep(.v-list-item--link.v-list-item--active::before) {
    color: transparent;
  }

  :deep(.v-list-item--link.v-list-item--active:hover::before) {
    color: currentColor;
  }

  :deep(.has-no-active-child) {
    .theme--light & {
      color: map-deep-get($material-light, "text", "primary") !important;
      caret-color: map-deep-get($material-light, "text", "primary") !important;
    }

    .theme--dark & {
      color: map-deep-get($material-dark, "text", "primary") !important;
      caret-color: map-deep-get($material-dark, "text", "primary") !important;
    }

  }
}

</style>
