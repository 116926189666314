import axios from 'axios';
import * as types from './types';

export default {
  connectDeepCloudBankAccount({ rootState, commit }, { bankAccountId, abortController }) {
    // generate QR Code
    commit(types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT);
    const controller = abortController || new AbortController();

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/${bankAccountId}/connect`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT_CANCEL);
        return Promise.reject(error);
      }
      commit(types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  checkDeepCloudBankAccountStatus({ rootState, commit }, bankAccountId) {
    // check Status
    commit(types.CHECK_DEEP_CLOUD_BANK_ACCOUNT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/${bankAccountId}/connect-status`)
      .then((response) => {
        commit(types.CHECK_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS, response.data);
        return Promise.resolve(response.data);
      }).catch((error) => {
        commit(types.CHECK_DEEP_CLOUD_BANK_ACCOUNT_FAILURE, error);
        return Promise.reject(error);
      });
  },
  loginDeepCloudBankAccount({ rootState, dispatch, commit }, bankAccountId) {
    commit(types.LOGIN_DEEP_CLOUD_BANK_ACCOUNT);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/${bankAccountId}/customer-login`)
      .then((response) => {
        commit(types.LOGIN_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS, response.data);
        dispatch('finances/bankAccount/setSession', response, { root: true });
        return Promise.resolve(response.data);
      }).catch((error) => {
        commit(types.LOGIN_DEEP_CLOUD_BANK_ACCOUNT_FAILURE, error);
        return Promise.reject(error);
      });
  },
  async syncDeepCloudBankAccount({ dispatch, commit }, bankAccountId) {
    commit(types.SYNC_DEEP_CLOUD_BANK_ACCOUNT);
    const form = {
      id: bankAccountId,
      type: 'sync',
    };
    try {
      await dispatch('loginDeepCloudBankAccount', bankAccountId);
      await dispatch('finances/bankAccount/syncEbanking', form, { root: true });
      await dispatch('finances/bankAccount/logoutEbanking', null, { root: true });
      commit(types.SYNC_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS);
      return Promise.resolve(true);
    } catch (error) {
      commit(types.SYNC_DEEP_CLOUD_BANK_ACCOUNT_FAILURE);
      return Promise.reject(error);
    }
  },
};
