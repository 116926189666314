import * as types from './types';

export default {
  [types.FETCH_ACCOUNT](state) {
    state.isLoadingAccount = true;
  },
  [types.FETCH_ACCOUNT_SUCCESS](state, { data }) {
    state.account = data;
    state.isLoadingAccount = false;
  },
  [types.FETCH_ACCOUNT_CANCEL](state) {
    state.isLoadingAccount = false;
  },
  [types.FETCH_ACCOUNT_FAILURE](state) {
    state.isLoadingAccount = false;
  },

  [types.STORE_ACCOUNT](state) {
    state.isLoadingAccount = true;
  },
  [types.STORE_ACCOUNT_SUCCESS](state, { data }) {
    state.account = {
      ...state.account,
      ...data,
    };
    state.isLoadingAccount = false;
  },
  [types.STORE_ACCOUNT_FAILURE](state) {
    state.isLoadingAccount = false;
  },

  [types.FETCH_ACCOUNT_INVOICE](state) {
    state.isLoadingAccountInvoice = true;
  },
  [types.FETCH_ACCOUNT_INVOICE_SUCCESS](state, { data }) {
    state.account.invoice_item_taxes = data.invoice_item_taxes;
    state.account.invoice_terms = data.invoice_terms;

    state.account.payment_terms = data.payment_terms;

    state.account.condition_amount1 = data.condition_amount1;
    state.account.condition_amount2 = data.condition_amount2;
    state.account.condition_amount3 = data.condition_amount3;

    state.account.condition_days1 = data.condition_days1;
    state.account.condition_days2 = data.condition_days2;
    state.account.condition_days3 = data.condition_days3;

    state.isLoadingAccountInvoice = false;
  },
  [types.FETCH_ACCOUNT_INVOICE_CANCEL](state) {
    state.isLoadingAccountInvoice = false;
  },
  [types.FETCH_ACCOUNT_INVOICE_FAILURE](state) {
    state.isLoadingAccountInvoice = false;
  },

  [types.STORE_ACCOUNT_INVOICE](state) {
    state.isStoringAccountInvoice = true;
  },
  [types.STORE_ACCOUNT_INVOICE_SUCCESS](state, { data }) {
    state.account.invoice_item_taxes = data.invoice_item_taxes;
    state.account.invoice_terms = data.invoice_terms;
    state.isStoringAccountInvoice = false;
  },
  [types.STORE_ACCOUNT_INVOICE_FAILURE](state) {
    state.isStoringAccountInvoice = false;
  },

  [types.FETCH_ACCOUNT_INVOICE_PRICES](state) {
    state.isLoadingAccountInvoicePrices = true;
  },
  [types.FETCH_ACCOUNT_INVOICE_PRICES_SUCCESS](state, { data }) {
    state.account.prices_include_tax = data.prices_include_tax;
    state.isLoadingAccountInvoicePrices = false;
  },
  [types.FETCH_ACCOUNT_INVOICE_PRICES_CANCEL](state) {
    state.isLoadingAccountInvoicePrices = false;
  },
  [types.FETCH_ACCOUNT_INVOICE_PRICES_FAILURE](state) {
    state.isLoadingAccountInvoicePrices = false;
  },

  [types.STORE_ACCOUNT_INVOICE_PRICES](state) {
    state.isStoringAccountInvoicePrices = true;
  },
  [types.STORE_ACCOUNT_INVOICE_PRICES_SUCCESS](state, { data }) {
    state.account.prices_include_tax = data.prices_include_tax;
    state.isStoringAccountInvoicePrices = false;
  },
  [types.STORE_ACCOUNT_INVOICE_PRICES_FAILURE](state) {
    state.isStoringAccountInvoicePrices = false;
  },

  [types.STORE_ACCOUNT_QUOTE](state) {
    state.isStoringAccountQuote = true;
  },
  [types.STORE_ACCOUNT_QUOTE_SUCCESS](state, { data }) {
    state.account.quote_terms = data.quote_terms;
    state.isStoringAccountQuote = false;
  },
  [types.STORE_ACCOUNT_QUOTE_FAILURE](state) {
    state.isStoringAccountQuote = false;
  },

  [types.FETCH_ACCOUNT_QUOTE](state) {
    state.isLoadingAccountQuote = true;
  },
  [types.FETCH_ACCOUNT_QUOTE_SUCCESS](state, { data }) {
    state.account.quote_terms = data.quote_terms;
    state.isLoadingAccountQuote = false;
  },
  [types.FETCH_ACCOUNT_QUOTE_CANCEL](state) {
    state.isLoadingAccountQuote = false;
  },
  [types.FETCH_ACCOUNT_QUOTE_FAILURE](state) {
    state.isLoadingAccountQuote = false;
  },

  [types.STORE_ACCOUNT_DOCUMENTS](state) {
    state.isStoringAccountDocuments = true;
  },
  [types.STORE_ACCOUNT_DOCUMENTS_SUCCESS](state, { data }) {
    state.account.delivery_note_text = data.delivery_note_text;
    state.account.contract_note_text = data.contract_note_text;
    state.isStoringAccountDocuments = false;
  },
  [types.STORE_ACCOUNT_DOCUMENTS_FAILURE](state) {
    state.isStoringAccountDocuments = false;
  },

  [types.FETCH_ACCOUNT_DOCUMENTS](state) {
    state.isLoadingAccountDocuments = true;
  },
  [types.FETCH_ACCOUNT_DOCUMENTS_SUCCESS](state, { data }) {
    state.account.delivery_note_text = data.delivery_note_text;
    state.account.contract_note_text = data.contract_note_text;
    state.isLoadingAccountDocuments = false;
  },
  [types.FETCH_ACCOUNT_DOCUMENTS_CANCEL](state) {
    state.isLoadingAccountDocuments = false;
  },
  [types.FETCH_ACCOUNT_DOCUMENTS_FAILURE](state) {
    state.isLoadingAccountDocuments = false;
  },

  [types.STORE_ACCOUNT_DESIGN](state) {
    state.isStoringAccountDesign = true;
  },
  [types.STORE_ACCOUNT_DESIGN_SUCCESS](state, { data }) {
    state.account.invoice_footer = data.invoice_footer;
    state.account.custom_invoice_text_label1 = data.custom_invoice_text_label1;
    state.account.custom_invoice_text_label2 = data.custom_invoice_text_label2;
    state.account.custom_invoice_text_label3 = data.custom_invoice_text_label3;
    state.account.custom_invoice_text_label4 = data.custom_invoice_text_label4;
    state.design = data;
    state.isStoringAccountDesign = false;
  },
  [types.STORE_ACCOUNT_DESIGN_FAILURE](state) {
    state.isStoringAccountDesign = false;
  },

  [types.FETCH_ACCOUNT_DESIGN](state) {
    state.isLoadingAccountDesign = true;
  },
  [types.FETCH_ACCOUNT_DESIGN_SUCCESS](state, { data }) {
    state.account.invoice_footer = data.invoice_footer;
    state.account.custom_invoice_text_label1 = data.custom_invoice_text_label1;
    state.account.custom_invoice_text_label2 = data.custom_invoice_text_label2;
    state.account.custom_invoice_text_label3 = data.custom_invoice_text_label3;
    state.account.custom_invoice_text_label4 = data.custom_invoice_text_label4;
    state.design = data;
    state.isLoadingAccountDesign = false;
  },
  [types.FETCH_ACCOUNT_DESIGN_CANCEL](state) {
    state.isLoadingAccountDesign = false;
  },
  [types.FETCH_ACCOUNT_DESIGN_FAILURE](state) {
    state.isLoadingAccountDesign = false;
  },
};
