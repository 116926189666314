import type { Payload } from '@/api/interfaces/payload';
import { useGetEntities } from '@/stores/composables/use-get-entities';
import type { AxiosPromise } from 'axios';

export const useRequireEntities = <R>(
  handler: (abortController: AbortController) => AxiosPromise<Payload<Array<R>>>,
) => {
  const getEntities = useGetEntities(handler);

  async function requireEntities() {
    if (!getEntities.isLoading.value && !getEntities.hasLoaded.value) {
      await getEntities.getEntities();
    }
    return true;
  }

  return {
    ...getEntities,
    requireEntities,
  };
};
