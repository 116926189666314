<template>
  <v-row :class="{ 'no-print': !printHeader }" dense>
    <v-col v-if="backButton" class="flex-grow-0">
      <v-btn
        v-if="!backButtonDefinedRoute"
        icon
        @click="() => router.back()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        @click="() => router.push({ name: backButtonDefinedRoute })"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-col>
    <v-col>
      <v-row>
        <v-col cols="auto">
          <h1 class="text-h1">
            <slot name="title">{{ title }} {{ titleAppend }}</slot>
          </h1>
        </v-col>
        <v-col v-if="slots.status">
          <v-chip-group class="chip-group--no-hover">
            <slot name="status"></slot>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-col>
    <template v-if="slots.default">
      <v-col v-if="vuetify.breakpoint.smAndUp" class="actions" cols="auto">
        <slot></slot>
      </v-col>
      <v-footer v-else class="footer" fixed>
        <slot></slot>
      </v-footer>
    </template>
  </v-row>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router/composables';
import { useSlots } from 'vue';
import { useVuetify } from '@/helpers/vuetify';

interface Props {
  title: string;
  titleAppend?: string;
  printHeader?: boolean;
  backButton?: boolean;
  backButtonDefinedRoute?: string;
}

withDefaults(defineProps<Props>(), {
  titleAppend: '',
  printHeader: true,
  backButtonDefinedRoute: '',
});

const router = useRouter();
const slots = useSlots();
const vuetify = useVuetify();
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import "vuetify/src/styles/styles";

.actions {
  display: flex;
  align-items: center;
  gap: $ninja-base-spacer * 2;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid map-get($material-light, "dividers");
  gap: $ninja-base-spacer * 2;
}
</style>
