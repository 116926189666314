import store from '@/store';
import type { InternalAxiosRequestConfig } from 'axios';

export const requestApiInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (config.baseURL !== import.meta.env.VITE_URL) {
    return config;
  }

  const { uuid } = store.state.definitions.account.account;

  return {
    ...config,
    url: `/accounts/${uuid}${config.url}`,
  };
};
