import { requestBasePathInterceptor } from '@/interceptors/request-base-path-interceptor';
import { requestBearerTokenInterceptor } from '@/interceptors/request-bearer-token-interceptor';
import { requestParamsSerializerInterceptor } from '@/interceptors/request-params-serializer-interceptor';
import { requestWebapiInterceptor } from '@/interceptors/request-webapi-interceptor';
import axios from 'axios';

const httpClient = axios.create({
  baseURL: `${import.meta.env.VITE_URL}`,
});

/*
 * Create Base Url Path
 */
httpClient.interceptors.request.use(requestBasePathInterceptor);

/*
 * Attach /webapi/accounts/uuid/ as prefix to internal urls
 */
httpClient.interceptors.request.use(requestWebapiInterceptor);

/*
 * Attach Bearer token and content type to external requests request
 */
httpClient.interceptors.request.use(requestBearerTokenInterceptor);

/*
 * Apply params serializer
 */
httpClient.interceptors.request.use(requestParamsSerializerInterceptor);

export default httpClient;
