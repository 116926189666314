import * as types from './types';

const mutations = {
  [types.FETCH_COMPANIES_PENDING](state, isPending) {
    state.companiesPending = isPending;
  },
  [types.FETCH_COMPANIES](state, { data }) {
    state.companies = data;
  },
};

export default mutations;
