// initial data load
export const FETCH_ACCOUNT_PLAN = 'FETCH_ACCOUNT_PLAN';
export const FETCH_ACCOUNT_PLAN_SUCCESS = 'FETCH_ACCOUNT_PLAN_SUCCESS';
export const FETCH_ACCOUNT_PLAN_FAILURE = 'FETCH_ACCOUNT_PLAN_FAILURE';
export const FETCH_ACCOUNT_PLAN_CANCEL = 'FETCH_ACCOUNT_PLAN_CANCEL';

// import account plan
export const IMPORT_ACC_PLAN_JSON = 'IMPORT_ACC_PLAN_JSON';
export const IMPORT_ACC_PLAN_JSON_SUCCESS = 'IMPORT_ACC_PLAN_JSON_SUCCESS';
export const IMPORT_ACC_PLAN_JSON_CANCEL = 'IMPORT_ACC_PLAN_JSON_CANCEL';
export const IMPORT_ACC_PLAN_JSON_FAILURE = 'IMPORT_ACC_PLAN_JSON_FAILURE';

// import account plan
export const IMPORT_ACC_PLAN_AMID = 'IMPORT_ACC_PLAN_AMID';
export const IMPORT_ACC_PLAN_AMID_SUCCESS = 'IMPORT_ACC_PLAN_AMID_SUCCESS';
export const IMPORT_ACC_PLAN_AMID_CANCEL = 'IMPORT_ACC_PLAN_AMID_CANCEL';
export const IMPORT_ACC_PLAN_AMID_FAILURE = 'IMPORT_ACC_PLAN_AMID_FAILURE';

// generate account plan from template
export const GENERATE_ACC_PLAN_FROM_TEMPLATE = 'GENERATE_ACC_PLAN_FROM_TEMPLATE';
export const GENERATE_ACC_PLAN_FROM_TEMPLATE_SUCCESS = 'GENERATE_ACC_PLAN_FROM_TEMPLATE_SUCCESS';
export const GENERATE_ACC_PLAN_FROM_TEMPLATE_FAILURE = 'GENERATE_ACC_PLAN_FROM_TEMPLATE_FAILURE';

// delete account plan
export const DELETE_ACCOUNT_PLAN = 'DELETE_ACCOUNT_PLAN';
export const DELETE_ACCOUNT_PLAN_SUCCESS = 'DELETE_ACCOUNT_PLAN_SUCCESS';
export const DELETE_ACCOUNT_PLAN_FAILURE = 'DELETE_ACCOUNT_PLAN_FAILURE';

// fetch classifications (flat array)
export const FETCH_CLASSIFICATIONS = 'FETCH_CLASSIFICATIONS';
export const FETCH_CLASSIFICATIONS_SUCCESS = 'FETCH_CLASSIFICATIONS_SUCCESS';
export const FETCH_CLASSIFICATIONS_FAILURE = 'FETCH_CLASSIFICATIONS_FAILURE';
export const FETCH_CLASSIFICATIONS_CANCEL = 'FETCH_CLASSIFICATIONS_CANCEL';

// fetch account plan master
export const FETCH_ACCOUNT_PLAN_MASTER = 'FETCH_ACCOUNT_PLAN_MASTER';
export const FETCH_ACCOUNT_PLAN_MASTER_SUCCESS = 'FETCH_ACCOUNT_PLAN_MASTER_SUCCESS';
export const FETCH_ACCOUNT_PLAN_MASTER_CANCEL = 'FETCH_ACCOUNT_PLAN_MASTER_CANCEL';
export const FETCH_ACCOUNT_PLAN_MASTER_FAILURE = 'FETCH_ACCOUNT_PLAN_MASTER_FAILURE';

// fetch account plan sheet PDF export
export const FETCH_ACCOUNT_PLAN_PDF_EXPORT = 'FETCH_ACCOUNT_PLAN_PDF_EXPORT';
export const FETCH_ACCOUNT_PLAN_PDF_EXPORT_SUCCESS = 'FETCH_ACCOUNT_PLAN_PDF_EXPORT_SUCCESS';
export const FETCH_ACCOUNT_PLAN_PDF_EXPORT_FAILURE = 'FETCH_ACCOUNT_PLAN_PDF_EXPORT_FAILURE';
export const FETCH_ACCOUNT_PLAN_PDF_EXPORT_CANCEL = 'FETCH_ACCOUNT_PLAN_PDF_EXPORT_CANCEL';

// fetch account plan sheet XLS export
export const FETCH_ACCOUNT_PLAN_XLS_EXPORT = 'FETCH_ACCOUNT_PLAN_XLS_EXPORT_PENDING';
export const FETCH_ACCOUNT_PLAN_XLS_EXPORT_SUCCESS = 'FETCH_ACCOUNT_PLAN_XLS_EXPORT_SUCCESS';
export const FETCH_ACCOUNT_PLAN_XLS_EXPORT_FAILURE = 'FETCH_ACCOUNT_PLAN_XLS_EXPORT_FAILURE';
export const FETCH_ACCOUNT_PLAN_XLS_EXPORT_CANCEL = 'FETCH_ACCOUNT_PLAN_XLS_EXPORT_CANCEL';
