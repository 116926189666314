<template>
  <v-slide-y-transition>
    <v-alert
      v-show="isNewVersionAvailable"
      dense
      color="warning"
      class="version-info"
    >
      <span>{{ $t('texts.new_version_available') }}&nbsp;</span>
      <v-btn outlined small @click="refresh">{{ $t('texts.refresh') }}</v-btn>
    </v-alert>
  </v-slide-y-transition>
</template>
<script>

export default {
  data() {
    return {
      intervalTimer: null,
      indexHtml: null,
      isNewVersionAvailable: false,
    };
  },
  beforeDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  },
  created() {
    if (import.meta.env.VITE_ENVIRONMENT === 'local') {
      return;
    }
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.intervalTimer = setInterval(() => {
      this.checkVersion();
    }, 60000);
  },
  methods: {
    refresh() {
      window.location.reload(true);
    },
    async checkVersion() {
      await fetch('index.html', { cache: 'no-cache' }).then(
        (response) => {
          if (response.status !== 200) {
            return Promise.reject(new Error('index.html is offline'));
          }
          return Promise.resolve(response.text());
        },
      ).then(
        (html) => {
          if (!this.indexHtml) {
            this.indexHtml = html;
            return Promise.resolve();
          }
          if (this.indexHtml !== html) {
            this.isNewVersionAvailable = true;
            clearInterval(this.intervalTimer);
          }
          return Promise.resolve();
        },
      ).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.version-info {
  z-index: 7;
  width: 100%;
  position: fixed;
  top: 57px;
  padding: 2px;
  text-align: center;
}
</style>
