import { employeeGroupsRoutes } from '@/router/time-tracking/employee-groups';
import { employeesRoutes } from '@/router/time-tracking/employees';

export default {
  path: '/time-tracking',
  name: 'time',
  component: () => import('@/views/PassThrough.vue'),
  children: [
    ...employeesRoutes,
    ...employeeGroupsRoutes,

    {
      path: 'default/:employeeUuid?/:date?',
      name: 'time-tracking-default',
      component: () => import('@/pages/time-tracking/TimeTrackingDefault.vue'),
      props: true,
    },
    {
      path: 'list/:employeeUuid/:date',
      name: 'time-tracking',
      component: () => import('@/pages/time-tracking/TimeTrackingList.vue'),
      props: true,
    },
    {
      path: ':employeeUuid/:activityUuid?',
      name: 'activity',
      component: () => import('@/pages/time-tracking/activities/ActivityForm.vue'),
      props: true,
    },
  ],
};
