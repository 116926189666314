import initialState from '@/store/modules/accounting/account-statement/state';
import * as types from './types';

const mutations = {

  // FETCH_JOURNAL
  [types.FETCH_JOURNAL](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
    state.journal = [];
    state.meta = {
      ...initialState.meta,
    };
  },
  [types.FETCH_JOURNAL_SUCCESS](state, { data, meta }) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;
    state.journal = data;
    state.meta = {
      ...state.meta,
      max_page: meta.max_page,
      extend_search: meta.extend_search,
    };
  },
  [types.FETCH_JOURNAL_FAILURE](state, error) {
    state.hasLoaded = false;
    state.isLoading = false;
    state.error = error;
    state.journal = [];
  },
  [types.FETCH_JOURNAL_CANCEL](state) {
    state.isLoading = false;
    state.error = null;
  },

  // FETCH_MORE_JOURNAL
  [types.FETCH_MORE_JOURNAL](state) {
    state.isLoading = true;
  },
  [types.FETCH_MORE_JOURNAL_SUCCESS](state, { data, meta }) {
    state.isLoading = false;
    state.journal = [...state.journal, ...data];
    state.meta.page = meta.page;
  },
  [types.FETCH_MORE_JOURNAL_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.FETCH_MORE_JOURNAL_CANCEL](state) {
    state.isLoading = false;
    state.error = null;
  },

  // FETCH_ACCOUNT_STATEMENT_PDF_EXPORT
  [types.FETCH_JOURNAL_PDF_EXPORT](state) {
    state.isLoadingJournalPdfExport = true;
  },
  [types.FETCH_JOURNAL_PDF_EXPORT_SUCCESS](state) {
    state.isLoadingJournalPdfExport = false;
  },
  [types.FETCH_JOURNAL_PDF_EXPORT_FAILURE](state) {
    state.isLoadingJournalPdfExport = false;
  },
  [types.FETCH_JOURNAL_PDF_EXPORT_CANCEL](state) {
    state.isLoadingJournalPdfExport = false;
  },

  // FETCH_ACCOUNT_STATEMENT_XLS_EXPORT
  [types.FETCH_JOURNAL_XLS_EXPORT](state) {
    state.isLoadingJournalXlsExport = true;
  },
  [types.FETCH_JOURNAL_XLS_EXPORT_SUCCESS](state) {
    state.isLoadingJournalXlsExport = false;
  },
  [types.FETCH_JOURNAL_XLS_EXPORT_FAILURE](state) {
    state.isLoadingJournalXlsExport = false;
  },
  [types.FETCH_JOURNAL_XLS_EXPORT_CANCEL](state) {
    state.isLoadingJournalXlsExport = false;
  },

  [types.SET_SEARCH](state, {
    form, valutaRangeValue, valutaRange, bookingDateRangeValue, bookingDateRange, selectedOperator,
  }) {
    state.isSearching = true;
    state.search.form = form;
    state.search.valutaRangeValue = valutaRangeValue;
    state.search.valutaRange = valutaRange;
    state.search.bookingDateRangeValue = bookingDateRangeValue;
    state.search.bookingDateRange = bookingDateRange;
    state.search.selectedOperator = selectedOperator;
  },
  [types.RESET_SEARCH](state) {
    state.isSearching = false;
    state.search = { ...initialState.search };
  },

  // OLD

  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_ENTRIES
  [types.FETCH_ENTRIES_PENDING](state) {
    state.entries = [];
    state.entriesLoading = true;
  },
  [types.FETCH_ENTRIES_FULFILLED](state, { data }) {
    state.entries = data;
    state.lastLoaded = false;
    state.nextEntriesPending = false;
    state.entriesLoading = false;
  },
  [types.FETCH_ENTRIES_ERROR](state) {
    state.entriesLoading = false;
  },
  // FETCH_NEXT_ENTRIES
  [types.FETCH_NEXT_ENTRIES_PENDING](state) {
    state.nextEntriesPending = true;
  },
  [types.FETCH_NEXT_ENTRIES_FULFILLED](state, { data }) {
    if (data.length === 0) {
      state.lastLoaded = true;
      return;
    }

    state.lastLoaded = false;
    state.entries = [...state.entries.concat(data)];
    state.nextEntriesPending = false;
  },
  // UPDATE_PARAMS
  [types.UPDATE_PARAMS](state, params) {
    Object.assign(state.params, params);
  },
  // STORE_ENTRY
  [types.STORE_ENTRY_PENDING](state) {
    state.storeEntryPending = true;
  },
  [types.STORE_ENTRY_FULFILLED](state) {
    state.storeEntryPending = false;
  },
  [types.STORE_ENTRY_ERROR](state) {
    state.storeEntryPending = false;
  },
  // FETCH_JOURNAL_EXPORT
  [types.FETCH_JOURNAL_EXPORT_PENDING](state) {
    state.fetchJournalExportPending = true;
  },
  [types.FETCH_JOURNAL_EXPORT_FULFILLED](state) {
    state.fetchJournalExportPending = false;
  },
  [types.FETCH_JOURNAL_EXPORT_ERROR](state) {
    state.fetchJournalExportPending = false;
  },
  // SYNC_ENTRY
  [types.SYNC_ENTRY_PENDING](state) {
    state.syncEntryPending = true;
  },
  [types.SYNC_ENTRY_FULFILLED](state) {
    state.syncEntryPending = false;
  },
  [types.SYNC_ENTRY_ERROR](state) {
    state.syncEntryPending = false;
  },
  // STORE_BULK
  [types.STORE_BULK_PENDING](state) {
    state.storeBulkPending = true;
  },
  [types.STORE_BULK_FULFILLED](state) {
    state.storeBulkPending = false;
  },
  [types.STORE_BULK_ERROR](state) {
    state.storeBulkPending = false;
  },
};

export default mutations;
