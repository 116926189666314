import axios from 'axios';

const CALLBACK_ABACUS_PENDING = 'CALLBACK_ABACUS_PENDING';
const CALLBACK_ABACUS_FULFILLED = 'CALLBACK_ABACUS_FULFILLED';
const CALLBACK_ABACUS_ERROR = 'CALLBACK_ABACUS_ERROR';

const initialState = {
  fetchAbabankBanksPending: false,
  ababankBanks: [],
  storeAbabankPending: false,
  storePostfinanceAccountPending: false,
  fetchPostfinanceAccountsPending: false,
  postfinanceAccounts: [],
  fetchPostfinanceDocumentsPending: false,
  fetchAbabankStatementsPending: false,
  removeAbabankPending: false,
  connectAbabankPending: false,
  onboardingAbaBankPending: false,
  callbackAbacusPending: false,
};

const mutations = {
  [CALLBACK_ABACUS_PENDING](state) {
    state.callbackAbacusPending = true;
  },
  [CALLBACK_ABACUS_FULFILLED](state) {
    state.callbackAbacusPending = false;
  },
  [CALLBACK_ABACUS_ERROR](state) {
    state.callbackAbacusPending = false;
  },
};

const actions = {
  async handleCallback({ commit, rootState }, { form }) {
    commit(CALLBACK_ABACUS_PENDING);
    try {
      const params = { params: { ...form } };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/oauth/cb`, params);
      commit(CALLBACK_ABACUS_FULFILLED);
      return Promise.resolve(response);
    } catch (error) {
      commit(CALLBACK_ABACUS_ERROR);
      return Promise.reject(error.data);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
