import { useI18n } from '@/helpers/i18n';
import { useMoment } from '@/helpers/moment';
import { useJournalYear } from '@/helpers/use-journal-year';
import { useTimeActivityTypesStore } from '@/stores/time/settings/time-activity-types';
import VeeValidate, { Validator } from 'vee-validate';
import de from 'vee-validate/dist/locale/de';
import en from 'vee-validate/dist/locale/en';
import fr from 'vee-validate/dist/locale/fr';
import it from 'vee-validate/dist/locale/it';
import Vue from 'vue';

const locale = 'de';
const i18n = useI18n();
const moment = useMoment();

Vue.use(VeeValidate, {
  errorBagName: 'errors',
  locale,
  inject: false,
});

Validator.localize('de', de);
Validator.localize('it', it);
Validator.localize('en', en);
Validator.localize('fr', fr);
Validator.localize(locale);

Validator.extend('duplicate', {
  getMessage(field) {
    return i18n.tc('texts.duplicate_field', undefined, { field });
  },
  validate(value, args) {
    return Array.isArray(args) ? !args.find((arg) => arg === value) : args !== value;
  },
});

Validator.extend('isBookingDateValid', {
  validate: (date) => useJournalYear().isBookingDateValid(date),
});

Validator.extend('min_count', {
  getMessage(field, [count]) {
    return i18n.tc('texts.validator_min_count', count, { count });
  },
  validate(value, [count]) {
    return {
      valid: value.length >= parseInt(count, 10),
      data: {
        required: true,
      },
    };
  },
}, {
  computesRequired: true,
});

Validator.extend('greaterThan', {
  validate(value, [minValue]) {
    if (minValue === false) {
      return true;
    }

    return value > minValue;
  },
  getMessage(field, [minValue]) {
    return i18n.t('texts.activity_nr_not_in_range', { from: 1, to: minValue });
  },
});

Validator.extend('uniqueActivityTypeNumber', {
  async validate(value, [currentUuid]) {
    if (currentUuid === false) {
      return true;
    }

    const timeActivityTypesStore = useTimeActivityTypesStore();

    timeActivityTypesStore.cancel();

    try {
      const response = await timeActivityTypesStore.getEntities();
      const duplicateActivityNumber = response.data.data.find((activity) => activity.activityNumber === value);
      if (duplicateActivityNumber) {
        return duplicateActivityNumber.uuid === currentUuid;
      }
      return true;
    } catch {
      return false;
    }
  },
  getMessage() {
    return i18n.t('texts.activity_nr_duplicate');
  },
});

Validator.extend('time', {
  validate: (value) => moment(value, 'HH:mm').isValid(),
  getMessage: () => i18n.tc('texts.time_not_valid'),
});

Validator.extend('timeGreaterThan', {
  paramNames: ['target'],

  validate: (value, { target }) => {
    const timeValue = moment(value, 'HH:mm');
    const timeTarget = moment(target, 'HH:mm');

    if (!timeValue.isValid() || !timeTarget.isValid()) {
      return false;
    }
    return moment(timeValue).isAfter(timeTarget);
  },

  getMessage: (field) => i18n.tc('texts.time_needs_to_be_greater_than', undefined, { target: field }),
}, {
  hasTarget: true,
});

Validator.extend('dateGreaterOrEqualThan', {
  paramNames: ['target'],

  validate: (value, { target }) => (target ? moment(value).isSameOrAfter(target) : false),
}, {
  hasTarget: true,
});
