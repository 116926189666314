import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    controlReport: [],

    isLoading: false,
    hasLoaded: false,

    isLoadingVatControlExport: false,
    hasLoadedVatControlExport: false,

    year: null,
    period: {},

    error: null,

    controlReportMeta: {},
  },
  actions,
  mutations,
};
