import axios from 'axios';
import * as types from './types';

export default {
  fetchProductGroup({ rootState, commit }, { id, abortController }) {
    const controller = abortController || new AbortController();
    commit(types.FETCH_PRODUCT_GROUP);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/products/groups/${id}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_PRODUCT_GROUP_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_PRODUCT_GROUP_CANCEL);
      } else {
        commit(types.FETCH_PRODUCT_GROUP_FAILURE, error);
      }
      return Promise.reject(error);
    });
  },

  storeProductGroup({ rootState, commit }, productGroup) {
    const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/products/groups`;

    commit(types.STORE_PRODUCT_GROUP);

    let promise;
    if (!productGroup.id) {
      promise = axios.post(baseUrl, productGroup);
    } else {
      promise = axios.patch(`${baseUrl}/${productGroup.id}`, productGroup);
    }

    return promise.then((response) => {
      commit(types.STORE_PRODUCT_GROUP_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.STORE_PRODUCT_GROUP_FAILURE, error);
      return Promise.reject(error);
    });
  },

  async validateProductGroupNumber({ rootState, commit }, { groupNumber, groupId }) {
    commit(types.VALIDATE_PRODUCT_GROUP_NUMBER);
    try {
      await axios.get(
        `/accounts/${rootState.definitions.account.account.uuid}/products/groups/checkgroupnumber/${groupNumber}`,
        { params: { groupId } },
      );
      return { valid: true };
    } catch {
      return { valid: false };
    } finally {
      commit(types.FINISHED_VALIDATING_PRODUCT_GROUP_NUMBER);
    }
  },
};
