// initial data load
export const FETCH_OPENING_BALANCE = 'FETCH_OPENING_BALANCE';
export const FETCH_OPENING_BALANCE_SUCCESS = 'FETCH_OPENING_BALANCE_SUCCESS';
export const FETCH_OPENING_BALANCE_FAILURE = 'FETCH_OPENING_BALANCE_FAILURE';
export const FETCH_OPENING_BALANCE_CANCEL = 'FETCH_OPENING_BALANCE_CANCEL';

// fetch balance sheet PDF export
export const FETCH_OPENING_BALANCE_PDF_EXPORT = 'FETCH_OPENING_BALANCE_PDF_EXPORT';
export const FETCH_OPENING_BALANCE_PDF_EXPORT_SUCCESS = 'FETCH_OPENING_BALANCE_PDF_EXPORT_SUCCESS';
export const FETCH_OPENING_BALANCE_PDF_EXPORT_FAILURE = 'FETCH_OPENING_BALANCE_PDF_EXPORT_FAILURE';
export const FETCH_OPENING_BALANCE_PDF_EXPORT_CANCEL = 'FETCH_OPENING_BALANCE_PDF_EXPORT_CANCEL';

// fetch balance sheet XLS export
export const FETCH_OPENING_BALANCE_XLS_EXPORT = 'FETCH_OPENING_BALANCE_XLS_EXPORT_PENDING';
export const FETCH_OPENING_BALANCE_XLS_EXPORT_SUCCESS = 'FETCH_OPENING_BALANCE_XLS_EXPORT_SUCCESS';
export const FETCH_OPENING_BALANCE_XLS_EXPORT_FAILURE = 'FETCH_OPENING_BALANCE_XLS_EXPORT_FAILURE';
export const FETCH_OPENING_BALANCE_XLS_EXPORT_CANCEL = 'FETCH_OPENING_BALANCE_XLS_EXPORT_CANCEL';
