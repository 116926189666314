export default {
  isLoadingAccountProposal: false,
  hasLoadedAccountProposal: false,
  accountProposalError: null,

  isPostingAccountProposal: false,
  postingError: null,

  isPatchingAccountProposal: false,
  patchingError: null,

  isDeletingAccountProposal: false,
  deletingError: null,

  accountProposal: null,
};
