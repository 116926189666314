export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const FETCH_COMMENTS_FULFILLED = 'FETCH_COMMENTS_FULFILLED';
export const FETCH_COMMENTS_ERROR = 'FETCH_COMMENTS_ERROR';

export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_FULFILLED = 'POST_COMMENT_FULFILLED';
export const POST_COMMENT_ERROR = 'POST_COMMENT_ERROR';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_FULFILLED = 'DELETE_COMMENT_FULFILLED';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const RESET_COMMENT = 'RESET_COMMENT';
export const UPDATE_COMMENT_META = 'UPDATE_COMMENT_META';
