<template>
  <page-loader v-if="fetchBankAccountPending || !qrCodeUri || isSyncingDeepCloudBankAccount" />
  <v-container v-else>
    <aba-title-actions
      :title="$tc('texts.deepcloud_add_bank_account_direct')"
      back-button
    />
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col :cols="12">
                {{ $t('texts.deepcloud_add_bank_account_without_connection_yapeal') }}
              </v-col>
            </v-row>
            <v-row v-if="!!qrCodeUri">
              <v-col>
                <img :src="qrCodeUri" alt="" width="300">
              </v-col>
            </v-row>
          </v-card-text>
          <v-alert class="mb-0" type="info">
            <!-- YAPEAL bank account limitation -->
            <span>{{ $t('texts.banking_account_limitation_company') }}.</span>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapState: mapBankAccountState,
  mapActions: mapBankAccountActions,
} = createNamespacedHelpers('finances/bankAccount');
const {
  mapState: mapDeepCloudConnectState,
  mapActions: mapDeepCloudConnectActions,
} = createNamespacedHelpers('finances/deepCloudConnect');
const { mapActions: mapMessagesActions } = createNamespacedHelpers('messages');

export default {
  data() {
    return {
      id: null,
      maxRetries: 60,
      qrCodeUri: '',
      connectDeepCloudBankAccountAbortController: new AbortController(),
    };
  },
  computed: {
    ...mapBankAccountState([
      'fetchBankAccountPending',
      'bankAccount',
    ]),
    ...mapDeepCloudConnectState([
      'isConnectingDeepCloudBankAccount',
      'isCheckingDeepCloudBankAccount',
      'isSyncingDeepCloudBankAccount',
    ]),
  },
  created() {
    this.id = parseInt(this.$route.params.id, 10);
    if (!this.bankAccount.id || this.bankAccount.id !== this.id) {
      this.fetchBankAccount(this.id).then(() => {
        this.connect();
      });
    } else {
      this.connect();
    }
  },
  destroyed() {
    this.connectDeepCloudBankAccountAbortController.abort();
  },
  methods: {
    ...mapBankAccountActions([
      'fetchBankAccount',
    ]),
    ...mapDeepCloudConnectActions([
      'connectDeepCloudBankAccount',
      'checkDeepCloudBankAccountStatus',
      'syncDeepCloudBankAccount',
    ]),
    ...mapMessagesActions([
      'showMessage',
    ]),
    connect() {
      if (this.bankAccount.is_deepcloud_active) {
        return;
      }
      this.connectDeepCloudBankAccount({
        bankAccountId: this.id,
        abortController: this.connectDeepCloudBankAccountAbortController,
      }).then((response) => {
        this.qrCodeUri = response.qrCodeUri;
        this.check();
      });
    },
    check() {
      this.checkDeepCloudBankAccountStatus(this.id).then((response) => {
        this.maxRetries -= 1;
        if (response.active) {
          this.sync();
          return;
        }
        if (this.maxRetries === 0) {
          this.showMessage({ key: 'expired', color: 'error' });
          this.$router.push({ name: 'finances-banking' }).catch(() => {});
          return;
        }
        setTimeout(() => {
          this.check();
        }, 5000);
      }).catch(() => {
        this.$router.push({ name: 'finances-banking' }).catch(() => {});
      });
    },
    sync() {
      this.syncDeepCloudBankAccount(this.id).then(() => {
        this.showMessage({ key: 'connection_successful', color: 'success' });
        this.$router.push({ name: 'finances-banking' }).catch(() => {});
      });
    },
  },
};
</script>
