/* global moment */
import * as types from './types';

function earliestBookingDate(journalYears) {
  if (!journalYears || journalYears.length === 0) {
    return moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
  }

  const earliestJournalYearIndex = journalYears.findIndex((journalYear) => !journalYear.closed);
  const earliestJournalYear = journalYears[earliestJournalYearIndex];
  if (earliestJournalYearIndex && earliestJournalYearIndex > 0) {
    return earliestJournalYear.no_booking_before || earliestJournalYear.financial_year_start;
  }

  const earliestJournalYearIsCurrentYear = moment().isSameOrAfter(earliestJournalYear.financial_year_start)
    && moment().isSameOrBefore(earliestJournalYear.financial_year_end);
  if (!earliestJournalYearIsCurrentYear) {
    return earliestJournalYear.no_booking_before || earliestJournalYear.financial_year_start;
  }
  return moment(earliestJournalYear.financial_year_start).subtract(1, 'year').format('YYYY-MM-DD');
}

const mutations = {
  [types.INITIAL_LOAD_PENDING](state, isPending) {
    state.definitionsPending = isPending;
  },
  [types.INITIAL_LOAD_FULFILLED](state, { data }) {
    state.user = data.user;
  },
  [types.INITIAL_LOAD_ERROR](state) {
    state.definitionsPending = false;
  },
  [types.FETCH_BETA_FEATURES](state, { data }) {
    state.betaFeatures = data.map((tag) => tag.tag) || [];
  },
  [types.FETCH_ALL_ACCOUNTS](state, { data }) {
    state.activeAccounts = data.filter((item) => item.id);
    state.inactiveAccounts = data.filter((account) => !account.id);
    state.accountsWithoutCollective = data.filter((item) => item.is_collective_account === false);
  },
  [types.SET_FAVORITE_BOOKING_ACCOUNT](state, { id, isFavorite }) {
    const bookingAccountIndex = state.activeAccounts.findIndex((item) => item.id === id);
    state.activeAccounts[bookingAccountIndex].is_favorite = isFavorite;
  },
  [types.FETCH_MODULES](state, { data }) {
    state.activeModules = data;
  },
  [types.FETCH_BANK_ACCOUNTS](state, { data }) {
    state.bankAccounts = data;
  },
  [types.FETCH_FREQUENCIES](state, { data }) {
    state.frequencies = data;
  },
  [types.FETCH_EXCHANGE_RATES](state, { exchangeRates, currenciesSync }) {
    state.autoExchangeRates = currenciesSync.auto_exchange_rates;
    const grouped = [];
    const rateIndex = (currencyCode, exchangeCurrencyCode) => grouped.findIndex((item) => {
      const sameCurrencyCode = item.currency_code === currencyCode;
      const sameExchangeCurrenyCode = item.exchange_currency_code === exchangeCurrencyCode;
      return sameCurrencyCode && sameExchangeCurrenyCode;
    });
    exchangeRates
      .sort((a, b) => new Date(b.valid_from) - new Date(a.valid_from))
      .map((rate) => {
        let index = rateIndex(rate.currency_code, rate.exchange_currency_code);
        if (index < 0) {
          grouped.push({
            currency_code: rate.currency_code,
            exchange_currency_code: rate.exchange_currency_code,
            rates: [],
          });
          index = rateIndex(rate.currency_code, rate.exchange_currency_code);
        }
        if (index >= 0) {
          grouped[index].rates.push({
            id: rate.id,
            isManual: !!rate.account_id,
            rate: rate.rate,
            validFrom: rate.valid_from,
          });
        }
        return rate;
      });
    state.exchangeRates = grouped;
  },
  [types.FETCH_JOURNALYEARS](state, { data }) {
    state.journalYears = data;
    // set earliestPossibleBookingDate
    state.earliestBookingDate = earliestBookingDate(data);
  },
  [types.FETCH_ACCOUNTING_FRAMEWORKS](state, { data }) {
    state.accountingFrameworks = data;
  },
  [types.FETCH_ACCOUNTING_FRAMEWORKS_PENDING](state, isPending) {
    state.accountingFrameworksPending = isPending;
  },
  [types.FETCH_USER_SETTINGS](state, { data }) {
    state.userSetting = data;
  },
  [types.FETCH_USER_SETTINGS_PENDING](state, isPending) {
    state.userSettingPending = isPending;
  },
  [types.FETCH_USERS](state, { data }) {
    state.users = data;
  },
  [types.FETCH_USERS_PENDING](state) {
    state.fetchUsersPending = true;
  },
  [types.FETCH_USERS_FULFILLED](state, { data }) {
    state.users = data;
    state.fetchUsersPending = false;
  },
  [types.FETCH_USERS_ERROR](state) {
    state.fetchUsersPending = false;
  },
  [types.FETCH_USER_GROUPS](state, { data }) {
    state.userGroups = data;
  },
  [types.REGISTRATION_PENDING](state, isPending) {
    state.registrationPending = isPending;
  },
  [types.CONFIRM_PENDING](state, isPending) {
    state.confirmPending = isPending;
  },
  [types.LOGIN_STATE](state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  [types.STORE_USER_SETTINGS_PENDING](state, isPending) {
    state.userSettingStorePending = isPending;
  },
  [types.STORE_SETTINGS_PENDING](state, isPending) {
    state.storeSettingsPending = isPending;
  },
  [types.FETCH_LOCALIZATION_SETTINGS](state, { data }) {
    state.localizationSettings = data;
  },
  [types.FETCH_SETTINGS_PENDING](state, isPending) {
    state.fetchSettingsPending = isPending;
  },
  [types.FETCH_IMPORT_QUEUE](state, { data }) {
    state.importQueue = data;
  },
  [types.FETCH_IMPORT_DETAIL_INITIAL_PENDING](state, isPending) {
    state.importDetailInitialPending = isPending;
  },
  [types.FETCH_IMPORT_DETAIL_PENDING](state, isPending) {
    state.importDetailPending = isPending;
  },
  [types.FETCH_IMPORT_DETAIL](state, { data }) {
    state.importDetail = data;
  },
  // META
  [types.FETCH_TABLE_META](state, { meta, key }) {
    if (meta) {
      state.meta[key] = {
        sortDesc: [meta.direction === 'DESC'],
        itemsPerPage: parseInt(meta.per_page, 10),
        sortBy: [meta.sort],
        page: parseInt(meta.page, 10),
        search: meta.search,
        extendSearch: meta.extend_search,
        maxPage: parseInt(meta.max_page, 10),
        totalItems: meta.max_page * meta.per_page,
      };
    }
  },
  [types.FETCH_EXPORT_QUEUE](state, { data }) {
    state.exportQueue = data;
  },
  [types.IMPORT_ACC_JOURNAL_YEARS_PENDING](state, isPending) {
    state.importAccJournalYearsPending = isPending;
  },
  [types.STORE_JOURNAL_YEAR](state, { data }) {
    const journalYear = state.journalYears.findIndex((journalYearValue) => journalYearValue.id === data.id);
    if (journalYear === -1) {
      state.journalYears.push(data);
      return;
    }
    state.journalYears[journalYear] = data;
  },
  [types.STORE_ACC_ACCOUNT](state, { data }) {
    const activeAccountIndex = state.activeAccounts.findIndex((account) => account.id === data.id);
    if (activeAccountIndex === -1) {
      state.activeAccounts.push(data);
      return;
    }
    state.activeAccounts[activeAccountIndex] = data;
  },

  [types.FETCH_MODULE_SERVICES_SUCCESS](state, { data }) {
    state.services = data;
  },

  // UPDATE_LIMIT
  [types.UPDATE_LIMIT_PENDING](state) {
    state.updateLimitPending = true;
  },
  [types.UPDATE_LIMIT_FULFILLED](state) {
    state.updateLimitPending = false;
  },
  [types.UPDATE_LIMIT_ERROR](state) {
    state.updateLimitPending = false;
  },
  // REMOVE_ACC_BASE_ACCOUNTS
  [types.REMOVE_ACC_BASE_ACCOUNTS_PENDING](state) {
    state.removeAccBaseAccountPending = true;
  },
  [types.REMOVE_ACC_BASE_ACCOUNTS_FULFILLED](state) {
    state.removeAccBaseAccountPending = false;
  },
  [types.REMOVE_ACC_BASE_ACCOUNTS_ERROR](state) {
    state.removeAccBaseAccountPending = false;
  },
  // DEEPBOX FETCH INITIAL
  [types.FETCH_DEEPBOX_INITIAL_PENDING](state) {
    state.deepBoxFetchInitialPending = true;
  },
  [types.FETCH_DEEPBOX_INITIAL_FULFILLED](state, { data }) {
    state.deepBoxFetchInitialPending = false;
    state.deepBoxService = data;
  },
  [types.FETCH_DEEPBOX_INITIAL_ERROR](state) {
    state.deepBoxFetchInitialPending = false;
  },

  // DEEPBOX STORE SETTINGS
  [types.STORE_DEEPBOX_SETTINGS_PENDING](state) {
    state.storeDeepBoxSettingsPending = true;
  },
  [types.STORE_DEEPBOX_SETTINGS_FULFILLED](state) {
    state.storeDeepBoxSettingsPending = false;
  },
  [types.STORE_DEEPBOX_SETTINGS_ERROR](state) {
    state.storeDeepBoxSettingsPending = false;
  },

  [types.FETCH_NUMBER_RANGE_PENDING](state) {
    state.fetchNumberRangePending = true;
  },
  [types.FETCH_NUMBER_RANGE_FULFILLED](state, data) {
    state.numberRanges[data.type] = data;
    state.fetchNumberRangePending = false;
  },
  [types.FETCH_NUMBER_RANGE_ERROR](state) {
    state.fetchNumberRangePending = false;
  },

  [types.STORE_NUMBER_RANGE_PENDING](state) {
    state.storeNumberRangePending = true;
  },
  [types.STORE_NUMBER_RANGE_FULFILLED](state, data) {
    state.numberRanges[data.type] = data;
    state.storeNumberRangePending = false;
  },
  [types.STORE_NUMBER_RANGE_ERROR](state) {
    state.storeNumberRangePending = false;
  },

  [types.SET_NUMBER_RANGE_SETTINGS](state, { type, is_active: isActive }) {
    state.numberRanges[type].is_active = isActive;
  },
  // FETCH_AGREEMENTS
  [types.FETCH_AGREEMENTS_PENDING](state) {
    state.fetchAgreementsPending = true;
  },
  [types.FETCH_AGREEMENTS_SUCCESSFUL](state) {
    state.fetchAgreementsPending = false;
  },
  [types.FETCH_AGREEMENTS_ERROR](state) {
    state.fetchAgreementsPending = false;
  },
  // CONFIRM_AGREEMENTS
  [types.CONFIRM_AGREEMENTS_PENDING](state) {
    state.confirmAgreementsPending = true;
  },
  [types.CONFIRM_AGREEMENTS_SUCCESSFUL](state) {
    state.confirmAgreementsPending = false;
  },
  [types.CONFIRM_AGREEMENTS_ERROR](state) {
    state.confirmAgreementsPending = false;
  },
};
export default mutations;
