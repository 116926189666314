import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_VAT_JOURNAL
  [types.FETCH_VAT_JOURNAL_PENDING](state) {
    state.vatJournalPending = true;
  },
  [types.FETCH_VAT_JOURNAL_FULFILLED](state, { data, meta }) {
    state.vatJournal = data;
    state.vatJournalMeta = meta;
    state.vatJournalPending = false;
  },
  [types.FETCH_VAT_JOURNAL_ERROR](state) {
    state.vatJournalPending = true;
  },
  // ADD_VAT_NUMBER
  [types.ADD_VAT_NUMBER](state, key) {
    state.vatJournal[key] = {};
  },
  // CLOSE_VAT_JOURNAL
  [types.CLOSE_VAT_JOURNAL_PENDING](state) {
    state.closeJournalPending = true;
  },
  [types.CLOSE_VAT_JOURNAL_FULFILLED](state) {
    state.closeJournalPending = false;
  },
  [types.CLOSE_VAT_JOURNAL_ERROR](state) {
    state.closeJournalPending = false;
  },
};

export default mutations;
