const swiss21Subscriptions = [
  'Starter',
  'Basic',
  'Pro',
  'ProFree',
  'EnterpriseM',
  'EnterpriseL',
  'EnterpriseXL',
  'EnterpriseXXL',
  'EnterpriseInfinity',
];

export default {
  isNewSwiss21Subscription: (state) => !!state.activeModules.find((item) => swiss21Subscriptions.includes(item)),

  selectedSwiss21Subscription: (state, getters) => {
    if (!getters.isNewSwiss21Subscription) {
      return undefined;
    }
    let selected = '';
    swiss21Subscriptions.reverse().forEach((item) => {
      if (!selected && state.activeModules.includes(item)) {
        selected = item;
      }
      return item;
    });
    return selected;
  },
};
