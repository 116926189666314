import axios from 'axios';
import * as types from './types';

export default {
  fetchBaseTypes({ rootState, state, commit }, abortController) {
    if (state.baseTypes.length) {
      return Promise.resolve(state.baseTypes);
    }
    commit(types.FETCH_BASE_TYPES);
    const controller = abortController || new AbortController();

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-types`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_BASE_TYPES_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_BASE_TYPES_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_BASE_TYPES_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
