import * as types from './types';

const mutations = {
  [types.FETCH_INCOME_STATEMENT](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
  },
  [types.FETCH_INCOME_STATEMENT_SUCCESS](state, { data, meta }) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;

    state.incomeStatement = data;
    state.meta.availableLevels = meta.availableLevels;
    if (meta && meta.success) {
      state.meta.success = meta.success;
    } else {
      state.meta.success = [];
    }
  },
  [types.FETCH_INCOME_STATEMENT_CANCEL](state) {
    state.isLoading = false;
  },
  [types.FETCH_INCOME_STATEMENT_FAILURE](state, { error }) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },

  // fetch PDF balance sheet
  [types.FETCH_INCOME_STATEMENT_PDF_EXPORT](state) {
    state.isLoadingIncomeStatementPdfExport = true;
  },
  [types.FETCH_INCOME_STATEMENT_PDF_EXPORT_SUCCESS](state) {
    state.isLoadingIncomeStatementPdfExport = false;
  },
  [types.FETCH_INCOME_STATEMENT_PDF_EXPORT_FAILURE](state) {
    state.isLoadingIncomeStatementPdfExport = false;
  },
  [types.FETCH_INCOME_STATEMENT_PDF_EXPORT_CANCEL](state) {
    state.isLoadingIncomeStatementPdfExport = false;
  },

  // fetch XLS balance sheet
  [types.FETCH_INCOME_STATEMENT_XLS_EXPORT](state) {
    state.isLoadingIncomeStatementXlsExport = true;
  },
  [types.FETCH_INCOME_STATEMENT_XLS_EXPORT_SUCCESS](state) {
    state.isLoadingIncomeStatementXlsExport = false;
  },
  [types.FETCH_INCOME_STATEMENT_XLS_EXPORT_FAILURE](state) {
    state.isLoadingIncomeStatementXlsExport = false;
  },
  [types.FETCH_INCOME_STATEMENT_XLS_EXPORT_CANCEL](state) {
    state.isLoadingIncomeStatementXlsExport = false;
  },
};

export default mutations;
