export const FETCH_PRODUCT_GROUP_PENDING = 'FETCH_PRODUCT_GROUP_PENDING';
export const FETCH_PRODUCT_GROUP_FULFILLED = 'FETCH_PRODUCT_GROUP_FULFILLED';
export const FETCH_PRODUCT_GROUP_ERROR = 'FETCH_PRODUCT_GROUP_ERROR';

export const STORE_PRODUCT_GROUP_PENDING = 'STORE_PRODUCT_GROUP_PENDING';
export const STORE_PRODUCT_GROUP_FULFILLED = 'STORE_PRODUCT_GROUP_FULFILLED';
export const STORE_PRODUCT_GROUP_ERROR = 'STORE_PRODUCT_GROUP_ERROR';

export const VALIDATE_GROUP_NUMBER_PENDING = 'VALIDATE_GROUP_NUMBER_PENDING';
export const VALIDATE_GROUP_NUMBER_FULFILLED = 'VALIDATE_GROUP_NUMBER_FULFILLED';
export const RESET_PRODUCT_GROUP = 'RESET_PRODUCT_GROUP';

export const ARCHIVE_PRODUCT_GROUP_PENDING = 'ARCHIVE_PRODUCT_GROUP_PENDING';
export const ARCHIVE_PRODUCT_GROUP_FULFILLED = 'ARCHIVE_PRODUCT_GROUP_FULFILLED';
export const ARCHIVE_PRODUCT_GROUP_ERROR = 'ARCHIVE_PRODUCT_GROUP_ERROR';

export const RESTORE_PRODUCT_GROUP_PENDING = 'RESTORE_PRODUCT_GROUP_PENDING';
export const RESTORE_PRODUCT_GROUP_FULFILLED = 'RESTORE_PRODUCT_GROUP_FULFILLED';
export const RESTORE_PRODUCT_GROUP_ERROR = 'RESTORE_PRODUCT_GROUP_ERROR';
