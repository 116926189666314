import actions from '@/store/modules/accounting/account-proposal/actions';
import mutations from '@/store/modules/accounting/account-proposal/mutations';
import initialState from '@/store/modules/accounting/account-proposal/state';

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
};
