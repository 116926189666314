<template>
  <div>
    <template v-if="isAccountReady">
      <mega-menu v-if="hasPermissionToViewActionMenu" :menu-item-groups="permittedActionMenuItemGroups">
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            color="primary"
            depressed
            fab
            small
            v-on="on"
          >
            <v-icon class="text--white">mdi-plus</v-icon>
          </v-btn>
        </template>
      </mega-menu>
      <v-badge
        :value="inboxCount > 0"
        bordered
        class="mr-2"
        color="primary"
        offset-x="16"
        offset-y="16"
        overlap
      >
        <template #badge>
          <span>{{ inboxCount > 99 ? ':D' : inboxCount }}</span>
        </template>
        <v-btn
          :to="{ name: 'inbox' }"
          depressed
          fab
          small
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <account-menu
      v-if="account.id > 0 && permissionsStore.entity"
      :account="account"
      :is-account-ready="isAccountReady"
      :logout="logout"
      :permissions="permissionsStore.entity"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Account } from '@/api/interfaces/user/account';
import AccountMenu from '@/components/app-layout/AccountMenu.vue';
import type { ActionMenuItem, ActionMenuItemGroup } from '@/components/app-layout/action-menu-item-groups';
import parameterisedActionMenuItemGroups from '@/components/app-layout/action-menu-item-groups';
import MegaMenu from '@/components/MegaMenu.vue';
import { usePermissionsStore } from '@/stores/definitions/permissions';
import { useUserStore } from '@/stores/users/user';
import { computed } from 'vue';

interface Props {
  logout: () => void;
  account: Account;
  isAccountReady?: boolean;
  inboxCount?: number;
}

withDefaults(defineProps<Props>(), {
  isAccountReady: false,
  inboxCount: 0,
});

const permissionsStore = usePermissionsStore();
const userStore = useUserStore();

const employeeUuid = computed(() => userStore.entity?.employee_uuid);

const actionMenuItemGroups = computed<Array<ActionMenuItemGroup>>(() => parameterisedActionMenuItemGroups({
  employeeUuid: employeeUuid.value,
}));

function permittedMenuItems(children: Array<ActionMenuItem>) {
  return children.filter(
    (menuItem) => permissionsStore.hasTopLevelRoutePermission(menuItem.topLevelRoutePermission)
      && permissionsStore.hasRoutePermission(menuItem.topLevelRoutePermission)
      && permissionsStore.hasRoutePermission(menuItem.route),
  );
}

const permittedActionMenuItemGroups = computed<Array<ActionMenuItemGroup>>(() => actionMenuItemGroups.value
  .filter((menuItemGroup) => !!permittedMenuItems(menuItemGroup.children).length)
  .map((menuItemGroup) => ({
    ...menuItemGroup,
    children: permittedMenuItems(menuItemGroup.children),
  })));

const hasPermissionToViewActionMenu = computed(() => !!permittedActionMenuItemGroups.value.length);
</script>

<style lang="scss" scoped>
:deep(.v-badge--overlap .v-badge__badge) {
  top: 0;
  right: 0;
}
</style>
