import axios from 'axios';
import Vue from 'vue';
import * as types from './types';

const actions = {
  async fetchDefinitions({ rootState, commit }) {
    commit(types.INITIAL_LOAD_PENDING, true);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions`);
      commit(types.INITIAL_LOAD_FULFILLED, response);
      commit(types.INITIAL_LOAD_PENDING, false);
      return Promise.resolve(response.data);
    } catch (error) {
      console.error(`[INITIAL_LOAD_ERROR] ${error}`);
      commit(types.INITIAL_LOAD_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchBetaFeatures({ rootState, commit }) {
    const url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/settings/tags/addresses?per_page=all&extend_search[tag]=[`;
    const data = await axios.get(url);
    commit(types.FETCH_BETA_FEATURES, data);
  },
  async activateUnit({ rootState, commit }, { unitId }) {
    try {
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/units/${unitId}/activation`);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async addUnit({ rootState, commit }, unit) {
    try {
      commit(types.STORE_USER_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/accounts/${
        rootState.definitions.account.account.uuid
      }/units`, unit);
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async addMasterUnit({ rootState, commit }, unit) {
    try {
      commit(types.STORE_USER_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/accounts/${
        rootState.definitions.account.account.uuid
      }/units/${unit.id}`, unit);
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async updateUnit({ rootState, commit }, unit) {
    try {
      commit(types.STORE_USER_SETTINGS_PENDING, true);
      const data = await axios.patch(
        `/settings/accounts/${rootState.definitions.account.account.uuid}/units/${unit.id}`,
        unit,
      );
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchAllAccounts({ rootState, commit }) {
    commit(types.FETCH_ALL_ACCOUNTS, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journals`));
  },
  setFavoriteBookingAccount({ commit }, payload) {
    commit(types.SET_FAVORITE_BOOKING_ACCOUNT, payload);
  },
  async fetchModules({ rootState, commit }) {
    commit(types.FETCH_MODULES, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/modules`));
  },
  async fetchUsers({ rootState, commit }) {
    commit(types.FETCH_USERS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/users`);
      commit(types.FETCH_USERS_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_USERS_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchBankAccounts({ rootState, commit }) {
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/bankaccounts`);
      commit(types.FETCH_BANK_ACCOUNTS, response);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchFrequencies({ rootState, commit }) {
    commit(types.FETCH_FREQUENCIES, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/frequencies`));
  },
  async fetchJournalYears({ rootState, commit }) {
    commit(types.FETCH_JOURNALYEARS, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_years`));
  },
  async fetchAccountingFrameworks({ rootState, commit }) {
    commit(types.FETCH_ACCOUNTING_FRAMEWORKS, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/accounting_frameworks`));
  },
  async fetchAccountingFrameworksFiltered(
    { rootState, commit },
    { country_code: countryCode, legal_form_id: legalFormId },
  ) {
    try {
      commit(types.FETCH_ACCOUNTING_FRAMEWORKS_PENDING, true);
      const params = { country_code: countryCode, legal_form_id: legalFormId };
      const result = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/accounting_frameworks`, { params });

      commit(types.FETCH_ACCOUNTING_FRAMEWORKS, result);
      commit(types.FETCH_ACCOUNTING_FRAMEWORKS_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_ACCOUNTING_FRAMEWORKS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchUsersPermission({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/users`);
      commit(types.FETCH_USERS, data);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  fetchUserPermission({ rootState }, { userId }) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/users/${userId}`);
  },
  async storeUserPermission({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      let data;
      if (form.id) {
        data = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/users/${form.id}`, form);
      } else {
        data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/users`, form);
      }
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  inviteUserPermission({ rootState, commit }, { userId }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = axios.post(`/accounts/${rootState.definitions.account.account.uuid}/users/${userId}/confirmation`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async deleteUserPermission({ rootState, commit }, { userId }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/users/${userId}`, {});
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchUserGroups({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/groups`);
      commit(types.FETCH_USER_GROUPS, data);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeGroupPermission({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/groups/${form.id}`, form);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeSwiss21DefaultGroup({ rootState, commit }, { id }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/groups/${id}/default`, {});
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchUserSettings({ rootState, commit }) {
    try {
      commit(types.FETCH_USER_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/notifications`);
      commit(types.FETCH_USER_SETTINGS, data);
      commit(types.FETCH_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeUserSettings({ rootState, commit }, { userSetting }) {
    try {
      commit(types.STORE_USER_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/notifications`, userSetting);
      commit(types.FETCH_USER_SETTINGS, data);
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchLocalizationSettings({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/localization`);
      commit(types.FETCH_LOCALIZATION_SETTINGS, data);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeLocalizationSettings({ rootState, commit }, { localizationSettings }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/localization`, localizationSettings);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchModuleServices({ rootState, commit }) {
    try {
      commit(types.FETCH_USER_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/services`);
      commit(types.FETCH_MODULE_SERVICES_SUCCESS, data);
      commit(types.FETCH_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeModuleAbacus({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/services/abacus`, form);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeModuleAbaNet({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/${rootState.definitions.account.account.uuid}/services/abanet/connect`, form);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async storeModuleDeepBox({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_DEEPBOX_SETTINGS_PENDING);
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/settings/services/deepbox`, form);
      commit(types.STORE_DEEPBOX_SETTINGS_FULFILLED);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_DEEPBOX_SETTINGS_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchModuleService({ rootState, commit }, { key }) {
    try {
      commit(types.FETCH_USER_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/services/${key}`);
      commit(types.FETCH_USER_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_USER_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async deleteModuleService({ rootState, commit }, { key }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.delete(`/settings/accounts/${rootState.definitions.account.account.uuid}/services/${key}`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async confirmAccount({ rootState, commit, dispatch }, accountValue) {
    commit(types.CONFIRM_PENDING, true);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/confirm`, accountValue);
      window.location.href = '/dashboard';
      commit(types.CONFIRM_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.CONFIRM_PENDING, false);
      dispatch('messages/showMessage', {
        key: 'error_save_settings',
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async storeRegistration({ rootState, commit, dispatch }, accountValue) {
    commit(types.REGISTRATION_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/wizard`;
      const response = await axios.post(url, accountValue);
      commit(types.REGISTRATION_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.REGISTRATION_PENDING, false);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchAgreements({ rootState, commit, dispatch }) {
    commit(types.FETCH_AGREEMENTS_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/agreements`;
      const response = await axios.get(url);
      commit(types.FETCH_AGREEMENTS_SUCCESSFUL);
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.FETCH_AGREEMENTS_ERROR);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async confirmAgreements({ rootState, commit, dispatch }, data) {
    commit(types.CONFIRM_AGREEMENTS_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/agreements`;
      await axios.post(url, data);
      if (!rootState.definitions.account.account.has_all_agb_accepted) {
        commit(types.CONFIRM_AGREEMENTS_ERROR);
        return Promise.reject(new Error('CONFIRM_AGREEMENTS_ERROR'));
      }
      commit(types.CONFIRM_AGREEMENTS_SUCCESSFUL);
      return Promise.resolve();
    } catch (error) {
      commit(types.CONFIRM_AGREEMENTS_ERROR);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async storeSchoolAccount({ rootState, commit, dispatch }) {
    commit(types.REGISTRATION_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/copy`;
      const response = await axios.post(url);
      commit(types.REGISTRATION_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.REGISTRATION_PENDING, false);
      dispatch('messages/showMessage', {
        key: 'error_save_settings',
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  storeLogo({ rootState }, file) {
    const data = new FormData();
    data.set('logo', file);

    return axios.postForm(
      `/accounts/${rootState.definitions.account.account.uuid}/logo`,
      data,
      {
        transformRequest: (formData) => formData,
      },
    );
  },
  deleteLogo({ rootState }) {
    return axios.delete(
      `/accounts/${rootState.definitions.account.account.uuid}/logo`,
    );
  },
  loginState({ commit }, isLoggedIn) {
    commit(types.LOGIN_STATE, isLoggedIn);
    if (Vue.$tokenRefreshInterval) {
      clearInterval(Vue.$tokenRefreshInterval);
    }
  },
  storeTokenTime(context, tokenTime) {
    localStorage.setItem(`${document.location.host}_tokenCreateTime`, tokenTime);
  },
  fetchTokenTime() {
    const tokenTime = localStorage.getItem(`${document.location.host}_tokenCreateTime`);
    if (!tokenTime) {
      return null;
    }
    return new Date(tokenTime);
  },
  deleteTokenTime() {
    localStorage.removeItem(`${document.location.host}_tokenCreateTime`);
  },
  sessionKeepAlive({ rootState }) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
  },
  async patternPreview({ rootState }, { patternObject }) {
    const data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/pattern`, patternObject);
    return data.data.previewNumber;
  },
  async fetchReceiptNumber({ rootState, commit }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/receipts`);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return data.data;
  },
  async storeReceiptNumber({ rootState, commit }, { patternObject }) {
    commit(types.STORE_SETTINGS_PENDING, true);
    const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/receipts`, patternObject);
    commit(types.STORE_SETTINGS_PENDING, false);
    return data.data;
  },
  async fetchMailsGeneral({ rootState, commit }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/emails/general`);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return data.data;
  },
  async storeMailsGeneral({ rootState, commit }, { payload }) {
    commit(types.STORE_SETTINGS_PENDING, true);
    const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/emails/general`, payload);
    commit(types.STORE_SETTINGS_PENDING, false);
    return data.data;
  },
  async fetchMails({ rootState, commit }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/emails`);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return data.data;
  },
  async storeMails({ rootState, commit }, { payload }) {
    commit(types.STORE_SETTINGS_PENDING, true);
    const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/emails`, payload);
    commit(types.STORE_SETTINGS_PENDING, false);
    return data.data;
  },
  mailPreview({ rootState }, { previewHtml, mailType, locale, design, abortController }) {
    const controller = abortController || new AbortController();

    return axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/emails/preview`, {
      template: previewHtml,
      entityType: mailType,
      locale,
      design,
    }, { signal: controller.signal });
  },
  async fetchExchangeRates({ rootState, commit, dispatch }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const [exchangeRates, currenciesSync] = await Promise.all([
      axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rates`),
      dispatch('fetchCurrenciesSync'),
    ]);
    const payload = {
      exchangeRates: exchangeRates.data,
      currenciesSync,
    };
    commit(types.FETCH_EXCHANGE_RATES, payload);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return exchangeRates.data;
  },
  async fetchExchangeRate({ rootState, commit }, { id }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rates/${id}`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeExchangeRates({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      let data;
      if (payload.id) {
        data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rates/${payload.id}`, payload);
      } else {
        data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rates`, payload);
      }
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchCurrenciesSync({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rate/sync`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeCurrenciesSync({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rate/sync`, payload);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchJournalYearRates({ rootState, commit }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years`);
    commit(types.FETCH_JOURNALYEARS, data);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return data.data;
  },

  async createJournalYear({ rootState, commit }) {
    commit(types.STORE_SETTINGS_PENDING, true);
    const data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years`);
    commit(types.STORE_SETTINGS_PENDING, false);
    return data.data;
  },

  async activateJournalYear({ rootState, commit }, { journalYearId }) {
    commit(types.STORE_SETTINGS_PENDING, true);
    const journalYear = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years/${journalYearId}/activation`);
    const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years`);
    commit(types.FETCH_JOURNALYEARS, data);
    commit(types.STORE_SETTINGS_PENDING, false);
    return journalYear.data;
  },

  async closeJournalYear({ rootState, commit }, { journalYearId }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years/${journalYearId}/close`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(true);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async deleteJournalYear({ rootState, commit }, { journalYearId }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      await axios.delete(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years/${journalYearId}`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(true);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeJournalYear({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_journal_years/${payload.id}`, payload);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async exportAll({ commit, rootState }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/export/all`, payload);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async exportDatev({ commit, rootState }, payload) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/export_datev/csv`, payload, { responseType: 'blob' });
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async importForm({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = new FormData();
      data.append('source', 'CSV');
      data.append('macos', payload.macos);

      if (payload.product_file) {
        data.append('product_file', payload.product_file);
      }
      if (payload.address_file) {
        data.append('address_file', payload.address_file);
      }

      const result = await axios.postForm(
        `/accounts/${rootState.definitions.account.account.uuid}/import/csv/form`,
        data,
        {
          transformRequest: (formData) => formData,
        },
      );

      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(result);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async doImport({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/import/csv/process`, payload);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchImportQueue({ rootState, commit }) {
    try {
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/import/queue/list`);
      commit(types.FETCH_IMPORT_QUEUE, data);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchImportDetailInitial({ commit, dispatch }, { id, meta, metaKey }) {
    commit(types.FETCH_IMPORT_DETAIL_INITIAL_PENDING, true);
    commit(types.FETCH_IMPORT_DETAIL, { data: [] });
    dispatch('fetchImportDetail', { id, meta, metaKey });
    commit(types.FETCH_IMPORT_DETAIL_INITIAL_PENDING, false);
  },
  async fetchImportDetail({ state, commit, rootState }, { id, meta, metaKey }) {
    try {
      commit(types.FETCH_IMPORT_DETAIL_PENDING, true);
      let params = {};
      const metaValue = meta || state.meta[metaKey];
      if (metaValue) {
        params = {
          direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy[0],
          page: metaValue.page,
          search: metaValue.search,
        };
      }
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/imports/${id}/details`, { params });
      if (data.meta) {
        commit(types.FETCH_TABLE_META, { meta: data.meta, key: metaKey });
      }
      commit(types.FETCH_IMPORT_DETAIL, data);
      commit(types.FETCH_IMPORT_DETAIL_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_IMPORT_DETAIL_PENDING, false);
      return Promise.reject(error);
    }
  },
  async abortImportQueue({ rootState }, id) {
    try {
      const data = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/import/queue/abort/${id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchExportQueue({ rootState, commit }) {
    try {
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/export/queue/list`);
      commit(types.FETCH_EXPORT_QUEUE, data);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchAccAccountsGrouped({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/acc-plan`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async importAccJournalYears({ rootState, commit, dispatch }, file) {
    commit(types.IMPORT_ACC_JOURNAL_YEARS_PENDING, true);
    try {
      const formData = new FormData();
      formData.append('xml', file);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      await axios.post(
        `/accounts/${rootState.definitions.account.account.uuid}/accounting/import/acc_journal_years`,
        formData,
        config,
      );
      commit(types.IMPORT_ACC_JOURNAL_YEARS_PENDING, false);
      dispatch('messages/showMessage', {
        key: 'xml_upload_success',
        color: 'success',
      }, { root: true });
      window.location.reload();
      return Promise.resolve();
    } catch (error) {
      commit(types.IMPORT_ACC_JOURNAL_YEARS_PENDING, false);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchAccAccount({ rootState, commit }, id) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts/${id}`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data.accAccount);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeAccAccounts({ rootState, commit, dispatch }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts`, payload);
      await dispatch('fetchAllAccounts');
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeAccAccount({ rootState, commit, dispatch }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const method = payload.id ? 'PATCH' : 'POST';
      const baseUrl = `/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts`;
      const url = payload.id ? `${baseUrl}/${payload.id}` : baseUrl;
      const data = await axios({ url, method, data: payload });
      commit(types.STORE_ACC_ACCOUNT, data);
      commit(types.STORE_SETTINGS_PENDING, false);
      dispatch('fetchAllAccounts');
      return Promise.resolve(data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async removeAccBaseAccount({ rootState, commit, dispatch }, id) {
    try {
      commit(types.REMOVE_ACC_BASE_ACCOUNTS_PENDING);
      const response = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-accounts/${id}`);
      commit(types.REMOVE_ACC_BASE_ACCOUNTS_FULFILLED);
      dispatch('messages/showMessage', {
        text: response.data.message,
        color: 'success',
      }, { root: true });
      return Promise.resolve(response);
    } catch (error) {
      commit(types.REMOVE_ACC_BASE_ACCOUNTS_ERROR);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchKeyFigures({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_key_figures`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeKeyFigure({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      let data;
      if (payload.id) {
        data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_key_figures/${payload.id}`, payload);
      } else {
        data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_key_figures`, payload);
      }
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchGateways({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeGateway({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      let data;
      if (payload.id) {
        data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways/${payload.id}`, payload);
      } else {
        data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways`, payload);
      }
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async archiveGateway({ rootState, commit }, { id }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways/${id}/archive`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchDunnings({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/dunnings`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeDunnings({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/dunnings`, payload);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async updateLimit({ rootState, commit }) {
    commit(types.UPDATE_LIMIT_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/limitupdate`);
      commit(types.UPDATE_LIMIT_FULFILLED);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.UPDATE_LIMIT_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchModuleDeepBoxInitial({ rootState, commit }) {
    commit(types.FETCH_DEEPBOX_INITIAL_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/settings/services/deepbox`);
      commit(types.FETCH_DEEPBOX_INITIAL_FULFILLED, response);
      return Promise.resolve(true);
    } catch (error) {
      commit(types.FETCH_DEEPBOX_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  setNumberRangeSettings({ commit }, data) {
    commit(types.SET_NUMBER_RANGE_SETTINGS, data);
  },
};

export default actions;
