export default {
  account: {},
  isLoadingAccount: false,
  isLoadingAccountInvoice: false,
  isStoringAccountInvoice: false,
  isLoadingAccountInvoicePrices: false,
  isStoringAccountInvoicePrices: false,
  isLoadingAccountQuote: false,
  isStoringAccountQuote: false,
  isLoadingAccountDocuments: false,
  isStoringAccountDocuments: false,
  design: {},
  isLoadingAccountDesign: false,
  isStoringAccountDesign: false,
};
