// initial data load
export const FETCH_INCOME_STATEMENT = 'FETCH_INCOME_STATEMENT';
export const FETCH_INCOME_STATEMENT_SUCCESS = 'FETCH_INCOME_STATEMENT_SUCCESS';
export const FETCH_INCOME_STATEMENT_FAILURE = 'FETCH_INCOME_STATEMENT_FAILURE';
export const FETCH_INCOME_STATEMENT_CANCEL = 'FETCH_INCOME_STATEMENT_CANCEL';

// fetch balance sheet PDF export
export const FETCH_INCOME_STATEMENT_PDF_EXPORT = 'FETCH_INCOME_STATEMENT_PDF_EXPORT';
export const FETCH_INCOME_STATEMENT_PDF_EXPORT_SUCCESS = 'FETCH_INCOME_STATEMENT_PDF_EXPORT_SUCCESS';
export const FETCH_INCOME_STATEMENT_PDF_EXPORT_FAILURE = 'FETCH_INCOME_STATEMENT_PDF_EXPORT_FAILURE';
export const FETCH_INCOME_STATEMENT_PDF_EXPORT_CANCEL = 'FETCH_INCOME_STATEMENT_PDF_EXPORT_CANCEL';

// fetch balance sheet XLS export
export const FETCH_INCOME_STATEMENT_XLS_EXPORT = 'FETCH_INCOME_STATEMENT_XLS_EXPORT_PENDING';
export const FETCH_INCOME_STATEMENT_XLS_EXPORT_SUCCESS = 'FETCH_INCOME_STATEMENT_XLS_EXPORT_SUCCESS';
export const FETCH_INCOME_STATEMENT_XLS_EXPORT_FAILURE = 'FETCH_INCOME_STATEMENT_XLS_EXPORT_FAILURE';
export const FETCH_INCOME_STATEMENT_XLS_EXPORT_CANCEL = 'FETCH_INCOME_STATEMENT_XLS_EXPORT_CANCEL';
