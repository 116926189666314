import * as types from './types';

export default {
  [types.FETCH_COMMENTS]: (state, { id, type }) => {
    state.isLoading = true;
    state.hasLoaded = false;

    state.id = id;
    state.type = type;
  },
  [types.FETCH_COMMENTS_FULFILLED]: (state, { data, meta }) => {
    state.isLoading = false;
    state.hasLoaded = true;

    state.ids = data.map((entity) => entity.id);
    data.forEach((entity) => {
      state.entities[entity.id] = entity;
    });
    state.meta = {
      sortDesc: [meta.direction === 'DESC'],
      itemsPerPage: parseInt(meta.per_page, 10),
      sortBy: [meta.sort],
      page: parseInt(meta.page, 10),
      search: meta.search,
      extendSearch: meta.extend_search,
      maxPage: parseInt(meta.max_page, 10),
      totalItems: meta.max_page * meta.per_page,
    };
  },
  [types.FETCH_COMMENTS_ERROR]: (state, error) => {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },

  [types.POST_COMMENT]: (state) => {
    state.isPosting = true;
  },
  [types.POST_COMMENT_FULFILLED]: (state, { data }) => {
    state.isPosting = false;

    state.ids.unshift(data.id);
    state.entities[data.id] = data;
  },
  [types.POST_COMMENT_ERROR]: (state, error) => {
    state.isPosting = false;
    state.error = error;
  },

  [types.DELETE_COMMENT]: (state, { id }) => {
    state.isDeletingIds.push(id);
  },
  [types.DELETE_COMMENT_FULFILLED]: (state, { id }) => {
    state.isDeletingIds.splice(state.isDeletingIds.indexOf(id), 1);
    state.ids.splice(state.ids.indexOf(id), 1);
    delete state.entities[id];
  },
  [types.DELETE_COMMENT_ERROR]: (state, { error, id }) => {
    state.isDeletingIds.splice(state.isDeletingIds.indexOf(id), 1);
    state.error = error;
  },

  [types.RESET_COMMENT]: (state) => {
    state.id = null;
    state.type = '';

    state.ids = [];
    state.entities = {};

    state.isLoading = false;
    state.hasLoaded = false;
    state.isPosting = false;
    state.isDeletingIds = [];

    state.error = null;
    state.meta = {
      sortDesc: [true],
      itemsPerPage: 10,
      sortBy: ['date'],
      page: 1,
      search: null,
      extendSearch: null,
      maxPage: 1,
      totalItems: null,
    };
  },

  [types.UPDATE_COMMENT_META]: (state, { meta }) => {
    state.meta = {
      ...state.meta,
      ...meta,
    };
  },
};
