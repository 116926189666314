import type { Payload } from '@/api/interfaces/payload';
import type { UseCancellablePromise } from '@/helpers/useCancellablePromise';
import { useEntity } from '@/stores/composables/use-entity';
import { useLoadable } from '@/stores/composables/use-loadable';
import { useStatusCode } from '@/stores/composables/use-status-code';
import type { AxiosPromise } from 'axios';

export const useGetEntityV3 = <TArgs extends unknown[], TResponse>(
  handler: (...args: TArgs) => UseCancellablePromise<AxiosPromise<Payload<TResponse>>>,
) => {
  const { isLoading, hasLoaded, abortController, cancel } = useLoadable();
  const { entity } = useEntity<TResponse>();
  const { statusCode } = useStatusCode();

  async function getEntity(...args: TArgs) {
    cancel();

    const { abortController: controller, promise } = handler(...args);
    abortController.value = controller;

    try {
      isLoading.value = true;

      const response = await promise();
      entity.value = response.data.data;
      statusCode.value = response.status;
      hasLoaded.value = true;
      isLoading.value = false;

      return response;
    } finally {
      isLoading.value = false;
    }
  }

  async function requireEntity(...args: TArgs) {
    if (!isLoading.value && !hasLoaded.value) {
      await getEntity(...args);
    }

    return true;
  }

  return {
    isLoading,
    hasLoaded,
    entity,
    statusCode,
    cancel,
    getEntity,
    requireEntity,
  };
};
