import { requestBasePathInterceptor } from '@/interceptors/request-base-path-interceptor';
import { requestBearerTokenInterceptor } from '@/interceptors/request-bearer-token-interceptor';
import { requestParamsSerializerInterceptor } from '@/interceptors/request-params-serializer-interceptor';
import { requestWebapiBaseInterceptor } from '@/interceptors/request-webapi-base-interceptor';
import axios from 'axios';

const webApiClient = axios.create({
  baseURL: `${import.meta.env.VITE_URL}`,
});

/*
 * Create Base Url Path
 */
webApiClient.interceptors.request.use(requestBasePathInterceptor);

/*
 * Attach /webapi/accounts/uuid/ as prefix to internal urls
 */
webApiClient.interceptors.request.use(requestWebapiBaseInterceptor);

/*
 * Attach Bearer token and content type to external requests request
 */
webApiClient.interceptors.request.use(requestBearerTokenInterceptor);

/*
 * Apply params serializer
 */
webApiClient.interceptors.request.use(requestParamsSerializerInterceptor);

export default webApiClient;
