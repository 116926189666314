import axios from 'axios';

const CHECK_SWISS21_ACTIVE_PENDING = 'CHECK_SWISS21_ACTIVE_PENDING';
const CHECK_SWISS21_ACTIVE_FULFILLED = 'CHECK_SWISS21_ACTIVE_FULFILLED';
const CHECK_SWISS21_ACTIVE_ERROR = 'CHECK_SWISS21_ACTIVE_ERROR';

const MIGRATION_PENDING = 'MIGRATION_PENDING';
const MIGRATION_FULFILLED = 'MIGRATION_FULFILLED';
const MIGRATION_ERROR = 'MIGRATION_ERROR';

const SAVE_ACCOUNT_PENDING = 'SAVE_ACCOUNT_PENDING';
const SAVE_ACCOUNT_FULFILLED = 'SAVE_ACCOUNT_FULFILLED';
const SAVE_ACCOUNT_ERROR = 'SAVE_ACCOUNT_ERROR';

const FETCH_APPSWITCH_PENDING = 'FETCH_APPSWITCH_PENDING';
const FETCH_APPSWITCH_FULFILLED = 'FETCH_APPSWITCH_FULFILLED';
const FETCH_APPSWITCH_ERROR = 'FETCH_APPSWITCH_ERROR';

export default {
  namespaced: true,
  state: {
    checkPending: false,
    redirectPending: false,
    swiss21_agb_at_url: '',
    swiss21_data_protection_at_url: '',
    migrationError: false,
    fetchAppSwitchPending: false,
    swiss21Apps: [],
  },
  mutations: {
    // CHECK_SWISS21_ACTIVE
    [CHECK_SWISS21_ACTIVE_PENDING](state) {
      state.checkPending = true;
    },
    [CHECK_SWISS21_ACTIVE_FULFILLED](state, data) {
      state.checkPending = false;
      if (!data.isActive) {
        state.swiss21_agb_at_url = data.swiss21_agb_path;
        state.swiss21_data_protection_at_url = data.swiss21_data_protection_path;
      }
    },
    [CHECK_SWISS21_ACTIVE_ERROR](state) {
      state.checkPending = false;
    },
    // MIGRATION
    [MIGRATION_PENDING](state) {
      state.redirectPending = true;
      state.migrationError = false;
    },
    [MIGRATION_FULFILLED](state) {
      state.redirectPending = false;
    },
    [MIGRATION_ERROR](state) {
      state.redirectPending = false;
      state.migrationError = true;
    },
    // SAVE_ACCOUNT
    [SAVE_ACCOUNT_PENDING](state) {
      state.redirectPending = true;
    },
    [SAVE_ACCOUNT_FULFILLED](state) {
      state.redirectPending = true;
    },
    [SAVE_ACCOUNT_ERROR](state) {
      state.redirectPending = false;
    },
    // FETCH_APPSWITCH
    [FETCH_APPSWITCH_PENDING](state) {
      state.fetchAppSwitchPending = true;
    },
    [FETCH_APPSWITCH_FULFILLED](state, { data }) {
      state.swiss21Apps = data;
      state.fetchAppSwitchPending = false;
    },
    [FETCH_APPSWITCH_ERROR](state) {
      state.fetchAppSwitchPending = false;
    },
  },
  actions: {
    async checkSwiss21Active({ rootState, commit }) {
      commit(CHECK_SWISS21_ACTIVE_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/checkSwiss21Active`);
        commit(CHECK_SWISS21_ACTIVE_FULFILLED, response.data);
        return Promise.resolve(response.data);
      } catch (error) {
        commit(CHECK_SWISS21_ACTIVE_ERROR);
        return Promise.reject(error);
      }
    },
    async storeAccount({ rootState, commit, dispatch }, form) {
      commit(SAVE_ACCOUNT_PENDING);
      try {
        commit(SAVE_ACCOUNT_FULFILLED, await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/saveAccountInformation`, form));
        await dispatch('migrateToSwiss21');
        return true;
      } catch (error) {
        commit(SAVE_ACCOUNT_ERROR);
        return Promise.reject(error);
      }
    },
    async migrateToSwiss21({ rootState, commit }) {
      commit(MIGRATION_PENDING);
      try {
        commit(MIGRATION_FULFILLED, await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/migrateToSwiss21`));
        return Promise.resolve();
      } catch (error) {
        commit(MIGRATION_ERROR);
        return Promise.reject(error);
      }
    },
    async fetchAppSwitch({ rootState, commit }) {
      commit(FETCH_APPSWITCH_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/appswitch`);
        commit(FETCH_APPSWITCH_FULFILLED, response);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_APPSWITCH_ERROR);
        return Promise.reject(error);
      }
    },
  },
};
