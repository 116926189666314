<template>
  <v-row class="justify-center" fill-height>
    <span ref="trigger" class="trigger"></span>
    <v-col :style="small ? 'max-width: 750px;' : ''">
      <v-row v-if="slots.header" class="header py-3">
        <v-col>
          <slot name="header"></slot>
        </v-col>
      </v-row>
      <v-row v-if="slots.content" class="content">
        <v-col>
          <slot name="content"></slot>
        </v-col>
      </v-row>
      <v-row v-if="slots.footer">
        <v-col>
          <slot name="footer"></slot>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>

import { computed, useSlots } from 'vue';
import { useVuetify } from '@/helpers/vuetify';

interface Props {
  small?: boolean;
}

withDefaults(defineProps<Props>(), {
  small: false,
});

const vuetify = useVuetify();
const slots = useSlots();

const topOffset = computed(() => `${vuetify.application.top}px`);
</script>

<style lang="scss" scoped>
@import "vuetify/src/styles/styles";

.trigger {
  height: 1px;
}

@media #{map-get($display-breakpoints, "sm-and-up")}{
  .header {
    position: sticky;
    z-index: 3;
    top: v-bind(topOffset);
    backdrop-filter: saturate(50%) blur(8px);

    .theme--light & {
      background-color: rgba(248, 249, 250, 0.7);
      // background-color: rgba(map-get($material-light, "background"), 0.7);
    }

    .theme--dark & {
      background-color: rgba(18, 18, 18, 0.7);
      // background-color: rgba(map-get($material-dark, "background"), 0.7);
    }
  }
}
</style>
