import * as types from './types';

export default {
  // POST
  [types.POST_ACCOUNTING_ENTRY](state) {
    state.isPosting = true;
    state.postingError = null;
  },
  [types.POST_ACCOUNTING_ENTRY_SUCCESS](state) {
    state.isPosting = false;
    state.postingError = null;
  },
  [types.POST_ACCOUNTING_ENTRY_FAILURE](state, error) {
    state.isPosting = false;
    state.postingError = error;
  },

  // PATCH
  [types.PATCH_ACCOUNTING_ENTRY](state) {
    state.isPatching = true;
    state.patchingError = null;
  },
  [types.PATCH_ACCOUNTING_ENTRY_SUCCESS](state) {
    state.isPatching = false;
    state.patchingError = null;
  },
  [types.PATCH_ACCOUNTING_ENTRY_FAILURE](state, error) {
    state.isPatching = false;
    state.patchingError = error;
  },

  // FILE
  [types.POST_ACCOUNTING_FILE](state) {
    state.isPostingFile = true;
    state.postingFileError = null;
  },
  [types.POST_ACCOUNTING_FILE_SUCCESS](state) {
    state.isPostingFile = false;
    state.postingFileError = null;
  },
  [types.POST_ACCOUNTING_FILE_FAILURE](state, error) {
    state.isPostingFile = false;
    state.postingFileError = error;
  },

  // DELETE
  [types.DELETE_ACCOUNTING_ENTRY](state) {
    state.isDeleting = true;
    state.deletingError = null;
  },
  [types.DELETE_ACCOUNTING_ENTRY_SUCCESS](state) {
    state.isDeleting = false;
    state.deletingError = null;
  },
  [types.DELETE_ACCOUNTING_ENTRY_FAILURE](state, error) {
    state.isDeleting = false;
    state.deletingError = error;
  },

  // MOVE
  [types.MOVE_ACCOUNTING_ENTRY](state) {
    state.isMoving = true;
    state.movingError = null;
  },
  [types.MOVE_ACCOUNTING_ENTRY_SUCCESS](state) {
    state.isMoving = false;
    state.movingError = null;
  },
  [types.MOVE_ACCOUNTING_ENTRY_FAILURE](state, error) {
    state.isMoving = false;
    state.movingError = error;
  },

  // CLOSE
  [types.CLOSE_ACCOUNTING_ENTRY](state) {
    state.isClosing = true;
    state.closingError = null;
  },
  [types.CLOSE_ACCOUNTING_ENTRY_SUCCESS](state) {
    state.isClosing = false;
    state.closingError = null;
  },
  [types.CLOSE_ACCOUNTING_ENTRY_FAILURE](state, error) {
    state.isClosing = false;
    state.closingError = error;
  },

  // CANCEL
  [types.CANCEL_ACCOUNTING_ENTRY](state) {
    state.isCancelling = true;
    state.cancellingError = null;
  },
  [types.CANCEL_ACCOUNTING_ENTRY_SUCCESS](state) {
    state.isCancelling = false;
    state.cancellingError = null;
  },
  [types.CANCEL_ACCOUNTING_ENTRY_FAILURE](state, error) {
    state.isCancelling = false;
    state.cancellingError = error;
  },

  // BULK CLOSE
  [types.BULK_CLOSE_ACCOUNTING_ENTRIES](state) {
    state.isBulkClosing = true;
    state.bulkClosingError = null;
  },
  [types.BULK_CLOSE_ACCOUNTING_ENTRIES_SUCCESS](state) {
    state.isBulkClosing = false;
    state.bulkClosingError = null;
  },
  [types.BULK_CLOSE_ACCOUNTING_ENTRIES_FAILURE](state, error) {
    state.isBulkClosing = false;
    state.bulkClosingError = error;
  },

  // FETCH DOCUMENT
  [types.FETCH_ACCOUNTING_DOCUMENT](state) {
    state.isLoadingDocument = true;
    state.loadingDocumentError = null;
  },
  [types.FETCH_ACCOUNTING_DOCUMENT_SUCCESS](state) {
    state.isLoadingDocument = false;
    state.loadingDocumentError = null;
  },
  [types.FETCH_ACCOUNTING_DOCUMENT_FAILURE](state, error) {
    state.isLoadingDocument = false;
    state.loadingDocumentError = error;
  },
  [types.FETCH_ACCOUNTING_DOCUMENT_CANCEL](state) {
    state.isLoadingDocument = false;
  },

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [types.FETCH_ACCOUNT_READY_PENDING](state) {
    state.checkAccountPending = true;
  },
  [types.FETCH_ACCOUNT_READY_FULFILLED](state) {
    state.checkAccountPending = false;
  },
  [types.FETCH_ACCOUNT_READY_ERROR](state) {
    state.checkAccountPending = false;
  },
  [types.FETCH_ACCOUNTING_READY_PENDING](state) {
    state.fetchAccountingReadyPending = true;
  },
  [types.FETCH_ACCOUNTING_READY_FULFILLED](state, { data }) {
    state.settingsTodo = data;
    state.settings = { ...data };

    state.isReady = Object.keys(data).every((key) => data[key]);
    if (!data.acc_accounts) {
      state.isReady = false;
    }
    state.fetchAccountingReadyPending = false;
  },
  [types.FETCH_ACCOUNTING_READY_ERROR](state) {
    state.fetchAccountingReadyPending = false;
  },
  [types.FETCH_ACCOUNTING_YEARS_PENDING](state) {
    state.fetchAccountingYearsPending = true;
  },
  [types.FETCH_ACCOUNTING_YEARS_FULFILLED](state, { data, yearId }) {
    state.accountingYears = data;

    state.currentAccountingYear = data.find(
      (accountingYear) => accountingYear.id === parseInt(yearId, 10),
    );

    if (!state.currentAccountingYear) {
      state.currentAccountingYear = data.find((accountingYear) => accountingYear.is_active);
    }
    state.fetchAccountingYearsPending = false;
  },
  [types.FETCH_ACCOUNTING_YEARS_ERROR](state) {
    state.fetchAccountingYearsPending = false;
  },
  [types.CHANGE_ACCOUNTING_YEAR](state, id) {
    const currentJournalIndex = state.accountingYears.findIndex(
      (accountingYears) => accountingYears.id === id,
    );
    state.currentAccountingYear = state.accountingYears[currentJournalIndex];
  },
  // FETCH_VAT_PERIODS
  [types.FETCH_VAT_PERIODS_PENDING](state) {
    state.fetchVatPeriodsPending = true;
  },
  [types.FETCH_VAT_PERIODS_FULFILLED](state, { data }) {
    state.vatPeriods = data;
    state.fetchVatPeriodsPending = false;
  },
  [types.FETCH_VAT_PERIODS_ERROR](state) {
    state.fetchVatPeriodsPending = false;
  },
  // CHECK_VAT_PERIODS
  [types.CHECK_VAT_PERIODS_PENDING](state) {
    state.checkVatPeriodsPending = true;
  },
  [types.CHECK_VAT_PERIODS_FULFILLED](state) {
    state.checkVatPeriodsPending = false;
  },
  [types.CHECK_VAT_PERIODS_ERROR](state) {
    state.checkVatPeriodsPending = false;
  },
};
