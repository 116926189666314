import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_ACTIONS
  [types.FETCH_ACTIONS_PENDING](state) {
    state.fetchSingleActionsPending = true;
  },
  [types.FETCH_ACTIONS_FULFILLED](state, { data }) {
    state.singleActions = data;
    state.fetchSingleActionsPending = false;
  },
  // FETCH_DUPLICATE_ADDRESSES
  [types.FETCH_DUPLICATE_ADDRESSES_FULFILLED](state, addresses) {
    state.duplicateAddresses = addresses;
  },
  // FETCH_NEXTNUMBER
  [types.FETCH_NEXTNUMBER_FULFILLED](state, { invoice_number: invoiceNumber }) {
    state.nextNumber = invoiceNumber;
  },
  // FETCH_ADDRESS
  [types.FETCH_ADDRESS_PENDING](state) {
    state.fetchAddressPending = true;
  },
  [types.FETCH_ADDRESS_FULFILLED](state, { data }) {
    state.address = data;
    state.fetchAddressPending = false;
  },
  [types.FETCH_ADDRESS_ERROR](state) {
    state.fetchAddressPending = false;
  },
  // IMPORT_RECEIPT
  [types.IMPORT_INVOICE_PENDING](state) {
    state.importReceiptPending = true;
  },
  [types.IMPORT_INVOICE_FULFILLED](state, invoice) {
    state.importedInvoice = invoice;
    state.importReceiptPending = false;
  },
  [types.IMPORT_INVOICE_ERROR](state) {
    state.importReceiptPending = false;
  },
  [types.FETCH_IMPORT_INVOICE_QUEUE](state, { data }) {
    state.invoiceQueue = data;
  },
  // FETCH_RECEIPT
  [types.FETCH_IMPORTED_INVOICE_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_IMPORTED_INVOICE_FULFILLED](state, invoice) {
    state.importedInvoice = invoice;
    state.initialLoading = false;
  },
  [types.FETCH_IMPORTED_INVOICE_ERROR](state) {
    state.initialLoading = false;
  },
  // STORE_RECEIPT
  [types.STORE_IMPORTED_INVOICE_PENDING](state) {
    state.storeImportedInvoicePending = true;
  },
  [types.STORE_IMPORTED_INVOICE_FULFILLED](state) {
    state.storeImportedInvoicePending = false;
  },
  [types.STORE_IMPORTED_INVOICE_ERROR](state) {
    state.storeImportedInvoicePending = false;
  },
  [types.RESET_IMPORTED_INVOICE](state) {
    state.storeImportedInvoicePending = false;
    state.importedInvoice = {};
  },
};

export default mutations;
