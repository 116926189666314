// initial data load
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_ACCOUNTING_YEAR_PENDING = 'FETCH_ACCOUNTING_YEAR_PENDING';
export const FETCH_ACCOUNTING_YEAR_FULFILLED = 'FETCH_ACCOUNTING_YEAR_FULFILLED';
export const FETCH_ACCOUNTING_YEAR_ERROR = 'FETCH_ACCOUNTING_YEAR_ERROR';

export const CHANGE_ACCOUNTING_YEAR = 'CHANGE_ACCOUNTING_YEAR';
export const CHANGE_PERIODS = 'CHANGE_PERIODS';
export const CHANGE_RANGE = 'CHANGE_RANGE';

export const FETCH_KEYFIGURES_PENDING = 'FETCH_KEYFIGURES_PENDING';
export const FETCH_KEYFIGURES_FULFILLED = 'FETCH_KEYFIGURES_FULFILLED';
export const FETCH_KEYFIGURES_ERROR = 'FETCH_KEYFIGURES_ERROR';
