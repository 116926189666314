import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchValue({ commit, rootState }, { id, abortController }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_VALUE_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/account_user_store/${id}`;
      const response = await axios.get(url, {
        signal: controller.signal,
      });
      let value = response ? response.data : {};
      if (response.data && response.data.value) {
        value = JSON.parse(response.data.value);
      }
      commit(types.FETCH_VALUE, { data: value, id });
      commit(types.FETCH_VALUE_PENDING, false);
      return Promise.resolve(value);
    } catch (error) {
      commit(types.FETCH_VALUE, { data: {}, id });
      commit(types.FETCH_VALUE_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeValue({ commit, rootState }, { data, id }) {
    commit(types.STORE_VALUE_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/account_user_store/${id}`;
      const response = await axios.post(url, { value: JSON.stringify(data) });
      commit(types.STORE_VALUE, { response, id });
      commit(types.STORE_VALUE_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.STORE_VALUE_PENDING, false);
      return Promise.reject(error);
    }
  },
};

export default actions;
