import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      const [nextNumber] = await Promise.all([
        dispatch('fetchNextNumber', 'invoice'),
      ]);
      await dispatch('fetchImportQueues', { type: 'invoices' });
      commit(types.FETCH_NEXTNUMBER_FULFILLED, nextNumber);
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve(nextNumber);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  importInvoice({ commit }, data) {
    commit(types.IMPORT_INVOICE_PENDING);
    try {
      commit(types.FETCH_DUPLICATE_ADDRESSES_FULFILLED, data.addresses);
      commit(types.IMPORT_INVOICE_FULFILLED, data.invoice);
    } catch (error) {
      console.error(error);
      commit(types.IMPORT_INVOICE_ERROR);
    }
  },
  async fetchImportedInvoice({ rootState, commit, dispatch }, id) {
    commit(types.FETCH_IMPORTED_INVOICE_PENDING);
    try {
      const response = await Promise.all([
        dispatch('fetchSingleInvoice', id),
        dispatch('fetchActions', id),
      ]);
      if (response[0].data.invoice.address_id) {
        const address = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${response[0].data.invoice.address_id}`);
        commit(types.FETCH_ADDRESS_FULFILLED, address);
        commit(types.FETCH_DUPLICATE_ADDRESSES_FULFILLED, [address.data]);
      } else {
        commit(types.FETCH_DUPLICATE_ADDRESSES_FULFILLED, response[0].data.addresses);
      }

      commit(types.FETCH_IMPORTED_INVOICE_FULFILLED, response[0].data.invoice);
    } catch (error) {
      commit(types.FETCH_IMPORTED_INVOICE_ERROR, error);
    }
  },
  async storeImportedInvoice({ rootState, commit, dispatch }, data) {
    commit(types.STORE_IMPORTED_INVOICE_PENDING);
    try {
      const { id } = data;
      const method = id ? 'PATCH' : 'POST';
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/import/invoices`;
      const url = id ? `${baseUrl}/${id}` : baseUrl;
      // ABANINJA-9900: xsrf is funny
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
      const response = await axios({ url, method, data });
      // fetch bankAccounts if a new one will be created
      if (!data.bank_account_id && data.esr_party_id) {
        await dispatch('definitions/fetchBankAccounts', null, { root: true });
      }
      commit(types.STORE_IMPORTED_INVOICE_FULFILLED);
      const successMessage = data.action === 'email'
        ? 'imported_invoice_send_successful'
        : 'imported_invoice_store_successful';

      dispatch('messages/showMessage', { key: successMessage, color: 'success' }, { root: true });
      return Promise.resolve(response.data);
    } catch (error) {
      const errorMessage = data.action === 'email' ? 'imported_invoice_send_error' : 'imported_invoice_store_error';
      dispatch('messages/showMessage', { key: errorMessage, color: 'error' }, { root: true });
      commit(types.STORE_IMPORTED_INVOICE_ERROR, error);
      return Promise.reject(error);
    }
  },
  resetImportedInvoice({ commit }) {
    commit(types.RESET_IMPORTED_INVOICE);
  },
  fileIsSending({ commit }) {
    commit(types.IMPORT_INVOICE_PENDING);
  },
  fetchSingleInvoice({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/import/invoices/${id}`);
  },
  async fetchActions({ rootState, commit }, id) {
    try {
      commit(types.FETCH_ACTIONS_PENDING);
      const fetchedActions = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/singleactions/invoice/${id}`);
      commit(types.FETCH_ACTIONS_FULFILLED, fetchedActions);
      return Promise.resolve(fetchedActions);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchNextNumber({ rootState }, type) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/nextnumber/${type}`);
  },
  async fetchImportQueues({ rootState, commit }) {
    try {
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoice/imports/queue`);
      commit(types.FETCH_IMPORT_INVOICE_QUEUE, data);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchImportQueue({ rootState }, { type, id }) {
    try {
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/${type}/imports/queue/${id}`);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteImportQueue({ rootState, dispatch }, id) {
    try {
      const data = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/invoice/imports/${id}`);
      await dispatch('fetchImportQueues', { type: 'invoices' });
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchAddress({ rootState, commit }, id) {
    commit(types.FETCH_ADDRESS_PENDING);
    try {
      commit(types.FETCH_ADDRESS_FULFILLED, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${id}`));
    } catch (error) {
      commit(types.FETCH_ADDRESS_ERROR, error);
    }
  },
};

export default actions;
