export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_FULFILLED = 'FETCH_PRODUCTS_FULFILLED';
export const FETCH_PAGINATION_PRODUCTS_FULFILLED = 'FETCH_PAGINATION_PRODUCTS_FULFILLED';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const FETCH_ARCHIVE_PRODUCTS_PENDING = 'FETCH_ARCHIVE_PRODUCTS_PENDING';
export const FETCH_ARCHIVE_PRODUCTS_FULFILLED = 'FETCH_ARCHIVE_PRODUCTS_FULFILLED';
export const FETCH_ARCHIVE_PRODUCTS_ERROR = 'FETCH_ARCHIVE_PRODUCTS_ERROR';

export const FETCH_PRODUCT_GROUPS_PENDING = 'FETCH_PRODUCT_GROUPS_PENDING';
export const FETCH_PRODUCT_GROUPS_FULFILLED = 'FETCH_PRODUCT_GROUPS_FULFILLED';
export const FETCH_PRODUCT_GROUPS_ERROR = 'FETCH_PRODUCT_GROUPS_ERROR';

export const FETCH_ARCHIVE_PRODUCT_GROUPS_PENDING = 'FETCH_ARCHIVE_PRODUCT_GROUPS_PENDING';
export const FETCH_ARCHIVE_PRODUCT_GROUPS_FULFILLED = 'FETCH_ARCHIVE_PRODUCT_GROUPS_FULFILLED';
export const FETCH_ARCHIVE_PRODUCT_GROUPS_ERROR = 'FETCH_ARCHIVE_PRODUCT_GROUPS_ERROR';

export const FETCH_TABLE_META = 'FETCH_TABLE_META';

export const FETCH_TAG_PENDING = 'FETCH_TAG_PENDING';
export const FETCH_TAG = 'FETCH_TAG';

export const RESET_PRODUCTS_PAGINATION_LIST = 'RESET_PRODUCTS_PAGINATION_LIST';

export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const SEARCH_PRODUCT_FAILURE = 'SEARCH_PRODUCT_FAILURE';
export const SEARCH_PRODUCT_CANCEL = 'SEARCH_PRODUCT_CANCEL';

export const FETCH_EMPTY_SEARCH_PRODUCTS = 'FETCH_EMPTY_SEARCH_PRODUCTS';
export const FETCH_EMPTY_SEARCH_PRODUCTS_SUCCESS = 'FETCH_EMPTY_SEARCH_PRODUCTS_SUCCESS';
export const FETCH_EMPTY_SEARCH_PRODUCTS_FAILURE = 'FETCH_EMPTY_SEARCH_PRODUCTS_FAILURE';
export const FETCH_EMPTY_SEARCH_PRODUCTS_CANCEL = 'FETCH_EMPTY_SEARCH_PRODUCTS_CANCEL';
