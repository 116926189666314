import * as types from './types';

export default {
  // GET single account-proposal
  [types.FETCH_ACCOUNT_PROPOSAL](state) {
    state.isLoadingAccountProposal = true;
    state.hasLoadedAccountProposal = false;
    state.accountProposalError = null;
  },
  [types.FETCH_ACCOUNT_PROPOSAL_SUCCESS](state, accountProposal) {
    state.accountProposal = accountProposal;

    state.isLoadingAccountProposal = false;
    state.hasLoadedAccountProposal = true;
    state.accountProposalError = null;
  },
  [types.FETCH_ACCOUNT_PROPOSAL_FAILURE](state, error) {
    state.isLoadingAccountProposal = false;
    state.hasLoadedAccountProposal = false;
    state.accountProposalError = error;
  },
  [types.FETCH_ACCOUNT_PROPOSAL_CANCEL](state) {
    state.isLoadingAccountProposal = false;
    state.accountProposalError = null;
  },

  // POST account-proposal
  [types.POST_ACCOUNT_PROPOSAL](state) {
    state.isPostingAccountProposal = true;
    state.postingError = null;
  },
  [types.POST_ACCOUNT_PROPOSAL_SUCCESS](state) {
    state.isPostingAccountProposal = false;
    state.postingError = null;
  },
  [types.POST_ACCOUNT_PROPOSAL_FAILURE](state, error) {
    state.isPostingAccountProposal = false;
    state.postingError = error;
  },

  // PATCH account-proposal
  [types.PATCH_ACCOUNT_PROPOSAL](state) {
    state.isPatchingAccountProposal = true;
    state.patchingError = null;
  },
  [types.PATCH_ACCOUNT_PROPOSAL_SUCCESS](state) {
    state.isPatchingAccountProposal = false;
    state.patchingError = null;
  },
  [types.PATCH_ACCOUNT_PROPOSAL_FAILURE](state, error) {
    state.isPatchingAccountProposal = false;
    state.patchingError = error;
  },

  // DELETE account-proposal
  [types.DELETE_ACCOUNT_PROPOSAL](state) {
    state.isDeletingAccountProposal = true;
    state.deletingError = null;
  },
  [types.DELETE_ACCOUNT_PROPOSAL_SUCCESS](state) {
    state.isDeletingAccountProposal = false;
    state.deletingError = null;
  },
  [types.DELETE_ACCOUNT_PROPOSAL_FAILURE](state, error) {
    state.isDeletingAccountProposal = false;
    state.deletingError = error;
  },

};
