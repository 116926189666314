import hasRole from '@/components/guards/hasRoleGuard';

const DeepPayDocument = () => import('@/pages/admin/deeppay/DeepPayDocument.vue');
const Notification = () => import('@/pages/admin/notification-center/Notification.vue');
const NotificationCenter = () => import('@/pages/admin/notification-center/NotificationCenter.vue');
const Notifications = () => import('@/pages/admin/notification-center/Notifications.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');

export default {
  path: '/admin',
  component: PassThroughKeyed,
  name: 'admin',
  children: [
    {
      path: 'notification-center',
      component: NotificationCenter,
      beforeEnter: (to, from, next) => hasRole(to, from, next, 'notification_center_admin'),
      children: [
        {
          path: '',
          name: 'notification-center',
          component: Notifications,
        },
        {
          path: 'edit/:id?',
          name: 'notification-edit',
          component: Notification,
        },
      ],
    },
    {
      path: 'deeppay',
      component: PassThroughKeyed,
      beforeEnter: (to, from, next) => hasRole(to, from, next, 'notification_center_admin'),
      children: [
        {
          path: 'documents/:uuid',
          name: 'admin-deeppay-document',
          component: DeepPayDocument,
        },
      ],
    },
  ],
};
