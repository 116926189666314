import { useEmployeeTabs } from '@/pages/time-tracking/employees/composables/employee-tabs';
import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');
const EmployeeForm = () => import('@/pages/time-tracking/employees/employee/EmployeeForm.vue');
const EmployeeOverview = () => import('@/pages/time-tracking/employees/employee/EmployeeOverview.vue');
const Employees = () => import('@/pages/time-tracking/employees/Employees.vue');
const EmployeesInactive = () => import('@/pages/time-tracking/employees/EmployeesInactive.vue');
const EmployeesList = () => import('@/pages/time-tracking/employees/EmployeesList.vue');
const EmployeeTimeCorrectionForm = () => import('@/pages/time-tracking/employees/employee/time-correction/EmployeeTimeCorrectionForm.vue');

export const employeesRoutes: Array<RouteConfig> = [
  {
    path: 'employees',
    component: Employees,
    redirect: { name: 'employees' },
    children: [
      {
        path: '',
        name: 'employees',
        component: EmployeesList,
        meta: {
          title: 'employees',
        },
      },
      {
        path: 'inactive',
        name: 'employees-inactive',
        component: EmployeesInactive,
        meta: {
          title: 'employees',
        },
      },
    ],
  },

  {
    path: 'employees/employee',
    component: PassThrough,
    children: [
      {
        path: ':employeeUuid?',
        name: 'employee-form',
        component: EmployeeForm,
        props: true,
      },
      {
        path: ':employeeUuid/contract/:contractUuid?',
        name: 'employee-contract',
        component: () => import('@/pages/time-tracking/employees/employee/contract/EmployeeContractForm.vue'),
        props: true,
      },
      {
        path: ':employeeUuid/time-correction/:timeCorrectionUuid?',
        name: 'employee-time-correction',
        component: EmployeeTimeCorrectionForm,
        props: true,
      },
      {
        path: ':employeeUuid/:employeeView/:reportView',
        name: 'employee',
        component: EmployeeOverview,
        props: true,
        beforeEnter(to, _, next) {
          if (!to.params.employeeView || !to.params.reportView) {
            const tabs = useEmployeeTabs();

            const { employeeUuid } = to.params;
            const employeeView = to.params.employeeView || tabs.employeeTabs[0].params.employeeView || '';
            const reportView = to.params.reportView || tabs.reportTabs[0].params.reportView || '';

            next({
              name: 'employee',
              params: {
                employeeUuid,
                employeeView,
                reportView,
                savePosition: 'true',
              },
            });
          } else {
            next();
          }
        },
      },
    ],
  },
];
