// eslint-disable-next-line import/extensions
import localesDe from '@/i18n/frontend_de.json';
// eslint-disable-next-line import/extensions
import localesEn from '@/i18n/frontend_en.json';
// eslint-disable-next-line import/extensions
import localesFr from '@/i18n/frontend_fr.json';
// eslint-disable-next-line import/extensions
import localesIt from '@/i18n/frontend_it.json';
import axios from 'axios';
import { Vue2 } from 'vue-demi';
import VueI18n from 'vue-i18n';

const dateTimeFormats: VueI18n.DateTimeFormats = {
  de: {
    onlyYear: {
      year: 'numeric',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
    },
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    shortDateTime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
    longFullDay: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export const defaultLocale = 'de';

function languageTexts(locale: LanguageLocale) {
  switch (locale) {
    case 'de':
      return { texts: localesDe };
    case 'en':
      return { texts: localesEn };
    case 'fr':
      return { texts: localesFr };
    case 'it':
      return { texts: localesIt };
    default:
      return { texts: localesDe };
  }
}

const loadedLanguages = ['de'];

function createI18n(): VueI18n {
  Vue2?.use(VueI18n);
  return new VueI18n({
    locale: defaultLocale,
    dateTimeFormats,
    fallbackLocale: defaultLocale,
    messages: {
      de: {
        texts: localesDe,
      },
      en: {},
      fr: {},
      it: {},
    },
  });
}

const i18n = (() => {
  let instance: VueI18n;

  return {
    getInstance: () => {
      if (!instance) {
        instance = createI18n();
      }
      return instance;
    },
  };
})();

export function useI18n() {
  return i18n.getInstance();
}

function setI18nLanguage(locale: LanguageLocale) {
  useI18n().locale = locale;
  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html')?.setAttribute('lang', locale);
  return locale;
}

export function setLanguage(locale: LanguageLocale) {
  if (useI18n().locale === locale) {
    setI18nLanguage(locale);
  }
  if (loadedLanguages.includes(locale)) {
    return setI18nLanguage(locale);
  }

  useI18n().setLocaleMessage(locale, languageTexts(locale));
  loadedLanguages.push(locale);
  return setI18nLanguage(locale);
}

setLanguage(defaultLocale);
