import { createOrUpdateUserValue, getUserValue } from '@/api/account-user-store';
import { useCreateEntityV3 } from '@/stores/composables/useCreateEntityV3';
import { useGetEntityV3 } from '@/stores/composables/useGetEntityV3';

export const useValueStore = <T>(key: string) => {
  const entity = useGetEntityV3(getUserValue<Partial<T>>);
  const create = useCreateEntityV3(createOrUpdateUserValue<T>);

  function getEntity() {
    return entity.getEntity(key);
  }

  function requireEntity() {
    return entity.requireEntity(key);
  }

  async function createEntity(data: T) {
    const response = await create.createEntity(key, data);
    entity.entity.value = {
      ...entity.entity.value,
      ...data,
    };
    return response;
  }

  return {
    ...entity,
    ...create,
    getEntity,
    requireEntity,
    createEntity,
  };
};
