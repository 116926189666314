export const deepMerge = (...objects) => {
  const isObject = (obj) => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = deepMerge(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, Array.isArray(objects[0]) ? [] : {});
};

export default {
  methods: {
    deepMerge,
  },
};
