export const FETCH_TAXES = 'FETCH_TAXES';
export const FETCH_ALL_ACCOUNTS = 'FETCH_ALL_ACCOUNTS';
export const SET_FAVORITE_BOOKING_ACCOUNT = 'SET_FAVORITE_BOOKING_ACCOUNT';
export const FETCH_MODULES = 'FETCH_MODULES';
export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';
export const FETCH_FREQUENCIES = 'FETCH_FREQUENCIES';
export const FETCH_EXCHANGE_RATES = 'FETCH_EXCHANGE_RATES';
export const FETCH_JOURNALYEARS = 'FETCH_JOURNALYEARS';
export const FETCH_ACCOUNTING_FRAMEWORKS = 'FETCH_ACCOUNTING_FRAMEWORKS';
export const FETCH_ACCOUNTING_FRAMEWORKS_PENDING = 'FETCH_ACCOUNTING_FRAMEWORKS_PENDING';
export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';
export const FETCH_USER_SETTINGS_PENDING = 'FETCH_USER_SETTINGS_PENDING';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS';
export const FETCH_TAX_TIMELINES = 'FETCH_TAX_TIMELINES';
export const FETCH_ALL_TAXES = 'FETCH_ALL_TAXES';
export const FETCH_TABLE_META = 'FETCH_TABLE_META';
export const FETCH_IMPORT_DETAIL_INITIAL_PENDING = 'FETCH_IMPORT_DETAIL_INITIAL_PENDING';
export const FETCH_IMPORT_DETAIL_PENDING = 'FETCH_IMPORT_DETAIL_PENDING';
export const FETCH_IMPORT_DETAIL = 'FETCH_IMPORT_DETAIL';
export const FETCH_IMPORT_QUEUE = 'FETCH_IMPORT_QUEUE';
export const FETCH_EXPORT_QUEUE = 'FETCH_EXPORT_QUEUE';

export const STORE_USER_SETTINGS_PENDING = 'STORE_USER_SETTINGS_PENDING';

export const STORE_SETTINGS_PENDING = 'STORE_SETTINGS_PENDING';

export const FETCH_LOCALIZATION_SETTINGS = 'FETCH_LOCALIZATION_SETTINGS';
export const FETCH_SETTINGS_PENDING = 'FETCH_SETTINGS_PENDING';

export const STORE_JOURNAL_YEAR = 'STORE_JOURNAL_YEAR';
export const STORE_ACC_ACCOUNT = 'STORE_ACC_ACCOUNT';
export const STORE_ALL_TAX = 'STORE_ALL_TAX';

export const INITIAL_LOAD_PENDING = 'INITIAL_LOAD_PENDING';
export const INITIAL_LOAD_FULFILLED = 'INITIAL_LOAD_FULFILLED';
export const INITIAL_LOAD_ERROR = 'INITIAL_LOAD_ERROR';
export const REGISTRATION_PENDING = 'REGISTRATION_PENDING';
export const CONFIRM_PENDING = 'CONFIRM_PENDING';
export const LOGIN_STATE = 'LOGIN_STATE';

export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const FETCH_MODULE_SERVICES_SUCCESS = 'FETCH_MODULE_SERVICES_SUCCESS';

export const UPDATE_LIMIT_PENDING = 'UPDATE_LIMIT_PENDING';
export const UPDATE_LIMIT_FULFILLED = 'UPDATE_LIMIT_FULFILLED';
export const UPDATE_LIMIT_ERROR = 'UPDATE_LIMIT_ERROR';

export const REMOVE_ACC_BASE_ACCOUNTS_PENDING = 'REMOVE_ACC_BASE_ACCOUNTS_PENDING';
export const REMOVE_ACC_BASE_ACCOUNTS_FULFILLED = 'REMOVE_ACC_BASE_ACCOUNTS_FULFILLED';
export const REMOVE_ACC_BASE_ACCOUNTS_ERROR = 'REMOVE_ACC_BASE_ACCOUNTS_ERROR';

// DeepBox
export const FETCH_DEEPBOX_INITIAL_PENDING = 'FETCH_DEEPBOX_INITIAL_PENDING';
export const FETCH_DEEPBOX_INITIAL_FULFILLED = 'FETCH_DEEPBOX_INITIAL_FULFILLED';
export const FETCH_DEEPBOX_INITIAL_ERROR = 'FETCH_DEEPBOX_INITIAL_ERROR';

export const FETCH_DEEPBOX_MAPPINGS_PENDING = 'FETCH_DEEPBOX_MAPPINGS_PENDING';
export const FETCH_DEEPBOX_MAPPINGS_FULFILLED = 'FETCH_DEEPBOX_MAPPINGS_FULFILLED';
export const FETCH_DEEPBOX_MAPPINGS_ERROR = 'FETCH_DEEPBOX_MAPPINGS_PENDING';

export const FETCH_DEEPBOX_FOLDERS_PENDING = 'FETCH_DEEPBOX_FOLDERS_PENDING';
export const FETCH_DEEPBOX_FOLDERS_FULFILLED = 'FETCH_DEEPBOX_FOLDERS_FULFILLED';
export const FETCH_DEEPBOX_FOLDERS_ERROR = 'FETCH_DEEPBOX_FOLDERS_ERROR';

export const STORE_DEEPBOX_SETTINGS_PENDING = 'STORE_DEEPBOX_SETTINGS_PENDING';
export const STORE_DEEPBOX_SETTINGS_FULFILLED = 'STORE_DEEPBOX_SETTINGS_FULFILLED';
export const STORE_DEEPBOX_SETTINGS_ERROR = 'STORE_DEEPBOX_SETTINGS_ERROR';

export const FETCH_NUMBER_RANGE_PENDING = 'FETCH_NUMBER_RANGE_PENDING';
export const FETCH_NUMBER_RANGE_FULFILLED = 'FETCH_NUMBER_RANGE_FULFILLED';
export const FETCH_NUMBER_RANGE_ERROR = 'FETCH_NUMBER_RANGE_ERROR';

export const STORE_NUMBER_RANGE_PENDING = 'STORE_NUMBER_RANGE_PENDING';
export const STORE_NUMBER_RANGE_FULFILLED = 'STORE_NUMBER_RANGE_FULFILLED';
export const STORE_NUMBER_RANGE_ERROR = 'STORE_NUMBER_RANGE_ERROR';

export const SET_NUMBER_RANGE_SETTINGS = 'SET_NUMBER_RANGE_SETTINGS';

export const FETCH_BETA_FEATURES = 'FETCH_BETA_FEATURES';

export const IMPORT_ACC_JOURNAL_YEARS_PENDING = 'IMPORT_ACC_JOURNAL_YEARS_PENDING';

export const FETCH_AGREEMENTS_PENDING = 'FETCH_AGREEMENTS_PENDING';
export const FETCH_AGREEMENTS_SUCCESSFUL = 'FETCH_AGREEMENTS_SUCCESSFUL';
export const FETCH_AGREEMENTS_ERROR = 'FETCH_AGREEMENTS_ERROR';

export const CONFIRM_AGREEMENTS_PENDING = 'CONFIRM_AGREEMENTS_PENDING';
export const CONFIRM_AGREEMENTS_SUCCESSFUL = 'CONFIRM_AGREEMENTS_SUCCESSFUL';
export const CONFIRM_AGREEMENTS_ERROR = 'CONFIRM_AGREEMENTS_ERROR';
