import type { RouteConfig } from 'vue-router/types/router';
import { DocumentType } from '@/components/Document/types/document-type';

const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const DocumentImportQueue = () => import('@/pages/document-import/DocumentImportQueue.vue');
const DocumentImported = () => import('@/pages/document-imported/DocumentImported.vue');
const DocumentImport = () => import('@/pages/document-import/DocumentImport.vue');
const SupplierCreditNotes = () => import('@/pages/suppliers/supplier-credit-notes/SupplierCreditNotes.vue');

const supplierCreditNotes: Array<RouteConfig> = [{
  path: 'supplier-credit-notes',
  component: PassThroughKeyed,
  children: [
    {
      path: 'import',
      name: 'supplier-credit-note-import',
      component: DocumentImport,
      props: { type: DocumentType.SUPPLIER_CREDIT_NOTE },
      meta: {
        title: 'supplier_credit_note_import',
      },
    },
    {
      path: 'import/:id',
      name: 'supplier-credit-note-import-queue',
      component: DocumentImportQueue,
      props: { type: DocumentType.SUPPLIER_CREDIT_NOTE },
      meta: {
        title: 'supplier_credit_notes',
      },
    },
    {
      path: 'edit/:uuid',
      name: 'supplier-credit-note',
      component: DocumentImported,
      props: { type: DocumentType.SUPPLIER_CREDIT_NOTE },
      meta: {
        title: 'supplier_credit_note_title',
      },
    },
    {
      path: ':group?',
      name: 'supplier-credit-notes',
      meta: {
        title: 'supplier_credit_notes',
      },
      component: SupplierCreditNotes,
      beforeEnter(to, _, next) {
        if (!to.params.group) {
          next({ name: 'supplier-credit-notes', params: { group: 'open' } });
        } else {
          next();
        }
      },
    },
  ],
}];

export default supplierCreditNotes;
