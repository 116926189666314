import { useI18n } from '@/helpers/i18n';

const i18n = useI18n();

export default {
  allStatus: () => [
    // customers
    { color: 'primary', text: i18n.t('texts.dunning_level_1'), textId: 'dunning_level_1' },
    { color: 'warning', text: i18n.t('texts.dunning_level_2'), textId: 'dunning_level_2' },
    { color: 'error', text: i18n.t('texts.dunning_level_3'), textId: 'dunning_level_3' },
    { color: 'grey', text: i18n.t('texts.status_draft'), textId: 'status_draft' },
    { color: 'info', text: i18n.t('texts.status_readyforsending'), textId: 'status_readyforsending' },
    { color: 'error', text: i18n.t('texts.status_sendfailed'), textId: 'status_sendfailed' },
    { color: 'info', text: i18n.t('texts.partial_payment'), textId: 'partial_payment' },
    { color: 'info', text: i18n.t('texts.status_sent'), textId: 'status_sent' },
    { color: 'info', text: i18n.t('texts.status_planned'), textId: 'status_planned' },
    { color: 'grey', text: i18n.t('texts.status_pending'), textId: 'status_pending' },
    { color: 'warning', text: i18n.t('texts.status_viewed'), textId: 'status_viewed' },
    { color: 'success', text: i18n.t('texts.status_approved'), textId: 'status_approved' },
    { color: 'error', text: i18n.t('texts.status_declined'), textId: 'status_declined' },
    { color: 'success', text: i18n.t('texts.status_collected'), textId: 'status_collected' },
    { color: 'presuccess', text: i18n.t('texts.status_partial'), textId: 'status_partial' },
    { color: 'success', text: i18n.t('texts.status_paid'), textId: 'status_paid' },
    { color: 'warning', text: i18n.t('texts.payment_receipt'), textId: 'payment_receipt' },
    { color: 'error', text: i18n.t('texts.invoices_due'), textId: 'invoices_due' },
    { color: 'error', text: i18n.t('texts.expired'), textId: 'expired' },
    { color: 'success', text: i18n.t('texts.converted'), textId: 'converted' },
    { color: 'warning', text: i18n.t('texts.archived'), textId: 'archived' },
    // receipt
    {
      color: 'warning',
      text: i18n.t('texts.status_receipt_readyforsending'),
      textId: 'status_receipt_readyforsending',
    },
    { color: 'grey', text: i18n.t('texts.receipts_open'), textId: 'receipts_open' },
    // payments
    { color: 'error', text: i18n.t('texts.payment_cancelled'), textId: 'payment_cancelled' },
    { color: 'primary', text: i18n.t('texts.payment_status_open'), textId: 'payment_status_open' },
    { color: 'warning', text: i18n.t('texts.payment_status_wait'), textId: 'payment_status_wait' },
    { color: 'success', text: i18n.t('texts.payment_status_ready'), textId: 'payment_status_ready' },
    { color: 'warning', text: i18n.t('texts.payment_status_assign'), textId: 'payment_status_assign' },
    // Bankfiles
    { color: 'warning', text: i18n.t('texts.bank_files_status_open'), textId: 'bank_files_status_open' },
    { color: 'success', text: i18n.t('texts.bank_files_status_imported'), textId: 'bank_files_status_imported' },
  ],
  typeList: {
    quotes: {
      open: [
        'status_draft',
        'status_planned',
        'status_readyforsending',
        'status_sendfailed',
        'status_sent',
        'status_viewed',
        'status_approved',
      ],
      closed: [
        'status_approved',
        'status_declined',
        'status_collected',
        'converted',
        'expired',
      ],
      archived: [
        'archived',
      ],
    },
    contract_notes: {
      open: [
        'status_draft',
        'status_planned',
        'status_readyforsending',
        'status_pending',
        'status_sendfailed',
        'status_sent',
        'status_viewed',
      ],
      closed: [
        'converted',
      ],
      archived: [
        'archived',
      ],
    },
    delivery_notes: {
      open: [
        'status_draft',
        'status_planned',
        'status_readyforsending',
        'status_sendfailed',
        'status_sent',
        'status_viewed',
      ],
      closed: [
        'converted',
      ],
      archived: [
        'archived',
      ],
    },
    invoices: {
      open: [
        'status_draft',
        'status_planned',
        'status_readyforsending',
        'status_sendfailed',
        'status_sent',
        'status_viewed',
        'invoices_due',
        'dunning_level_1',
        'dunning_level_2',
        'dunning_level_3',
        'partial_payment',
      ],
      imported: [],
      closed: [
        'status_paid',
      ],
      archived: [
        'archived',
      ],
    },
    credit_notes: {
      open: [
        'status_draft',
        'status_planned',
        'status_readyforsending',
        'status_sendfailed',
        'status_sent',
        'status_viewed',
        'invoices_due',
        'partial_payment',
        'payment_receipt',
      ],
      closed: [
        'status_paid',
        'converted',
      ],
      archived: [
        'archived',
      ],
    },
    receipts: {
      open: [
        'receipts_open',
        'invoices_due',
        'status_approved',
        'partial_payment',
        'payment_receipt',
        'status_receipt_readyforsending',
      ],
      closed: [
        'status_paid',
      ],
      archived: [
        'archived',
      ],
    },
    payments: {
      open: [
        'payment_status_open',
        'payment_status_assign',
        'payment_status_wait',
      ],
      closed: [
        'payment_status_ready',
      ],
      archived: [
        'archived',
        'payment_cancelled',
      ],
      invoice: [
        'payment_status_open',
        'payment_status_assign',
        'payment_status_wait',
        'payment_status_ready',
        'archived',
        'payment_cancelled',
      ],
    },
    bankFiles: {
      open: [
        'bank_files_status_open',
        'bank_files_status_imported',
      ],
    },
  },
  statusTypeList(type, group) {
    if (!this.typeList[type]) {
      return [];
    }
    let typeList = [];
    if (group && this.typeList[type][group]) {
      typeList = this.typeList[type][group];
    } else {
      typeList = [
        ...this.typeList[type].open,
        ...this.typeList[type].closed,
        ...this.typeList[type].archived,
      ];
    }
    return typeList;
  },
  status(type, group) {
    return this.statusTypeList(type, group).map(
      (textId) => this.allStatus().find((status) => status.textId === textId),
    );
  },
};
