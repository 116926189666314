import axios from 'axios';
import * as types from './types';

export default {
  fetchClassification({ rootState, commit }, { classificationId, abortController }) {
    commit(types.FETCH_CLASSIFICATION);
    const controller = abortController || new AbortController();

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-classifications/${classificationId}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_CLASSIFICATION_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_CLASSIFICATION_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_CLASSIFICATION_FAILURE, error);
      return Promise.reject(error);
    });
  },

  postClassification({ rootState, commit }, classification) {
    commit(types.POST_CLASSIFICATION);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-classifications`, classification).then((response) => {
      commit(types.POST_CLASSIFICATION_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.POST_CLASSIFICATION_FAILURE, error);
      return Promise.reject(error);
    });
  },

  patchClassification({ rootState, commit }, classification) {
    commit(types.PATCH_CLASSIFICATION);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-classifications/${classification.id}`, {
      ...classification,
    }).then((response) => {
      commit(types.PATCH_CLASSIFICATION_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.PATCH_CLASSIFICATION_FAILURE, error);
      return Promise.reject(error);
    });
  },

  deleteClassification({ rootState, commit }, classificationId) {
    commit(types.DELETE_CLASSIFICATION);

    return axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-classifications/${classificationId}`)
      .then((response) => {
        commit(types.DELETE_CLASSIFICATION_SUCCESS, response);
        return Promise.resolve(response);
      }).catch((error) => {
        commit(types.DELETE_CLASSIFICATION_FAILURE, error);
        return Promise.reject(error);
      });
  },

};
