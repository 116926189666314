import type { AxiosPromise } from 'axios';
import type { Payload } from '@/api/interfaces/payload';
import httpClient from '@/helpers/client/http-client';
import type { AccountUser } from '@/api/interfaces/user/account-user';
import webApiClient from '@/helpers/client/webapi-client';

export function getUser(abortController: AbortController = new AbortController()): AxiosPromise<never> {
  return webApiClient.get('/user', {
    signal: abortController.signal,
  });
}

export function getAccountUser(
  abortController: AbortController = new AbortController(),
): AxiosPromise<Payload<AccountUser>> {
  return httpClient.get('/users/me', {
    signal: abortController.signal,
  });
}
