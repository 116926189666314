import axios from 'axios';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_PRESET_PENDING = 'FETCH_PRESET_PENDING';
const FETCH_PRESET_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_PRESET_ERROR = 'FETCH_PRESET_ERROR';

const STORE_PRESET_PENDING = 'STORE_PRESET_PENDING';
const STORE_PRESET_FULFILLED = 'STORE_PRESET_FULFILLED';
const STORE_PRESET_ERROR = 'STORE_PRESET_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    fetchPresetPending: false,
    preset: {
      active: [],
      passive: [],
    },
    storePresetPending: false,
    fetchExchangeRatesPending: false,
    exchangeRates: [],
  },
  mutations: {
    // FETCH_INITIAL
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    // FETCH_PRESET
    [FETCH_PRESET_PENDING](state) {
      state.fetchPresetPending = true;
    },
    [FETCH_PRESET_FULFILLED](state, { data }) {
      const formatNumber = (value) => (value !== 0 ? value.toFixed(2) : '0.00');

      const prepareItem = (item) => {
        item.credit_value = formatNumber(item.credit_value);
        item.debit_value = formatNumber(item.debit_value);
        item.pre_year_ending_balance = formatNumber(item.pre_year_ending_balance);
        item.exchange_credit_value = formatNumber(item.exchange_credit_value);
        item.exchange_debit_value = formatNumber(item.exchange_debit_value);
        item.exchange_pre_year_ending_balance = formatNumber(item.exchange_pre_year_ending_balance);
        return item;
      };
      const active = data.A.map((item) => prepareItem(item));
      const passive = data.B.map((item) => prepareItem(item));
      state.preset.active = active;
      state.preset.passive = passive;
      state.fetchPresetPending = false;
    },
    [FETCH_PRESET_ERROR](state) {
      state.fetchPresetPending = false;
    },
    // STORE_PRESET
    [STORE_PRESET_PENDING](state) {
      state.storePresetPending = true;
    },
    [STORE_PRESET_FULFILLED](state) {
      state.storePresetPending = false;
    },
    [STORE_PRESET_ERROR](state) {
      state.storePresetPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }, accountingYearId) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await Promise.all([
          dispatch('fetchPreset', accountingYearId),
          dispatch('definitions/fetchExchangeRates', null, { root: true }),
        ]);
        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchPreset({ rootState, commit }, accountingYearId) {
      commit(FETCH_PRESET_PENDING);
      try {
        const params = { idYear: accountingYearId };
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_preset_get`, { params });
        commit(FETCH_PRESET_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_PRESET_ERROR, error);
        return Promise.reject(error);
      }
    },
    async storePreset({ rootState, commit, dispatch }, { accountingYearId, payload }) {
      commit(STORE_PRESET_PENDING);
      try {
        const params = { idYear: accountingYearId };
        await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting_preset_set`, payload, { params });
        await dispatch('fetchPreset', accountingYearId);
        dispatch('messages/showMessage', {
          key: 'accounting_preset_store_successful',
          color: 'success',
        }, { root: true });
        commit(STORE_PRESET_FULFILLED);
        return true;
      } catch (error) {
        commit(STORE_PRESET_ERROR, error);
        dispatch('messages/showMessage', {
          key: 'accounting_preset_store_error',
          color: 'error',
        }, { root: true });
        return Promise.reject(error);
      }
    },
  },
};
