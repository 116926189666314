import * as types from './types';

export default {
  /*
   * single booking-account
   */
  [types.FETCH_BOOKING_ACCOUNT](state) {
    state.isLoadingBookingAccount = true;
    state.hasLoadedBookingAccount = false;
    state.bookingAccountError = null;
  },
  [types.FETCH_BOOKING_ACCOUNT_SUCCESS](state, bookingAccount) {
    state.bookingAccount = bookingAccount;

    state.isLoadingBookingAccount = false;
    state.hasLoadedBookingAccount = true;
    state.bookingAccountError = null;
  },
  [types.FETCH_BOOKING_ACCOUNT_FAILURE](state, error) {
    state.isLoadingBookingAccount = false;
    state.hasLoadedBookingAccount = false;
    state.bookingAccountError = error;
  },
  [types.FETCH_BOOKING_ACCOUNT_CANCEL](state) {
    state.isLoadingBookingAccount = false;
    state.bookingAccountError = null;
  },

  /*
   * POST booking-account
   */
  [types.POST_BOOKING_ACCOUNT](state) {
    state.isPostingBookingAccount = true;
    state.postingError = null;
  },
  [types.POST_BOOKING_ACCOUNT_SUCCESS](state) {
    state.isPostingBookingAccount = false;
    state.postingError = null;
  },
  [types.POST_BOOKING_ACCOUNT_FAILURE](state, error) {
    state.isPostingBookingAccount = false;
    state.postingError = error;
  },

  /*
   * PATCH booking-account
   */
  [types.PATCH_BOOKING_ACCOUNT](state) {
    state.isPatchingBookingAccount = true;
    state.patchingError = null;
  },
  [types.PATCH_BOOKING_ACCOUNT_SUCCESS](state) {
    state.isPatchingBookingAccount = false;
    state.patchingError = null;
  },
  [types.PATCH_BOOKING_ACCOUNT_FAILURE](state, error) {
    state.isPatchingBookingAccount = false;
    state.patchingError = error;
  },

  /*
   * DELETE booking-account
   */
  [types.DELETE_BOOKING_ACCOUNT](state) {
    state.isDeletingBookingAccount = true;
    state.deletingError = null;
  },
  [types.DELETE_BOOKING_ACCOUNT_SUCCESS](state) {
    state.isDeletingBookingAccount = false;
    state.deletingError = null;
  },
  [types.DELETE_BOOKING_ACCOUNT_FAILURE](state, error) {
    state.isDeletingBookingAccount = false;
    state.deletingError = error;
  },

  /*
   * FAVORITE booking-account
   */
  [types.FAVORITE_BOOKING_ACCOUNT](state, id) {
    state.isPendingFavoriteAccounts.push(id);
  },
  [types.FAVORITE_BOOKING_ACCOUNT_SUCCESS](state, id) {
    state.isPendingFavoriteAccounts = state.isPendingFavoriteAccounts.filter((item) => item !== id);
  },
  [types.FAVORITE_BOOKING_ACCOUNT_FAILURE](state, id) {
    state.isPendingFavoriteAccounts = state.isPendingFavoriteAccounts.filter((item) => item !== id);
  },
};
