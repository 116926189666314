import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }, { metaKey }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await Promise.all([
        dispatch('fetchList', { metaKey }),
        dispatch('fetchBulkActions'),
        dispatch('fetchSigningUsers'),
      ]);

      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchList({
    rootState, commit, state, dispatch,
  }, { meta, metaKey }) {
    commit(types.FETCH_LIST_PENDING);
    try {
      let params = {};
      const metaValue = meta || state.meta[metaKey];
      if (metaValue) {
        params = {
          direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy[0],
          page: metaValue.page,
          search: metaValue.search,
        };
      }
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/inbox/user`, { params });
      if (response.meta) {
        commit(types.FETCH_TABLE_META, { meta: response.meta, key: metaKey });
      }
      commit(types.FETCH_LIST_FULFILLED, response);
      dispatch('fetchInboxCount');
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_LIST_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchSingleActions({ rootState, dispatch }, { id }) {
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/inbox/${id}/actions`);
      return Promise.resolve(response.data);
    } catch ({ data }) {
      // TODO: harmonize error message object key
      const message = data.data || data.message;
      dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      return Promise.reject(data);
    }
  },
  async storeAction({ rootState, commit, dispatch }, { metaKey, params }) {
    commit(types.STORE_ACTION_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/inbox/action`, params);
      await dispatch('fetchList', { metaKey });
      commit(types.STORE_ACTION_FULFILLED);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.STORE_ACTION_ERROR, error);
      // TODO: harmonize error message object key
      const message = error.data.data || error.data.message;
      dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchBulkActions({ rootState, commit, dispatch }) {
    commit(types.FETCH_BULK_ACTIONS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/inbox/bulk`);
      commit(types.FETCH_BULK_ACTIONS_FULFILLED, response);
      return true;
    } catch ({ data }) {
      commit(types.FETCH_BULK_ACTIONS_ERROR, data);
      // TODO: harmonize error message object key
      const message = data.data || data.message;
      dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      return Promise.reject(data);
    }
  },
  async storeBulk({ rootState, commit, dispatch }, { metaKey, params }) {
    commit(types.STORE_BULK_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/inbox/bulk`, params);
      await dispatch('fetchList', { metaKey });
      if (response.message) {
        dispatch('messages/showMessage', {
          text: response.message,
          color: response.data.failed > 0 ? 'warning' : 'success',
        }, { root: true });
      }
      commit(types.STORE_BULK_FULFILLED);
      return Promise.resolve(response);
    } catch ({ data }) {
      commit(types.STORE_BULK_ERROR, data);
      // TODO: harmonize error message object key
      const message = data.data || data.message;
      dispatch('messages/showMessage', { text: message.name || message, color: 'error' }, { root: true });
      return Promise.reject(data);
    }
  },
  async storeSigning({ rootState, commit, dispatch }, params) {
    commit(types.STORE_SIGNING_PENDING);
    try {
      const form = { signoff_user_id: params.signoff_user_id };
      const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/receipts/${params.id}/sign`, form);
      await dispatch('fetchList', params.list);
      commit(types.STORE_SIGNING_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'signing_successful', color: 'success' }, { root: true });
      return true;
    } catch (error) {
      commit(types.STORE_SIGNING_ERROR, error);
      dispatch('messages/showMessage', { key: 'signing_error', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchSigningUsers({ rootState, commit }) {
    commit(types.FETCH_SIGNING_USERS_PENDING);
    try {
      const accountId = rootState.definitions.account.account.uuid;
      const response = await axios.get(`/accounts/${accountId}/signoffusers`);
      commit(types.FETCH_SIGNING_USERS_FULFILLED, response);
      return true;
    } catch (error) {
      commit(types.FETCH_SIGNING_USERS_ERROR, error);
      return Promise.reject(error);
    }
  },
  async markAsRead({ rootState, commit }, id) {
    try {
      const accountId = rootState.definitions.account.account.uuid;
      const response = await axios.post(`/accounts/${accountId}/inbox/read/${id}`);
      commit(types.MARK_AS_READ);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchInboxCount({ rootState, commit }) {
    try {
      const accountId = rootState.definitions.account.account.uuid;
      const response = await axios.get(`/accounts/${accountId}/inbox/count_unread`);
      commit(types.FETCH_INBOX_COUNT, response);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default actions;
