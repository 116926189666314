// initial data load
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_NEXTNUMBER_FULFILLED = 'FETCH_NEXTNUMBER_FULFILLED';

export const FETCH_INVOICE_PENDING = 'FETCH_INVOICE_PENDING';
export const FETCH_INVOICE_FULFILLED = 'FETCH_INVOICE_FULFILLED';
export const FETCH_INVOICE_ERROR = 'FETCH_INVOICE_ERROR';

export const FETCH_SINGLE_INVOICE_PENDING = 'FETCH_SINGLE_INVOICE_PENDING';
export const FETCH_SINGLE_INVOICE_FULFILLED = 'FETCH_SINGLE_INVOICE_FULFILLED';
export const FETCH_SINGLE_INVOICE_ERROR = 'FETCH_SINGLE_INVOICE_ERROR';

export const FETCH_ACTIONS_PENDING = 'FETCH_ACTIONS_PENDING';
export const FETCH_ACTIONS_FULFILLED = 'FETCH_ACTIONS_FULFILLED';
export const FETCH_ACTIONS_ERROR = 'FETCH_ACTIONS_ERROR';

export const FETCH_RELATED_INVOICES_PENDING = 'FETCH_RELATED_INVOICES_PENDING';
export const FETCH_RELATED_INVOICES_FULFILLED = 'FETCH_RELATED_INVOICES_FULFILLED';

export const FETCH_RECURRING_PENDING = 'FETCH_RECURRING_PENDING';
export const FETCH_RECURRING_FULFILLED = 'FETCH_RECURRING_FULFILLED';

export const PRECALCULATE_INVOICE_PENDING = 'PRECALCULATE_INVOICE_PENDING';
export const PRECALCULATE_INVOICE_FULFILLED = 'PRECALCULATE_INVOICE_FULFILLED';
export const PRECALCULATE_INVOICE_ERROR = 'PRECALCULATE_INVOICE_ERROR';

export const GENERATE_PREVIEW_PENDING = 'GENERATE_PREVIEW_PENDING';
export const GENERATE_PREVIEW_FULFILLED = 'GENERATE_PREVIEW_FULFILLED';
export const GENERATE_PREVIEW_ERROR = 'GENERATE_PREVIEW_ERROR';

export const STORE_INVOICE_PENDING = 'STORE_INVOICE_PENDING';
export const STORE_INVOICE_FULFILLED = 'STORE_INVOICE_FULFILLED';
export const STORE_INVOICE_ERROR = 'STORE_INVOICE_ERROR';

export const FETCH_TABLE_META = 'FETCH_TABLE_META';
