import { IntlCurrencyCountryCodes, IntlLanguageLocales } from '@/enums/intl';
import store from '@/store';
import Vue from 'vue';

export default function init() {
  // TODO: remove this filter if Laravel locale is more specific (de-CH instead of de)
  Vue.filter('formatNumber', (value, languageId = null, returnZero = true) => {
    if (!returnZero && (!value || Number.isNaN(value))) {
      return '';
    }
    let languageLocaleId = 3;
    if (languageId) {
      languageLocaleId = languageId;
    } else if (store.state.definitions.account.account.language_id) {
      languageLocaleId = store.state.definitions.account.account.language_id;
    }
    return new Intl.NumberFormat(IntlLanguageLocales.get(languageLocaleId), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  });

  const defaultCurrencyCode = store.state.definitions.account.account.currency_code
    ?? 'CHF';

  const taxRateFormatter = new Intl.NumberFormat(IntlCurrencyCountryCodes.get(defaultCurrencyCode), {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  Vue.filter('formatCurrency', (currency, currencyCode, countryCode) => {
    const currencyFormatter = new Intl.NumberFormat(countryCode
      ?? IntlCurrencyCountryCodes.get(currencyCode ?? defaultCurrencyCode), {
      style: 'currency',
      currency: currencyCode ?? defaultCurrencyCode,
    });
    return currencyFormatter.format(currency);
  });

  Vue.filter('toHours', (value, decimals = 2) => {
    if (Number.isNaN(parseFloat(value))) {
      return '';
    }

    return Vue.prototype.$moment.duration(parseFloat(value), 'minutes').asHours().toFixed(decimals);
  });

  Vue.filter(
    'dateShort',
    (value) => (
      value
        ? Vue.prototype.$moment(value).format(store.getters['definitions/account/accountDateFormat'].date)
        : ''
    ),
  );

  Vue.filter(
    'dateTimeLong',
    (value) => (
      value
        ? Vue.prototype.$moment(value).format(store.getters['definitions/account/accountDateFormat'].dateTime)
        : ''
    ),
  );

  Vue.filter(
    'dateDayMonth',
    (value) => (
      value
        ? Vue.prototype.$moment(value).format(store.getters['definitions/account/accountDateFormat'].dateDayMonth)
        : ''
    ),
  );

  Vue.filter(
    'dateMonthYear',
    (value) => (
      value
        ? Vue.prototype.$moment(value).format(store.getters['definitions/account/accountDateFormat'].dateMonthYear)
        : ''
    ),
  );

  Vue.filter(
    'formatTime',
    (value) => {
      if (!value) {
        return value;
      }
      const inputNumber = value.replace(/\D/g, '');

      if (inputNumber.length === 0) {
        return null;
      }

      // check first two digits
      const hourPart = inputNumber.slice(0, 2);
      const minutesPart = inputNumber.slice(2, 4);

      // if hour part is smaller than 23 - use those as hours
      if (hourPart < 24 && minutesPart < 60) {
        return Vue.prototype.$moment({ hour: hourPart, minute: minutesPart }).format('HH:mm');
      }

      // if only two digits are given, parse the first digit as hour and the second as minute
      if (inputNumber.length === 2) {
        return Vue.prototype.$moment({ hour: inputNumber[0], minute: inputNumber[1] }).format('HH:mm');
      }

      // if there are three digits, the first digit is the hour, the last two are the minutes
      if (Vue.prototype.$moment(inputNumber, ['h', 'hmm']).isValid()) {
        return Vue.prototype.$moment(inputNumber, ['h', 'hmm']).format('HH:mm');
      }
      return value;
    },
  );

  Vue.filter('initials', (value) => {
    if (value) {
      return value
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    }
    return '';
  });

  Vue.filter('nl2br', (value) => {
    if (typeof value === 'undefined' || value === null) {
      return '';
    }
    return (`${value}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
  });

  Vue.filter('trim', (value, length) => {
    if (value && value.length > length) {
      return `${value.substring(0, length)}...`;
    }
    return value;
  });

  Vue.filter('capitalize', (value) => {
    if (!value) return '';
    return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
  });

  Vue.filter('taxRate', (percentage) => taxRateFormatter.format(percentage));
}
