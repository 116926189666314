/* global moment, LeanSyncAPI */
import axios from 'axios';
import { useI18n } from '@/helpers/i18n';

const i18n = useI18n();

const FETCH_LEANSYNC_KEY_PENDING = 'FETCH_LEANSYNC_KEY_PENDING';
const FETCH_LEANSYNC_KEY_FULFILLED = 'FETCH_LEANSYNC_KEY_FULFILLED';
const FETCH_LEANSYNC_KEY_ERROR = 'FETCH_LEANSYNC_KEY_ERROR';

const AUTHENTICATE_LEANSYNC_PENDING = 'AUTHENTICATE_LEANSYNC_PENDING';
const AUTHENTICATE_LEANSYNC_FULFILLED = 'AUTHENTICATE_LEANSYNC_FULFILLED';
const AUTHENTICATE_LEANSYNC_ERROR = 'AUTHENTICATE_LEANSYNC_ERROR';

const INITIATE_OUTBOUND_CALL_PENDING = 'INITIATE_OUTBOUND_CALL_PENDING';
const INITIATE_OUTBOUND_CALL_FULFILLED = 'INITIATE_OUTBOUND_CALL_FULFILLED';
const INITIATE_OUTBOUND_CALL_ERROR = 'INITIATE_OUTBOUND_CALL_ERROR';

const INBOUND_CALL = 'INBOUND_CALL';
const SET_INBOUND_CALLER = 'SET_INBOUND_CALLER';
const REMOVE_INBOUND_CALLER = 'REMOVE_INBOUND_CALLER';

const initialState = {
  leansyncKeyPending: false,
  leansyncKey: '',
  platform: '',
  connectionPending: false,
  connected: false,
  callPending: false,
  callers: [],
  inboundCall: false,
};

const mutations = {
  [FETCH_LEANSYNC_KEY_PENDING](state) {
    state.leansyncPending = true;
  },
  [FETCH_LEANSYNC_KEY_FULFILLED](state, { data }) {
    if (data) {
      state.leansyncKey = data.leansync_key;
      state.platform = data.platform;
      state.leansyncPending = false;
    }
  },
  [FETCH_LEANSYNC_KEY_ERROR](state) {
    state.leansyncPending = false;
  },
  [AUTHENTICATE_LEANSYNC_PENDING](state) {
    state.connectionPending = true;
  },
  [AUTHENTICATE_LEANSYNC_FULFILLED](state) {
    state.connected = true;
    state.connectionPending = false;
  },
  [AUTHENTICATE_LEANSYNC_ERROR](state) {
    state.connectionPending = false;
  },
  [INITIATE_OUTBOUND_CALL_PENDING](state) {
    state.callPending = true;
  },
  [INITIATE_OUTBOUND_CALL_FULFILLED](state) {
    state.callPending = false;
  },
  [INITIATE_OUTBOUND_CALL_ERROR](state) {
    state.callPending = false;
  },
  [INBOUND_CALL](state) {
    state.inboundCall = true;
  },
  [SET_INBOUND_CALLER](state, caller) {
    state.callers.push(caller);
  },
  [REMOVE_INBOUND_CALLER](state, index) {
    state.callers.splice(index, 1);
    if (state.callers.legth <= 0) {
      state.inboundCall = false;
    }
  },
};

const actions = {
  async fetchLeansync({ rootState, commit, dispatch }) {
    commit(FETCH_LEANSYNC_KEY_PENDING);
    try {
      const leansyncUser = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/leansync/user`);
      commit(FETCH_LEANSYNC_KEY_FULFILLED, leansyncUser);
      dispatch('leansyncAuthentication', leansyncUser);
    } catch (error) {
      console.error(error);
      commit(FETCH_LEANSYNC_KEY_ERROR);
    }
  },
  leansyncAuthentication({ state, commit, dispatch }, { data }) {
    if (!data) {
      return;
    }
    commit(AUTHENTICATE_LEANSYNC_PENDING);
    if (typeof LeanSyncAPI === 'undefined') {
      commit(AUTHENTICATE_LEANSYNC_ERROR);
      dispatch('messages/showMessage', { key: 'leansync_connection_failed_error', color: 'error' }, { root: true });
      return;
    }
    LeanSyncAPI.authentication(state.leansyncKey, (result) => {
      if (typeof result.code !== 'undefined') {
        commit(AUTHENTICATE_LEANSYNC_FULFILLED);
        dispatch('leansyncListenForIncomingCall');
      } else {
        commit(AUTHENTICATE_LEANSYNC_ERROR);
        let message = `
            ${i18n.t('texts.swisscom_inone_call_error')}<br>
          `;
        if (result.error.description === 'Wrong ID') {
          message += `
            ${i18n.t('texts.leansync_wrong_id_error')}
          `;
        } else if (result.error.description === 'Key inactive') {
          message += `
            ${i18n.t('texts.leansync_key_inactive_error')}
          `;
        } else {
          message += `
            ${i18n.t('texts.leansync_unknown_error')}
          `;
        }
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      }
    }, data);
  },
  leansyncListenForIncomingCall({ dispatch }) {
    LeanSyncAPI.incomingCall((data) => {
      const caller = decodeURIComponent(data.caller);
      if (data.type === 'incomingCall') {
        dispatch('getCaller', caller);
      }
    });
  },
  leansyncCall({ state, commit, dispatch }, phoneNumber) {
    commit(INITIATE_OUTBOUND_CALL_PENDING);
    LeanSyncAPI.initiateCall(phoneNumber, (data) => {
      if (data.type === 'initiateCall') {
        commit(INITIATE_OUTBOUND_CALL_FULFILLED);
        dispatch('messages/showMessage', { key: 'swisscom_inone_call_redirected', color: 'success' }, { root: true });
      } else {
        commit(INITIATE_OUTBOUND_CALL_ERROR);
        let message = `
            ${i18n.t('texts.swisscom_inone_call_error')}<br>
          `;
        if (data.error.description === 'Wrong ID') {
          message += `
            ${i18n.t('texts.leansync_wrong_id_error')}
          `;
        } else if (data.error.description === 'Key inactive') {
          message += `
            ${i18n.t('texts.leansync_key_inactive_error')}
          `;
        } else {
          message += `
            ${i18n.t('texts.leansync_unknown_error')}
          `;
        }
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      }
    }, { platform: state.platform });
  },
  async getCaller({ rootState, commit }, phoneNumber) {
    try {
      const caller = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/leansync/caller/${phoneNumber}`);
      caller.data.time = moment().format('LT');
      const alreadyCalling = initialState.callers.find((call) => {
        const sameAddressId = call.addressId === caller.data.addressId;
        const sameTime = call.time === caller.data.time;
        return sameAddressId && sameTime;
      });
      if (!alreadyCalling) {
        commit(INBOUND_CALL);
        commit(SET_INBOUND_CALLER, caller.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  removeCaller({ commit }, index) {
    commit(REMOVE_INBOUND_CALLER, index);
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
