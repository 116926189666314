export default {
  isPosting: false,
  postingError: null,

  isPatching: false,
  patchingError: null,

  isPostingFile: false,
  postingFileError: false,

  isDeleting: false,
  deletingError: null,

  isClosing: false,
  closingError: null,

  isCancelling: false,
  cancellingError: null,

  isMoving: false,
  movingError: null,

  isBulkClosing: false,
  bulkClosingError: null,

  isLoadingDocument: false,
  loadingDocumentError: null,

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  isReady: false,
  checkAccountPending: false,
  fetchAccountingReadyPending: false,
  fetchAccountingYearsPending: false,
  settingsTodo: {},
  settings: {},
  accountingYears: [],
  currentAccountingYear: {},
  fetchVatPeriodsPending: false,
  vatPeriods: [],
  checkVatPeriodsPending: false,
};
