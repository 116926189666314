import statusList from '@/components/status/StatusList';
import axios from 'axios';
import Vue from 'vue';
import { useI18n } from '@/helpers/i18n';

const i18n = useI18n();

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';
const FETCH_TABLE_META = 'FETCH_TABLE_META';

const FETCH_BULK_ACTIONS_PENDING = 'FETCH_BULK_ACTIONS_PENDING';
const FETCH_BULK_ACTIONS_FULFILLED = 'FETCH_BULK_ACTIONS_FULFILLED';
const FETCH_BULK_ACTIONS_ERROR = 'FETCH_BULK_ACTIONS_ERROR';

const STORE_ACTION_PENDING = 'STORE_ACTION_PENDING';
const STORE_ACTION_FULFILLED = 'STORE_ACTION_FULFILLED';
const STORE_ACTION_ERROR = 'STORE_ACTION_ERROR';

const STORE_BULK_PENDING = 'STORE_BULK_PENDING';
const STORE_BULK_FULFILLED = 'STORE_BULK_FULFILLED';
const STORE_BULK_ERROR = 'STORE_BULK_ERROR';

const SEND_PAYMENT_ORDERS_PENDING = 'SEND_PAYMENT_ORDERS_PENDING';
const SEND_PAYMENT_ORDERS_FULFILLED = 'SEND_PAYMENT_ORDERS_FULFILLED';
const SEND_PAYMENT_ORDERS_ERROR = 'SEND_PAYMENT_ORDERS_ERROR';

const SEND_ABABANK_PAYMENT_ORDERS_PENDING = 'SEND_ABABANK_PAYMENT_ORDERS_PENDING';
const SEND_ABABANK_PAYMENT_ORDERS_FULFILLED = 'SEND_ABABANK_PAYMENT_ORDERS_FULFILLED';
const SEND_ABABANK_PAYMENT_ORDERS_ERROR = 'SEND_ABABANK_PAYMENT_ORDERS_ERROR';

const SEND_EBANKING_PAYMENT_ORDERS_PENDING = 'SEND_EBANKING_PAYMENT_ORDERS_PENDING';
const SEND_EBANKING_PAYMENT_ORDERS_FULFILLED = 'SEND_EBANKING_PAYMENT_ORDERS_FULFILLED';
const SEND_EBANKING_PAYMENT_ORDERS_ERROR = 'SEND_EBANKING_PAYMENT_ORDERS_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    bulkActionsPending: false,
    bulkActions: [],
    storeBulkPending: false,
    listPending: false,
    list: [],
    meta: {},
    storeActionPending: false,
    sendPaymentOrdersPending: false,
    sendAbabankPaymentOrdersPending: false,
    sendEbankingPaymentOrdersPending: false,
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
      state.list = [];
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_TABLE_META](state, { meta, key }) {
      if (meta) {
        state.meta[key] = {
          sortDesc: [meta.direction === 'DESC'],
          itemsPerPage: parseInt(meta.per_page, 10),
          sortBy: [meta.sort],
          page: parseInt(meta.page, 10),
          search: meta.search,
          extendSearch: meta.extend_search,
          maxPage: parseInt(meta.max_page, 10),
          totalItems: meta.max_page * meta.per_page,
        };
      }
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    [FETCH_BULK_ACTIONS_PENDING](state) {
      state.bulkActionsPending = true;
    },
    [FETCH_BULK_ACTIONS_FULFILLED](state, { data }) {
      state.bulkActions = Object.keys(data).map((key) => ({ id: key, text: data[key] }));
      state.bulkActionsPending = false;
    },
    [FETCH_BULK_ACTIONS_ERROR](state) {
      state.bulkActionsPending = false;
    },
    [STORE_BULK_PENDING](state) {
      state.storeBulkPending = true;
    },
    [STORE_BULK_FULFILLED](state) {
      state.storeBulkPending = false;
    },
    [STORE_BULK_ERROR](state) {
      state.storeBulkPending = false;
    },
    [STORE_ACTION_PENDING](state) {
      state.storeActionPending = true;
    },
    [STORE_ACTION_FULFILLED](state) {
      state.storeActionPending = false;
    },
    [STORE_ACTION_ERROR](state) {
      state.storeActionPending = false;
    },
    [SEND_PAYMENT_ORDERS_PENDING](state) {
      state.sendPaymentOrdersPending = true;
    },
    [SEND_PAYMENT_ORDERS_FULFILLED](state) {
      state.sendPaymentOrdersPending = false;
    },
    [SEND_PAYMENT_ORDERS_ERROR](state) {
      state.sendPaymentOrdersPending = false;
    },
    [SEND_ABABANK_PAYMENT_ORDERS_PENDING](state) {
      state.sendAbabankPaymentOrdersPending = true;
    },
    [SEND_ABABANK_PAYMENT_ORDERS_FULFILLED](state) {
      state.sendAbabankPaymentOrdersPending = false;
    },
    [SEND_ABABANK_PAYMENT_ORDERS_ERROR](state) {
      state.sendAbabankPaymentOrdersPending = false;
    },
    [SEND_EBANKING_PAYMENT_ORDERS_PENDING](state) {
      state.sendEbankingPaymentOrdersPending = true;
    },
    [SEND_EBANKING_PAYMENT_ORDERS_FULFILLED](state) {
      state.sendEbankingPaymentOrdersPending = false;
    },
    [SEND_EBANKING_PAYMENT_ORDERS_ERROR](state) {
      state.sendEbankingPaymentOrdersPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }, params) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await Promise.all([
          dispatch('fetchList', params),
          dispatch('fetchBulkActions'),
        ]);

        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, state, commit }, { list, id, meta }) {
      commit(FETCH_LIST_PENDING);
      try {
        let params = {};
        const metaKey = `${list}${id}`;
        const metaValue = meta || state.meta[metaKey];
        const extendedSearch = {};
        const search = [];
        if (metaValue) {
          extendedSearch.status_name_OR = metaValue.search.filter(
            (filterValue) => {
              const hasFilter = statusList.allStatus().find(
                (status) => status.text === filterValue,
              );
              if (!hasFilter) {
                search.push(filterValue);
              }
              return hasFilter;
            },
          );
          params = {
            direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy[0],
            page: metaValue.page,
            search,
          };
        }
        // prepare extended search parameter
        const extendedSearchParameter = Object.keys(extendedSearch).map(
          (extendedSearchKey) => {
            if (!extendedSearch[extendedSearchKey]) {
              return undefined;
            }
            return `extend_search[${extendedSearchKey}]=${extendedSearch[extendedSearchKey].join()}`;
          },
        ).filter((value) => value !== undefined).join('&');

        const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}`;
        let url = list === 'invoice' ? `${baseUrl}/payments` : `${baseUrl}/pagination/payments`;
        url = list !== 'address' ? `${url}/${list}` : url;
        if (list === 'address' || list === 'invoice') {
          url = `${url}/${id}`;
        }
        url = extendedSearchParameter ? `${url}?${extendedSearchParameter}` : url;
        const response = await axios.get(url, { params });
        if (response.meta) {
          if (metaValue) {
            response.meta.search = metaValue.search; // keep current search params
          }
          commit(FETCH_TABLE_META, { meta: response.meta, key: metaKey });
        }
        commit(FETCH_LIST_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchBulkActions({ rootState, commit }) {
      commit(FETCH_BULK_ACTIONS_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/payments/bulkactions`);
        commit(FETCH_BULK_ACTIONS_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_BULK_ACTIONS_ERROR, error);
        return Promise.reject(error);
      }
    },
    async storeBulk({ rootState, commit, dispatch }, { list, params }) {
      commit(STORE_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/payments/bulk`, params);
        dispatch('fetchList', list);
        commit(STORE_BULK_FULFILLED);
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
        return Promise.resolve(response);
      } catch ({ data }) {
        commit(STORE_ACTION_ERROR, data);
        // TODO: harmonize error message object key
        const message = data.data || data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(data);
      }
    },
    async storeAction({ rootState, commit, dispatch }, { list, params }) {
      commit(STORE_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/payments/action`, params);
        dispatch('fetchList', list);
        commit(STORE_ACTION_FULFILLED);
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
        return Promise.resolve(response);
      } catch (error) {
        commit(STORE_ACTION_ERROR, error);
        // TODO: harmonize error message object key
        const message = error.data.data || error.data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async sendPaymentOrders({ commit, dispatch }, { isAbabankActive, isOauthActive }) {
      commit(SEND_PAYMENT_ORDERS_PENDING);
      try {
        if (isAbabankActive) {
          await dispatch('sendAbabankPaymentOrders');
        }
        if (isOauthActive) {
          await dispatch('sendEbankingPaymentOrders');
        }
        commit(SEND_PAYMENT_ORDERS_FULFILLED);
        return Promise.resolve(true);
      } catch (error) {
        commit(SEND_PAYMENT_ORDERS_ERROR, error);
        dispatch('messages/showMessage', { key: error, color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async sendAbabankPaymentOrders({ rootState, commit, dispatch }) {
      commit(SEND_ABABANK_PAYMENT_ORDERS_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/ababank/payment_orders`);
        commit(SEND_ABABANK_PAYMENT_ORDERS_FULFILLED);
        if (response.data.total) {
          const messageColor = response.data.failed > 0 ? 'warning' : 'success';
          const message = `
            ${i18n.t('texts.ababank_send_payment_orders_uploaded')}: ${response.data.uploaded}<br>
            ${i18n.t('texts.ababank_send_payment_orders_failed')}: ${response.data.failed}<br>
            ${i18n.t('texts.ababank_send_payment_orders_deleted')}: ${response.data.deleted.join(', ')}
          `;
          dispatch('messages/showMessage', { text: message, color: messageColor }, { root: true });
        } else {
          // no payments
          dispatch('messages/showMessage', { key: 'no_payments_found_to_upload', color: 'success' }, { root: true });
        }
        return Promise.resolve(response);
      } catch (error) {
        error.key = 'texts.test';
        commit(SEND_ABABANK_PAYMENT_ORDERS_ERROR, error);
        dispatch('messages/showMessage', { key: error, color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async sendEbankingPaymentOrders({ rootState, commit, dispatch }) {
      commit(SEND_EBANKING_PAYMENT_ORDERS_PENDING);
      try {
        const headers = {
          'X-Session-Id': null,
        };
        const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/sync`, null, { headers });
        if (response.state && response.state !== 'SUCCESS') {
          Vue.prototype.$bus.$emit('EBANKING_CONFIRM_PAYMENTS');
          return Promise.resolve(response);
        }
        if (response.status === 204) {
          dispatch('messages/showMessage', { key: 'no_payments_found_to_upload', color: 'success' }, { root: true });
        } else {
          const messageColor = response.data.failed > 0 ? 'warning' : 'success';
          const message = `
            ${i18n.t('texts.banking_payments_accepted')}: ${response.data.accepted}<br>
            ${i18n.t('texts.banking_payments_changed')}: ${response.data.changed}<br>
            ${i18n.t('texts.banking_payments_rejected')}: ${response.data.rejected}<br>
            ${i18n.t('texts.banking_payments_found')}: ${response.data.found}<br>
            ${i18n.t('texts.banking_payments_failed')}: ${response.data.failed}
          `;
          dispatch('messages/showMessage', { text: message, color: messageColor }, { root: true });
        }
        commit(SEND_EBANKING_PAYMENT_ORDERS_FULFILLED);
        return Promise.resolve(response);
      } catch (error) {
        commit(SEND_EBANKING_PAYMENT_ORDERS_ERROR, error);
        dispatch('messages/showMessage', {
          text: i18n.t('texts.banking_upload_payments_failed'),
          color: 'error',
        }, { root: true });
        return Promise.reject(error);
      }
    },
  },
};
