import axios from 'axios';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

const FETCH_TABLE_META = 'FETCH_TABLE_META';

const FETCH_OLD_ADDRESS_ACTIVITIES_PENDING = 'FETCH_OLD_ADDRESS_ACTIVITIES_PENDING';
const FETCH_OLD_ADDRESS_ACTIVITIES_SUCCESSFUL = 'FETCH_OLD_ADDRESS_ACTIVITIES_SUCCESSFUL';
const FETCH_OLD_ADDRESS_ACTIVITIES_ERROR = 'FETCH_OLD_ADDRESS_ACTIVITIES_ERROR';

const FETCH_OLD_INVOICE_ACTIVITIES_PENDING = 'FETCH_OLD_INVOICE_ACTIVITIES_PENDING';
const FETCH_OLD_INVOICE_ACTIVITIES_FULFILLED = 'FETCH_OLD_INVOICE_ACTIVITIES_FULFILLED';
const FETCH_OLD_INVOICE_ACTIVITIES_ERROR = 'FETCH_OLD_INVOICE_ACTIVITIES_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    listPending: false,
    list: [],
    meta: {},
    oldAddressActivities: [],
    fetchOldAddressActivitiesPending: false,
    oldInvoiceActivities: [],
    fetchOldInvoiceActivitiesPending: false,
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
      state.list = [];
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    [FETCH_TABLE_META](state, { meta, key }) {
      if (meta) {
        state.meta[key] = {
          sortDesc: [meta.direction === 'DESC'],
          itemsPerPage: parseInt(meta.per_page, 10),
          sortBy: [meta.sort],
          page: parseInt(meta.page, 10),
          search: meta.search,
          extendSearch: meta.extend_search,
          maxPage: parseInt(meta.max_page, 10),
          totalItems: meta.max_page * meta.per_page,
        };
      }
    },
    // FETCH_OLD_ADDRESS_ACTIVITIES
    [FETCH_OLD_ADDRESS_ACTIVITIES_PENDING](state) {
      state.fetchOldAddressActivitiesPending = true;
    },
    [FETCH_OLD_ADDRESS_ACTIVITIES_SUCCESSFUL](state, { data }) {
      state.oldAddressActivities = data;
      state.fetchOldAddressActivitiesPending = false;
    },
    [FETCH_OLD_ADDRESS_ACTIVITIES_ERROR](state) {
      state.fetchOldAddressActivitiesPending = false;
    },
    // FETCH_OLD_INVOICE_ACTIVITIES
    [FETCH_OLD_INVOICE_ACTIVITIES_PENDING](state) {
      state.fetchOldInvoiceActivitiesPending = true;
    },
    [FETCH_OLD_INVOICE_ACTIVITIES_FULFILLED](state, { data }) {
      state.oldInvoiceActivities = data;
      state.fetchOldInvoiceActivitiesPending = false;
    },
    [FETCH_OLD_INVOICE_ACTIVITIES_ERROR](state) {
      state.fetchOldInvoiceActivitiesPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }, params) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await dispatch('fetchList', params);
        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, commit, state }, { type, id, meta }) {
      commit(FETCH_LIST_PENDING);
      // pagination/activities/addresses/${id}
      // pagination/activities/invoices/${id}
      // pagination/activities/products/${id}
      // pagination/activities/productgroups/${id}
      // pagination/activities/banking/${id}

      try {
        let params = {};
        const metaKey = `${type}-${id}`;
        const metaValue = meta || state.meta[metaKey];
        const search = [];
        if (metaValue) {
          params = {
            direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy[0],
            page: metaValue.page,
            search,
          };
        }
        const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/pagination/activities`;
        const url = `${baseUrl}/${type}/${id}`;
        const response = await axios.get(url, { params });
        if (response.meta) {
          if (metaValue) {
            response.meta.search = metaValue.search; // keep current search params
          }
          commit(FETCH_TABLE_META, { meta: response.meta, key: metaKey });
        }
        commit(FETCH_LIST_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchOldAddressActivities({ rootState, state, commit }, id) {
      if (state.fetchOldAddressActivitiesPending) { return; }
      commit(FETCH_OLD_ADDRESS_ACTIVITIES_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/addresses/activities/${id}`);
        commit(FETCH_OLD_ADDRESS_ACTIVITIES_SUCCESSFUL, response);
      } catch (error) {
        commit(FETCH_OLD_ADDRESS_ACTIVITIES_ERROR);
      }
    },
    async fetchOldInvoiceActivities({ rootState, state, commit }, id) {
      if (state.fetchOldInvoiceActivitiesPending) {
        return undefined;
      }
      commit(FETCH_OLD_INVOICE_ACTIVITIES_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/activities/${id}`);
        commit(FETCH_OLD_INVOICE_ACTIVITIES_FULFILLED, response);
        return response.data;
      } catch (error) {
        commit(FETCH_OLD_INVOICE_ACTIVITIES_ERROR, error);
        return Promise.reject(error);
      }
    },
  },
};
