import * as types from './types';

export default {
  // GET DeepCloud Bank Connection
  [types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT](state) {
    state.isConnectingDeepCloudBankAccount = true;
  },
  [types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS](state) {
    state.isConnectingDeepCloudBankAccount = false;
  },
  [types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT_CANCEL](state) {
    state.isConnectingDeepCloudBankAccount = false;
  },
  [types.CONNECT_DEEP_CLOUD_BANK_ACCOUNT_FAILURE](state) {
    state.isConnectingDeepCloudBankAccount = false;
  },

  // Check DeepCloud Bank Account Connection
  [types.CHECK_DEEP_CLOUD_BANK_ACCOUNT](state) {
    state.isCheckingDeepCloudBankAccount = true;
  },
  [types.CHECK_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS](state) {
    state.isCheckingDeepCloudBankAccount = false;
  },
  [types.CHECK_DEEP_CLOUD_BANK_ACCOUNT_FAILURE](state) {
    state.isCheckingDeepCloudBankAccount = false;
  },

  // Login to DeepCloud Bank
  [types.LOGIN_DEEP_CLOUD_BANK_ACCOUNT](state) {
    state.isLoginToDeepCloudBankAccount = true;
  },
  [types.LOGIN_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS](state) {
    state.isLoginToDeepCloudBankAccount = false;
  },
  [types.LOGIN_DEEP_CLOUD_BANK_ACCOUNT_FAILURE](state) {
    state.isLoginToDeepCloudBankAccount = false;
  },

  // Syncing DeepCloud
  [types.SYNC_DEEP_CLOUD_BANK_ACCOUNT](state) {
    state.isSyncingDeepCloudBankAccount = true;
  },
  [types.SYNC_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS](state) {
    state.isSyncingDeepCloudBankAccount = false;
  },
  [types.SYNC_DEEP_CLOUD_BANK_ACCOUNT_FAILURE](state) {
    state.isSyncingDeepCloudBankAccount = false;
  },

};
