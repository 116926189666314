import type { RouteConfig } from 'vue-router/types/router';

const SettingsAbasalaryMappings = () => import('@/pages/settings/accounting/abasalary-mappings/SettingsAbasalaryMappings.vue');
const SettingsAbasalaryMappingForm = () => import('@/pages/settings/accounting/abasalary-mappings/SettingsAbasalaryMappingForm.vue');
const PassThrough = () => import('@/views/PassThrough.vue');

export const abasalaryMappingsRoutes: Array<RouteConfig> = [{
  path: 'abasalary-mappings',
  component: PassThrough,
  children: [
    {
      path: '',
      name: 'abasalary-mappings',
      component: SettingsAbasalaryMappings,
      meta: {
        title: 'abasalary_mappings',
      },
    },
    {
      path: 'abasalary-mapping/:uuid?',
      name: 'abasalary-mapping',
      component: SettingsAbasalaryMappingForm,
      meta: {
        title: 'abasalary_mappings',
      },
    },
  ],
}];
