import standardTemplate from './standardTemplate';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    initialLoading: false,
    initialError: false,
    templates: [],
    designs: [],
    fetchDesignPending: false,
    changeTemplatePending: false,
    storeDesignPending: false,
    view: {
      selectedTemplate: null,
      nextPage: false,
    },
    selectedDesign: {
      id: null,
      title: 'Individual',
      status: 'active',
      standard: true,
      settings: standardTemplate,
      kind: 'template',
    },
  },
  mutations,
  actions,
};
