import type { TopLevelRoutePermission } from '@/api/interfaces/definitions/permission';
import type { User } from '@/api/interfaces/user';
import type { NavigationItem } from '@/helpers/navigation/navigation-items';
import { DocumentType, DocumentTypePlural } from '@/components/Document/types/document-type';
import { useMoment } from '@/helpers/moment';
import { useI18n } from '@/helpers/i18n';
import { computed, type ComputedRef } from 'vue';

export interface ActionMenuItem extends Omit<NavigationItem, 'active' | 'children' | 'exact'> {
  topLevelRoutePermission: TopLevelRoutePermission;
  description: ComputedRef<string>;
}

export interface ActionMenuItemGroup {
  name: ComputedRef<string>;
  children: Array<ActionMenuItem>;
}

interface ActionMenuItemGroupParams {
  employeeUuid?: User['employee_uuid'];
}

const i18n = useI18n();

export const miscMenuItemGroupName = computed(() => '');

const ACTION_MENU_ITEM_GROUPS: Array<ActionMenuItemGroup> = [
  {
    name: miscMenuItemGroupName,
    children: [
      {
        topLevelRoutePermission: 'addresses',
        icon: 'swiss21-addresses',
        title: computed(() => i18n.tc('texts.addresses')),
        route: 'address-create',
        description: computed(() => i18n.tc('texts.create_new_address')),
      },
      {
        topLevelRoutePermission: 'products',
        icon: 'swiss21-products',
        title: computed(() => i18n.tc('texts.products')),
        route: 'product-create',
        description: computed(() => i18n.tc('texts.new_product')),
      },
    ],
  },
  {
    name: computed(() => i18n.tc('texts.customers')),
    children: [
      {
        topLevelRoutePermission: 'customers',
        icon: 'swiss21-customers',
        title: computed(() => i18n.tc('texts.invoice')),
        route: 'invoices-create',
        params: { typePlural: DocumentTypePlural[DocumentType.INVOICE] },
        description: computed(() => i18n.tc('texts.action_name_createInvoice')),
      },
      {
        topLevelRoutePermission: 'customers',
        icon: 'swiss21-customers',
        title: computed(() => i18n.tc('texts.quote')),
        params: { typePlural: DocumentTypePlural[DocumentType.QUOTE] },
        route: 'invoices-create',
        description: computed(() => i18n.tc('texts.create_quote')),
      },
      {
        topLevelRoutePermission: 'customers',
        icon: 'swiss21-customers',
        title: computed(() => i18n.tc('texts.contract_note')),
        route: 'invoices-create',
        params: { typePlural: DocumentTypePlural[DocumentType.CONTRACT_NOTE] },
        description: computed(() => i18n.tc('texts.create_contract_note')),
      },
      {
        topLevelRoutePermission: 'customers',
        icon: 'swiss21-customers',
        title: computed(() => i18n.tc('texts.delivery_note')),
        route: 'invoices-create',
        params: { typePlural: DocumentTypePlural[DocumentType.DELIVERY_NOTE] },
        description: computed(() => i18n.tc('texts.create_delivery_note')),
      },
      {
        topLevelRoutePermission: 'customers',
        icon: 'swiss21-customers',
        title: computed(() => i18n.tc('texts.booking_text_credit_note')),
        route: 'invoices-create',
        params: { typePlural: DocumentTypePlural[DocumentType.CREDIT_NOTE] },
        description: computed(() => i18n.tc('texts.new_credit_note')),
      },
    ],
  },
  {
    name: computed(() => i18n.tc('texts.vendors')),
    children: [
      {
        topLevelRoutePermission: 'vendors',
        title: computed(() => i18n.tc('texts.receipt')),
        route: 'suppliers-receipt-create',
        icon: 'swiss21-suppliers',
        description: computed(() => i18n.tc('texts.import_receipt')),
      },
      {
        topLevelRoutePermission: 'vendors',
        title: computed(() => i18n.tc('texts.supplier_credit_note')),
        route: 'supplier-credit-note-import',
        icon: 'swiss21-suppliers',
        description: computed(() => i18n.tc('texts.supplier_credit_note_import_short')),
      },
    ],
  },
  {
    name: computed(() => i18n.tc('texts.finances')),
    children: [
      {
        topLevelRoutePermission: 'payments',
        icon: 'swiss21-finances',
        title: computed(() => i18n.tc('texts.payment_in')),
        route: 'payments-in-create',
        description: computed(() => i18n.tc('texts.new_payment_in')),
      },
      {
        topLevelRoutePermission: 'payments',
        icon: 'swiss21-finances',
        title: computed(() => i18n.tc('texts.payment_out')),
        route: 'payments-out-create',
        description: computed(() => i18n.tc('texts.new_payment_out')),
      },
    ],
  },
];

const parameterisedActionMenuItemGroups = (params: ActionMenuItemGroupParams): Array<ActionMenuItemGroup> => {
  if (params.employeeUuid) {
    const moment = useMoment();

    return [
      ...ACTION_MENU_ITEM_GROUPS,
      {
        name: computed(() => i18n.tc('texts.time_tracking')),
        children: [
          {
            topLevelRoutePermission: 'time',
            icon: 'swiss21-time',
            title: computed(() => i18n.tc('texts.timetracking_in_and_out')),
            route: 'employee',
            params: { employeeUuid: params.employeeUuid, employeeView: 'details', reportView: 'in-and-out' },
            description: computed(() => i18n.tc('texts.employee_report_in_out')),
          },
          {
            topLevelRoutePermission: 'time',
            icon: 'swiss21-time',
            title: computed(() => i18n.tc('texts.time_activity')),
            route: 'activity',
            params: { employeeUuid: params.employeeUuid },
            query: { date: moment().format('YYYY-MM-DD') },
            description: computed(() => i18n.tc('texts.activity_create')),
          },
        ],
      },
    ];
  }
  return ACTION_MENU_ITEM_GROUPS;
};

export default parameterisedActionMenuItemGroups;
