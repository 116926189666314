import type { Payload } from '@/api/interfaces/payload';
import { useGetEntitiesV2 } from '@/stores/composables/use-get-entities-v2';
import type { AxiosPromise } from 'axios';

export const useRequireEntitiesV2 = <TArgs extends unknown[], TResponse>(
  handler: (...args: TArgs) => {
    promise: AxiosPromise<Payload<Array<TResponse>>>;
    abortController: AbortController;
  },
) => {
  const getEntities = useGetEntitiesV2(handler);

  async function requireEntities(...args: TArgs) {
    if (!getEntities.isLoading.value && !getEntities.hasLoaded.value) {
      await getEntities.getEntities(...args);
    }
    return true;
  }

  return {
    ...getEntities,
    requireEntities,
  };
};
