<template>
  <div v-if="dialog.open || !isLoggedIn">
    <aba-dialog
      :closable="false"
      :dialog="dialog"
      :options="{ width: 600 }"
      :title="$tc('texts.session_expiring_title', 1, { time: timeLeft })"
      @close="refresh()"
    >
      <template #content>{{ $t('texts.session_expiring_message') }}</template>
      <template #buttons>
        <v-btn color="error" depressed @click="logout()">{{ $t('texts.logout') }}</v-btn>
        <v-btn color="primary" depressed @click="refresh()">{{ $t('texts.work_on') }}</v-btn>
      </template>
    </aba-dialog>
    <aba-dialog
      :closable="false"
      :dialog="{ open: !isLoggedIn }"
      :options="{ width: 500 }"
      :persistent="true"
      :title="$tc('texts.session_expired_title')"
    >
      <template #content>{{ $t('texts.session_expired_message') }}</template>
      <template #buttons>
        <v-btn color="primary" depressed @click="login()">{{ $t('texts.do_sign_up') }}</v-btn>
      </template>
    </aba-dialog>
  </div>
</template>

<script>
/* global moment */
import AbaDialog from '@/layouts/AbaDialog.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapDefinitionsState, mapActions } = createNamespacedHelpers('definitions');
const { mapActions: mapUserActions } = createNamespacedHelpers('user');

export default {
  components: {
    AbaDialog,
  },
  data() {
    return {
      displayMessageAt: 120000, // 2min
      currentTokenLifetime: 3600000, // 30min
      tokenLifeTime: 3600000, // 30min
      tokenTime: new Date(),
      loginTimer: null,
    };
  },
  computed: {
    ...mapDefinitionsState([
      'isLoggedIn',
    ]),
    dialog() {
      return {
        open: this.isLoggedIn && this.currentTokenLifetime < this.displayMessageAt,
      };
    },
    timeLeft() {
      if (this.dialog.open) {
        const showMinutes = this.currentTokenLifetime > 60000;
        const formattedTime = moment(this.currentTokenLifetime).format(showMinutes ? 'm:ss' : 's');
        const timeSuffix = showMinutes ? this.$tc('texts.minutes') : this.$tc('texts.seconds');
        return `${formattedTime} ${timeSuffix}`;
      }
      return '';
    },
  },
  created() {
    this.deleteTokenTime();
    this.sessionTimer();
  },
  beforeDestroy() {
    if (this.loginTimer) {
      clearInterval(this.loginTimer);
    }
  },
  methods: {
    ...mapActions([
      'sessionKeepAlive',
      'fetchTokenTime',
      'deleteTokenTime',
      'loginState',
    ]),
    ...mapUserActions(['logout']),
    refresh() {
      this.sessionKeepAlive().catch(
        () => this.login(),
      );
    },
    login() {
      window.location.reload();
    },
    sessionTimer() {
      if (this.loginTimer) {
        clearInterval(this.loginTimer);
      }
      this.loginTimer = setInterval(() => {
        if (!this.isLoggedIn) {
          return;
        }
        this.fetchTokenTime().then((currentTokenTime) => {
          if (!currentTokenTime) {
            return;
          }
          const timeDifference = new Date().getTime() - currentTokenTime.getTime();
          let timeTokenExpired = this.tokenLifeTime - timeDifference;
          if (timeTokenExpired <= 0) {
            /* eslint-disable no-console */
            console.group('AbaNinja logout due to inactivity');
            console.log('Start time:', currentTokenTime);
            console.log('End time:', new Date());
            console.groupEnd();
            /* eslint-enable */
            timeTokenExpired = 0;
            sessionStorage.removeItem('kc_token');
            sessionStorage.removeItem('kc_refreshToken');
            this.deleteTokenTime();
            this.loginState(false);
          }
          this.currentTokenLifetime = timeTokenExpired;
        });
      }, 1000);
    },
  },
};
</script>
