export default {
  tableSettings: {
    qty: true,
    discount: false,
    taxRate: true,
  },
  invoiceItems: [],
  ghostItems: [],
  maxItems: 200,
};
