import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialPending = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialPending = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialPending = false;
  },

  // FETCH_PAYMENT
  [types.FETCH_PAYMENT_PENDING](state) {
    state.fetchPaymentPending = true;
  },
  [types.FETCH_PAYMENT_FULFILLED](state, { data }) {
    state.payment = data;
    state.fetchPaymentPending = false;
  },
  [types.FETCH_PAYMENT_ERROR](state) {
    state.fetchPaymentPending = false;
  },

  // FETCH_ACTIONS
  [types.FETCH_ACTIONS_PENDING](state) {
    state.fetchSingleActionsPending = true;
  },
  [types.FETCH_ACTIONS_FULFILLED](state, { data }) {
    state.singleActions = data;
    state.fetchSingleActionsPending = false;
  },
  [types.FETCH_ACTIONS_ERROR](state) {
    state.fetchSingleActionsPending = false;
  },

  // STORE_PAYMENT
  [types.STORE_PAYMENT_PENDING](state) {
    state.storePaymentPending = true;
  },
  [types.STORE_PAYMENT_FULFILLED](state) {
    state.storePaymentPending = false;
  },
  [types.STORE_PAYMENT_ERROR](state) {
    state.storePaymentPending = false;
  },

  // STORE_ACTION
  [types.STORE_ACTION_PENDING](state) {
    state.storeActionPending = true;
  },
  [types.STORE_ACTION_FULFILLED](state) {
    state.storeActionPending = false;
  },
  [types.STORE_ACTION_ERROR](state) {
    state.storeActionPending = false;
  },
};

export default mutations;
