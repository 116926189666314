// FETCH_INITIAL
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';
// FETCH
export const FETCH_ACTIONS_PENDING = 'FETCH_ACTIONS_PENDING';
export const FETCH_ACTIONS_FULFILLED = 'FETCH_ACTIONS_FULFILLED';
export const FETCH_DUPLICATE_ADDRESSES_FULFILLED = 'FETCH_DUPLICATE_ADDRESSES_FULFILLED';
export const FETCH_NEXTNUMBER_FULFILLED = 'FETCH_NEXTNUMBER_FULFILLED';
export const FETCH_IMPORT_INVOICE_QUEUE = 'FETCH_IMPORT_INVOICE_QUEUE';
// FETCH_ADDRESS
export const FETCH_ADDRESS_PENDING = 'FETCH_ADDRESS_PENDING';
export const FETCH_ADDRESS_FULFILLED = 'FETCH_ADDRESS_FULFILLED';
export const FETCH_ADDRESS_ERROR = 'FETCH_ADDRESS_ERROR';
// IMPORT_RECEIPT
export const IMPORT_INVOICE_PENDING = 'IMPORT_INVOICE_PENDING';
export const IMPORT_INVOICE_FULFILLED = 'IMPORT_INVOICE_FULFILLED';
export const IMPORT_INVOICE_ERROR = 'IMPORT_INVOICE_ERROR';
// FETCH_RECEIPT
export const FETCH_IMPORTED_INVOICE_PENDING = 'FETCH_IMPORTED_INVOICE_PENDING';
export const FETCH_IMPORTED_INVOICE_FULFILLED = 'FETCH_IMPORTED_INVOICE_FULFILLED';
export const FETCH_IMPORTED_INVOICE_ERROR = 'FETCH_IMPORTED_INVOICE_ERROR';
// STORE_RECEIPT
export const STORE_IMPORTED_INVOICE_PENDING = 'STORE_IMPORTED_INVOICE_PENDING';
export const STORE_IMPORTED_INVOICE_FULFILLED = 'STORE_IMPORTED_INVOICE_FULFILLED';
export const STORE_IMPORTED_INVOICE_ERROR = 'STORE_IMPORTED_INVOICE_ERROR';
export const RESET_IMPORTED_INVOICE = 'RESET_IMPORTED_INVOICE';
