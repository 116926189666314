import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    fetchValuePending: false,
    storeValuePending: false,
  },
  mutations,
  actions,
};
