/**
 * This file exports global used components
 */
import ConfirmationDialog from '@/components/dialog/ConfirmationDialog.vue';
import Loader from '@/components/Loader.vue';
import PageLoader from '@/components/PageLoader.vue';
import AbaForm from '@/lib/abacus/components/AbaForm.vue';
import AbaTitleActions from '@/lib/abacus/components/AbaTitleActions.vue';
import Vue from 'vue';

Vue.component('Loader', Loader);
Vue.component('PageLoader', PageLoader);
Vue.component('ConfirmationDialog', ConfirmationDialog);
Vue.component('AbaForm', AbaForm);
Vue.component('AbaTitleActions', AbaTitleActions);
