import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    notificationsPending: false,
    notifications: [],
    notificationPending: false,
    notificaton: {},
    notificationTypesPending: false,
    notificationTypes: [],
    notificationUserGroupsPending: false,
    notificationUserGroups: [],
  },
  actions,
  mutations,
};
