<template>
  <v-menu
    content-class="mega-menu"
    left
    offset-y
  >
    <template #activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" name="activator"></slot>
    </template>
    <v-row no-gutters>
      <v-col class="py-3 px-0" cols="8">
        <v-list v-for="menuItemGroup in remainingMenuItemGroups" :key="menuItemGroup.name.value" dense nav>
          <v-subheader class="group-name align-end text-uppercase py-0 px-3">
            {{ menuItemGroup.name.value }}
          </v-subheader>
          <v-row no-gutters>
            <v-col v-for="menuItem in menuItemGroup.children" :key="menuItem.title.value" class="pa-1" cols="6">
              <mega-menu-item :menu-item="menuItem" />
            </v-col>
          </v-row>
        </v-list>
      </v-col>
      <v-divider class="my-6" vertical />
      <v-col class="py-3 px-1" cols="4">
        <v-list class="mt-8" dense nav>
          <mega-menu-item
            v-for="menuItem in miscMenuItemGroup?.children"
            :key="menuItem.title.value"
            :menu-item="menuItem"
          />
        </v-list>
      </v-col>
    </v-row>
  </v-menu>
</template>

<script lang="ts" setup>
import MegaMenuItem from '@/components/MegaMenuItem.vue';
import type { ActionMenuItemGroup } from '@/components/app-layout/action-menu-item-groups';
import { miscMenuItemGroupName } from '@/components/app-layout/action-menu-item-groups';
import { computed } from 'vue';

interface Props {
  menuItemGroups: Array<ActionMenuItemGroup>;
}

const props = defineProps<Props>();
const miscMenuItemGroup = computed<ActionMenuItemGroup | undefined>(
  () => props.menuItemGroups.find((menuItemGroup) => menuItemGroup.name.value === miscMenuItemGroupName.value),
);

const remainingMenuItemGroups = computed<Array<ActionMenuItemGroup>>(
  () => props.menuItemGroups.filter((menuItemGroup) => menuItemGroup.name.value !== miscMenuItemGroupName.value),
);
</script>

<style lang="scss" scoped>
@import "vuetify/src/styles/styles";
.mega-menu {
  max-width: 50rem;
  max-height: 80vh;
  .theme--dark & {
    background-color: map-get($material-dark, "cards");
  }
  .theme--light & {
    background-color: map-get($material-light, "cards");
  }
}

.group-name {
  height: 2rem;
}
</style>
