import * as types from '@/store/modules/accounting/account-plan/types';
import axios from 'axios';

export default {
  fetchAccountPlan({ rootState, commit }, {
    abortController,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_PLAN);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-plan`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_PLAN_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_PLAN_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_PLAN_FAILURE, error);
      return Promise.reject(error);
    });
  },

  importAccountPlanJson({ rootState, commit }, {
    abortController,
    file,
  }) {
    const controller = abortController || new AbortController();

    commit(types.IMPORT_ACC_PLAN_JSON, true);

    const data = new FormData();
    data.set('json', file);

    return axios.postForm(
      `/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-plan/import/json`,
      data,
      {
        signal: controller.signal,
        transformRequest: (formData) => formData,
      },
    ).then((response) => {
      commit(types.IMPORT_ACC_PLAN_JSON_SUCCESS, response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.IMPORT_ACC_PLAN_JSON_CANCEL);
        return Promise.reject(error);
      }
      commit(types.IMPORT_ACC_PLAN_JSON_FAILURE, error);
      return Promise.reject(error);
    });
  },

  importAccountPlanAmid({ rootState, commit }, abortController) {
    const controller = abortController || new AbortController();

    commit(types.IMPORT_ACC_PLAN_AMID, true);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-plan/import/amid`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.IMPORT_ACC_PLAN_AMID_SUCCESS, response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.IMPORT_ACC_PLAN_AMID_CANCEL);
        return Promise.reject(error);
      }
      commit(types.IMPORT_ACC_PLAN_AMID_FAILURE, error);
      return Promise.reject(error);
    });
  },

  generateAccountPlanFromTemplate({ rootState, commit }, form) {
    commit(types.GENERATE_ACC_PLAN_FROM_TEMPLATE);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-plan/template`, form).then((response) => {
      commit(types.GENERATE_ACC_PLAN_FROM_TEMPLATE_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.GENERATE_ACC_PLAN_FROM_TEMPLATE_FAILURE, error);
      return Promise.reject(error);
    });
  },

  deleteAccountPlan({ rootState, commit }, hasFlexibleAccPlan) {
    commit(types.DELETE_ACCOUNT_PLAN);

    // delete flexible or fixed acc plan
    // will be changed after backend refactoring to one route
    const deleteRoutePath = hasFlexibleAccPlan
      ? `/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-plan`
      : `/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts`;

    return axios.delete(deleteRoutePath).then((response) => {
      commit(types.DELETE_ACCOUNT_PLAN_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.DELETE_ACCOUNT_PLAN_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchClassifications({ rootState, commit }, {
    abortController,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_CLASSIFICATIONS);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-classifications`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_CLASSIFICATIONS_SUCCESS, response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_CLASSIFICATIONS_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_CLASSIFICATIONS_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchAccountPlanMaster({ rootState, commit }, {
    onlyActive,
    abortController,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_PLAN_MASTER);

    let viewKey = 0;
    switch (onlyActive) {
      case true:
        viewKey = 1; // active
        break;
      case false:
        viewKey = 2; // inactive
        break;
      default:
        viewKey = 0; // all
        break;
    }

    return axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts/show/${viewKey}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_PLAN_MASTER_SUCCESS, response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_PLAN_MASTER_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_PLAN_MASTER_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchAccountPlanPdfExport({ rootState, commit }, {
    abortController,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_PLAN_PDF_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/accounting/acc-classifications/toDefine`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
    }).then((payload) => {
      commit(types.FETCH_ACCOUNT_PLAN_PDF_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_PLAN_PDF_EXPORT_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_PLAN_PDF_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchAccountPlanXlsExport({ rootState, commit }, {
    abortController,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_PLAN_XLS_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/export/acc_accounts`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
    }).then((payload) => {
      commit(types.FETCH_ACCOUNT_PLAN_XLS_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_PLAN_XLS_EXPORT_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_PLAN_XLS_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
