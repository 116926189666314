import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialPending = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialPending = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialPending = false;
  },
  // FETCH_BANKFILE
  [types.IMPORT_BANKFILE_PENDING](state) {
    state.importBankfilePending = true;
  },
  [types.IMPORT_BANKFILE_FULFILLED](state) {
    state.importBankfilePending = false;
  },
  [types.IMPORT_BANKFILE_ERROR](state) {
    state.importBankfilePending = false;
  },
  // FETCH_BANKFILE
  [types.FETCH_BANKFILE_PENDING](state) {
    state.fetchBankfilePending = true;
  },
  [types.FETCH_BANKFILE_FULFILLED](state, { data }) {
    state.bankfile = data;
    state.fetchBankfilePending = false;
  },
  [types.FETCH_BANKFILE_ERROR](state) {
    state.fetchBankfilePending = false;
  },
  // STORE_BANKFILE
  [types.STORE_BANKFILE_PENDING](state) {
    state.storeBankfilePending = true;
  },
  [types.STORE_BANKFILE_FULFILLED](state) {
    state.storeBankfilePending = false;
  },
  [types.STORE_BANKFILE_ERROR](state) {
    state.storeBankfilePending = false;
  },
  // STORE_BANKFILE
  [types.DELETE_BANKFILE_PENDING](state) {
    state.deleteBankfilePending = true;
  },
  [types.DELETE_BANKFILE_FULFILLED](state) {
    state.deleteBankfilePending = false;
  },
  [types.DELETE_BANKFILE_ERROR](state) {
    state.deleteBankfilePending = false;
  },
};

export default mutations;
