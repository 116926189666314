import axios from 'axios';
import * as types from './types';

export default {
  async setupDeepCloudBankAccounts({ rootState, commit, dispatch }, { iban }) {
    commit(types.SETUP_DEEPCLOUD_BANKACCOUNTS_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/deepcloud`, { iban });
      commit(types.SETUP_DEEPCLOUD_BANKACCOUNTS_FULFILLED);
      if (response.message) {
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      }
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.SETUP_DEEPCLOUD_BANKACCOUNTS_ERROR);
      if (error.data && error.data.message) {
        dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async deepCloudLogin({ rootState, commit, dispatch }, iban) {
    commit(types.DEEPCLOUD_LOGIN_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/deepcloud/login`, { iban });
      commit(types.DEEPCLOUD_LOGIN_FULFILLED);
      dispatch('finances/bankAccount/setSession', response, { root: true });
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.DEEPCLOUD_LOGIN_ERROR);
      if (error.data && error.data.message) {
        dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      } else {
        dispatch('messages/showMessage', { key: 'bank_account_login_failed', color: 'error' }, { root: true });
      }
      return Promise.reject(error);
    }
  },
  async syncDeepCloud({ commit, dispatch }, { form, iban }) {
    commit(types.DEEPCLOUD_SYNC_PENDING);
    try {
      await dispatch('deepCloudLogin', iban);
      await dispatch('finances/bankAccount/syncEbanking', form, { root: true });
      await dispatch('finances/bankAccount/logoutEbanking', null, { root: true });
      commit(types.DEEPCLOUD_SYNC_FULFILLED);
      return true;
    } catch (error) {
      commit(types.DEEPCLOUD_SYNC_ERROR);
      return Promise.reject(error);
    }
  },
  async deleteDeepCloud({ rootState, commit, dispatch }, bankAccountId) {
    commit(types.REMOVE_DEEPCLOUD_CONNECTION_PENDING);
    try {
      await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/finances/ebanking/deepcloud/${bankAccountId}`);
      dispatch('messages/showMessage', { key: 'bank_connection_removed', color: 'success' }, { root: true });
      commit(types.REMOVE_DEEPCLOUD_CONNECTION_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      dispatch('messages/showMessage', { key: 'bank_connection_remove_failed', color: 'error' }, { root: true });
      commit(types.REMOVE_DEEPCLOUD_CONNECTION_ERROR);
      return Promise.reject(error);
    }
  },
};
