const BalanceSheet = () => import('@/pages/accounting/report/balance-sheet/BalanceSheet.vue');
const IncomeStatement = () => import('@/pages/accounting/report/income-statement/IncomeStatement.vue');
const KeyFigures = () => import('@/pages/accounting/key-figures/KeyFigures.vue');
const OpeningBalance = () => import('@/pages/accounting/report/opening-balance/OpeningBalance.vue');
const OpenItems = () => import('@/pages/accounting/op-list/OpenItems.vue');
const OpList = () => import('@/pages/accounting/op-list/OpList.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');

export default [{
  path: 'report',
  name: 'accounting-report',
  component: PassThroughKeyed,
  children: [
    {
      path: 'open-items',
      name: 'open-items',
      redirect: { name: 'open-items-receivable' },
      component: OpenItems,
      meta: {
        title: 'op',
      },
      children: [
        {
          path: 'receivable',
          name: 'open-items-receivable',
          component: OpList,
          meta: {
            opType: 'receivable',
          },
        },
        {
          path: 'payable',
          name: 'open-items-payable',
          component: OpList,
          meta: {
            opType: 'payable',
          },
        },
      ],
    },
    {
      path: 'balance-sheet',
      name: 'balance-sheet',
      component: BalanceSheet,
      meta: {
        title: 'balance_sheet_title',
      },
    },
    {
      path: 'opening_balance',
      name: 'opening-balance',
      component: OpeningBalance,
      meta: {
        title: 'opening_balance_title',
      },
    },
    {
      path: 'income-statement',
      name: 'income-statement',
      component: IncomeStatement,
      meta: {
        title: 'income_statement_title',
      },
    },
    {
      path: 'key-figures',
      name: 'key-figures',
      component: KeyFigures,
      meta: {
        title: 'key_figures',
      },
    },
  ],
}];
