import axios from 'axios';
import * as types from './types';

export default {
  importJson({ commit }, {
    documentType,
    jsonContent,
  }) {
    commit(types.IMPORT_DOCUMENT_JSON, true);

    return axios.post(`/api/documents/v2/${documentType}`, jsonContent)
      .then((response) => {
        commit(types.IMPORT_DOCUMENT_JSON_SUCCESS, response);
        return Promise.resolve(response);
      }).catch((error) => {
        commit(types.IMPORT_DOCUMENT_JSON_FAILURE, error);
        return Promise.reject(error);
      });
  },
  fetchDocumentQueue({ commit }, queueUuid) {
    commit(types.FETCH_DOCUMENT_QUEUE, true);
    return axios.get(`/api/documents/v2/queue/${queueUuid}`)
      .then((response) => {
        commit(types.FETCH_DOCUMENT_QUEUE_SUCCESS, response);
        return Promise.resolve(response);
      }).catch((error) => {
        commit(types.FETCH_DOCUMENT_QUEUE_FAILURE, error);
        return Promise.reject(error);
      });
  },
};
