import * as types from './types';

export default {
  [types.FETCH_PRODUCT_GROUP](state) {
    state.isLoading = true;
    state.productGroup = null;
  },
  [types.FETCH_PRODUCT_GROUP_SUCCESS](state, { data }) {
    state.isLoading = false;
    state.productGroup = data;
    state.searchError = null;
  },
  [types.FETCH_PRODUCT_GROUP_FAILURE](state) {
    state.isLoading = false;
    state.productGroup = null;
  },
  [types.FETCH_PRODUCT_GROUP_CANCEL](state) {
    state.isLoading = false;
  },

  [types.STORE_PRODUCT_GROUP](state) {
    state.isStoring = true;
  },
  [types.STORE_PRODUCT_GROUP_SUCCESS](state) {
    state.isStoring = false;
  },
  [types.STORE_PRODUCT_GROUP_FAILURE](state) {
    state.isStoring = false;
  },

  [types.VALIDATE_PRODUCT_GROUP_NUMBER](state) {
    state.isValidatingProductGroupNumber = true;
  },
  [types.FINISHED_VALIDATING_PRODUCT_GROUP_NUMBER](state) {
    state.isValidatingProductGroupNumber = false;
  },
};
