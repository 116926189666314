export default {
  userGroups: [],
  userSetting: {},
  isLoggedIn: true,
  tokenTime: new Date(),
  currentTokenLifetime: 1800000,
  limits: [],
  exchangeRates: [],
  autoExchangeRates: true,
  services: {},
  // receivableAccounts: [],
  // payableAccounts: [],
  accountingFrameworksPending: false,
  accountingFrameworks: [],
  activeAccounts: [],
  /** Supplier* */
  invoice_number_padding: null,
  receiptNumber: {},
  definitionsPending: false,
  registrationPending: false,
  confirmPending: false,
  userSettingPending: false,
  userSettingStorePending: false,
  storeSettingsPending: false,
  fetchSettingsPending: false,
  importQueue: [],
  exportQueue: [],
  updateLimitPending: false,
  removeAccBaseAccountPending: false,
  storeDeepBoxSettingsPending: false,
  deepBoxFetchInitialPending: false,
  deepBoxService: {
    service: false,
    mappings: [],
    folders: [],
  },
  storeDeepCloudSettingsPending: false,
  deepCloudFetchInitialPending: false,
  fetchNumberRangePending: false,
  storeNumberRangePending: false,
  numberRanges: {
    address: {},
  },
  meta: {},
  importAccFrameworkPending: false,
  importAccJournalYearsPending: false,
  fetchAgreementsPending: false,
  confirmAgreementsPending: false,
  importDetailPending: false,
  importDetail: {
    details: [],
  },
  localizationSettings: {},
};
