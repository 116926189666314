import statusList from '@/components/status/StatusList';
import axios from 'axios';
import receipt from './receipt';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

const FETCH_TABLE_META = 'FETCH_TABLE_META';

const FETCH_BULK_ACTIONS_PENDING = 'FETCH_BULK_ACTIONS_PENDING';
const FETCH_BULK_ACTIONS_FULFILLED = 'FETCH_BULK_ACTIONS_FULFILLED';
const FETCH_BULK_ACTIONS_ERROR = 'FETCH_BULK_ACTIONS_ERROR';

const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

const STORE_SIGNING_PENDING = 'STORE_SIGNING_PENDING';
const STORE_SIGNING_FULFILLED = 'STORE_SIGNING_FULFILLED';
const STORE_SIGNING_ERROR = 'STORE_SIGNING_ERROR';

const STORE_ACTION_PENDING = 'STORE_ACTION_PENDING';
const STORE_ACTION_FULFILLED = 'STORE_ACTION_FULFILLED';
const STORE_ACTION_ERROR = 'STORE_ACTION_ERROR';

const STORE_BULK_ACTION_PENDING = 'STORE_BULK_ACTION_PENDING';
const STORE_BULK_ACTION_FULFILLED = 'STORE_BULK_ACTION_FULFILLED';
const STORE_BULK_ACTION_ERROR = 'STORE_BULK_ACTION_ERROR';

const FETCH_SIGNING_USERS_PENDING = 'FETCH_SIGNING_USERS_PENDING';
const FETCH_SIGNING_USERS_FULFILLED = 'FETCH_SIGNING_USERS_FULFILLED';
const FETCH_SIGNING_USERS_ERROR = 'FETCH_SIGNING_USERS_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    listPending: false,
    list: [],
    meta: {},
    fetchUsersPending: false,
    fetchSigningUsersPending: false,
    users: [],
    signingUsers: [],
    storeSigningPending: false,
    storeActionPending: false,
    bulkActionsPending: false,
    bulkActions: [],
    storeBulkActionPending: false,
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
      state.list = [];
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = true;
    },
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    [FETCH_TABLE_META](state, { meta, key }) {
      if (meta) {
        state.meta[key] = {
          sortDesc: [meta.direction === 'DESC'],
          itemsPerPage: parseInt(meta.per_page, 10),
          sortBy: [meta.sort],
          page: parseInt(meta.page, 10),
          search: meta.search,
          maxPage: parseInt(meta.max_page, 10),
          totalItems: meta.max_page * meta.per_page,
        };
      }
    },
    [FETCH_BULK_ACTIONS_PENDING](state) {
      state.bulkActionsPending = true;
    },
    [FETCH_BULK_ACTIONS_FULFILLED](state, { data }) {
      state.bulkActions = Object.keys(data).map((key) => ({ id: key, text: data[key] }));
      state.bulkActionsPending = false;
    },
    [FETCH_BULK_ACTIONS_ERROR](state) {
      state.bulkActionsPending = false;
    },
    [FETCH_USERS_PENDING](state) {
      state.fetchUsersPending = true;
    },
    [FETCH_USERS_FULFILLED](state, { data }) {
      state.users = data;
      state.fetchUsersPending = false;
    },
    [FETCH_USERS_ERROR](state) {
      state.fetchUsersPending = false;
    },
    [STORE_SIGNING_PENDING](state) {
      state.storeSigningPending = true;
    },
    [STORE_SIGNING_FULFILLED](state) {
      state.storeSigningPending = false;
    },
    [STORE_SIGNING_ERROR](state) {
      state.storeSigningPending = false;
    },
    [STORE_ACTION_PENDING](state) {
      state.storeActionPending = true;
    },
    [STORE_ACTION_FULFILLED](state) {
      state.storeActionPending = false;
    },
    [STORE_ACTION_ERROR](state) {
      state.storeActionPending = false;
    },
    [STORE_BULK_ACTION_PENDING](state) {
      state.storeBulkActionPending = true;
    },
    [STORE_BULK_ACTION_FULFILLED](state) {
      state.storeBulkActionPending = false;
    },
    [STORE_BULK_ACTION_ERROR](state) {
      state.storeBulkActionPending = false;
    },
    [FETCH_SIGNING_USERS_PENDING](state) {
      state.fetchSigningUsersPending = false;
    },
    [FETCH_SIGNING_USERS_FULFILLED](state, { data }) {
      state.fetchSigningUsersPending = false;
      state.signingUsers = data;
    },
    [FETCH_SIGNING_USERS_ERROR](state) {
      state.fetchSigningUsersPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }, params) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await Promise.all([
          dispatch('fetchList', params),
          dispatch('fetchBulkActions'),
          dispatch('fetchSigningUsers'),
        ]);

        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, state, commit }, { list, addressId, meta }) {
      commit(FETCH_LIST_PENDING);

      try {
        let params = {};
        const metaKey = `${list}-${addressId}`;
        const metaValue = meta || state.meta[metaKey] || null;
        const extendedSearch = {};
        const search = [];
        if (metaValue) {
          if (metaValue.search) {
            extendedSearch.status_name_OR = metaValue.search.filter(
              (filterValue) => {
                const hasFilter = statusList.allStatus().find(
                  (status) => status.text === filterValue,
                );
                if (!hasFilter) {
                  search.push(filterValue);
                }
                return hasFilter;
              },
            );
          }
          params = {
            direction: metaValue.sortDesc && metaValue.sortDesc[0] ? 'DESC' : 'ASC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy ? metaValue.sortBy[0] : undefined,
            page: metaValue.page,
            search,
          };
        }
        // prepare extended search parameter
        const extendedSearchParameter = Object.keys(extendedSearch).map(
          (extendedSearchKey) => {
            if (!extendedSearch[extendedSearchKey]) {
              return undefined;
            }
            return `extend_search[${extendedSearchKey}]=${extendedSearch[extendedSearchKey].join()}`;
          },
        ).filter((value) => value !== undefined).join('&');
        let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/suppliers`;
        url = list === 'address' ? `${url}/${addressId}` : url;
        url = `${url}/receipts`;
        url = list !== 'address' ? `${url}/${list}` : url;
        url = extendedSearchParameter ? `${url}?${extendedSearchParameter}` : url;

        const data = await axios.get(url, { params });
        if (data.meta) {
          if (metaValue) {
            data.meta.search = metaValue.search; // keep current search params
          }
          commit(FETCH_TABLE_META, { meta: data.meta, key: metaKey });
        }
        commit(FETCH_LIST_FULFILLED, data);
        return Promise.resolve(data);
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchBulkActions({ rootState, commit }) {
      commit(FETCH_BULK_ACTIONS_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/bulkactions/receipt`);
        commit(FETCH_BULK_ACTIONS_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_BULK_ACTIONS_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchUsers({ rootState, commit }) {
      commit(FETCH_USERS_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/employees/users`);
        commit(FETCH_USERS_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_USERS_ERROR, error);
        return Promise.reject(error);
      }
    },
    async storeSigning({ rootState, commit, dispatch }, params) {
      commit(STORE_SIGNING_PENDING);
      try {
        const form = { signoff_user_id: params.signoff_user_id };
        const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/receipts/${params.id}/sign`, form);
        await dispatch('fetchList', params.list);
        commit(STORE_SIGNING_FULFILLED, response);
        dispatch('messages/showMessage', { key: 'signing_successful', color: 'success' }, { root: true });
        return true;
      } catch (error) {
        commit(STORE_SIGNING_ERROR, error);
        dispatch('messages/showMessage', { key: 'signing_error', color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async storeAction({ rootState, commit, dispatch }, { params, list }) {
      commit(STORE_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/invoices/receipt/action`, params);
        dispatch('fetchList', list);
        commit(STORE_ACTION_FULFILLED);
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
        return Promise.resolve(response);
      } catch (error) {
        commit(STORE_ACTION_ERROR, error);
        // TODO: harmonize error message object key
        const message = error.data.data || error.data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async storeBulkAction({ rootState, commit, dispatch }, { params, list }) {
      commit(STORE_BULK_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/invoices/receipt/bulk`, params);
        dispatch('fetchList', list);
        commit(STORE_BULK_ACTION_FULFILLED, response);
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
        return Promise.resolve(response);
      } catch ({ data }) {
        commit(STORE_BULK_ACTION_ERROR, data);
        // TODO: harmonize error message object key
        const message = data.data || data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(data);
      }
    },
    async fetchSigningUsers({ rootState, commit }) {
      commit(FETCH_SIGNING_USERS_PENDING);
      const accountId = rootState.definitions.account.account.uuid;
      try {
        const response = await axios.get(`/accounts/${accountId}/signoffusers`);
        commit(FETCH_SIGNING_USERS_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_SIGNING_USERS_ERROR, error);
        return Promise.reject(error);
      }
    },
  },
  modules: {
    receipt,
  },
};
