import contractNotes from '@/router/customers/contract-notes';
import creditNotes from '@/router/customers/credit-notes';
import deliveryNotes from '@/router/customers/delivery-notes';
import invoices from '@/router/customers/invoices';
import quotes from '@/router/customers/quotes';
import recurringInvoices from '@/router/customers/recurring-invoices';
import templates from '@/router/customers/templates';
import type { RouteConfig } from 'vue-router/types/router';
import { DocumentType, DocumentTypePlural } from '@/components/Document/types/document-type';

const Customers = () => import('@/pages/customers/Customers.vue');
const InvoiceForm = () => import('@/components/invoice/InvoiceForm.vue');
const JsonImport = () => import('@/pages/customers/JsonImport.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');

export default {
  path: '/customers',
  name: 'customers',
  component: Customers,
  redirect: { name: 'quotes-list' },
  children: [
    {
      path: 'json-import',
      name: 'document-json-import',
      component: JsonImport,
    },

    {
      path: ':typePlural',
      component: PassThroughKeyed,
      children: [
        {
          path: 'create/:addressId?',
          name: 'invoices-create',
          component: InvoiceForm,
          beforeEnter(to, from, next) {
            if (!to.meta) {
              to.meta = {};
            }

            const foundTypeEntry = Object.entries(DocumentTypePlural)
              .find((entry) => entry[1] === to.params.typePlural);

            const documentType = foundTypeEntry ? foundTypeEntry[0] : DocumentType.INVOICE;

            to.meta.title = `create_${documentType}`;
            next();
          },
        },
        {
          path: 'edit/:id',
          name: 'invoices-edit',
          component: InvoiceForm,
          meta: {
            paymentListType: 'invoice',
          },
          beforeEnter(to, from, next) {
            if (!to.meta) {
              to.meta = {};
            }

            const foundTypeEntry = Object.entries(DocumentTypePlural)
              .find((entry) => entry[1] === to.params.typePlural);

            const documentType = foundTypeEntry ? foundTypeEntry[0] : DocumentType.INVOICE;

            to.meta.title = `edit_${documentType}`;
            next();
          },
        },
      ],
    },

    ...quotes,
    ...contractNotes,
    ...deliveryNotes,
    ...invoices,
    ...creditNotes,
    ...recurringInvoices,
    ...templates,
  ],
} as RouteConfig;
