export const FETCH_CLASSIFICATION = 'FETCH_CLASSIFICATION';
export const FETCH_CLASSIFICATION_SUCCESS = 'FETCH_CLASSIFICATION_SUCCESS';
export const FETCH_CLASSIFICATION_FAILURE = 'FETCH_CLASSIFICATION_FAILURE';
export const FETCH_CLASSIFICATION_CANCEL = 'FETCH_CLASSIFICATION_CANCEL';

export const POST_CLASSIFICATION = 'POST_CLASSIFICATION';
export const POST_CLASSIFICATION_SUCCESS = 'POST_CLASSIFICATION_SUCCESS';
export const POST_CLASSIFICATION_FAILURE = 'POST_CLASSIFICATION_FAILURE';

export const PATCH_CLASSIFICATION = 'PATCH_CLASSIFICATION';
export const PATCH_CLASSIFICATION_SUCCESS = 'PATCH_CLASSIFICATION_SUCCESS';
export const PATCH_CLASSIFICATION_FAILURE = 'PATCH_CLASSIFICATION_FAILURE';

export const DELETE_CLASSIFICATION = 'DELETE_CLASSIFICATION';
export const DELETE_CLASSIFICATION_SUCCESS = 'DELETE_CLASSIFICATION_SUCCESS';
export const DELETE_CLASSIFICATION_FAILURE = 'DELETE_CLASSIFICATION_FAILURE';
