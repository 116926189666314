import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    form: {
      currencies: [],
      currency_code: 'CHF',
    },
    fetchDashboardSettingsPending: false,
    defaultDashboardWidgetsLayout: [
      [
        { id: 'financesBalance', isActive: true },
        { id: 'activities', isActive: true },
        { id: 'help', isActive: true },
      ],
      [
        { id: 'firstSteps', isActive: true },
        { id: 'topClients', isActive: true },
        { id: 'topProducts', isActive: true },
        { id: 'totalButtonQuotes', isActive: true },
        { id: 'totalButtonInvoices', isActive: true },
        { id: 'totalButtonCreditNotes', isActive: true },
        { id: 'totalButtonContractNotes', isActive: true },
        { id: 'totalButtonReceipts', isActive: true },
      ],
    ],
    dashboardWidgets: [],
    dashboardUserSettings: {},
    initialPending: false,
    topClientsPending: false,
    topClients: {},
    topProductsPending: false,
    topProducts: {},
    customerBalancePending: false,
    customerBalance: {
      datasets: [],
      totals: {},
    },
    supplierBalancePending: false,
    supplierBalance: {
      datasets: [],
      totals: {},
    },
    balancePending: false,
    totalsPending: {
      quotes: false,
      invoices: false,
      credit_notes: false,
      receipts: false,
    },
    totals: {},
    dataPending: false,
    cashpointRevenues: {},
    balances: [],
    oldActivities: [],
    lastpayments: [],
    totalInvoice: [],
    activitiesPending: false,
    activities: [],
    firstStepsPending: false,
    firstSteps: [],
    statusTotalPending: false,
    statusTotal: {
      draft: [],
      open: [],
      partial: [],
      overdue: [],
    },
    posDataPending: false,
    posData: {
      data: [],
      totals: {},
    },
    salesPending: false,
    sales: {
      data: [],
      totals: {},
    },
    liquidityPending: false,
    liquidity: {
      data: [],
      totals: {},
    },
  },
  mutations,
  actions,
};
