import { useValueStore } from '@/stores/composables/useValueStore';
import type { DisplayOptions } from '@/stores/user-value/types/display-options';
import { acceptHMRUpdate, defineStore } from 'pinia';

export const useUserValueDisplayOptionsStore = defineStore('userValueDisplayOptions', () => ({
  ...useValueStore<DisplayOptions>('display-options'),
}));

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserValueDisplayOptionsStore, import.meta.hot));
}
