const INVOICE_ITEM_KIND = {
  DIVIDER: 'divider',
  GROUP: 'group',
  PAGE_BREAK: 'page_break',
  PRODUCT: 'product',
  SECTION_TOTAL: 'section_total',
  SUBTITLE: 'subtitle',
  TEXT: 'text',
};

export default INVOICE_ITEM_KIND;
