import * as types from './types';

const mutations = {
  [types.FETCH_BALANCE_SHEET](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
  },
  [types.FETCH_BALANCE_SHEET_SUCCESS](state, response) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;

    state.balanceSheet = response.data;
    state.meta.availableLevels = response.meta.availableLevels;
  },
  [types.FETCH_BALANCE_SHEET_CANCEL](state) {
    state.isLoading = false;
  },
  [types.FETCH_BALANCE_SHEET_FAILURE](state, { error }) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },

  [types.FETCH_E_BALANCE_SHEET](state) {
    state.isLoadingEBalance = true;
  },
  [types.FETCH_E_BALANCE_SHEET_SUCCESS](state) {
    state.isLoadingEBalance = false;
  },
  [types.FETCH_E_BALANCE_SHEET_FAILURE](state) {
    state.isLoadingEBalance = false;
  },
  [types.FETCH_E_BALANCE_SHEET_CANCEL](state) {
    state.isLoadingEBalance = false;
  },

  [types.POST_E_BALANCE_SHEET_DEEP_CLOUD](state) {
    state.isPostingEBalance = true;
  },
  [types.POST_E_BALANCE_SHEET_DEEP_CLOUD_SUCCESS](state) {
    state.isPostingEBalance = false;
  },
  [types.POST_E_BALANCE_SHEET_DEEP_CLOUD_FAILURE](state) {
    state.isPostingEBalance = false;
  },

  // fetch PDF balance sheet
  [types.FETCH_BALANCE_SHEET_PDF_EXPORT](state) {
    state.isLoadingBalanceSheetPdfExport = true;
  },
  [types.FETCH_BALANCE_SHEET_PDF_EXPORT_SUCCESS](state) {
    state.isLoadingBalanceSheetPdfExport = false;
  },
  [types.FETCH_BALANCE_SHEET_PDF_EXPORT_FAILURE](state) {
    state.isLoadingBalanceSheetPdfExport = false;
  },
  [types.FETCH_BALANCE_SHEET_PDF_EXPORT_CANCEL](state) {
    state.isLoadingBalanceSheetPdfExport = false;
  },

  // fetch XLS balance sheet
  [types.FETCH_BALANCE_SHEET_XLS_EXPORT](state) {
    state.isLoadingBalanceSheetXlsExport = true;
  },
  [types.FETCH_BALANCE_SHEET_XLS_EXPORT_SUCCESS](state) {
    state.isLoadingBalanceSheetXlsExport = false;
  },
  [types.FETCH_BALANCE_SHEET_XLS_EXPORT_FAILURE](state) {
    state.isLoadingBalanceSheetXlsExport = false;
  },
  [types.FETCH_BALANCE_SHEET_XLS_EXPORT_CANCEL](state) {
    state.isLoadingBalanceSheetXlsExport = false;
  },
};

export default mutations;
