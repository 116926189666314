import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    meta: {},
    opList: [],
    initialPending: false,
    opListPending: false,

    isPdfExporting: false,
    isXlsExporting: false,
    isAddressPdfExporting: false,
    isPostingToDeepBox: false,
  },
  mutations,
  actions,
};
