import { CurrencyCode } from '@/api/interfaces/accounting/currency';
import type { Duration } from '@/api/interfaces/time/employee-daily-total';
import { IntlCurrencyCountryCodes, IntlLanguageLocales } from '@/enums/intl';
import { useMoment } from '@/helpers/moment';
import { useVuexStore } from '@/helpers/vuex';
import { useLanguagesStore } from '@/stores/basics/languages';

export const useFilters = () => {
  const vuexStore = useVuexStore();
  const moment = useMoment();
  const language = useLanguagesStore();

  const languageLocaleId = vuexStore.state.definitions.account.account.language_id ?? 3;

  const formatNumber = (value: number, languageId: number | null = null, returnZero = true) => {
    if ((!returnZero && !value) || Number.isNaN(value)) {
      return '';
    }

    return new Intl.NumberFormat(IntlLanguageLocales.get(languageId ?? languageLocaleId), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const defaultCurrencyCode: CurrencyCode = vuexStore.state.definitions.account.account.currency_code
    ?? CurrencyCode.CHF;

  const formatCurrency = (currency: number, currencyCode?: CurrencyCode, countryCode?: string) => {
    const currencyFormatter = new Intl.NumberFormat(countryCode
      ?? IntlCurrencyCountryCodes.get(currencyCode ?? defaultCurrencyCode), {
      style: 'currency',
      currency: currencyCode ?? defaultCurrencyCode,
    });
    return currencyFormatter.format(currency);
  };

  const taxRateFormatter = new Intl.NumberFormat(IntlCurrencyCountryCodes.get(defaultCurrencyCode), {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  const formatTaxRate = (percentage: number) => taxRateFormatter.format(percentage);

  const trim = (value: string, length: number): string => {
    if (value && value.length > length) {
      return `${value.substring(0, length)}...`;
    }
    return value;
  };

  const nl2br = (value?: string | null) => {
    if (typeof value === 'undefined' || value === null) {
      return '';
    }
    return (`${value}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
  };

  const dateShort = (value?: string | null) => {
    if (value) {
      return moment(value).format(vuexStore.getters['definitions/account/accountDateFormat'].date);
    }
    return '';
  };

  const dateTimeLong = (value?: string | null) => {
    if (value) {
      return moment(value).format(vuexStore.getters['definitions/account/accountDateFormat'].dateTime);
    }
    return '';
  };

  const initials = (value: string) => {
    if (!value) {
      return '';
    }
    return value
      .match(/(\b\S)?/g)
      ?.join('')
      .match(/(^\S|\S$)?/g)
      ?.join('').toUpperCase()
      || '';
  };

  const formatTime = (value: string) => {
    if (!value) {
      return value;
    }
    const inputNumber = value.replace(/\D/g, '');

    if (inputNumber.length === 0) {
      return null;
    }

    // check first two digits
    const hourPart = parseInt(inputNumber.slice(0, 2) || '0', 10);
    const minutesPart = parseInt(inputNumber.slice(2, 4) || '0', 10);

    // if hour part is smaller than 23 - use those as hours
    if (hourPart < 24 && minutesPart < 60) {
      return moment({ hour: hourPart, minute: minutesPart }).format('HH:mm');
    }

    // if only two digits are given, parse the first digit as hour and the second as minute
    if (inputNumber.length === 2) {
      return moment({ hour: inputNumber[0], minute: inputNumber[1] }).format('HH:mm');
    }

    // if there are three digits, the first digit is the hour, the last two are the minutes
    if (moment(inputNumber, ['h', 'hmm']).isValid()) {
      return moment(inputNumber, ['h', 'hmm']).format('HH:mm');
    }
    return value;
  };

  const duration = (value: Duration | null) => {
    const result = [];

    if (value?.workDays) {
      result.push(Intl.NumberFormat(language.intlLocale, {
        style: 'unit',
        unit: 'day',
        maximumFractionDigits: 2,
      }).format(value.workDays));
    }

    if (value?.workDays && !value.hours && !value.minutes) {
      return result.join(' ');
    }

    result.push(Intl.NumberFormat(language.intlLocale, {
      style: 'unit',
      unit: 'hour',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(moment.duration(value || {}).asHours()));

    return result.join(' ');
  };

  return {
    dateTimeLong,
    dateShort,
    formatNumber,
    formatTime,
    formatCurrency,
    formatTaxRate,
    trim,
    initials,
    nl2br,
    duration,
  };
};
