import type { RouteConfig } from 'vue-router/types/router';
import { DocumentType, DocumentTypePlural } from '@/components/Document/types/document-type';

const InvoiceForm = () => import('@/components/invoice/InvoiceForm.vue');
const PassThrough = () => import('@/views/PassThrough.vue');
const TemplatesList = () => import('@/pages/customers/templates/TemplatesList.vue');

const templatesRoutes: RouteConfig[] = [{
  path: 'templates',
  alias: ['template'],
  component: PassThrough,
  children: [
    {
      path: ':typePlural?',
      component: PassThrough,
      redirect: { name: 'templates-list' },
      children: [
        {
          path: '',
          name: 'templates-list',
          props: true,
          component: TemplatesList,
          beforeEnter(to, _, next) {
            if (!to.params.typePlural) {
              next({ name: 'templates-list', params: { typePlural: DocumentTypePlural[DocumentType.QUOTE] } });
            } else {
              next();
            }
          },
        },
        {
          path: 'edit/:id?',
          name: 'document-template-edit',
          component: InvoiceForm,
          beforeEnter(to, from, next) {
            if (!to.meta) {
              to.meta = {};
            }

            const foundTypeEntry = Object.entries(DocumentTypePlural)
              .find((entry) => entry[1] === to.params.typePlural);

            const documentType = foundTypeEntry ? foundTypeEntry[0] : DocumentType.INVOICE;

            to.meta.title = to.params.id ? `edit_template_${documentType}` : `create_template_${documentType}`;
            next();
          },
        },
      ],
    },
  ],
}];

export default templatesRoutes;
