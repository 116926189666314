import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }, id) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await dispatch('fetchBankfile', id);
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  importBankfile({ commit }) {
    commit(types.IMPORT_BANKFILE_PENDING);
    try {
      commit(types.IMPORT_BANKFILE_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.IMPORT_BANKFILE_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchBankfile({ rootState, commit }, id) {
    commit(types.FETCH_BANKFILE_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/statements/${id}`);
      commit(types.FETCH_BANKFILE_FULFILLED, response);
      return response.data;
    } catch (error) {
      commit(types.FETCH_BANKFILE_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storeBankfile({ rootState, commit, dispatch }, id) {
    commit(types.STORE_BANKFILE_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/statements/${id}/import`);
      commit(types.STORE_BANKFILE_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'bankfile_store_successful', color: 'success' }, { root: true });
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { key: 'bankfile_store_error', color: 'error' }, { root: true });
      commit(types.STORE_BANKFILE_ERROR, error);
      return false;
    }
  },
  async deleteBankfile({ rootState, commit, dispatch }, id) {
    commit(types.DELETE_BANKFILE_PENDING);
    try {
      const response = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/statements/${id}`);
      commit(types.DELETE_BANKFILE_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'bankfile_delete_successful', color: 'success' }, { root: true });
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { key: 'bankfile_delete_error', color: 'error' }, { root: true });
      commit(types.DELETE_BANKFILE_ERROR);
      return false;
    }
  },
  setUploadedBankfile({ commit }, bankfile) {
    commit(types.FETCH_BANKFILE_FULFILLED, bankfile);
  },
};

export default actions;
