export const useCancellablePromise = <R>(
  promise: (abortController: AbortController) => R,
) => {
  const abortController = new AbortController();

  return {
    promise: () => promise(abortController),
    abortController,
    cancel: () => abortController.abort(),
  };
};

export type UseCancellablePromise<T> = ReturnType<typeof useCancellablePromise<T>>;
