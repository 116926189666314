import axios from 'axios';
import * as types from './types';

export default {
  fetchBaseGroups({ rootState, state, commit }, abortController) {
    if (state.baseGroups.length) {
      return Promise.resolve(state.baseGroups);
    }
    commit(types.FETCH_BASE_GROUPS);
    const controller = abortController || new AbortController();

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-groups`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_BASE_GROUPS_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_BASE_GROUPS_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_BASE_GROUPS_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
