export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  data: [],
  meta: {
    sortDesc: [true],
    itemsPerPage: 10,
    sortBy: ['date_from'],
    page: 1,
    search: null,
    extendSearch: null,
    maxPage: 1,
  },
};
