import * as types from './types';
import standardTemplate from './standardTemplate';

const assignTemplateHelper = (state, templateSettings) => {
  // Prepare new settings from default template
  const newSettings = { ...standardTemplate };

  // Merge settings with default template
  Object.keys(newSettings).forEach((key) => {
    Object.assign(newSettings[key], templateSettings[key]);
  });

  // Type casting
  newSettings.design.fontSize = parseInt(newSettings.design.fontSize, 10);
  newSettings.design.h1FontSize = parseInt(newSettings.design.h1FontSize, 10);
  newSettings.design.h2FontSize = parseInt(newSettings.design.h2FontSize, 10);
  newSettings.senderAddress.fontSize = parseInt(newSettings.senderAddress.fontSize, 10);
  newSettings.recipientAddress.fontSize = parseInt(newSettings.recipientAddress.fontSize, 10);
  newSettings.details.fontSize = parseInt(newSettings.details.fontSize, 10);
  newSettings.tableHeader.fontSize = parseInt(newSettings.tableHeader.fontSize, 10);
  newSettings.notes.fontSize = parseInt(newSettings.notes.fontSize, 10);
  newSettings.terms.fontSize = parseInt(newSettings.terms.fontSize, 10);
  newSettings.footerSenderAddress.fontSize = parseInt(newSettings.footerSenderAddress.fontSize, 10);
  newSettings.footerText.fontSize = parseInt(newSettings.footerText.fontSize, 10);
  newSettings.footerBankDetails.fontSize = parseInt(newSettings.footerBankDetails.fontSize, 10);

  // Assign settings
  state.selectedDesign.settings = newSettings;
};

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_TEMPLATES
  [types.FETCH_TEMPLATES_PENDING](state) {
    state.fetchTemplatesPending = true;
  },
  [types.FETCH_TEMPLATES_FULFILLED](state, { data }) {
    state.templates = data;
    state.fetchTemplatesPending = false;
  },
  [types.FETCH_TEMPLATES_ERROR](state) {
    state.fetchTemplatesPending = false;
  },
  // FETCH_DESIGNS
  [types.FETCH_DESIGNS_PENDING](state) {
    state.fetchDesignsPending = true;
  },
  [types.FETCH_DESIGNS_FULFILLED](state, { data }) {
    state.designs = data;
    state.fetchDesignsPending = false;
  },
  [types.FETCH_DESIGNS_ERROR](state) {
    state.fetchDesignsPending = false;
  },
  // FETCH_DESIGN
  [types.FETCH_DESIGN_PENDING](state) {
    state.fetchDesignPending = true;
  },
  [types.FETCH_DESIGN_FULFILLED](state, { data }) {
    state.selectedDesign.id = data.id;
    state.selectedDesign.title = data.title;
    state.selectedDesign.kind = 'usercreated';
    assignTemplateHelper(state, data.settings);
    state.fetchDesignPending = false;
  },
  [types.FETCH_DESIGN_ERROR](state) {
    state.fetchDesignPending = false;
  },
  // SET_TEMPLATE
  [types.CHANGE_TEMPLATE_PENDING](state) {
    state.changeTemplatePending = true;
  },
  [types.CHANGE_TEMPLATE_FULFILLED](state) {
    state.changeTemplatePending = false;
  },
  [types.CHANGE_TEMPLATE_ERROR](state) {
    state.changeTemplatePending = false;
  },
  // SET_STANDARD_TEMPLATE
  [types.SET_STANDARD_TEMPLATE](state) {
    state.selectedDesign.settings = JSON.parse(JSON.stringify(standardTemplate));
  },
  // SET_TEMPLATE
  [types.SET_TEMPLATE](state, id) {
    const template = state.templates.find((templateItem) => templateItem.id === id);
    state.selectedDesign.id = id;
    state.selectedDesign.title = template.title;
    state.selectedDesign.kind = 'template';
    assignTemplateHelper(state, template.settings);
  },
  // TRIGGER_NEXT_PAGE
  [types.TRIGGER_NEXT_PAGE](state, value) {
    state.view.nextPage = value;
  },
  // STORE_DESIGN
  [types.STORE_DESIGN_PENDING](state) {
    state.storeDesignPending = true;
  },
  [types.STORE_DESIGN_FULFILLED](state) {
    state.storeDesignPending = false;
  },
  [types.STORE_DESIGN_ERROR](state) {
    state.storeDesignPending = false;
  },
  [types.CHANGE_SETTING](state, change) {
    const { group, setting } = change;
    Object.assign(state.settings[group][setting], change.value);
  },
  [types.SET_VIEW_SELECT](state, payload) {
    state.view.selectedTemplate = payload;
  },
};

export default mutations;
