import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchAddress({ rootState, commit }, id) {
    commit(types.FETCH_ADDRESS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${id}`);
      commit(types.FETCH_ADDRESS_FULFILLED, response);
      if (response.data.company) {
        if (!response.data.company.id_number || !response.data.company.industry_id) {
          axios.get('/api/deep-company-registry/v2/complete', {
            params: {
              addressUuid: response.data.uuid,
              saveDirectly: 1,
            },
          });
        }
      }
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.FETCH_ADDRESS_ERROR, error);
      console.error('[FETCH_ADDRESS_ERROR] error');
      return Promise.reject(error);
    }
  },
  async fetchAddressDetail({ commit, dispatch }, id) {
    commit(types.FETCH_ADDRESS_DETAIL_PENDING);
    try {
      const [
        address,
        singleActions,
        opReceivablesAmount,
        opReceivablesPaidAmount,
        opCreditnotesAmount,
        opPayablesAmount,
        opPayablesPaidAmount,
      ] = await Promise.all([
        dispatch('fetchSingleAddress', id),
        dispatch('fetchSingleActions', id),
        dispatch('fetchOpReveivablesAmount', id),
        dispatch('fetchOpReceivablesPaidAmount', id),
        dispatch('fetchOpCreditnotesAmount', id),
        dispatch('fetchOpPayablesAmount', id),
        dispatch('fetchOpPayablesPaidAmount', id),
      ]);
      commit(types.FETCH_ADDRESS_SINGLE_ACTIONS_FULFILLED, singleActions);
      commit(types.FETCH_ADDRESS_OP_RECEIVABLES_FULFILLED, opReceivablesAmount);
      commit(types.FETCH_ADDRESS_OP_RECEIVABLES_PAID_FULFILLED, opReceivablesPaidAmount);
      commit(types.FETCH_ADDRESS_OP_CREDITNOTES_FULFILLED, opCreditnotesAmount);
      commit(types.FETCH_ADDRESS_OP_PAYABLES_FULFILLED, opPayablesAmount);
      commit(types.FETCH_ADDRESS_OP_PAYABLES_PAID_FULFILLED, opPayablesPaidAmount);
      commit(types.FETCH_ADDRESS_DETAIL_FULFILLED, address);
    } catch (error) {
      commit(types.FETCH_ADDRESS_DETAIL_ERROR, error);
      console.error(error);
    }
  },
  async fetchAddressPayments({ commit }, id) {
    // TODO Move to payments if package is ready
    commit(types.FETCH_ADDRESS_PAYMENTS_PENDING);
    try {
      const response = await axios.get(`/payments/list/address/${id}`);
      commit(types.FETCH_ADDRESS_PAYMENTS_SUCCESSFUL, response);
    } catch (error) {
      commit(types.FETCH_ADDRESS_PAYMENTS_ERROR);
      console.error(error);
    }
  },
  async storeAddress({ rootState, commit, dispatch }, form) {
    commit(types.STORE_ADDRESS_PENDING);
    if (form.type === 'company') {
      form.company.persons = form.company.persons.filter((person) => person.first_name || person.last_name);
    }
    const method = form.id ? 'PUT' : 'POST';
    const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/address`;
    const url = form.id ? `${baseUrl}/${form.id}` : baseUrl;
    try {
      const [address] = await Promise.all([
        await axios({ url, method, data: form }),
      ]);
      await commit(types.STORE_ADDRESS_FULFILLED, address);
      dispatch('messages/showMessage', {
        key: 'address_store_successfull',
        color: 'success',
      }, { root: true });
      return Promise.resolve(address);
    } catch (error) {
      console.error(`[STORE_ADDRESS_ERROR] ${error}`);
      dispatch('messages/showMessage', {
        text: error.data.error || error.data.message,
        color: 'error',
      }, { root: true });
      commit(types.STORE_ADDRESS_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchZipPlaceAutoCompletion({ commit }, form) {
    commit(types.FETCH_ZIP_PLACE_AUTO_COMPLETION_PENDING);
    try {
      // TODO: use axios query parameter
      const url = form.type === 'zip' ? `/addressing/${form.country_code}?zip=` : `/addressing/${form.country_code}?place=`;
      const response = await axios.get(`${url}${form.value}`);
      commit(types.FETCH_ZIP_PLACE_AUTO_COMPLETION_FULFILLED);
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.FETCH_ZIP_PLACE_AUTO_COMPLETION_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchCustomerNumberPreview({ rootState, commit, dispatch }) {
    if (Object.prototype.hasOwnProperty.call(rootState.definitions.numberRanges.address, 'is_active')
      && !rootState.definitions.numberRanges.address.is_active) {
      return undefined;
    }
    commit(types.FETCH_CUSTOMER_NUMBER_PREVIEW_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/number-ranges/address/preview`;
      const response = await axios.get(url);
      commit(types.FETCH_CUSTOMER_NUMBER_PREVIEW_FULFILLED);
      if (Object.prototype.hasOwnProperty.call(rootState.definitions.numberRanges.address, 'is_active')) {
        dispatch('definitions/setNumberRangeSettings', {
          type: 'address',
          is_active: !!response.data.preview,
        }, { root: true });
      }
      return Promise.resolve(response.data.preview);
    } catch (error) {
      commit(types.FETCH_CUSTOMER_NUMBER_PREVIEW_ERROR);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  async validateCustomerNumber({ rootState, commit }, { customerNumber, addressId }) {
    commit(types.VALIDATE_CUSTOMER_NUMBER_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/addresses/checkcustomernumber`;
      await axios.get(url, { params: { addressId, customerNumber } });
      commit(types.VALIDATE_CUSTOMER_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_CUSTOMER_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  async validateBicNumber({ commit }, { bicNumber }) {
    commit(types.VALIDATE_BIC_NUMBER_PENDING);
    try {
      await axios.get(`/bankaccounts/checkbic/${bicNumber}`);
      commit(types.VALIDATE_BIC_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_BIC_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  async validateAccountNumber({ commit }, { accountNumber, isEsr }) {
    commit(types.VALIDATE_ACCOUNT_NUMBER_PENDING);
    try {
      await axios.get(`/bankaccounts/checknumber/${accountNumber}`, { params: { isEsr: !!isEsr } });
      commit(types.VALIDATE_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_ACCOUNT_NUMBER_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  fetchSingleAddress({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${id}`);
  },
  fetchSingleActions({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/addresses/singleactions/${id}`);
  },
  fetchOpReveivablesAmount({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_op_list/receivables/${id}`);
  },
  fetchOpPayablesAmount({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_op_list/payables/${id}`);
  },
  fetchOpReceivablesPaidAmount({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_op_list/receivables/${id}/paid`);
  },
  fetchOpPayablesPaidAmount({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_op_list/payables/${id}/paid`);
  },
  async fetchStreetAutoCompletion({ commit }, value) {
    commit(types.FETCH_STREET_AUTO_COMPLETION_PENDING);
    try {
      const url = '/addressing/street/autocomplete';
      const response = await axios.get(url, { params: { q: value } });
      commit(types.FETCH_STREET_AUTO_COMPLETION_FULFILLED);
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.FETCH_STREET_AUTO_COMPLETION_ERROR);
      return Promise.reject(error);
    }
  },
  async checkAbaNetAvailable({ rootState, commit }, params) {
    commit(types.CHECK_ABANET_AVAILABLE_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/abanet/check_address`;
      const response = await axios.get(url, { params });
      commit(types.CHECK_ABANET_AVAILABLE_FULFILLED);
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.CHECK_ABANET_AVAILABLE_ERROR);
      return Promise.reject(error);
    }
  },
  fetchOpCreditnotesAmount({ rootState }, id) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_op_list/creditnotes/${id}`);
  },
  fetchDeliveryAddresses({ rootState }, mainAddressId) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/addresses/${mainAddressId}/delivery_addresses`);
  },
  storeDeliveryAddress({ rootState }, { mainAddressId, deliveryAddress }) {
    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/addresses/${mainAddressId}/delivery_addresses`, deliveryAddress);
  },
  deleteDeliveryAddress({ rootState }, { mainAddressId, id }) {
    return axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/addresses/${mainAddressId}/delivery_addresses/${id}`);
  },
  resetAddress({ commit }) {
    commit(types.RESET_ADDRESS);
  },
};

export default actions;
