export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

export const FETCH_INBOX_COUNT = 'FETCH_INBOX_COUNT';
export const MARK_AS_READ = 'MARK_AS_READ';

export const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
export const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
export const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';
export const FETCH_TABLE_META = 'FETCH_TABLE_META';

export const FETCH_ACTION_PENDING = 'FETCH_ACTION_PENDING';
export const FETCH_ACTION_FULFILLED = 'FETCH_ACTION_FULFILLED';
export const FETCH_ACTION_ERROR = 'FETCH_ACTION_ERROR';

export const FETCH_BULK_ACTIONS_PENDING = 'FETCH_BULK_ACTIONS_PENDING';
export const FETCH_BULK_ACTIONS_FULFILLED = 'FETCH_BULK_ACTIONS_FULFILLED';
export const FETCH_BULK_ACTIONS_ERROR = 'FETCH_BULK_ACTIONS_ERROR';

export const STORE_BULK_PENDING = 'STORE_BULK_PENDING';
export const STORE_BULK_FULFILLED = 'STORE_BULK_FULFILLED';
export const STORE_BULK_ERROR = 'STORE_BULK_ERROR';

export const STORE_SIGNING_PENDING = 'STORE_SIGNING_PENDING';
export const STORE_SIGNING_FULFILLED = 'STORE_SIGNING_FULFILLED';
export const STORE_SIGNING_ERROR = 'STORE_SIGNING_ERROR';

export const FETCH_SIGNING_USERS_PENDING = 'FETCH_SIGNING_USERS_PENDING';
export const FETCH_SIGNING_USERS_FULFILLED = 'FETCH_SIGNING_USERS_FULFILLED';
export const FETCH_SIGNING_USERS_ERROR = 'FETCH_SIGNING_USERS_ERROR';

export const STORE_ACTION_PENDING = 'STORE_ACTION_PENDING';
export const STORE_ACTION_FULFILLED = 'STORE_ACTION_FULFILLED';
export const STORE_ACTION_ERROR = 'STORE_ACTION_ERROR';
