import App from '@/App.vue';

// Global components
import '@/components/global';
import Constants from '@/constants';
import initFilters from '@/filters';
import { useI18n } from '@/helpers/i18n';
import { useMoment } from '@/helpers/moment';
import initInterceptors from '@/interceptors';

// Plugins
import KeycloakPlugin from '@/plugins/keycloak';
import resetStore from '@/plugins/pinia/reset-store';
import vuetify from '@/plugins/vuetify';
import router from '@/router';

import '@/scss/frontend.scss';
import store from '@/store';

// Zendesk
import Zendesk from '@dansmaculotte/vue-zendesk';
import VueGtm from '@gtm-support/vue2-gtm';

// Sentry
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

import axios from 'axios';

// highcharts.js
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { vMaska } from 'maska';

import { createPinia, PiniaVuePlugin } from 'pinia';

import Vue from 'vue';
import Meta from 'vue-meta';
import VueObserveVisibility from 'vue-observe-visibility';

// Vee Validate
import './validator';

Vue.use(KeycloakPlugin);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(resetStore);
Vue.use(pinia);

Vue.use(VueObserveVisibility);
Vue.use(Meta);

highchartsMore(Highcharts);
solidGauge(Highcharts);

Highcharts.setOptions({
  colors: [
    '#C0E0FB',
    '#22a8b7',
    '#434348',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  chart: {
    style: {
      fontFamily: 'Inter',
    },
    backgroundColor: 'transparent',
  },
});

Vue.use(HighchartsVue, { Highcharts });

initInterceptors();

Vue.router = router;

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//, import.meta.env.VITE_URL.replace('https://', '')],
        beforeNavigate: (context) => {
          let name = router.currentRoute.fullPath;
          if (router.currentRoute.matched.length) {
            name = router.currentRoute.matched[router.currentRoute.matched.length - 1].path;
          }

          return {
            ...context,
            name,
          };
        },
        shouldCreateSpanForRequest: (url) => !url.match(/\/count_unread$/),
      }),
      new Sentry.Replay(),
    ],

    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.VITE_SENTRY_RELEASE_VERSION,
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',

    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
  });
}

Vue.use(VueGtm, {
  enabled: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED === 'true' || false,
  id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_KEY || '',
  debug: import.meta.env.VITE_GOOGLE_TAG_MANAGER_DEBUG === 'true' || false,
  vueRouter: router,
});

const zendeskLanguages = {
  de: { key: 'de', name: 'Deutsch' },
  fr: { key: 'fr', name: 'Français' },
  it: { key: 'it', name: 'Italiano' },
  en: { key: 'en', name: 'English' },
};
const userLanguage = document.querySelector('html').getAttribute('lang');
const selectedZendeskLanguage = zendeskLanguages[userLanguage] ? zendeskLanguages[userLanguage] : zendeskLanguages.de;

Vue.use(Zendesk, {
  key: import.meta.env.VITE_ZENDESK_WIDGET_KEY,
  disabled: import.meta.env.VITE_ZENDESK_WIDGET_ENABLED !== 'true',
  hideOnLoad: true,
  settings: {
    webWidget: {
      chat: {
        departments: {
          enabled: [selectedZendeskLanguage.name],
          select: selectedZendeskLanguage.name,
        },
        suppress: false,
      },
    },
  },
});

Vue.prototype.$axios = axios;
window.moment = useMoment();

Vue.prototype.$moment = useMoment();

Vue.prototype.constants = Constants;

Vue.directive('maska', vMaska);

/*
 * Filters
 */

initFilters();

/**
 * Global $variables
 */

/**
 * Global event bus
 * Caution: https://v3.vuejs.org/guide/migration/events-api.html#root-component-events
 */
Vue.prototype.$bus = new Vue();

Vue.prototype.$env = import.meta.env;

/*
 * Turn off "You are running Vue in development mode."
 */
Vue.config.productionTip = false;
Vue.config.devtools = import.meta.env.VITE_ENVIRONMENT === 'local' || import.meta.env.VITE_ENVIRONMENT === 'dev';

function setUuids({ requestedAccountUuid, requestedAppInstanceUuid }) {
  if (requestedAppInstanceUuid) {
    sessionStorage.setItem('appInstanceUuid', requestedAppInstanceUuid);
    localStorage.setItem('appInstanceUuid', requestedAppInstanceUuid);
    sessionStorage.removeItem('accountUuid');
    localStorage.removeItem('accountUuid');
  } else if (requestedAccountUuid) {
    sessionStorage.setItem('accountUuid', requestedAccountUuid);
    localStorage.setItem('accountUuid', requestedAccountUuid);
    sessionStorage.removeItem('appInstanceUuid');
    localStorage.removeItem('appInstanceUuid');
  } else {
    // Set Uuids from loaded account
    const { account } = store.state.definitions;
    localStorage.setItem('accountUuid', account.uuid);
    localStorage.setItem('appInstanceUuid', account.app_instance_uuid);
  }
}

// uf uuid is set, switch to that company
const urlParams = new URLSearchParams(window.location.search);
const requestedAccountUuid = urlParams.get('uuid');
const requestedAppInstanceUuid = urlParams.get('app_instance_uuid');
if (requestedAccountUuid || requestedAppInstanceUuid) {
  setUuids({
    requestedAccountUuid: requestedAccountUuid || null,
    requestedAppInstanceUuid: requestedAppInstanceUuid || null,
  });
}

function login() {
  const url = new URL(window.location);
  const abaNinjaDomain = url.host;
  const kcIdpHint = urlParams.get('kc_idp_hint');
  const useDeepSso = kcIdpHint === 'deepsso'
    || abaNinjaDomain.includes('abaninja.deepbox.swiss')
    || import.meta.env.VITE_DEFAULT_SSO === 'deepsso';

  const abaskyidp = {
    redirectUri: import.meta.env.VITE_ABASKYIDP_LOGIN_REDIRECT,
    scope: import.meta.env.VITE_ABASKYIDP_SCOPE.replaceAll(',', ' '),
  };

  const deepsso = {
    redirectUri: import.meta.env.VITE_DEEPSSO_LOGIN_REDIRECT,
    scope: import.meta.env.VITE_DEEPSSO_SCOPE.replaceAll(',', ' '),
  };

  const ssoProvider = useDeepSso ? deepsso : abaskyidp;

  if (!window.location.pathname.includes('oauthcb')) {
    sessionStorage.setItem('requestedFullPath', `${window.location.pathname}${window.location.search}${window.location.hash}`);
  }
  Vue.$keycloak.login({
    ...ssoProvider,
    idpHint: kcIdpHint,
  });
}

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    setUuids({
      requestedAccountUuid: null,
      requestedAppInstanceUuid: null,
    });
  }
});

window.addEventListener('focus', () => {
  setUuids({
    requestedAccountUuid: null,
    requestedAppInstanceUuid: null,
  });
});

/*
 * keycloak init
 */
const keycloakInit = {
  enableLogging: import.meta.env.NODE_ENV === 'development',
  timeSkew: 0,
  checkLoginIframe: false,
  token: sessionStorage.getItem('kc_token'),
  refreshToken: sessionStorage.getItem('kc_refreshToken'),
};

Vue.$keycloak
  .init(keycloakInit)
  .then((authenticated) => {
    if (!authenticated) {
      login();
    } else {
      new Vue({
        i18n: useI18n(),
        router,
        store,
        pinia,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
      if (Vue.$keycloak.refreshToken) {
        sessionStorage.setItem('kc_token', Vue.$keycloak.token);
        sessionStorage.setItem('kc_refreshToken', Vue.$keycloak.refreshToken);
      }
      // Token Refresh
      Vue.prototype.$tokenRefreshInterval = setInterval(() => {
        Vue.$keycloak.updateToken(70).then(
          (isTokenUpdated) => {
            if (isTokenUpdated) {
              sessionStorage.setItem('kc_token', Vue.$keycloak.token);
              sessionStorage.setItem('kc_refreshToken', Vue.$keycloak.refreshToken);
            }
          },
        ).catch((error) => {
          console.error(error); // for information auto logout
          if (error && error.error && error.error === 'invalid_grant') {
            clearInterval(Vue.$tokenRefreshInterval);
            store.dispatch('definitions/loginState', false);
          }
        });
      }, 6000);
    }
  })
  .catch(() => {
    console.error('Authentication failed');
    sessionStorage.removeItem('kc_token', Vue.$keycloak.token);
    sessionStorage.removeItem('kc_refreshToken', Vue.$keycloak.refreshToken);
    login();
  });
