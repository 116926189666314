export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_CANCEL = 'FETCH_ACCOUNT_CANCEL';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';

export const STORE_ACCOUNT = 'STORE_ACCOUNT';
export const STORE_ACCOUNT_SUCCESS = 'STORE_ACCOUNT_SUCCESS';
export const STORE_ACCOUNT_FAILURE = 'STORE_ACCOUNT_FAILURE';

export const FETCH_ACCOUNT_INVOICE = 'FETCH_ACCOUNT_INVOICE';
export const FETCH_ACCOUNT_INVOICE_SUCCESS = 'FETCH_ACCOUNT_INVOICE_SUCCESS';
export const FETCH_ACCOUNT_INVOICE_CANCEL = 'FETCH_ACCOUNT_INVOICE_CANCEL';
export const FETCH_ACCOUNT_INVOICE_FAILURE = 'FETCH_ACCOUNT_INVOICE_FAILURE';

export const STORE_ACCOUNT_INVOICE = 'STORE_ACCOUNT_INVOICE';
export const STORE_ACCOUNT_INVOICE_SUCCESS = 'STORE_ACCOUNT_INVOICE_SUCCESS';
export const STORE_ACCOUNT_INVOICE_FAILURE = 'STORE_ACCOUNT_INVOICE_FAILURE';

export const FETCH_ACCOUNT_INVOICE_PRICES = 'FETCH_ACCOUNT_INVOICE_PRICES';
export const FETCH_ACCOUNT_INVOICE_PRICES_SUCCESS = 'FETCH_ACCOUNT_INVOICE_PRICES_SUCCESS';
export const FETCH_ACCOUNT_INVOICE_PRICES_CANCEL = 'FETCH_ACCOUNT_INVOICE_PRICES_CANCEL';
export const FETCH_ACCOUNT_INVOICE_PRICES_FAILURE = 'FETCH_ACCOUNT_INVOICE_PRICES_FAILURE';

export const STORE_ACCOUNT_INVOICE_PRICES = 'STORE_ACCOUNT_INVOICE_PRICES';
export const STORE_ACCOUNT_INVOICE_PRICES_SUCCESS = 'STORE_ACCOUNT_INVOICE_PRICES_SUCCESS';
export const STORE_ACCOUNT_INVOICE_PRICES_FAILURE = 'STORE_ACCOUNT_INVOICE_PRICES_FAILURE';

export const FETCH_ACCOUNT_QUOTE = 'FETCH_ACCOUNT_QUOTE';
export const FETCH_ACCOUNT_QUOTE_SUCCESS = 'FETCH_ACCOUNT_QUOTE_SUCCESS';
export const FETCH_ACCOUNT_QUOTE_CANCEL = 'FETCH_ACCOUNT_QUOTE_CANCEL';
export const FETCH_ACCOUNT_QUOTE_FAILURE = 'FETCH_ACCOUNT_QUOTE_FAILURE';

export const STORE_ACCOUNT_QUOTE = 'STORE_ACCOUNT_QUOTE';
export const STORE_ACCOUNT_QUOTE_SUCCESS = 'STORE_ACCOUNT_QUOTE_SUCCESS';
export const STORE_ACCOUNT_QUOTE_FAILURE = 'STORE_ACCOUNT_QUOTE_FAILURE';

export const FETCH_ACCOUNT_DOCUMENTS = 'FETCH_ACCOUNT_DOCUMENTS';
export const FETCH_ACCOUNT_DOCUMENTS_SUCCESS = 'FETCH_ACCOUNT_DOCUMENTS_SUCCESS';
export const FETCH_ACCOUNT_DOCUMENTS_CANCEL = 'FETCH_ACCOUNT_DOCUMENTS_CANCEL';
export const FETCH_ACCOUNT_DOCUMENTS_FAILURE = 'FETCH_ACCOUNT_DOCUMENTS_FAILURE';

export const STORE_ACCOUNT_DOCUMENTS = 'STORE_ACCOUNT_DOCUMENTS';
export const STORE_ACCOUNT_DOCUMENTS_SUCCESS = 'STORE_ACCOUNT_DOCUMENTS_SUCCESS';
export const STORE_ACCOUNT_DOCUMENTS_FAILURE = 'STORE_ACCOUNT_DOCUMENTS_FAILURE';

export const FETCH_ACCOUNT_DESIGN = 'FETCH_ACCOUNT_DESIGN';
export const FETCH_ACCOUNT_DESIGN_SUCCESS = 'FETCH_ACCOUNT_DESIGN_SUCCESS';
export const FETCH_ACCOUNT_DESIGN_CANCEL = 'FETCH_ACCOUNT_DESIGN_CANCEL';
export const FETCH_ACCOUNT_DESIGN_FAILURE = 'FETCH_ACCOUNT_DESIGN_FAILURE';

export const STORE_ACCOUNT_DESIGN = 'STORE_ACCOUNT_DESIGN';
export const STORE_ACCOUNT_DESIGN_SUCCESS = 'STORE_ACCOUNT_DESIGN_SUCCESS';
export const STORE_ACCOUNT_DESIGN_FAILURE = 'STORE_ACCOUNT_DESIGN_FAILURE';
