import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ rootState, commit, dispatch }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      const [, designs] = await Promise.all([
        dispatch('fetchTemplates'),
        dispatch('fetchDesigns'),
      ]);
      if (designs.length > 0) {
        await dispatch('fetchDesign', designs[0].id);
      } else if (rootState.definitions.account.invoice_design_id > 0) {
        const templateId = rootState.definitions.account.invoice_design_id - 1;
        dispatch('changeTemplate', { id: templateId, kind: 'template' });
      } else {
        dispatch('changeTemplate', { id: null, kind: 'template' });
      }

      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchTemplates({ commit }) {
    commit(types.FETCH_TEMPLATES_PENDING);
    try {
      const response = await axios.get('/designers/documents/designs/templates');
      commit(types.FETCH_TEMPLATES_FULFILLED, response);
      return response.data;
    } catch (error) {
      commit(types.FETCH_TEMPLATES_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchDesigns({ rootState, commit }) {
    commit(types.FETCH_DESIGNS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/designers/documents/designs/document`);
      commit(types.FETCH_DESIGNS_FULFILLED, response);
      return response.data;
    } catch (error) {
      commit(types.FETCH_DESIGNS_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchDesign({ rootState, commit }, id) {
    commit(types.FETCH_DESIGN_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/designers/documents/designs/${id}`);
      commit(types.FETCH_DESIGN_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_DESIGN_ERROR);
      return Promise.reject(error);
    }
  },
  async storeDesign({
    rootState, state, commit, dispatch,
  }) {
    commit(types.STORE_DESIGN_PENDING);
    try {
      // Prepare Payload
      const formattedPayload = { ...state.selectedDesign };
      formattedPayload.settings = JSON.stringify(formattedPayload.settings);
      const userDesignId = state.designs.length > 0 ? state.designs[0].id : null;
      if (formattedPayload.kind === 'template') {
        formattedPayload.id = userDesignId;
        formattedPayload.title = 'Individual';
      }
      // Prepare Request
      const method = formattedPayload.id ? 'PATCH' : 'POST';
      let url = `/accounts/${rootState.definitions.account.account.uuid}/designers/documents/designs`;
      url = formattedPayload.id ? `${url}/${formattedPayload.id}` : url;
      const response = await axios({ url, method, data: formattedPayload });
      // If first design
      if (state.designs.length === 0) {
        state.designs.push(response.data);
      }
      commit(types.STORE_DESIGN_FULFILLED);
      dispatch('messages/showMessage', { key: 'designer_message_create_successful', color: 'success' }, { root: true });
      return true;
    } catch (error) {
      commit(types.STORE_DESIGN_ERROR);
      dispatch('messages/showMessage', { key: 'designer_message_create_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  sessionKeepAlive({ rootState }) {
    axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`).then(() => {});
  },
  triggerNextPage({ commit }, value) {
    commit(types.TRIGGER_NEXT_PAGE, value);
  },
  async changeTemplate({ commit, dispatch }, { id, kind }) {
    commit(types.CHANGE_TEMPLATE_PENDING);
    try {
      if (id !== null) {
        if (kind === 'usercreated') {
          await dispatch('fetchDesign', id);
        } else {
          commit(types.SET_TEMPLATE, id);
        }
      } else {
        commit(types.SET_STANDARD_TEMPLATE);
      }
      commit(types.CHANGE_TEMPLATE_FULFILLED);
    } catch (error) {
      commit(types.CHANGE_TEMPLATE_ERROR, id);
    }
  },
};

export default actions;
