import statusList from '@/components/status/StatusList';
import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ rootState, commit, dispatch }, data) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      const nextNumber = await dispatch('fetchNextNumber', data);
      if (!rootState.documentDesigner.selectedDesign.id) {
        await dispatch('documentDesigner/fetchInitial', null, { root: true });
      }

      commit(types.FETCH_NEXTNUMBER_FULFILLED, nextNumber);
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve(nextNumber);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchInvoice({ commit, dispatch }, data) {
    commit(types.FETCH_INVOICE_PENDING);
    try {
      await Promise.all([
        dispatch('fetchSingleInvoice', data.id),
        dispatch('fetchActions', data),
      ]);
      commit(types.FETCH_INVOICE_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INVOICE_ERROR, error);
      return Promise.reject(error);
    }
  },
  precalculate({ rootState, commit }, { abortController, data }) {
    const controller = abortController || new AbortController();
    commit(types.PRECALCULATE_INVOICE_PENDING);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/invoices/precalculate`, data, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.PRECALCULATE_INVOICE_FULFILLED, response);
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        const errorData = {
          discount: 0,
          position_total: 0,
          rounding_difference: 0,
          taxes: [],
          total: 0,
        };

        // set zero when an error occurred
        commit(types.PRECALCULATE_INVOICE_FULFILLED, { data: errorData });
      }
    });
  },
  async generatePreview({ rootState, commit }, form) {
    commit(types.GENERATE_PREVIEW_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/invoices/pdfpreview`;
      const response = await axios.post(url, { form });
      commit(types.GENERATE_PREVIEW_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.GENERATE_PREVIEW_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storeInvoice({ rootState, commit, dispatch }, form) {
    commit(types.STORE_INVOICE_PENDING);
    try {
      // csrf is awesome
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
      const formData = { ...form };
      const newFiles = [...form.files].filter((file) => file instanceof File);
      const newFilePromises = [];
      formData.files = [...form.files].filter((file) => !(file instanceof File));
      formData.document_ids = [...form.files].filter((file) => !(file instanceof File)).map((file) => file.id);
      formData.action = 'save';
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/invoices`;
      let response = {};

      if (!form.id) {
        response = await axios.post(baseUrl, formData);
        if (!formData.id) {
          formData.id = response.data.id;
        }
      }

      // upload new files
      if (newFiles.length > 0) {
        await newFiles
          .reduce((promise, file) => promise.then(() => dispatch('uploadFile', {
            invoiceId: formData.id,
            file,
          }).then((result) => {
            formData.files.push(result.data);
            formData.document_ids.push(result.data.id);
            newFilePromises.push(result.data);
            return Promise.resolve();
          })), Promise.resolve());
      }

      // delete removed files
      if (form.removedFiles.length > 0) {
        await form.removedFiles
          .reduce((promise, fileId) => promise.then(() => dispatch('deleteFile', {
            invoiceId: formData.id,
            fileId,
          }).then(() => {
            formData.files.filter((file) => file.id !== fileId);
            formData.document_ids.filter((id) => id !== fileId);
            Promise.resolve();
          })), Promise.resolve());
      }
      delete formData.removedFiles;

      if (form.id || (!form.id && form.action !== formData.action)) {
        formData.action = form.action;
        response = await axios.patch(`${baseUrl}/${formData.id}`, formData);
      }
      commit(types.STORE_INVOICE_FULFILLED, response.data);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      return Promise.resolve({
        ...response.data,
        newDocumentIds: formData.document_ids,
        newFiles: formData.files,
      });
    } catch (error) {
      commit(types.STORE_INVOICE_ERROR, error);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async uploadFile({ rootState, dispatch }, { invoiceId, file }) {
    try {
      const data = new FormData();
      data.set('file', file);

      const accountId = rootState.definitions.account.account.uuid;

      return await axios.postForm(
        `/accounts/${accountId}/invoices/${invoiceId}/documents`,
        data,
        {
          transformRequest: (formData) => formData,
        },
      );
    } catch (error) {
      dispatch('messages/showMessage', { key: 'upload_document_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  async deleteFile({ rootState, dispatch }, { invoiceId, fileId }) {
    try {
      const accountId = rootState.definitions.account.account.uuid;
      const url = `/accounts/${accountId}/invoices/${invoiceId}/documents/${fileId}`;
      return await axios.delete(url);
    } catch (error) {
      dispatch('messages/showMessage', { key: 'delete_document_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  fetchNextNumber({ rootState }, { type, isTemplate }) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/nextnumber/${type}${isTemplate ? '/true' : ''}`);
  },
  async fetchActions({ rootState, commit }, data) {
    commit(types.FETCH_ACTIONS_PENDING);
    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/invoices/singleactions`;
      const url = data.id ? `${baseUrl}/${data.type}/${data.id}` : `${baseUrl}/${data.type}`;
      const response = await axios.get(url);
      commit(types.FETCH_ACTIONS_FULFILLED, response);
      return response.data;
    } catch (error) {
      commit(types.FETCH_ACTIONS_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchSingleInvoice({ rootState, commit }, id) {
    commit(types.FETCH_SINGLE_INVOICE_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/${id}`);
      commit(types.FETCH_SINGLE_INVOICE_FULFILLED, response);
      return response.data;
    } catch (error) {
      commit(types.FETCH_SINGLE_INVOICE_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchRecurringList({ rootState, commit }, { id, meta, metaKey }) {
    commit(types.FETCH_RECURRING_PENDING, true);
    try {
      let params = {};
      const metaValue = meta || null;
      const extendedSearch = {};
      const search = [];
      if (metaValue) {
        extendedSearch.status_name_OR = metaValue.search.filter(
          (filterValue) => {
            const hasFilter = statusList.allStatus().find(
              (status) => status.text === filterValue,
            );
            if (!hasFilter) {
              search.push(filterValue);
            }
            return hasFilter;
          },
        );
        params = {
          direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy[0],
          page: metaValue.page,
          search,
        };
      }
      // prepare extended search parameter
      const extendedSearchParameter = Object.keys(extendedSearch).map(
        (extendedSearchKey) => {
          if (!extendedSearch[extendedSearchKey]) {
            return undefined;
          }
          return `extend_search[${extendedSearchKey}]=${extendedSearch[extendedSearchKey].join()}`;
        },
      ).filter((value) => value !== undefined).join('&');

      let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/customers/recurring_invoices/${id}/created_invoices`;
      url = extendedSearchParameter ? `${url}?${extendedSearchParameter}` : url;
      const data = await axios.get(url, { params });
      if (data.meta) {
        if (metaValue) {
          data.meta.search = metaValue.search; // keep current search params
        }
        commit(types.FETCH_TABLE_META, { meta: data.meta, key: metaKey });
      }
      commit(types.FETCH_RECURRING_FULFILLED, data);
      commit(types.FETCH_RECURRING_PENDING, false);
    } catch (error) {
      commit(types.FETCH_RECURRING_PENDING, false);
    }
  },
};

export default actions;
