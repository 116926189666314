import * as types from './types';

export default {
  /*
   * single classification
   */
  [types.FETCH_CLASSIFICATION](state) {
    state.isLoadingClassification = true;
    state.hasLoadedClassification = false;
    state.classificationError = null;
  },
  [types.FETCH_CLASSIFICATION_SUCCESS](state, classification) {
    state.classification = classification;

    state.isLoadingClassification = false;
    state.hasLoadedClassification = true;
    state.classificationError = null;
  },
  [types.FETCH_CLASSIFICATION_FAILURE](state, error) {
    state.isLoadingClassification = false;
    state.hasLoadedClassification = false;
    state.classificationError = error;
  },
  [types.FETCH_CLASSIFICATION_CANCEL](state) {
    state.isLoadingClassification = false;
    state.classificationError = null;
  },

  /*
   * POST classification
   */
  [types.POST_CLASSIFICATION](state) {
    state.isPostingClassification = true;
    state.postingError = null;
  },
  [types.POST_CLASSIFICATION_SUCCESS](state) {
    state.isPostingClassification = false;
    state.postingError = null;
  },
  [types.POST_CLASSIFICATION_FAILURE](state, error) {
    state.isPostingClassification = false;
    state.postingError = error;
  },

  /*
   * PATCH classification
   */
  [types.PATCH_CLASSIFICATION](state) {
    state.isPatchingClassification = true;
    state.patchingError = null;
  },
  [types.PATCH_CLASSIFICATION_SUCCESS](state) {
    state.isPatchingClassification = false;
    state.patchingError = null;
  },
  [types.PATCH_CLASSIFICATION_FAILURE](state, error) {
    state.isPatchingClassification = false;
    state.patchingError = error;
  },

  /*
   * DELETE classification
   */
  [types.DELETE_CLASSIFICATION](state) {
    state.isDeletingClassification = true;
    state.deletingError = null;
  },
  [types.DELETE_CLASSIFICATION_SUCCESS](state) {
    state.isDeletingClassification = false;
    state.deletingError = null;
  },
  [types.DELETE_CLASSIFICATION_FAILURE](state, error) {
    state.isDeletingClassification = false;
    state.deletingError = error;
  },
};
