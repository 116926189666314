import ababank from './ababank';
import banking from './banking';
import bankAccount from './bank-account';
import bankfiles from './bankfiles';
import bankfile from './bankfile';
import payments from './payments';
import payment from './payment';
import paymentsExport from './payments-export';
import paymentSystems from './payment-systems';
import creditCards from './creditcards';
import deepCloudLogin from './deep-cloud-login';
import deepCloudConnect from './deep-cloud-connect';

export default {
  namespaced: true,
  modules: {
    ababank,
    banking,
    bankAccount,
    bankfiles,
    bankfile,
    payments,
    payment,
    paymentsExport,
    paymentSystems,
    creditCards,
    deepCloudLogin,
    deepCloudConnect,
  },
};
