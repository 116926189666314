import axios from 'axios';

const FETCH_ADMIN_DEEPPAY_DOCUMENT_PENDING = 'FETCH_ADMIN_DEEPPAY_DOCUMENT_PENDING';
const FETCH_ADMIN_DEEPPAY_DOCUMENT_FULFILLED = 'FETCH_ADMIN_DEEPPAY_DOCUMENT_FULFILLED';
const FETCH_ADMIN_DEEPPAY_DOCUMENT_ERROR = 'FETCH_ADMIN_DEEPPAY_DOCUMENT_ERROR';

const initialState = {
  fetchDocumentPending: false,
};
const mutations = {
  [FETCH_ADMIN_DEEPPAY_DOCUMENT_PENDING](state) {
    state.fetchDocoumentPending = true;
  },
  [FETCH_ADMIN_DEEPPAY_DOCUMENT_ERROR](state) {
    state.fetchDocoumentPending = false;
  },
  [FETCH_ADMIN_DEEPPAY_DOCUMENT_FULFILLED](state) {
    state.fetchDocoumentPending = false;
  },
};
const actions = {
  async fetchDeepPayAdminDocument({ commit, dispatch }, uuid) {
    commit(FETCH_ADMIN_DEEPPAY_DOCUMENT_PENDING);
    try {
      const { data } = await axios.get(`/admin/finances/ebanking/documents/${uuid}`);
      commit(FETCH_ADMIN_DEEPPAY_DOCUMENT_FULFILLED);
      if (data && data.download_url) {
        window.location.href = data.download_url;
      }
      return Promise.resolve(true);
    } catch (error) {
      dispatch('messages/showMessage', { text: 'Error', color: 'error' }, { root: true });
      commit(FETCH_ADMIN_DEEPPAY_DOCUMENT_ERROR);
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
