import axios from 'axios';
import qs from 'qs';
import * as types from './types';

const actions = {

  fetchIncomeStatement({ rootState, commit }, {
    abortController,
    endDate,
    showNoBookings,
    showNumber,
    showPercentage,
    showPrevious,
    showInactive,
    showZero,
    startDate,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_INCOME_STATEMENT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/income_statement/${yearId}`, {
      signal: controller.signal,
      params: {
        start_date: startDate,
        end_date: endDate,
        show_zero: showZero,
        show_no_bookings: showNoBookings,
        show_previous: showPrevious,
        show_percentage: showPercentage,
        show_number: showNumber,
        show_inactive: showInactive,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_INCOME_STATEMENT_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_INCOME_STATEMENT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_INCOME_STATEMENT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchIncomeStatementPdfExport({ rootState, commit }, {
    abortController,
    endDate,
    showNoBookings,
    showNumber,
    showPercentage,
    showPrevious,
    showZero,
    startDate,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_INCOME_STATEMENT_PDF_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/income_statement/${yearId}`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
      params: {
        export: 'pdf',
        per_page: 'all',
        start_date: startDate,
        end_date: endDate,
        show_zero: showZero,
        show_no_bookings: showNoBookings,
        show_previous: showPrevious,
        show_percentage: showPercentage,
        show_number: showNumber,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_INCOME_STATEMENT_PDF_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_INCOME_STATEMENT_PDF_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_INCOME_STATEMENT_PDF_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchIncomeStatementXlsExport({ rootState, commit }, {
    abortController,
    endDate,
    showNoBookings,
    showNumber,
    showPercentage,
    showPrevious,
    showZero,
    startDate,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_INCOME_STATEMENT_XLS_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/income_statement/${yearId}`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
      params: {
        export: 'xls',
        per_page: 'all',
        start_date: startDate,
        end_date: endDate,
        show_zero: showZero,
        show_no_bookings: showNoBookings,
        show_previous: showPrevious,
        show_percentage: showPercentage,
        show_number: showNumber,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_INCOME_STATEMENT_XLS_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_INCOME_STATEMENT_XLS_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_INCOME_STATEMENT_XLS_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },
};

export default actions;
