<template>
  <v-dialog
    v-model="showDialog"
    :max-width="props.options.width"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title class="pr-4">
        <h3 class="text-h3">
          {{ dialogTitle }}
        </h3>
        <v-spacer />
        <v-btn icon small text @click="cancel()">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        v-show="!!formattedDialogMessage"
        class="pt-4"
      >
        <div v-html="formattedDialogMessage"></div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn v-if="!options.hideCancelButton" depressed name="confirmation_dialog_no" text @click="cancel()">
          {{ $t('texts.ask_no') }}
        </v-btn>
        <v-btn :color="options.agreeColor" depressed name="confirmation_dialog_yes" @click="agree()">
          <template v-if="options.confirmText">
            {{ options.confirmText }}
          </template>
          <template v-else>
            {{ $t('texts.ask_yes') }}
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import type { ConfirmationDialogOptions } from '@/components/dialog/confirmation-dialog-options';
import { editorRef } from '@/helpers/editorRef';
import { useFilters } from '@/helpers/use-filters';
import { computed, ref } from 'vue';

interface Props {
  options?: ConfirmationDialogOptions;
}

const props = withDefaults(defineProps<Props>(), {
  options: () => ({
    color: 'primary',
    agreeColor: 'primary',
    width: 500,
    hideCancelButton: false,
  }),
});

const filters = useFilters();

const showDialog = ref(false);
const dialogTitle = ref<string | null>(null);
const dialogMessage = ref<string | null>(null);
const dialogOptions = editorRef(props, 'options');
const resolveDialog = ref<(value: unknown) => void>(() => {
});

const formattedDialogMessage = computed(() => filters.nl2br(dialogMessage.value));

function open(title: string, message: string, options?: ConfirmationDialogOptions) {
  dialogTitle.value = title;
  dialogMessage.value = message;
  dialogOptions.value = {
    ...dialogOptions.value,
    ...options,
  };
  showDialog.value = true;
  return new Promise((resolve) => {
    resolveDialog.value = resolve;
  });
}

function agree() {
  resolveDialog.value(true);
  showDialog.value = false;
}

function cancel() {
  resolveDialog.value(false);
  showDialog.value = false;
}

defineExpose({
  open,
});

</script>
