// FETCH_INITIAL
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

// FETCH_LIST
export const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
export const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
export const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

// FETCH_GROUPED_LIST
export const FETCH_GROUPED_LIST_PENDING = 'FETCH_GROUPED_LIST_PENDING';
export const FETCH_GROUPED_LIST_FULFILLED = 'FETCH_GROUPED_LIST_FULFILLED';
export const FETCH_GROUPED_LIST_ERROR = 'FETCH_GROUPED_LIST_ERROR';
