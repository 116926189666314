const AccountStatement = () => import('@/pages/accounting/account-statement/AccountStatement.vue');

export default [
  {
    path: 'account-statement/:yearId?/:accountNumber?',
    name: 'account-statement',
    component: AccountStatement,
    meta: {
      title: 'account_statement',
    },
  },
];
