import { getAddressSearchIndexStatus, initAddressSearchIndex } from '@/components/Address/api';
import { useCreateEntityV3 } from '@/stores/composables/useCreateEntityV3';
import { useGetEntityV3 } from '@/stores/composables/useGetEntityV3';
import { createSharedComposable } from '@vueuse/shared';

export const useAddressIndexStore = () => ({
  ...useGetEntityV3(getAddressSearchIndexStatus),
  ...useCreateEntityV3(initAddressSearchIndex),
});

export const useSharedAddressIndexStore = createSharedComposable(useAddressIndexStore);
