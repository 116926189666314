export default {
  initialPending: false,
  productGroupsPending: false,
  productGroups: [],
  productsPending: false,
  archiveProductsPending: false,
  archiveProductGroupsPending: false,
  products: [],
  paginationProducts: [],
  tagsPending: false,
  tags: [],
  meta: {},
  archiveProducts: [],
  archiveProductGroups: [],

  searchProducts: [],
  isSearching: false,
  searchError: null,

  isFetchingEmptySearchProducts: false,
  emptySearchProducts: [],
  emptySearchProductsError: null,
};
