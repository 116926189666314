import type { RouteConfig } from 'vue-router/types/router';

const AccountingInbox = () => import('@/pages/accounting/inbox/Inbox.vue');

export const accountingInboxRoutes: Array<RouteConfig> = [{
  path: 'inbox',
  name: 'accounting-inbox',
  component: AccountingInbox,
  meta: {
    title: 'accounting_inbox',
  },
}];
