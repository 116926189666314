export default {
  accountDateFormat: (state) => {
    if (state.account.date_format) {
      const dayIndex = state.account.date_format.indexOf('D');
      const monthIndex = state.account.date_format.indexOf('M');
      const startDayMontIndex = Math.min(dayIndex, monthIndex);
      const dayMont = state.account.date_format.slice(startDayMontIndex, startDayMontIndex + 6);

      const yearIndex = state.account.date_format.indexOf('Y');
      const startMontYearIndex = Math.min(yearIndex, monthIndex);
      const montYear = state.account.date_format.slice(startMontYearIndex, startMontYearIndex + 7);
      return {
        date: state.account.date_format,
        dateTime: state.account.datetime_format,
        dateDayMonth: dayMont,
        dateMonthYear: montYear,
      };
    }
    return {
      date: 'DD.MM.YYYY',
      dateTime: 'DD.MM.YYYY H:mm:ss',
      dateDayMonth: 'DD.MM.',
      dateMonthYear: 'MM.YYYY',
    };
  },
  isDeepBoxAccount: (state) => state.account.is_deepbox_account,
  isSwiss21Account: (state) => state.account.is_swiss21_account,
  hasFlexibleAccPlan: (state) => state.account.has_flexible_acc_accounts,
};
