import * as types from './types';

export default {
  [types.FETCH_PERMISSIONS](state) {
    state.isLoadingPermissions = true;
  },
  [types.FETCH_PERMISSIONS_SUCCESS](state, data) {
    state.permissions = data;
    state.isLoadingPermissions = false;
  },
  [types.FETCH_PERMISSIONS_CANCEL](state) {
    state.isLoadingPermissions = false;
  },
  [types.FETCH_PERMISSIONS_FAILURE](state) {
    state.isLoadingPermissions = false;
  },
};
