import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialReportPending: false,
    reportTypes: {},
    fetchReportPending: false,
    fetchReportExportPending: false,
    fetchReportExportExcelPending: false,
    fetchReportExportPdfPending: false,
    listInitialPending: false,
    listPending: false,
    list: [],
    meta: {
      quotes: null,
      contract_notes: null,
      delivery_notes: null,
      invoices: null,
      credit_notes: null,
      receipts: null,
      payments: null,
      addresses: null,
      products: null,
      payment_comparison: null,
      activities: null,
      due_list: null,
    },
    totals: {},
  },
  mutations,
  actions,
};
