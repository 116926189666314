import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialLoading: true,
    initialError: false,
    vatJournalMeta: {},
    vatJournal: [],
    vatJournalPending: false,
    closeJournalPending: false,
  },
  actions,
  mutations,
};
