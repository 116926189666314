import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    companies: [],
    companiesPending: false,
  },
  mutations,
  actions,
};
