import mutations from '@/store/modules/comments/mutations';
import actions from '@/store/modules/comments/actions';
import getters from '@/store/modules/comments/getters';

export default {
  namespaced: true,
  state: {
    id: null,
    type: '',

    ids: [],
    entities: {},

    isLoading: false,
    hasLoaded: false,
    isPosting: false,
    isDeletingIds: [],

    error: null,
    meta: {
      sortDesc: [true],
      itemsPerPage: 10,
      sortBy: ['date'],
      page: 1,
      search: null,
      extendSearch: null,
      maxPage: 1,
    },
  },
  mutations,
  actions,
  getters,
};
