import { ref } from 'vue';

export const useCreateEntityV3 = <TArgs extends unknown[], TResponse>(
  handler: (...args: TArgs) => Promise<TResponse>,
) => {
  const isCreating = ref(false);

  async function createEntity(...args: TArgs) {
    isCreating.value = true;

    try {
      return await handler(...args);
    } finally {
      isCreating.value = false;
    }
  }

  return {
    isCreating,
    createEntity,
  };
};
