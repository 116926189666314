import initialState from './state';
import * as types from './types';

export default {
  // FETCH_ACCOUNT_STATEMENT
  [types.FETCH_ACCOUNT_STATEMENT](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
    state.accountStatement = [];
    state.meta = {
      ...initialState.meta,
    };
  },
  [types.FETCH_ACCOUNT_STATEMENT_SUCCESS](state, { data, meta }) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;
    state.accountStatement = data;
    state.accountInfo = meta.totals;
    state.meta = {
      ...state.meta,
      max_page: meta.max_page,
      extend_search: meta.extend_search,
    };
  },
  [types.FETCH_ACCOUNT_STATEMENT_FAILURE](state, error) {
    state.hasLoaded = false;
    state.isLoading = false;
    state.error = error;
    state.accountStatement = [];
  },
  [types.FETCH_ACCOUNT_STATEMENT_CANCEL](state) {
    state.isLoading = false;
    state.error = null;
  },

  // FETCH_MORE_ACCOUNT_STATEMENT
  [types.FETCH_MORE_ACCOUNT_STATEMENT](state) {
    state.isLoading = true;
  },
  [types.FETCH_MORE_ACCOUNT_STATEMENT_SUCCESS](state, { data, meta }) {
    state.isLoading = false;
    state.accountStatement = [...state.accountStatement, ...data];
    state.meta.page = meta.page;
  },
  [types.FETCH_MORE_ACCOUNT_STATEMENT_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.FETCH_MORE_ACCOUNT_STATEMENT_CANCEL](state) {
    state.isLoading = false;
    state.error = null;
  },

  // FETCH_ACCOUNT_STATEMENT_PDF_EXPORT
  [types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT](state) {
    state.isLoadingAccountStatementPdfExport = true;
  },
  [types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_SUCCESS](state) {
    state.isLoadingAccountStatementPdfExport = false;
  },
  [types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_FAILURE](state) {
    state.isLoadingAccountStatementPdfExport = false;
  },
  [types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_CANCEL](state) {
    state.isLoadingAccountStatementPdfExport = false;
  },

  // FETCH_ACCOUNT_STATEMENT_XLS_EXPORT
  [types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT](state) {
    state.isLoadingAccountStatementXlsExport = true;
  },
  [types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_SUCCESS](state) {
    state.isLoadingAccountStatementXlsExport = false;
  },
  [types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_FAILURE](state) {
    state.isLoadingAccountStatementXlsExport = false;
  },
  [types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_CANCEL](state) {
    state.isLoadingAccountStatementXlsExport = false;
  },

  // FETCH_ACCOUNT_STATEMENT_EXPORT
  [types.FETCH_ACCOUNT_STATEMENT_EXPORT](state) {
    state.isLoadingAccountStatementExport = true;
  },
  [types.FETCH_ACCOUNT_STATEMENT_EXPORT_SUCCESS](state) {
    state.isLoadingAccountStatementExport = false;
  },
  [types.FETCH_ACCOUNT_STATEMENT_EXPORT_FAILURE](state) {
    state.isLoadingAccountStatementExport = false;
  },
  [types.FETCH_ACCOUNT_STATEMENT_EXPORT_CANCEL](state) {
    state.isLoadingAccountStatementExport = false;
  },

  // BOOK_EXCHANGE_DIFFS
  [types.BOOK_EXCHANGE_DIFF](state) {
    state.isBookingExchangeDiff = true;
    state.bookingExchangeDiffError = null;
  },
  [types.BOOK_EXCHANGE_DIFF_SUCCESS](state) {
    state.isBookingExchangeDiff = false;
    state.bookingExchangeDiffError = null;
  },
  [types.BOOK_EXCHANGE_DIFF_FAILURE](state, error) {
    state.isBookingExchangeDiff = false;
    state.bookingExchangeDiffError = error;
  },

  [types.SET_SEARCH](state, {
    form, valutaRangeValue, valutaRange, bookingDateRangeValue, bookingDateRange, selectedOperator,
  }) {
    state.isSearching = true;
    state.search.form = form;
    state.search.valutaRangeValue = valutaRangeValue;
    state.search.valutaRange = valutaRange;
    state.search.bookingDateRangeValue = bookingDateRangeValue;
    state.search.bookingDateRange = bookingDateRange;
    state.search.selectedOperator = selectedOperator;
  },
  [types.RESET_SEARCH](state) {
    state.isSearching = false;
    state.search = { ...initialState.search };
  },
};
