export const FETCH_ACCOUNT_PROPOSAL = 'FETCH_ACCOUNT_PROPOSAL';
export const FETCH_ACCOUNT_PROPOSAL_SUCCESS = 'FETCH_ACCOUNT_PROPOSAL_SUCCESS';
export const FETCH_ACCOUNT_PROPOSAL_FAILURE = 'FETCH_ACCOUNT_PROPOSAL_FAILURE';
export const FETCH_ACCOUNT_PROPOSAL_CANCEL = 'FETCH_ACCOUNT_PROPOSAL_CANCEL';

export const POST_ACCOUNT_PROPOSAL = 'POST_ACCOUNT_PROPOSAL';
export const POST_ACCOUNT_PROPOSAL_SUCCESS = 'POST_ACCOUNT_PROPOSAL_SUCCESS';
export const POST_ACCOUNT_PROPOSAL_FAILURE = 'POST_ACCOUNT_PROPOSAL_FAILURE';

export const PATCH_ACCOUNT_PROPOSAL = 'PATCH_ACCOUNT_PROPOSAL';
export const PATCH_ACCOUNT_PROPOSAL_SUCCESS = 'PATCH_ACCOUNT_PROPOSAL_SUCCESS';
export const PATCH_ACCOUNT_PROPOSAL_FAILURE = 'PATCH_ACCOUNT_PROPOSAL_FAILURE';

export const DELETE_ACCOUNT_PROPOSAL = 'DELETE_ACCOUNT_PROPOSAL';
export const DELETE_ACCOUNT_PROPOSAL_SUCCESS = 'DELETE_ACCOUNT_PROPOSAL_SUCCESS';
export const DELETE_ACCOUNT_PROPOSAL_FAILURE = 'DELETE_ACCOUNT_PROPOSAL_FAILURE';
