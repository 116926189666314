import type { Payload } from '@/api/interfaces/payload';
import webApiClient from '@/helpers/client/webapi-client';
import type { AxiosPromise } from 'axios';

export function getLanguages(
  abortController: AbortController = new AbortController(),
): AxiosPromise<Payload<Array<Language>>> {
  return webApiClient.get('/basics/languages', {
    signal: abortController.signal,
  });
}
