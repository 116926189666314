import INVOICE_ITEM_KIND from '@/enums/invoice-item-kind';

export default {
  getIds: (state) => state.invoiceItems.map((invoiceItem) => invoiceItem.id || invoiceItem.uuid),

  getKinds: (state) => state.invoiceItems.map((invoiceItem) => invoiceItem.kind),

  getGhostItemIds: (state) => state.ghostItems.map((ghostItem) => ghostItem.id || ghostItem.uuid),

  getGroupInvoiceItemsIdsByGroupId: (state, getters) => (groupId) => state.invoiceItems[getters.getIds.indexOf(groupId)]
    .group_items
    .map((invoiceItem) => invoiceItem.id || invoiceItem.uuid),

  getInvoiceItemById: (state, getters) => (id) => state.invoiceItems[getters.getIds.indexOf(id)],

  getGhostItem: (state, getters) => (id) => state.ghostItems[getters.getGhostItemIds.indexOf(id)],

  getGroupItems: (state, getters) => (id) => state.invoiceItems[getters.getIds.indexOf(id)].group_items,

  getInvoiceItemFromGroup: (state, getters) => (groupId, id) => state.invoiceItems[getters.getIds.indexOf(groupId)]
    .group_items[getters.getGroupInvoiceItemsIdsByGroupId(groupId).indexOf(id)],

  getGroupTaxRate: (state, getters) => (groupId) => {
    const groupItems = getters.getGroupItems(groupId);
    if (groupItems.length) {
      return groupItems[0].tax_rate1;
    }
    return 0;
  },

  /**
   * @param state
   * @return {boolean}
   */
  getCanDisableQty: (state) => {
    const hasMoreThanOneQtyInFirstLevel = state.invoiceItems.filter(
      (item) => item.kind === INVOICE_ITEM_KIND.PRODUCT,
    ).some(
      (item) => item.qty && parseFloat(item.qty) !== 1,
    );
    if (hasMoreThanOneQtyInFirstLevel) {
      // fast exit if there are more than one item per row
      return false;
    }

    // else check for groups
    const hasMoreThanOneQtyInGroups = state.invoiceItems.filter((item) => item.kind === INVOICE_ITEM_KIND.GROUP).some(
      (group) => group.group_items.some((product) => product.qty && parseFloat(product.qty) !== 1),
    );
    if (hasMoreThanOneQtyInGroups) {
      return false;
    }

    // all quantities are equal to 0 or 1;
    return true;
  },

  /**
   * @param state
   * @return {boolean}
   */
  getCanDisableDiscount: (state) => {
    const hasDiscountInFirstLevel = state.invoiceItems.filter(
      (item) => item.kind === INVOICE_ITEM_KIND.PRODUCT,
    ).some(
      (item) => item.discount && parseFloat(item.discount) !== 0,
    );
    if (hasDiscountInFirstLevel) {
      return false;
    }

    // else check in groups
    const hasDiscountInGroups = state.invoiceItems.filter((item) => item.kind === INVOICE_ITEM_KIND.GROUP).some(
      (group) => group.group_items.some((product) => product.discount && parseFloat(product.discount) !== 0),
    );
    if (hasDiscountInGroups) {
      return false;
    }

    // all quantities are equal to 0 or 1;
    return true;
  },

  hasActivityWithDateInList: (state) => state.invoiceItems.some((invoiceItem) => {
    if (invoiceItem.employee_activity_start_date) {
      return true;
    }
    if (invoiceItem.group_items) {
      return invoiceItem.group_items.some((groupItem) => !!groupItem.employee_activity_start_date);
    }
    return false;
  }),

  hasProductNumbersInList: (state) => state.invoiceItems.some((invoiceItem) => {
    if (invoiceItem.product_key) {
      return true;
    }
    if (invoiceItem.group_items) {
      return invoiceItem.group_items.some((groupItem) => !!groupItem.product_key);
    }
    return false;
  }),

  getItemsCount: (state) => state.invoiceItems.reduce(
    (acc, invoiceItem) => acc + 1 + (invoiceItem.group_items ? invoiceItem.group_items.length : 0),
    0,
  ),

  isMaxCountReached: (state, getters) => getters.getItemsCount >= state.maxItems,

  getMaxItemsVisibilityThreshold: (state) => state.maxItems * 0.8,
};
