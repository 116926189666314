import { useColorMode } from '@vueuse/core';
import { createSharedComposable } from '@vueuse/shared';
import { computed } from 'vue';

const storageKey = 'theme';

export const useTheme = createSharedComposable(() => {
  const { system, store } = useColorMode({
    storageKey,
  });

  const currentTheme = computed(() => (store.value === 'auto' ? system.value : store.value));
  const isDarkTheme = computed(() => currentTheme.value === 'dark');

  return {
    currentTheme,
    isDarkTheme,
    store,
  };
});
