const LimitUpdate = () => import('@/pages/support/limitupdate/LimitUpdate.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const SupportInfo = () => import('@/pages/support/supportinfo/SupportInfo.vue');

export default {
  path: '/supportinfo',
  name: 'supportinfo',
  alias: ['/account-info'],
  component: PassThroughKeyed,
  redirect: { name: 'supportinfo-default' },
  children: [
    {
      path: '',
      name: 'supportinfo-default',
      component: SupportInfo,
      meta: {
        title: 'support',
      },
    },
    {
      path: 'limitupdate',
      name: 'limitupdate',
      component: LimitUpdate,
    },
  ],
};
