import { CurrencyCode } from '@/api/interfaces/accounting/currency';

const IntlLanguageLocales = new Map([
  [1, 'en'],
  [2, 'it-ch'],
  [3, 'de-ch'],
  [4, 'fr-ch'],
]);

const IntlCurrencyCountryCodes = new Map([
  [CurrencyCode.CHF, 'de-ch'],
  [CurrencyCode.EUR, 'de-de'],
  [CurrencyCode.USD, 'en-us'],
]);

export {
  IntlLanguageLocales,
  IntlCurrencyCountryCodes,
};
