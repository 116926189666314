import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');
const CreditNotesList = () => import('@/pages/customers/credit-notes/CreditNotesList.vue');

const creditNotesRoutes: Array<RouteConfig> = [
  {
    path: 'credit-notes',
    component: PassThrough,
    alias: ['credit_note'],
    children: [
      {
        path: ':group?',
        name: 'credit-notes-list',
        meta: {
          title: 'credit_notes',
        },
        component: CreditNotesList,
        beforeEnter(to, _, next) {
          if (!to.params.group) {
            next({ name: 'credit-notes-list', params: { group: 'open' } });
          } else {
            next();
          }
        },
      },
    ],
  },
];

export default creditNotesRoutes;
