import axios from 'axios';
import qs from 'qs';
import * as types from './types';

const actions = {

  fetchJournal({ rootState, commit, state }, {
    abortController, yearId, extendSearch, showYear, canceledBookings, showTwisted,
  }) {
    const controller = abortController || new AbortController();
    commit(types.FETCH_JOURNAL);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/journal/${yearId}`, {
      signal: controller.signal,
      params: {
        page: state.meta.page,
        per_page: state.meta.per_page,
        extend_search: extendSearch,
        show_year: showYear ? 1 : 0,
        canceled_bookings: canceledBookings ? 1 : 0,
        show_twisted: showTwisted ? 1 : 0,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_JOURNAL_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_JOURNAL_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_JOURNAL_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchMoreJournal({ rootState, commit, state }, {
    abortController, yearId, showYear, canceledBookings, showTwisted,
  }) {
    commit(types.FETCH_MORE_JOURNAL);

    const controller = abortController || new AbortController();

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/journal/${yearId}`, {
      signal: controller.signal,
      params: {
        page: parseInt(state.meta.page, 10) + 1,
        per_page: state.meta.per_page,
        extend_search: state.meta.extend_search,
        show_year: showYear ? 1 : 0,
        canceled_bookings: canceledBookings ? 1 : 0,
        show_twisted: showTwisted ? 1 : 0,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_MORE_JOURNAL_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_MORE_JOURNAL_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_MORE_JOURNAL_FAILURE, error);
      return Promise.reject(error);
    });
  },

  // export
  fetchJournalPdfExport({ rootState, commit, state }, {
    yearId, abortController, canceledBookings, showTwisted, showVatWithFigure,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_JOURNAL_PDF_EXPORT);
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/journal/${yearId}`, {
      signal: controller.signal,
      responseType: 'blob',
      params: {
        export: 'pdf',
        per_page: 'all',
        extend_search: state.meta.extend_search,
        show_year: 1,
        canceled_bookings: canceledBookings ? 1 : 0,
        show_twisted: showTwisted ? 1 : 0,
        show_vat_with_figure: showVatWithFigure ? 1 : 0,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_JOURNAL_PDF_EXPORT_SUCCESS, payload);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_JOURNAL_PDF_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_JOURNAL_PDF_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchJournalXlsExport({ rootState, commit, state }, {
    yearId, abortController, canceledBookings, showTwisted, showVatWithFigure,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_JOURNAL_XLS_EXPORT);
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/journal/${yearId}`, {
      signal: controller.signal,
      responseType: 'blob',
      params: {
        export: 'xls',
        per_page: 'all',
        extend_search: state.meta.extend_search,
        show_year: 1,
        canceled_bookings: canceledBookings ? 1 : 0,
        show_twisted: showTwisted ? 1 : 0,
        show_vat_with_figure: showVatWithFigure ? 1 : 0,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_JOURNAL_XLS_EXPORT_SUCCESS, payload);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_JOURNAL_XLS_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_JOURNAL_XLS_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  setSearch({ commit }, search) {
    commit(types.SET_SEARCH, search);
  },
  resetSearch({ commit }) {
    commit(types.RESET_SEARCH);
  },

  // old
  entriesCall({ rootState }, { yearId, params }) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_journal/${yearId}`, { params });
  },
  async fetchInitial({ commit, dispatch }, { params }) {
    commit(types.FETCH_INITIAL_PENDING);

    try {
      await Promise.all([
        dispatch('fetchEntries', params),
        dispatch('definitions/fetchExchangeRates', null, { root: true }),
      ]);

      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchNextEntries(
    {
      commit,
      dispatch,
      state,
      rootState,
    },
  ) {
    commit(types.FETCH_NEXT_ENTRIES_PENDING);
    commit(types.UPDATE_PARAMS, { offset: state.params.offset += state.params.limit });
    const yearId = rootState.accounting.currentAccountingYear.id;
    const response = await dispatch('entriesCall', { yearId, params: state.params });
    commit(types.FETCH_NEXT_ENTRIES_FULFILLED, response);
  },
  async fetchEntries(
    {
      commit,
      dispatch,
      state,
      rootState,
    },
    params,
  ) {
    commit(types.FETCH_ENTRIES_PENDING);
    commit(types.UPDATE_PARAMS, { ...params, offset: 0 });
    const yearId = rootState.accounting.currentAccountingYear.id;
    const response = await dispatch('entriesCall', { yearId, params: state.params });
    commit(types.FETCH_ENTRIES_FULFILLED, response);
  },
  async storeEntry({ rootState, commit, dispatch }, { data }) {
    commit(types.STORE_ENTRY_PENDING);

    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry`;
      const url = data.id ? `${baseUrl}/${data.id}` : baseUrl;
      const method = data.id ? 'PATCH' : 'POST';
      const formData = { ...data };
      delete formData.file;

      if (data.file instanceof File) {
        const fileResponse = await dispatch('uploadFile', { file: data.file });
        formData.document_id = fileResponse.data.id;
      } else if (data.id && data.file && Object.keys(data.file).length === 0) {
        formData.document_id = 0;
      }

      const createdEntry = await axios({ url, method, data: formData });
      commit(types.STORE_ENTRY_FULFILLED);

      await dispatch('fetchEntries');
      dispatch('messages/showMessage', { key: 'store_entry_successfull', color: 'success' }, { root: true });
      return Promise.resolve(createdEntry.data);
    } catch (error) {
      commit(types.STORE_ENTRY_ERROR, error);
      dispatch('messages/showMessage', { key: 'store_entry_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  uploadFile({ rootState }, { file }) {
    const data = new FormData();
    data.set('bookingvoucher', file);

    return axios.postForm(
      `/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/import`,
      data,
      {
        transformRequest: (formData) => formData,
      },
    );
  },
  async updateEntryState(
    {
      rootState,
      state,
      commit,
      dispatch,
    },
    { entry_id: entryId, type },
  ) {
    if (!state.possibleEntryUpdates.includes(type)) {
      return undefined;
    }

    commit(types.STORE_ENTRY_PENDING);
    try {
      const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}/${type}`);
      commit(types.STORE_ENTRY_FULFILLED, response);
      dispatch('messages/showMessage', { key: 'store_entry_successfull', color: 'success' }, { root: true });
      dispatch('fetchEntries');
      return Promise.resolve(response);
    } catch (error) {
      commit(types.STORE_ENTRY_ERROR, error);
      dispatch('messages/showMessage', { key: 'store_entry_failed', color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },

  async syncEntryToAbacus({ rootState, commit, dispatch }, { entry_id: entryId, forceSync }) {
    commit(types.SYNC_ENTRY_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}/sync`;
      await axios.patch(url, { forceSync });
      commit(types.SYNC_ENTRY_FULFILLED);
      dispatch('messages/showMessage', { key: 'synced_journal_entry', color: 'success' }, { root: true });
    } catch (error) {
      commit(types.SYNC_ENTRY_ERROR);
      dispatch('messages/showMessage', { key: 'journal_entry_sync_failed', color: 'error' }, { root: true });
    }
  },
  async storeBulk({ commit, dispatch, rootState }, payload) {
    commit(types.STORE_BULK_PENDING);
    try {
      const response = await axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/close`, payload);
      await dispatch('fetchEntries');
      commit(types.STORE_BULK_FULFILLED, response);
    } catch (error) {
      commit(types.STORE_BULK_ERROR, error);
    }
  },
};

export default actions;
