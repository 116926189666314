export const FETCH_PRODUCT_PENDING = 'FETCH_PRODUCT_PENDING';
export const FETCH_PRODUCT_FULFILLED = 'FETCH_PRODUCT_FULFILLED';
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';

export const STORE_PRODUCT_PENDING = 'STORE_PRODUCT_PENDING';
export const STORE_PRODUCT_FULFILLED = 'STORE_PRODUCT_FULFILLED';
export const STORE_PRODUCT_ERROR = 'STORE_PRODUCT_ERROR';

export const VALIDATE_PRODUCT_KEY_PENDING = 'VALIDATE_PRODUCT_KEY_PENDING';
export const VALIDATE_PRODUCT_KEY_FULFILLED = 'VALIDATE_PRODUCT_KEY_FULFILLED';

export const RESET_PRODUCT = 'RESET_PRODUCT';

export const ARCHIVE_PRODUCT_PENDING = 'ARCHIVE_PRODUCT_PENDING';
export const ARCHIVE_PRODUCT_FULFILLED = 'ARCHIVE_PRODUCT_FULFILLED';
export const ARCHIVE_PRODUCT_ERROR = 'ARCHIVE_PRODUCT_ERROR';

export const RESTORE_PRODUCT_PENDING = 'RESTORE_PRODUCT_PENDING';
export const RESTORE_PRODUCT_FULFILLED = 'RESTORE_PRODUCT_FULFILLED';
export const RESTORE_PRODUCT_ERROR = 'RESTORE_PRODUCT_ERROR';

export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING';
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
