import type { MomentRange } from 'moment-range';
import { extendMoment } from 'moment-range';
import moment from 'moment';

import 'moment/dist/locale/de';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/it';
import { defaultLocale } from '@/helpers/i18n';

const getMoment = (() => {
  let instance: MomentRange & typeof moment;

  return {
    getInstance: () => {
      if (!instance) {
        moment.locale(defaultLocale);
        instance = extendMoment(moment as unknown as Parameters<typeof extendMoment>[0]);
      }
      return instance;
    },
  };
})();

export function useMoment(): MomentRange & typeof moment {
  return getMoment.getInstance();
}
