import * as types from '@/store/modules/accounting/opening-balance/types';
import axios from 'axios';
import qs from 'qs';

export default {
  fetchOpeningBalance({ rootState, commit }, {
    abortController,
    showNumber,
    showZero,
    showInactive,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_OPENING_BALANCE);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/opening_balance/${yearId}`, {
      signal: controller.signal,
      params: {
        show_zero: showZero,
        show_number: showNumber,
        show_inactive: showInactive,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_OPENING_BALANCE_SUCCESS, response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_OPENING_BALANCE_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_OPENING_BALANCE_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchOpeningBalancePdfExport({ rootState, commit }, {
    abortController,
    showNumber,
    showZero,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_OPENING_BALANCE_PDF_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/opening_balance/${yearId}`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
      params: {
        export: 'pdf',
        per_page: 'all',
        show_zero: showZero,
        show_number: showNumber,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_OPENING_BALANCE_PDF_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_OPENING_BALANCE_PDF_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_OPENING_BALANCE_PDF_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchOpeningBalanceXlsExport({ rootState, commit }, {
    abortController,
    showNumber,
    showZero,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_OPENING_BALANCE_XLS_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/opening_balance/${yearId}`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
      params: {
        export: 'xls',
        per_page: 'all',
        show_zero: showZero,
        show_number: showNumber,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_OPENING_BALANCE_XLS_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_OPENING_BALANCE_XLS_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_OPENING_BALANCE_XLS_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
