import {
  FETCH_VAT_CONTROL_REPORT,
  FETCH_VAT_CONTROL_REPORT_ERROR,
  FETCH_VAT_CONTROL_REPORT_EXPORT,
  FETCH_VAT_CONTROL_REPORT_EXPORT_ERROR,
  FETCH_VAT_CONTROL_REPORT_EXPORT_FULFILLED,
  FETCH_VAT_CONTROL_REPORT_FULFILLED,
} from '@/store/modules/accounting/vat-control-report/types';

export default {
  [FETCH_VAT_CONTROL_REPORT]: (state, { year, period }) => {
    state.isLoading = true;
    state.hasLoaded = false;

    state.year = year;
    state.period = period;
  },
  [FETCH_VAT_CONTROL_REPORT_FULFILLED]: (state, { data, meta }) => {
    state.isLoading = false;
    state.hasLoaded = true;

    state.controlReport = data;
    state.controlReportMeta = meta;
  },
  [FETCH_VAT_CONTROL_REPORT_ERROR]: (state, error) => {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },

  [FETCH_VAT_CONTROL_REPORT_EXPORT]: (state) => {
    state.isLoadingVatControlExport = true;
    state.hasLoadedVatControlExport = false;
  },
  [FETCH_VAT_CONTROL_REPORT_EXPORT_FULFILLED](state) {
    state.isLoadingVatControlExport = false;
    state.hasLoadedVatControlExport = true;
  },
  [FETCH_VAT_CONTROL_REPORT_EXPORT_ERROR](state) {
    state.isLoadingVatControlExport = false;
    state.hasLoadedVatControlExport = false;
  },
};
