export default {
  isLoadingBookingAccount: false,
  hasLoadedBookingAccount: false,
  bookingAccountError: null,

  isPostingBookingAccount: false,
  postingError: null,

  isPatchingBookingAccount: false,
  patchingError: null,

  isDeletingBookingAccount: false,
  deletingError: null,

  isPendingFavoriteAccounts: [],

  bookingAccount: null,
};
