import * as types from '@/store/modules/accounting/opening-balance/types';

export default {
  [types.FETCH_OPENING_BALANCE](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = null;
  },
  [types.FETCH_OPENING_BALANCE_SUCCESS](state, response) {
    state.isLoading = false;
    state.hasLoaded = true;
    state.error = null;

    state.openingBalance = response.data;
    state.meta.availableLevels = response.meta.availableLevels;
    state.meta.provisionally = response.meta.provisionally;
  },
  [types.FETCH_OPENING_BALANCE_CANCEL](state) {
    state.isLoading = false;
  },
  [types.FETCH_OPENING_BALANCE_FAILURE](state, { error }) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;
  },

  // fetch PDF balance sheet
  [types.FETCH_OPENING_BALANCE_PDF_EXPORT](state) {
    state.isLoadingOpeningBalancePdfExport = true;
  },
  [types.FETCH_OPENING_BALANCE_PDF_EXPORT_SUCCESS](state) {
    state.isLoadingOpeningBalancePdfExport = false;
  },
  [types.FETCH_OPENING_BALANCE_PDF_EXPORT_FAILURE](state) {
    state.isLoadingOpeningBalancePdfExport = false;
  },
  [types.FETCH_OPENING_BALANCE_PDF_EXPORT_CANCEL](state) {
    state.isLoadingOpeningBalancePdfExport = false;
  },

  // fetch XLS balance sheet
  [types.FETCH_OPENING_BALANCE_XLS_EXPORT](state) {
    state.isLoadingOpeningBalanceXlsExport = true;
  },
  [types.FETCH_OPENING_BALANCE_XLS_EXPORT_SUCCESS](state) {
    state.isLoadingOpeningBalanceXlsExport = false;
  },
  [types.FETCH_OPENING_BALANCE_XLS_EXPORT_FAILURE](state) {
    state.isLoadingOpeningBalanceXlsExport = false;
  },
  [types.FETCH_OPENING_BALANCE_XLS_EXPORT_CANCEL](state) {
    state.isLoadingOpeningBalanceXlsExport = false;
  },
};
