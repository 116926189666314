import axios from 'axios';
import * as types from './types';

export default {
  fetchAddressTimeTracking({ commit, rootState, state }, {
    addressId, meta, abortController,
  }) {
    const controller = abortController || new AbortController();
    const metaValue = meta || state.meta;

    const params = {
      direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
      per_page: metaValue.itemsPerPage,
      sort: metaValue.sortBy[0],
      page: metaValue.page,
    };

    commit(types.FETCH_ADDRESS_TIME_TRACKING);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/addresses/${addressId}/time_tracking`, {
      signal: controller.signal,
      params,
    }).then((response) => {
      commit(types.FETCH_ADDRESS_TIME_TRACKING_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ADDRESS_TIME_TRACKING_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_ADDRESS_TIME_TRACKING_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
