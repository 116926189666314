import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');
const ContractNotesList = () => import('@/pages/customers/contract-notes/ContractNotesList.vue');

const contractNotesRoutes: Array<RouteConfig> = [
  {
    path: 'contract-notes',
    component: PassThrough,
    alias: ['contract_note'],
    children: [
      {
        path: ':group?',
        name: 'contract-notes-list',
        meta: {
          title: 'contract_notes',
        },
        component: ContractNotesList,
        beforeEnter(to, _, next) {
          if (!to.params.group) {
            next({ name: 'contract-notes-list', params: { group: 'open' } });
          } else {
            next();
          }
        },
      },
    ],
  },
];

export default contractNotesRoutes;
