import { acceptHMRUpdate, defineStore } from 'pinia';
import { useGetEntity } from '@/stores/composables/use-get-entity';
import { getUnreadInboxCount } from '@/api/definitions/unread-inbox-count';

export const useUnreadInboxCountStore = defineStore('unreadInboxCount', () => ({
  ...useGetEntity(getUnreadInboxCount),
}));

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUnreadInboxCountStore, import.meta.hot));
}
