import HttpStatusCode from '@/api/enums/http-status-code';
import type { Payload } from '@/api/interfaces/payload';
import httpClient from '@/helpers/client/http-client';
import { useCancellablePromise } from '@/helpers/useCancellablePromise';
import type { AxiosResponse } from 'axios';

export function getUserValue<T>(key: string) {
  return useCancellablePromise((abortController) => httpClient.get(`/account_user_store/${key}`, {
    signal: abortController.signal,
  }).then((response: AxiosResponse<Payload<{ value: string }>>) => ({
    ...response,
    data: {
      ...response.data,
      data: response.status === HttpStatusCode.NO_CONTENT
        ? ({} as T)
        : (JSON.parse(response.data.data.value as string) as T),
    },
  } as AxiosResponse<Payload<T>>)));
}

export function createOrUpdateUserValue<T>(key: string, data: T) {
  return httpClient.post(`/account_user_store/${key}`, { value: JSON.stringify(data) });
}
