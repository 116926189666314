import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch, rootState }) {
    commit(types.FETCH_INITIAL_PENDING);

    // fetch all start resources
    try {
      await dispatch('fetchKeyFigures', {
        year_id: [rootState.accounting.currentAccountingYear.id],
        type: ['Y'],
        value: [''],
      });
      commit(types.FETCH_INITIAL_FULFILLED);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
    }
  },
  changeRange({ commit }, range) {
    commit(types.CHANGE_RANGE, range);
  },
  async fetchKeyFigures({ rootState, commit, dispatch }, params) {
    commit(types.FETCH_KEYFIGURES_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_key_figures`, { params });
      commit(types.FETCH_KEYFIGURES_FULFILLED, response);
      dispatch('accounting/changeAccountingYear', params.year_id[0], { root: true });
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_KEYFIGURES_ERROR, error);
      return Promise.reject(error);
    }
  },
};

export default actions;
