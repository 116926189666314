import moment from 'moment';

export default Object.freeze({
  ROWS_PER_PAGE_INITIAL: 10,
  ROWS_PER_PAGE_ITEMS: [10, 25, 50, 100],
  TOTAL_VISIBLE_PAGINATION_NUMBERS: 6,
  USER_DATE_FORMAT: 'DD.MM.YYYY',
  MODEL_DATE_FORMAT: 'YYYY-MM-DD',
  DATE_TODAY: moment().format('YYYY-MM-DD'),
  LAST_POSSIBLE_INVOICE_DATE: moment().add(10, 'years').endOf('year').format('YYYY-MM-DD'),
  MAX_PRODUCT_TAGS: 100,
  MAX_ADDRESS_TAGS: 100,
  MAX_NUMBER_RANGE_PADDING_LENGTH: 8,
  MAX_TAX_POSITIONS: 100,
});
