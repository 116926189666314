export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  journal: [],

  meta: {
    page: 1,
    per_page: 20,
    max_page: null,
    extend_search: null,
  },

  isLoadingJournalPdfExport: false,
  isLoadingJournalXlsExport: false,

  isSearching: false,
  search: {
    form: {
      account_number: '',
      amount: '',
      booking_number: '',
      booking_text: '',
      contra_account_number: '',
      create_user_name: '',
      created_at: '',
      credit_value: '',
      debit_value: '',
      partner: '',
      public_notes: '',
      reference: '',
      tax_rate_id: '',
    },

    valutaRangeValue: '',

    valutaRange: {
      date_from: null,
      date_to: null,
    },

    bookingDateRangeValue: '',

    bookingDateRange: {
      date_from: null,
      date_to: null,
    },

    selectedOperator: {
      amount: 2,
      booking_number: 2,
      credit_value: 2,
      debit_value: 2,
    },
  },

  // old
  initialLoading: false,
  entriesLoading: false,
  currentJournal: null,
  possibleEntryUpdates: ['close', 'delete', 'cancel'],
  journals: [],
  entries: [],
  taxRates: [],
  params: {
    direction: 'desc',
    offset: 0,
    limit: 40,
  },
  lastLoaded: false,
  nextEntriesPending: false,
  initialError: false,
  storeEntryPending: false,
  syncEntryPending: false,
  fetchJournalExportPending: false,
  storeBulkPending: false,
};
