import * as types from './types';

export default {
  [types.SEARCH_PRODUCT_GROUP](state) {
    state.isSearching = true;
    state.searchProductGroups = [];
    state.searchError = null;
  },
  [types.SEARCH_PRODUCT_GROUP_SUCCESS](state, { data }) {
    state.isSearching = false;
    state.searchProductGroups = data;
    state.searchError = null;
  },
  [types.SEARCH_PRODUCT_GROUP_FAILURE](state, error) {
    state.isSearching = false;
    state.searchProductGroups = [];
    state.searchError = error;
  },
  [types.SEARCH_PRODUCT_GROUP_CANCEL](state) {
    state.isSearching = false;
  },
};
