import { useI18n } from '@/helpers/i18n';

const i18n = useI18n();

const SHOW_MESSAGE = 'SHOW_MESSAGE';
const HIDE_MESSAGE = 'HIDE_MESSAGE';

const initialState = Object.freeze({
  key: '',
  active: false,
  top: true,
  color: 'success',
  text: '',
  timeout: 5000,
  dismissable: false,
});

const mutations = {
  [SHOW_MESSAGE]: (state, { data }) => {
    let timeout;

    if (data.dismissable) {
      timeout = data.timeout || 30000;
    } else {
      timeout = data.timeout || initialState.timeout;
    }

    // if a ned message gets triggered, extend the timeout a little to be sure it gets recalculated
    if (timeout !== -1 && timeout === state.timeout) {
      timeout += (Math.random() * 10);
    }

    Object.assign(state, {
      ...initialState,
      ...data,
      timeout,
      active: true,
    });
  },
  [HIDE_MESSAGE]: (state) => {
    Object.assign(state, {
      active: false,
    });
  },
  /*
  [HIDE_MESSAGE](state) {
    state.snackbar = {
      key: '',
      active: false,
      top: true,
      color: 'success',
      text: '',
      timeout: 3000
    };
  },
  [SHOW_MESSAGE](state, data) {
    Object.assign(state.snackbar, { ...data, active: true });
  },
  */
};

const actions = {
  showMessage({ commit }, data) {
    commit(SHOW_MESSAGE, { data });
  },
  hideMessage({ commit }) {
    commit(HIDE_MESSAGE);
  },
  errorMessage({ dispatch }, data) {
    dispatch('showMessage', { ...data, ...{ color: 'error' } });
  },
  showMessageValidateError({ dispatch }) {
    const data = { key: 'form_validation_error', color: 'error' };
    dispatch('showMessage', data);
  },
  // TODO: change
  showMessageAdvancedAmidRequired({ rootGetters, dispatch }) {
    const advancedAmidText = `
      ${i18n.t('texts.swiss21_needs_one_following_module')}
      <ul>
      <li>${i18n.t('texts.advanced_accounting')}</li>
      <li>${i18n.t('texts.amid_account')}</li>
      </ul>
    `;
    const paidSubscriptionAmidText = i18n.t('texts.swiss21_needs_paid_subscription_or_amid');
    const data = {
      text: rootGetters['definitions/isNewSwiss21Subscription'] ? paidSubscriptionAmidText : advancedAmidText,
      color: 'info',
    };
    dispatch('showMessage', data);
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
};
