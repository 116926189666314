<template>
  <v-dialog
    v-model="dialog.open"
    :content-class="`${contentClass}${autoWidth ? ' auto-width' : ''}`"
    :max-width="options.width"
    :persistent="persistent"
    @input="!persistent ? close() : () => {}"
    @keydown.esc="closable ? close() : () => {}"
  >
    <v-card v-if="dialog.open">
      <v-card-title class="pr-4">
        <h3 class="text-h3">
          {{ title || dialog.title || $t('texts.info') }}
          <slot name="chip"></slot>
        </h3>
        <v-spacer />
        <v-btn
          v-if="closable"
          icon
          small
          @click="close()"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="subtitle" class="grey lighten-3">
        {{ subtitle }}
      </v-card-subtitle>
      <v-divider />

      <v-card-text v-if="$slots.content" :class="dense ? 'pa-0' : 'pt-4'" class="aba-dialog-content">
        <slot name="content"></slot>
      </v-card-text>

      <slot name="card-content"></slot>

      <v-divider v-if="!hideActions" />
      <v-card-actions v-if="!hideActions" class="justify-end sticky-b">
        <slot name="actions"></slot>
        <v-btn v-if="closable && !hideCloseButton" depressed text @click="close()">
          {{ $t(`texts.${closeLanguageKey}`) }}
        </v-btn>
        <slot name="buttons"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    closeCallback: {
      type: Function,
      default: () => {},
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({
        width: 500,
      }),
    },
    closeLanguageKey: {
      type: [String],
      default: 'close',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.dialog.open = false;
      this.closeCallback();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/vars-to-eliminate";

.sticky-b {
  position: sticky;
  bottom: 0;
}

.aba-dialog-content {
  overflow-y: auto;
  max-height: 60vh;
}

:deep(.v-dialog--pdf-preview) {
  height: 100vh;

  .aba-dialog-content {
    max-height: unset;
  }

  .v-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__text {
      position: relative;
      display: flex;
      flex: 1;

      iframe {
        flex: 1;
        border: solid $color-border 1px;
      }
    }
  }
}

:deep(.auto-width.v-dialog) {
  width: auto !important;
}
</style>
