import axios from 'axios';

const CALLBACK_ABANET_PENDING = 'CALLBACK_ABANET_PENDING';
const CALLBACK_ABANET_FULFILLED = 'CALLBACK_ABANET_FULFILLED';
const CALLBACK_ABANET_ERROR = 'CALLBACK_ABANET_ERROR';

const initialState = {
  callbackAbanetPending: false,
};

const mutations = {
  [CALLBACK_ABANET_PENDING](state) {
    state.callbackAbanetPending = true;
  },
  [CALLBACK_ABANET_FULFILLED](state) {
    state.callbackAbanetPending = false;
  },
  [CALLBACK_ABANET_ERROR](state) {
    state.callbackAbanetPending = false;
  },
};

const actions = {
  async handleCallback({ commit, rootState }, { form }) {
    commit(CALLBACK_ABANET_PENDING);
    try {
      form.account_uuid = rootState.definitions.account.account.uuid;
      const params = { params: { ...form } };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/services/abanet/cb`, params);
      commit(CALLBACK_ABANET_FULFILLED);
      return Promise.resolve(response);
    } catch (error) {
      commit(CALLBACK_ABANET_ERROR);
      return Promise.reject(error.data);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
