import actions from '@/store/modules/accounting/account-plan/actions';
import mutations from '@/store/modules/accounting/account-plan/mutations';
import initialState from '@/store/modules/accounting/account-plan/state';

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
};
