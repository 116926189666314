import type { AxiosPromise } from 'axios';
import type { Payload } from '@/api/interfaces/payload';
import type { Permissions } from '@/api/interfaces/definitions/permission';
import httpClient from '@/helpers/client/http-client';

export function getPermissions(
  abortController: AbortController = new AbortController(),
): AxiosPromise<Payload<Permissions>> {
  return httpClient.get('/permissions', {
    signal: abortController.signal,
  });
}
