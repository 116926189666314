import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchProduct({ rootState, commit }, id) {
    commit(types.FETCH_PRODUCT_PENDING, true);
    try {
      commit(types.FETCH_PRODUCT_FULFILLED, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/products/${id}`));
      commit(types.FETCH_PRODUCT_PENDING, false);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_PRODUCT_ERROR);
      return Promise.reject(error);
    }
  },
  async cloneProduct({ rootState, commit }, id) {
    commit(types.FETCH_PRODUCT_PENDING, true);
    try {
      const product = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/products/${id}`);
      delete product.data.id;
      delete product.data.deleted_at;
      delete product.data.product_key;
      commit(types.FETCH_PRODUCT_FULFILLED, product);
      commit(types.FETCH_PRODUCT_PENDING, false);
      return Promise.resolve(product.data);
    } catch (error) {
      commit(types.FETCH_PRODUCT_ERROR);
      return Promise.reject(error);
    }
  },
  async storeProduct({ rootState, commit, dispatch }, params) {
    commit(types.STORE_PRODUCT_PENDING);

    try {
      const { id } = params;
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/products`;
      const url = id ? `${baseUrl}/${id}` : baseUrl;
      const method = id ? 'PATCH' : 'POST';
      const response = await axios({ url, method, data: params });
      commit(types.STORE_PRODUCT_FULFILLED);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      return Promise.resolve(response);
    } catch (error) {
      let { message } = error.data;
      if (error.data && error.data.errors) {
        const firstErrorField = Object.keys(error.data.errors)[0];
        [message] = error.data.errors[firstErrorField];
      }
      dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      commit(types.STORE_PRODUCT_ERROR);
      return Promise.reject(error);
    }
  },
  async archiveProduct({ rootState, commit, dispatch }, id) {
    commit(types.ARCHIVE_PRODUCT_PENDING);

    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/products/${id}/archive`);
      commit(types.ARCHIVE_PRODUCT_FULFILLED, response);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      dispatch('products/fetchPaginationProducts', { archive: false }, { root: true });
    } catch (error) {
      dispatch('messages/showMessage', { text: error.message, color: 'error' }, { root: true });
      commit(types.ARCHIVE_PRODUCT_ERROR, error);
    }
  },
  async restoreProduct({ rootState, commit, dispatch }, id) {
    commit(types.RESTORE_PRODUCT_PENDING);

    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/products/${id}/restore`);
      commit(types.RESTORE_PRODUCT_FULFILLED, response);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      dispatch('products/fetchPaginationProducts', { archive: true }, { root: true });
    } catch (error) {
      dispatch('messages/showMessage', { text: error.message, color: 'error' }, { root: true });
      commit(types.RESTORE_PRODUCT_ERROR, error);
    }
  },
  async deleteProduct({ rootState, commit, dispatch }, id) {
    commit(types.DELETE_PRODUCT_PENDING);

    try {
      const response = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/products/${id}`);
      commit(types.DELETE_PRODUCT_FULFILLED, response);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      dispatch('products/fetchPaginationProducts', { archive: true }, { root: true });
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      commit(types.DELETE_PRODUCT_ERROR, error);
    }
  },
  async validateProductKey({ rootState, commit }, { productKey, productId }) {
    commit(types.VALIDATE_PRODUCT_KEY_PENDING);
    try {
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/products/checkproductkey`, {
        params: {
          productId,
          productKey,
        },
      });
      commit(types.VALIDATE_PRODUCT_KEY_FULFILLED);
      return Promise.resolve({
        valid: true,
      });
    } catch (error) {
      commit(types.VALIDATE_PRODUCT_KEY_FULFILLED);
      return Promise.resolve({
        valid: false,
      });
    }
  },
  resetProduct({ commit }) {
    commit(types.RESET_PRODUCT);
  },
};

export default actions;
