import { requestApiInterceptor } from '@/interceptors/request-api-interceptor';
import { requestBasePathInterceptor } from '@/interceptors/request-base-path-interceptor';
import { requestBearerTokenInterceptor } from '@/interceptors/request-bearer-token-interceptor';
import { requestParamsSerializerInterceptor } from '@/interceptors/request-params-serializer-interceptor';
import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${import.meta.env.VITE_URL}`,
});

/*
 * Create Base Url Path
 */
apiClient.interceptors.request.use(requestBasePathInterceptor);

/*
 * Attach /api/accounts/uuid/ as prefix to internal urls
 */
apiClient.interceptors.request.use(requestApiInterceptor);

/*
 * Attach Bearer token and content type to external requests request
 */
apiClient.interceptors.request.use(requestBearerTokenInterceptor);

/*
 * Apply params serializer
 */
apiClient.interceptors.request.use(requestParamsSerializerInterceptor);

export default apiClient;
