import * as types from './types';

const mutations = {
  // FETCH_OP_LIST
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialPending = true;
    state.opList = [];
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialPending = false;
  },
  [types.FETCH_OP_LIST_PENDING](state) {
    state.opListPending = true;
  },
  [types.FETCH_OP_LIST_FULFILLED](state, { data }) {
    state.opList = data;
    state.opListPending = false;
  },
  [types.FETCH_TABLE_META](state, { meta, key }) {
    if (meta) {
      state.meta[key] = {
        sortDesc: [meta.direction === 'DESC'],
        itemsPerPage: parseInt(meta.per_page, 10),
        sortBy: [meta.sort],
        page: parseInt(meta.page, 10),
        search: meta.search,
        extendSearch: meta.extend_search,
        maxPage: parseInt(meta.max_page, 10),
        totalItems: meta.max_page * meta.per_page,
        totals: meta.totals,
        headers: meta.headers,
      };
    }
  },
  [types.FETCH_OP_LIST_ERROR](state) {
    state.opListPending = true;
  },

  // PDF Export
  [types.FETCH_OP_LIST_PDF_EXPORT](state) {
    state.isPdfExporting = true;
  },
  [types.FETCH_OP_LIST_PDF_EXPORT_SUCCESS](state) {
    state.isPdfExporting = false;
  },
  [types.FETCH_OP_LIST_PDF_EXPORT_FAILURE](state) {
    state.isPdfExporting = false;
  },

  // XLS Export
  [types.FETCH_OP_LIST_XLS_EXPORT](state) {
    state.isXlsExporting = true;
  },
  [types.FETCH_OP_LIST_XLS_EXPORT_SUCCESS](state) {
    state.isXlsExporting = false;
  },
  [types.FETCH_OP_LIST_XLS_EXPORT_FAILURE](state) {
    state.isXlsExporting = false;
  },

  // PDF Export for single Address
  [types.FETCH_ADDRESS_OP_LIST_PDF_EXPORT](state) {
    state.isAddressPdfExporting = true;
  },
  [types.FETCH_ADDRESS_OP_LIST_PDF_EXPORT_SUCCESS](state) {
    state.isAddressPdfExporting = false;
  },
  [types.FETCH_ADDRESS_OP_LIST_PDF_EXPORT_FAILURE](state) {
    state.isAddressPdfExporting = false;
  },

  // DeepBox Upload
  [types.POST_OP_LIST_DEEP_BOX](state) {
    state.isPostingToDeepBox = true;
  },
  [types.POST_OP_LIST_DEEP_BOX_SUCCESS](state) {
    state.isPostingToDeepBox = false;
  },
  [types.POST_OP_LIST_DEEP_BOX_FAILURE](state) {
    state.isPostingToDeepBox = false;
  },
};

export default mutations;
