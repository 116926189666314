import axios from 'axios';
import * as types from './types';

export default {
  fetchPermissions({ commit }, { abortController, accountUuid }) {
    const controller = abortController || new AbortController();
    commit(types.FETCH_PERMISSIONS);
    return axios.get(`/accounts/${accountUuid}/definitions/permissions`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_PERMISSIONS_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        commit(types.FETCH_PERMISSIONS_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_PERMISSIONS_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
