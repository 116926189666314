import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    productPending: false,
    productKeyValidationPending: false,
    productStorePending: false,
    productArchivePending: false,
    productRestorePending: false,
    productDeletePending: false,
    product: {},
  },
  actions,
  mutations,
};
