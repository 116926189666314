import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchCompanies({ rootState, commit }) {
    try {
      commit(types.FETCH_COMPANIES_PENDING, true);
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/companies`);
      commit(types.FETCH_COMPANIES, data);
      commit(types.FETCH_COMPANIES_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_COMPANIES_PENDING, false);
      return Promise.reject(error);
    }
  },
  async setCompany({ rootState, commit }, companyUuid) {
    try {
      commit(types.FETCH_COMPANIES_PENDING, true);
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/companies/switch/${companyUuid}`);
      sessionStorage.setItem('accountUuid', data.uuid);
      sessionStorage.setItem('appInstanceUuid', data.app_instance_uuid);
      localStorage.setItem('accountUuid', data.uuid);
      localStorage.setItem('appInstanceUuid', data.app_instance_uuid);
      window.location.href = '/dashboard';
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_COMPANIES_PENDING, false);
      return Promise.reject(error);
    }
  },
};

export default actions;
