import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialLoading: false,
    initialError: false,
    bankAccountPending: false,
    fetchBankAccountPending: false,
    storeBankAccountPending: false,
    bankAccount: {},
    deleteBankAccountPending: false,
    bicNumberValidationPending: false,
    fetchBankInformationPending: false,
    bankInformation: {},
    loginEbankingPending: false,
    loginEbankingSuccessful: false,
    loginEbankingChangePassword: false,
    loginEbankingChoose: false,
    loginEbankingConfirmationSuccessful: false,
    ebankingLogin: {},
    blinkLoginPending: false,
    oauthLoginPending: false,
    storeOauthAuthorizationPending: false,
    storeOauthCallbackBlinkPending: false,
    pushbankingPaymentsPending: false,
    ebankingPayments: {},
    fetchEbankingTransactionsPending: false,
    ebankingTransactions: {},
    ebankingTransactionsError: false,
    syncEbankingPending: false,
    syncEbankingPendingIds: [],
    confirmEbankingPaymentsPending: false,
    logoutEbankingPending: false,
    earliestBookingDate: '',
    checkAccountNumberPending: false,
    validateQrAccountNumberPending: false,
    validateQRBesrIdPending: false,
    accountNumberValidationPending: false,
    oauthLogoutPending: false,
  },
  actions,
  mutations,
};
