import type { Location, RouteConfig } from 'vue-router/types/router';
import { DocumentType } from '@/components/Document/types/document-type';

type DocumentTypeWithPlural = DocumentType | keyof { [key in DocumentType as `${key}s`]: string }

const typeMap: Record<DocumentTypeWithPlural, string> = {
  // Customers
  quote: 'quotes-list',
  quotes: 'quotes-list',
  contract_note: 'contract-notes-list',
  contract_notes: 'contract-notes-list',
  delivery_note: 'delivery-notes-list',
  delivery_notes: 'delivery-notes-list',
  invoice: 'invoices-list',
  invoices: 'invoices-list',
  credit_note: 'credit-notes-list',
  credit_notes: 'credit-notes-list',
  recurring_invoice: 'recurring-invoices-list',
  recurring_invoices: 'recurring-invoices-list',
  template: 'templates-list',
  templates: 'templates-list',
  supplier_credit_note: 'supplier-credit-notes-list',
  supplier_credit_notes: 'supplier-credit-notes-list',

  // Suppliers
  receipt: 'receipts-list',
  receipts: 'receipts-list',
};

export function getDocumentRoute(
  type: DocumentTypeWithPlural = DocumentType.INVOICE,
  group = 'open',
  query: Location['query'] = undefined,
): Location {
  return {
    name: typeMap[type],
    params: {
      group,
    },
    query,
  };
}

const documentRoutes: Array<RouteConfig> = [{
  path: '/documents/:type/:group?',
  name: 'documents',
  beforeEnter(to, _, next) {
    next(getDocumentRoute(to.params.type as DocumentTypeWithPlural, to.params.group));
  },
}, {
  path: '/document/:documentUuid',
  name: 'document-resolve',
  component: () => import('@/pages/documents/DocumentResolver.vue'),
  props: true,
}];

export default documentRoutes;
