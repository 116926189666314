import axios from 'axios';
import * as types from './types';

export default {
  fetchInitial({ commit, dispatch }, {
    deadline, opType, metaKey, meta,
  }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      dispatch('fetchOpList', {
        deadline, opType, metaKey, meta,
      });
      commit(types.FETCH_INITIAL_FULFILLED);
    } catch (error) {
      commit(types.FETCH_INITIAL_FULFILLED);
    }
  },

  async fetchOpList({ rootState, state, commit }, {
    deadline, opType, metaKey, meta,
  }) {
    commit(types.FETCH_OP_LIST_PENDING);
    try {
      let params = {
        deadline,
      };
      const metaValue = meta || state.meta[metaKey];
      if (metaValue) {
        params = {
          direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy[0],
          page: metaValue.page,
          search: metaValue.search,
          deadline,
        };
      }
      const url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/open_items/${opType}`;
      const data = await axios.get(url, { params });
      if (data.meta) {
        if (metaValue) {
          data.meta.search = metaValue.search; // keep current search params
        }
        commit(types.FETCH_TABLE_META, { meta: data.meta, key: metaKey });
      }
      commit(types.FETCH_OP_LIST_FULFILLED, data);
    } catch (error) {
      commit(types.FETCH_OP_LIST_ERROR, error);
    }
  },

  fetchOpListPdfExport({ rootState, state, commit }, { deadline, opType, metaKey }) {
    commit(types.FETCH_OP_LIST_PDF_EXPORT);

    const metaValue = state.meta[metaKey];
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/open_items/${opType}`, {
      responseType: 'blob',
      params: {
        direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
        sort: metaValue.sortBy[0],
        search: metaValue.search,
        export: 'pdf',
        per_page: 'all',
        deadline,
      },
    }).then((response) => {
      commit(types.FETCH_OP_LIST_PDF_EXPORT_SUCCESS);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.FETCH_OP_LIST_PDF_EXPORT_FAILURE);
      return Promise.reject(error);
    });
  },

  fetchOpListXlsExport({ rootState, state, commit }, { deadline, opType, metaKey }) {
    commit(types.FETCH_OP_LIST_XLS_EXPORT);

    const metaValue = state.meta[metaKey];
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/open_items/${opType}`, {
      responseType: 'blob',
      params: {
        direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
        sort: metaValue.sortBy[0],
        search: metaValue.search,
        export: 'xls',
        per_page: 'all',
        deadline,
      },
    }).then((response) => {
      commit(types.FETCH_OP_LIST_XLS_EXPORT_SUCCESS);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.FETCH_OP_LIST_XLS_EXPORT_FAILURE);
      return Promise.reject(error);
    });
  },

  fetchAddressOpListPdfExport({ rootState, commit }, { deadline, opType, addressUuid }) {
    commit(types.FETCH_ADDRESS_OP_LIST_PDF_EXPORT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/open_items/${opType}/${addressUuid}`, {
      responseType: 'blob',
      params: {
        export: 'pdf',
        per_page: 'all',
        deadline,
      },
    }).then((response) => {
      commit(types.FETCH_ADDRESS_OP_LIST_PDF_EXPORT_SUCCESS);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.FETCH_ADDRESS_OP_LIST_PDF_EXPORT_FAILURE);
      return Promise.reject(error);
    });
  },

  postOpListDeepBox({ rootState, state, commit }, { deadline, opType, metaKey }) {
    commit(types.POST_OP_LIST_DEEP_BOX);
    const metaValue = state.meta[metaKey];

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/open_items/${opType}`, {
      params: {
        direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
        sort: metaValue.sortBy[0],
        search: metaValue.search,
        export: 'deepcloud_pdf',
        per_page: 'all',
        deadline,
      },
    }).then((response) => {
      commit(types.POST_OP_LIST_DEEP_BOX_SUCCESS);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.POST_OP_LIST_DEEP_BOX_FAILURE);
      return Promise.reject(error);
    });
  },
};
