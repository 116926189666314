export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_DESIGNS = 'SET_DESIGNS';
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const SET_VIEW_SELECT = 'SET_VIEW_SELECT';
export const SHOW_NEXT_PAGE = 'SHOW_NEXT_PAGE';

export const CHANGE_TEMPLATE_PENDING = 'CHANGE_TEMPLATE_PENDING';
export const CHANGE_TEMPLATE_FULFILLED = 'CHANGE_TEMPLATE_FULFILLED';
export const CHANGE_TEMPLATE_ERROR = 'CHANGE_TEMPLATE_ERROR';

export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_STANDARD_TEMPLATE = 'SET_STANDARD_TEMPLATE';
export const TRIGGER_NEXT_PAGE = 'TRIGGER_NEXT_PAGE';

export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_TEMPLATES_PENDING = 'FETCH_TEMPLATES_PENDING';
export const FETCH_TEMPLATES_FULFILLED = 'FETCH_TEMPLATES_FULFILLED';
export const FETCH_TEMPLATES_ERROR = 'FETCH_TEMPLATES_ERROR';

export const FETCH_DESIGNS_PENDING = 'FETCH_DESIGNS_PENDING';
export const FETCH_DESIGNS_FULFILLED = 'FETCH_DESIGNS_FULFILLED';
export const FETCH_DESIGNS_ERROR = 'FETCH_DESIGNS_ERROR';

export const FETCH_DESIGN_PENDING = 'FETCH_DESIGN_PENDING';
export const FETCH_DESIGN_FULFILLED = 'FETCH_DESIGN_FULFILLED';
export const FETCH_DESIGN_ERROR = 'FETCH_DESIGN_ERROR';

export const CHANGE_DESIGN_PENDING = 'CHANGE_DESIGN_PENDING';
export const CHANGE_DESIGN_FULFILLED = 'CHANGE_DESIGN_FULFILLED';
export const CHANGE_DESIGN_ERROR = 'CHANGE_DESIGN_ERROR';

export const STORE_DESIGN_PENDING = 'STORE_DESIGN_PENDING';
export const STORE_DESIGN_FULFILLED = 'STORE_DESIGN_FULFILLED';
export const STORE_DESIGN_ERROR = 'STORE_DESIGN_ERROR';

export const CHANGE_SETTING = 'CHANGE_SETTING';
