const PassThrough = () => import('@/views/PassThrough.vue');
const ChartsAndReports = () => import('@/pages/reports/ChartsAndReports.vue');

export default {
  path: '/reports',
  name: 'more',
  component: PassThrough,
  redirect: { name: 'more-default' },
  children: [
    {
      path: '',
      name: 'more-default',
      redirect: { name: 'ChartsAndReports' },
    },
    {
      path: 'charts_and_reports/:reportType?',
      name: 'ChartsAndReports',
      component: ChartsAndReports,
    },
  ],
};
