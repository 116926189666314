export const CONNECT_DEEP_CLOUD_BANK_ACCOUNT = 'CONNECT_DEEP_CLOUD_BANK_ACCOUNT';
export const CONNECT_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS = 'CONNECT_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS';
export const CONNECT_DEEP_CLOUD_BANK_ACCOUNT_CANCEL = 'CONNECT_DEEP_CLOUD_BANK_ACCOUNT_CANCEL';
export const CONNECT_DEEP_CLOUD_BANK_ACCOUNT_FAILURE = 'CONNECT_DEEP_CLOUD_BANK_ACCOUNT_FAILURE';

export const CHECK_DEEP_CLOUD_BANK_ACCOUNT = 'CHECK_DEEP_CLOUD_BANK_ACCOUNT';
export const CHECK_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS = 'CHECK_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS';
export const CHECK_DEEP_CLOUD_BANK_ACCOUNT_FAILURE = 'CHECK_DEEP_CLOUD_BANK_ACCOUNT_FAILURE';

export const LOGIN_DEEP_CLOUD_BANK_ACCOUNT = 'LOGIN_DEEP_CLOUD_BANK_ACCOUNT';
export const LOGIN_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS = 'LOGIN_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS';
export const LOGIN_DEEP_CLOUD_BANK_ACCOUNT_FAILURE = 'LOGIN_DEEP_CLOUD_BANK_ACCOUNT_FAILURE';

export const SYNC_DEEP_CLOUD_BANK_ACCOUNT = 'SYNC_DEEP_CLOUD_BANK_ACCOUNT';
export const SYNC_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS = 'SYNC_DEEP_CLOUD_BANK_ACCOUNT_SUCCESS';
export const SYNC_DEEP_CLOUD_BANK_ACCOUNT_FAILURE = 'SYNC_DEEP_CLOUD_BANK_ACCOUNT_FAILURE';
