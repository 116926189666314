import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    fetchListPending: false,
    list: [],
    meta: {},
    fetchBulkActionsPending: false,
    bulkActions: [],
    storeActionPending: false,
    storeBulkPending: false,
    storeSigningPending: false,
    fetchSigningUsersPending: false,
    signingUsers: [],
    inboxCount: 0,
  },
  mutations,
  actions,
};
