export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  accountPlan: {
    structure: [],
  },
  isImportingAccountPlanJson: false,
  isImportingAccountPlanAmid: false,
  isGeneratingAccountPlanFromTemplate: false,
  isDeletingAccountPlan: false,

  isLoadingClassifications: false,
  classifications: [],

  isLoadingAccountPlanMaster: false,
  accountPlanMaster: [],

  isLoadingAccountPlanPdfExport: false,
  isLoadingAccountPlanXlsExport: false,
};
