import actions from '@/store/modules/accounting/base-types/actions';
import mutations from '@/store/modules/accounting/base-types/mutations';
import initialState from '@/store/modules/accounting/base-types/state';

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
};
