import type { Dictionary } from 'vue-router/types/router';
import { useI18n } from '@/helpers/i18n';
import { computed, type ComputedRef, type Ref, ref } from 'vue';

export interface NavigationItem {
  title: ComputedRef<string>;
  route: string;
  icon?: string;
  active?: Ref<boolean>;
  exact?: boolean;
  params?: Dictionary<string>;
  children?: Array<NavigationItem>;
  query?: Dictionary<string>;
  beta?: boolean;
}

const i18n = useI18n();

export const NAVIGATION_ITEMS: Array<NavigationItem> = [
  {
    icon: 'swiss21-dashboard',
    title: computed(() => i18n.tc('texts.dashboard')),
    route: 'dashboard',
    exact: true,
    active: ref(false),
  },
  {
    icon: 'swiss21-addresses',
    title: computed(() => i18n.tc('texts.addresses')),
    route: 'addresses',
    active: ref(false),
  },
  {
    active: ref(false),
    icon: 'swiss21-customers',
    title: computed(() => i18n.tc('texts.customers')),
    route: 'customers',
    children: [
      {
        title: computed(() => i18n.tc('texts.quotes')),
        route: 'quotes-list',
      },
      {
        title: computed(() => i18n.tc('texts.contract_notes')),
        route: 'contract-notes-list',
      },
      {
        title: computed(() => i18n.tc('texts.delivery_notes')),
        route: 'delivery-notes-list',
      },
      {
        title: computed(() => i18n.tc('texts.invoices')),
        route: 'invoices-list',
      },
      {
        title: computed(() => i18n.tc('texts.credit_notes')),
        route: 'credit-notes-list',
      },
      {
        title: computed(() => i18n.tc('texts.recurring_invoices')),
        route: 'recurring-invoices-list',
      },
      {
        title: computed(() => i18n.tc('texts.templates')),
        route: 'templates-list',
      },
    ],
  },
  {
    active: ref(false),
    icon: 'swiss21-suppliers',
    title: computed(() => i18n.tc('texts.vendors')),
    route: 'vendors',
    params: { type: 'receipts' },
    children: [
      {
        title: computed(() => i18n.tc('texts.receipt', 2)),
        route: 'receipts-list',
      },
      {
        title: computed(() => i18n.tc('texts.supplier_credit_notes')),
        route: 'supplier-credit-notes',
      },
    ],
  },
  {
    active: ref(false),
    icon: 'swiss21-finances',
    title: computed(() => i18n.tc('texts.finances')),
    route: 'payments',
    children: [
      {
        title: computed(() => i18n.tc('texts.payments')),
        route: 'payments-list',
      },
      {
        title: computed(() => i18n.tc('texts.banking')),
        route: 'finances-banking',
      },
      {
        title: computed(() => i18n.tc('texts.creditcards')),
        route: 'creditcard',
      },
      {
        title: computed(() => i18n.tc('texts.payment_systems')),
        route: 'payment-systems',
      },
    ],
  },
  {
    icon: 'swiss21-products',
    title: computed(() => i18n.tc('texts.products')),
    route: 'products',
    active: ref(false),
    children: [
      {
        title: computed(() => i18n.tc('texts.products')),
        route: 'product-list',
      },
      {
        title: computed(() => i18n.tc('texts.product_groups')),
        route: 'product-group',
      },
    ],
  },
  {
    icon: 'swiss21-time',
    title: computed(() => i18n.tc('texts.time_tracking')),
    route: 'time',
    active: ref(false),
    children: [
      {
        title: computed(() => i18n.tc('texts.employees')),
        route: 'employees',
      },
      {
        title: computed(() => i18n.tc('texts.employee_groups')),
        route: 'employee-groups',
      },
    ],
  },
  {
    icon: 'swiss21-accounting',
    title: computed(() => i18n.tc('texts.accounting')),
    route: 'accounting',
    active: ref(false),
    children: [
      {
        title: computed(() => i18n.tc('texts.account_statement')),
        route: 'account-statement',
      },
      {
        title: computed(() => i18n.tc('texts.journal')),
        route: 'journal',
      },
      {
        title: computed(() => i18n.tc('texts.report')),
        route: 'accounting-report',
        active: ref(false),
        children: [
          {
            title: computed(() => i18n.tc('texts.op')),
            route: 'open-items',
          },
          {
            title: computed(() => i18n.tc('texts.opening_balance_title')),
            route: 'opening-balance',
          },
          {
            title: computed(() => i18n.tc('texts.balance_sheet_title')),
            route: 'balance-sheet',
          },
          {
            title: computed(() => i18n.tc('texts.income_statement_title')),
            route: 'income-statement',
          },
          {
            title: computed(() => i18n.tc('texts.key_figures')),
            route: 'key-figures',
          },
        ],
      },
      {
        title: computed(() => i18n.tc('texts.vat_report')),
        route: 'accounting-vat-report',
        active: ref(false),
        children: [
          {
            title: computed(() => i18n.tc('texts.vat_journal')),
            route: 'vat-journal',
          },
          {
            title: computed(() => i18n.tc('texts.vat_control_report')),
            route: 'vat-control-report',
          },
          {
            title: computed(() => i18n.tc('texts.vat_adjustment')),
            route: 'vat-adjustment',
          },
        ],
      },
    ],
  },
  {
    icon: 'swiss21-deepv',
    title: computed(() => i18n.tc('texts.deepv')),
    route: 'deepv',
    active: ref(false),
  },
  {
    icon: 'swiss21-more',
    title: computed(() => i18n.tc('texts.more')),
    active: ref(false),
    route: 'more',
    children: [
      {
        title: computed(() => i18n.tc('texts.charts_and_reports')),
        route: 'more',
      },
    ],
  },
];
