import type { InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';

export const requestParamsSerializerInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (!config.method || !['GET', 'DELETE'].includes(config.method.toUpperCase())) {
    return config;
  }

  return {
    ...config,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  };
};
