import axios from 'axios';
import Vue from 'vue';

const INITIALIZE_PENDING = 'INITIALIZE_PENDING';
const INITIALIZE_FULFILLED = 'INITIALIZE_FULFILLED';
const INITIALIZE_ERROR = 'INITIALIZE_ERROR';

const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

const LOGOUT_PENDING = 'LOGOUT_PENDING';
const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
const LOGOUT_ERROR = 'LOGOUT_ERROR';

const FETCH_NOTIFICATIONS_PENDING = 'FETCH_NOTIFICATIONS_PENDING';
const FETCH_NOTIFICATIONS_FULFILLED = 'FETCH_NOTIFICATIONS_FULFILLED';
const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

export default {
  namespaced: true,
  state: {
    initializePending: true,
    fetchUserPending: true,
    logoutPending: false,
    fetchNotificationPending: false,
    authenticated: false,
    user: {
      id: null,
      first_name: '',
      last_name: '',
      email: '',
      language: '',
      isOwner: false,
      roles: null,
      accounts: [],
    },
    notifications: [],
    token: '',
  },
  mutations: {
    // INITIALIZE
    [INITIALIZE_PENDING](state) {
      state.initializePending = true;
    },
    [INITIALIZE_FULFILLED](state) {
      state.initializePending = false;
    },
    [INITIALIZE_ERROR](state) {
      state.initializePending = false;
    },
    // INITIALIZE
    [FETCH_USER_PENDING](state) {
      state.fetchUserPending = true;
    },
    [FETCH_USER_FULFILLED](state, { data }) {
      state.user = data;
      state.fetchUserPending = false;
    },
    [FETCH_USER_ERROR](state) {
      state.fetchUserPending = false;
    },
    // LOGOUT
    [LOGOUT_PENDING](state) {
      state.logoutPending = true;
    },
    [LOGOUT_FULFILLED](state) {
      state.logoutPending = false;
    },
    [LOGOUT_ERROR](state) {
      state.logoutPending = false;
    },
    // NOTIFICATIONS
    [FETCH_NOTIFICATIONS_PENDING](state) {
      state.fetchNotificationPending = true;
    },
    [FETCH_NOTIFICATIONS_FULFILLED](state, { data }) {
      state.notifications = data;
      state.fetchNotificationPending = false;
    },
    [FETCH_NOTIFICATIONS_ERROR](state) {
      state.fetchNotificationPending = false;
    },
  },
  actions: {
    async initialize({ commit, dispatch, rootState }) {
      commit(INITIALIZE_PENDING);
      try {
        const [definitions] = await Promise.all([
          dispatch('definitions/fetchDefinitions', null, { root: true }),
          dispatch('basics/fetchBasics', rootState.definitions.account.account.locale, { root: true }),
        ]);

        if (definitions.leansync_active) {
          dispatch('leansync/fetchLeansync', null, { root: true });
        }

        dispatch('inbox/fetchInboxCount', null, { root: true }).catch(() => {});
        dispatch('startRefreshInboxCountInterval');
        commit(INITIALIZE_FULFILLED);
        return Promise.resolve({ hasAccount: true });
      } catch (error) {
        commit(INITIALIZE_ERROR);
        return Promise.reject(error);
      }
    },
    startRefreshInboxCountInterval({ dispatch, rootState }) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(() => {
        if (rootState.definitions.isLoggedIn) {
          dispatch('inbox/fetchInboxCount', null, { root: true }).catch(() => {});
        } else {
          clearInterval(this.refreshInterval);
        }
      }, 30 * 1000);
    },
    async fetchUser({ commit }) {
      commit(FETCH_USER_PENDING, true);
      try {
        const response = await axios.get('/user');
        commit(FETCH_USER_FULFILLED, response);
        return response.data;
      } catch (error) {
        commit(FETCH_USER_ERROR, false);
        return Promise.reject(error);
      }
    },
    async fetchNotifications({ commit }) {
      commit(FETCH_NOTIFICATIONS_PENDING, true);
      try {
        const response = await axios.get('/notifications');
        commit(FETCH_NOTIFICATIONS_FULFILLED, response);
        return response.data;
      } catch (error) {
        commit(FETCH_NOTIFICATIONS_ERROR, false);
        return Promise.reject(error);
      }
    },
    async notificationRead() {
      await axios.patch('/notifications/read');
    },
    async logout({ commit }) {
      commit(LOGOUT_PENDING);
      try {
        await axios.post('/logout');
        sessionStorage.removeItem('kc_token');
        sessionStorage.removeItem('kc_refreshToken');
        const url = new URL(window.location);
        Vue.$keycloak.logout({ redirectUri: `${url.origin}/oauthcb` });
        commit(LOGOUT_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(LOGOUT_ERROR);
        return Promise.reject(error);
      }
    },
  },
};
