export default {
  isCompanyAddress: (state) => !!state.address && !!state.address.company,
  addressUser: (state, getters) => {
    if (state.address) {
      return getters.isCompanyAddress ? state.address.company : state.address.person;
    }
    return null;
  },
  addressLanguage: (state, getters, rootState, rootGetters) => (getters.addressUser
    ? rootState.basics.languages.find((lang) => lang.id === getters.addressUser.language_id)
    : rootGetters['basics/currentMainLanguage']),
};
