import axios from 'axios';
import qs from 'qs';
import address from './address';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_BULK_ACTIONS_FULFILLED = 'FETCH_BULK_ACTIONS_FULFILLED';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';
const FETCH_TEMPLATES_FULFILLED = 'FETCH_TEMPLATES_FULFILLED';
const FETCH_TABLE_META = 'FETCH_TABLE_META';

const FETCH_ACTION_PENDING = 'FETCH_ACTION_PENDING';
const FETCH_ACTION_FULFILLED = 'FETCH_ACTION_FULFILLED';
const FETCH_ACTION_ERROR = 'FETCH_ACTION_ERROR';

const SEARCH_LIST_PENDING = 'SEARCH_LIST_PENDING';
const SEARCH_LIST_FULFILLED = 'SEARCH_LIST_FULFILLED';
const SEARCH_LIST_ERROR = 'SEARCH_LIST_ERROR';

const FETCH_TAG_PENDING = 'FETCH_TAG_PENDING';
const FETCH_TAG = 'FETCH_TAG';

export default {
  namespaced: true,
  state: {
    bulkActions: [],
    initialPending: false,
    listPending: false,
    list: [],
    tagsPending: false,
    tags: [],
    meta: {},
    searchListPending: false,
    templates: [],
    fetchActionPending: false,
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
      state.list = [];
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.list = [];
      state.initialPending = false;
    },
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_TAG_PENDING](state, isPending) {
      state.tagsPending = isPending;
    },
    [FETCH_TAG](state, { data }) {
      state.tags = data;
    },
    [FETCH_TABLE_META](state, { meta, key }) {
      if (meta) {
        state.meta[key] = {
          sortDesc: [meta.direction === 'DESC'],
          itemsPerPage: parseInt(meta.per_page, 10),
          sortBy: [meta.sort],
          page: parseInt(meta.page, 10),
          search: meta.search,
          maxPage: parseInt(meta.max_page, 10),
          totalItems: meta.max_page * meta.per_page,
          extendSearch: meta.extend_search,
        };
      }
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    [FETCH_TEMPLATES_FULFILLED](state, { data }) {
      state.templates = data;
    },
    [FETCH_BULK_ACTIONS_FULFILLED](state, { data }) {
      state.bulkActions = Object.keys(data).map((key) => ({ id: key, text: data[key] }));
    },
    [FETCH_ACTION_PENDING](state) {
      state.fetchActionPending = true;
      state.listPending = true;
    },
    [FETCH_ACTION_FULFILLED](state) {
      state.fetchActionPending = false;
      state.listPending = false;
    },
    [FETCH_ACTION_ERROR](state) {
      state.fetchActionPending = false;
      state.listPending = false;
    },
    [SEARCH_LIST_PENDING](state) {
      state.searchListPending = true;
    },
    [SEARCH_LIST_FULFILLED](state) {
      state.searchListPending = false;
    },
    [SEARCH_LIST_ERROR](state) {
      state.searchListPending = false;
    },
  },
  actions: {
    async fetchInitial({ rootState, commit }) {
      commit(FETCH_INITIAL_PENDING);
      try {
        const [bulkActions, templates] = await Promise.all([
          axios.get(`/accounts/${rootState.definitions.account.account.uuid}/addresses/bulkactions`),
          axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/list/template`),
        ]);

        commit(FETCH_BULK_ACTIONS_FULFILLED, bulkActions);
        commit(FETCH_TEMPLATES_FULFILLED, templates);
        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve({ bulkActions, templates });
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, state, commit }, { archive, meta }) {
      commit(FETCH_LIST_PENDING);

      try {
        let params = {};
        const metaKey = archive ? 'addressarchived' : 'addresslist';
        let metaValue = null;
        if (meta !== null) {
          metaValue = meta || state.meta[metaKey];
        }
        const search = [];

        if (metaValue) {
          const extendSearchTags = {
            tags_OR: metaValue.search.filter(
              (filterValue) => {
                const hasFilter = state.tags.find(
                  (tag) => tag.tag === filterValue,
                );
                if (!hasFilter) {
                  search.push(filterValue);
                }
                return hasFilter;
              },
            ).join(','),
          };

          params = {
            direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy[0],
            page: metaValue.page,
            extend_search: {
              ...metaValue.extendSearch,
              ...extendSearchTags,
            },
            search,
          };
        }

        let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/addresses`;
        url = archive ? `${url}/archived` : url;

        const data = await axios.get(url, {
          params,
          paramsSerializer: (item) => qs.stringify(item, { arrayFormat: 'brackets' }),
        });

        if (data.meta) {
          if (metaValue) {
            data.meta.search = metaValue.search; // keep current search params
          }
          commit(FETCH_TABLE_META, { meta: data.meta, key: metaKey });
        }
        commit(FETCH_LIST_FULFILLED, data);
        return Promise.resolve(data);
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchBulk({ rootState, commit, dispatch }, { params }) {
      commit(FETCH_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/addresses/bulk`, params);
        dispatch('fetchList', { archive: params.isArchive });
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
        return Promise.resolve(response);
      } catch ({ data }) {
        commit(FETCH_ACTION_ERROR, data);
        // TODO: harmonize error message object key
        const message = data.data || data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(data);
      }
    },
    async fetchSingle({ rootState, commit, dispatch }, params) {
      commit(FETCH_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/addresses/action`, params);
        dispatch('fetchList', { archive: params.isArchive });
        commit(FETCH_ACTION_FULFILLED);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_ACTION_ERROR, error);
        // TODO: harmonize error message object key
        const message = error.data || error.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async searchList({ rootState, commit }, params) {
      commit(SEARCH_LIST_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/addresses/search`, params);
        commit(SEARCH_LIST_FULFILLED);
        return Promise.resolve(response.data);
      } catch (error) {
        commit(SEARCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async deleteTag({ commit, dispatch, rootState }, { id, meta, metaKey }) {
      commit(FETCH_TAG_PENDING, true);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/tags/addresses/${id}`;
        await axios.delete(url);
        dispatch('fetchTags', { meta, metaKey });
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_TAG_PENDING, false);
        return Promise.reject(error);
      }
    },
    async fetchTags({ rootState, state, commit }, { meta, metaKey }) {
      commit(FETCH_TAG_PENDING, true);
      try {
        let params = {};
        const metaValue = meta || state.meta[metaKey];
        if (metaValue) {
          params = {
            direction: metaValue.sortDesc && metaValue.sortDesc[0] ? 'DESC' : 'ASC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy ? metaValue.sortBy[0] : undefined,
            page: metaValue.page,
            search: metaValue.search,
          };
        }
        const url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/settings/tags/addresses`;
        const data = await axios.get(url, { params });
        if (data.meta) {
          commit(FETCH_TABLE_META, { meta: data.meta, key: metaKey });
        }
        commit(FETCH_TAG, data);
        commit(FETCH_TAG_PENDING, false);
        return Promise.resolve(data);
      } catch (error) {
        commit(FETCH_TAG_PENDING, false);
        return Promise.reject(error);
      }
    },
    async updateTag({ rootState, commit, dispatch }, { tag, metaKey }) {
      commit(FETCH_TAG_PENDING, true);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/tags/addresses/${tag.id}`;
        await axios.patch(url, tag);
        await dispatch('fetchTags', { metaKey });
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_TAG_PENDING, false);
        return Promise.reject(error);
      }
    },
  },
  modules: {
    address,
  },
};
