// FETCH_INITIAL
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

// FETCH_USERS
export const FETCH_SIGNING_USERS_FULFILLED = 'FETCH_SIGNING_USERS_FULFILLED';
export const FETCH_DUPLICATE_ADDRESSES_FULFILLED = 'FETCH_DUPLICATE_ADDRESSES_FULFILLED';
export const FETCH_PROJECT_ADDRESS_FULFILLED = 'FETCH_PROJECT_ADDRESS_FULFILLED';

// FETCH_ADDRESS
export const FETCH_ADDRESS_PENDING = 'FETCH_ADDRESS_PENDING';
export const FETCH_ADDRESS_FULFILLED = 'FETCH_ADDRESS_FULFILLED';
export const FETCH_ADDRESS_ERROR = 'FETCH_ADDRESS_ERROR';

// IMPORT_RECEIPT
export const IMPORT_RECEIPT_PENDING = 'IMPORT_RECEIPT_PENDING';
export const IMPORT_RECEIPT_FULFILLED = 'IMPORT_RECEIPT_FULFILLED';
export const IMPORT_RECEIPT_ERROR = 'IMPORT_RECEIPT_ERROR';
export const FETCH_IMPORT_RECEIPT_QUEUE = 'FETCH_IMPORT_INVOICE_QUEUE';

// FETCH_RECEIPT
export const FETCH_RECEIPT_PENDING = 'FETCH_RECEIPT_PENDING';
export const FETCH_RECEIPT_FULFILLED = 'FETCH_RECEIPT_FULFILLED';
export const FETCH_RECEIPT_ERROR = 'FETCH_RECEIPT_ERROR';

// STORE_RECEIPT
export const STORE_RECEIPT_PENDING = 'STORE_RECEIPT_PENDING';
export const STORE_RECEIPT_FULFILLED = 'STORE_RECEIPT_FULFILLED';
export const STORE_RECEIPT_ERROR = 'STORE_RECEIPT_ERROR';
export const RESET_RECEIPT = 'RESET_RECEIPT';

// STORE_ACTION
export const STORE_ACTION_PENDING = 'STORE_ACTION_PENDING';
export const STORE_ACTION_FULFILLED = 'STORE_ACTION_FULFILLED';
export const STORE_ACTION_ERROR = 'STORE_ACTION_ERROR';

// FETCH_PRODUCT_RECOGNITION
export const FETCH_PRODUCT_RECOGNITION_PENDING = 'FETCH_PRODUCT_RECOGNITION_PENDING';
export const FETCH_PRODUCT_RECOGNITION_FULFILLED = 'FETCH_PRODUCT_RECOGNITION_FULFILLED';
export const FETCH_PRODUCT_RECOGNITION_ERROR = 'FETCH_PRODUCT_RECOGNITION_ERROR';
