import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    productGroup: {},
    productGroupPending: false,
    productGroupStorePending: false,
    productGroupNumberValidationPending: false,
    productGroupArchivePending: false,
    productGroupRestorePending: false,
  },
  actions,
  mutations,
};
