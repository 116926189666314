export const SETUP_DEEPCLOUD_BANKACCOUNTS_PENDING = 'SETUP_DEEPCLOUD_BANKACCOUNTS_PENDING';
export const SETUP_DEEPCLOUD_BANKACCOUNTS_FULFILLED = 'SETUP_DEEPCLOUD_BANKACCOUNTS_FULFILLED';
export const SETUP_DEEPCLOUD_BANKACCOUNTS_ERROR = 'SETUP_DEEPCLOUD_BANKACCOUNTS_ERROR';

export const DEEPCLOUD_LOGIN_PENDING = 'DEEPCLOUD_LOGIN_PENDING';
export const DEEPCLOUD_LOGIN_FULFILLED = 'DEEPCLOUD_LOGIN_FULFILLED';
export const DEEPCLOUD_LOGIN_ERROR = 'DEEPCLOUD_LOGIN_ERROR';

export const DEEPCLOUD_SYNC_PENDING = 'DEEPCLOUD_SYNC_PENDING';
export const DEEPCLOUD_SYNC_FULFILLED = 'DEEPCLOUD_SYNC_FULFILLED';
export const DEEPCLOUD_SYNC_ERROR = 'DEEPCLOUD_SYNC_ERROR';

export const REMOVE_DEEPCLOUD_CONNECTION_PENDING = 'REMOVE_DEEPCLOUD_CONNECTION_PENDING';
export const REMOVE_DEEPCLOUD_CONNECTION_FULFILLED = 'REMOVE_DEEPCLOUD_CONNECTION_FULFILLED';
export const REMOVE_DEEPCLOUD_CONNECTION_ERROR = 'REMOVE_DEEPCLOUD_CONNECTION_ERROR';
