import receipts from '@/router/suppliers/receipts';
import supplierCreditNotes from '@/router/suppliers/supplier-credit-notes';
import type { RouteConfig } from 'vue-router/types/router';

const Suppliers = () => import('@/pages/suppliers/Suppliers.vue');

export default {
  path: '/suppliers',
  name: 'vendors',
  component: Suppliers,
  redirect: { name: 'receipts' },
  children: [
    ...receipts,
    ...supplierCreditNotes,
  ],
} as RouteConfig;
