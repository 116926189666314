import actions from '@/store/modules/accounting/balance-sheet/actions';
import mutations from '@/store/modules/accounting/balance-sheet/mutations';
import initialState from '@/store/modules/accounting/balance-sheet/state';

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
};
