// initial data load
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_VAT_JOURNAL_PENDING = 'FETCH_VAT_JOURNAL_PENDING';
export const FETCH_VAT_JOURNAL_FULFILLED = 'FETCH_VAT_JOURNAL_FULFILLED';
export const FETCH_VAT_JOURNAL_ERROR = 'FETCH_VAT_JOURNAL_ERROR';

export const ADD_VAT_NUMBER = 'ADD_VAT_NUMBER';

export const CLOSE_VAT_JOURNAL_PENDING = 'CLOSE_VAT_JOURNAL_PENDING';
export const CLOSE_VAT_JOURNAL_FULFILLED = 'CLOSE_VAT_JOURNAL_FULFILLED';
export const CLOSE_VAT_JOURNAL_ERROR = 'CLOSE_VAT_JOURNAL_ERROR';
