import axios from 'axios';

const FETCH_APP_HEALTH_PENDING = 'FETCH_APP_HEALTH_PENDING';
const FETCH_APP_HEALTH_FULFILLED = 'FETCH_APP_HEALTH_FULFILLED';
const FETCH_APP_HEALTH_ERROR = 'FETCH_APP_HEALTH_ERROR';
const FETCH_BASICS_PENDING = 'FETCH_BASICS_PENDING';
const FETCH_BASICS_FULFILLED = 'FETCH_BASICS_FULFILLED';
const FETCH_BASICS_ERROR = 'FETCH_BASICS_ERROR';
const FETCH_PRINT_HEADER_PENDING = 'FETCH_PRINT_HEADER_PENDING';
const FETCH_PRINT_HEADER_FULFILLED = 'FETCH_PRINT_HEADER_FULFILLED';
const FETCH_PRINT_HEADER_ERROR = 'FETCH_PRINT_HEADER_ERROR';
const FETCH_LEGAL_FORMS = 'FETCH_LEGAL_FORMS';
const FETCH_LEGAL_FORMS_PENDING = 'FETCH_LEGAL_FORMS_PENDING';

const initialState = {
  basicsPending: false,
  basicsError: {},
  companySizes: [],
  contactTypes: [],
  countries: [],
  states: [],
  currencies: [],
  industries: [],
  languages: [],
  paymentTerms: [],
  paymentDiffTypes: [],
  salutations: [],
  printHeaderPending: false,
  printHeader: {},
  accountingKinds: [],
  accountingTypes: [],
  legalForms: [],
  legalFormsPending: false,
};

const mutations = {
  [FETCH_BASICS_PENDING](state) {
    state.basicsPending = true;
  },
  [FETCH_BASICS_FULFILLED](state, { data }) {
    state.companySizes = data.company_sizes;
    state.contactTypes = data.contact_types;
    state.countries = data.countries;
    state.states = data.states;
    state.languages = data.languages;
    state.paymentTerms = data.payment_terms;
    state.paymentDiffTypes = data.payment_diff_types;
    state.salutations = data.salutations;
    state.accountingKinds = data.accounting_kinds;
    state.accountingTypes = data.accounting_types;
    state.environment = data.environment;

    const preferredCurrencies = ['CHF', 'EUR', 'USD', 'GBP'];
    const preferredCurrenciesList = [];
    const currenciesList = [];
    data.currencies.map((currency) => {
      currency.concatenated = `${currency.currency_code} - ${currency.name}`;
      if (preferredCurrencies.includes(currency.currency_code)) {
        preferredCurrenciesList.push(currency);
      } else {
        currenciesList.push(currency);
      }
      return currency;
    });
    state.currencies = [
      ...preferredCurrenciesList.sort((a, b) => {
        if (a.currency_code < b.currency_code) {
          return -1;
        }
        if (a.currency_code > b.currency_code) {
          return 1;
        }
        return 0;
      }),
      { divider: true },
      ...currenciesList,
    ];

    state.industries = data.industries.map((industry) => {
      industry.concatenated = `${industry.code} ${industry.name}`;
      return industry;
    }).sort((a, b) => {
      const nameA = a.concatenated.toUpperCase();
      const nameB = b.concatenated.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    state.basicsPending = false;
  },
  [FETCH_BASICS_ERROR](state, error) {
    state.basicsError = error;
    state.basicsPending = false;
  },
  [FETCH_PRINT_HEADER_PENDING](state) {
    state.printHeaderPending = true;
  },
  [FETCH_PRINT_HEADER_FULFILLED](state, { data }) {
    state.printHeaderPending = false;
    state.printHeader = data;
  },
  [FETCH_PRINT_HEADER_ERROR](state) {
    state.printHeaderPending = false;
  },
  [FETCH_LEGAL_FORMS_PENDING](state, isPending) {
    state.legalFormsPending = isPending;
  },
  [FETCH_LEGAL_FORMS](state, { data }) {
    state.legalForms = data;
  },
};

const actions = {
  async checkAppHealth({ commit }) {
    commit(FETCH_APP_HEALTH_PENDING);
    try {
      const response = await axios.get('../_health');
      commit(FETCH_APP_HEALTH_FULFILLED);
      if (response.data.status !== 'UP') {
        window.location.href = import.meta.env.VITE_MAINTENANCE_URL;
        return false;
      }
      return true;
    } catch (error) {
      commit(FETCH_APP_HEALTH_ERROR);
      window.location.href = import.meta.env.VITE_MAINTENANCE_URL;
      return Promise.reject(error);
    }
  },
  async fetchBasics({ commit }, locale) {
    commit(FETCH_BASICS_PENDING);
    try {
      // Todo: Extend Locale
      commit(FETCH_BASICS_FULFILLED, await axios.get('/basics', { params: { locale } }));
    } catch (error) {
      commit(FETCH_BASICS_ERROR, error);
    }
  },
  async fetchPrintHeader({ rootState, commit }) {
    commit(FETCH_PRINT_HEADER_PENDING);
    try {
      commit(FETCH_PRINT_HEADER_FULFILLED, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_print_header`));
    } catch (error) {
      commit(FETCH_PRINT_HEADER_ERROR, error);
    }
  },
  async fetchLegalForms({ commit }) {
    commit(FETCH_LEGAL_FORMS_PENDING, true);
    try {
      commit(FETCH_LEGAL_FORMS, await axios.get('/basics/legal_forms'));
      commit(FETCH_LEGAL_FORMS_PENDING, false);
    } catch (error) {
      commit(FETCH_LEGAL_FORMS_PENDING, false);
    }
  },
};

const getters = {
  currentMainLanguage: (state, _, rootState) => {
    const mainLanguageIdx = state.languages.findIndex(
      (lang) => lang.id === rootState.definitions.account.account.language_id,
    );
    return state.languages[mainLanguageIdx];
  },
  languagesByMainLanguage: (state, _, rootState) => {
    const { languages } = state;
    const mainLanguageIdx = state.languages.findIndex(
      (lang) => lang.id === rootState.definitions.account.account.language_id,
    );
    languages.unshift(languages.splice(mainLanguageIdx, 1)[0]);
    return languages;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
