import Keycloak from 'keycloak-js';

const url = new URL(window.location.href);
const abaNinjaDomain = url.host;
const urlParams = new URLSearchParams(window.location.search);
const kcIdpHint = urlParams.get('kc_idp_hint');

export const useDeepSso = kcIdpHint === 'deepsso'
  || abaNinjaDomain.includes('abaninja.deepbox.swiss')
  || import.meta.env.VITE_DEFAULT_SSO === 'deepsso';

/**
 * @type Keycloak.KeycloakConfig
 */
const abaskyidp = {
  url: import.meta.env.VITE_ABASKYIDP_URL,
  realm: import.meta.env.VITE_ABASKYIDP_REALM,
  clientId: import.meta.env.VITE_ABASKYIDP_CLIENT_ID,
  redirectUri: import.meta.env.VITE_ABASKYIDP_LOGIN_REDIRECT,
};

const deepsso = {
  url: import.meta.env.VITE_DEEPSSO_URL,
  realm: import.meta.env.VITE_DEEPSSO_REALM,
  clientId: import.meta.env.VITE_DEEPSSO_CLIENT_ID,
  redirectUri: import.meta.env.VITE_DEEPSSO_LOGIN_REDIRECT,
};

// choose login provider
const ssoProvider = useDeepSso ? deepsso : abaskyidp;

const initOptions = {
  ...ssoProvider,
  checkLoginIframe: false,
};

export const keycloakInstance = new Keycloak(initOptions);

const Plugin = {
  install: (VueInstance) => {
    VueInstance.$keycloak = keycloakInstance;
  },
};

Plugin.install = (VueInstance) => {
  VueInstance.$keycloak = keycloakInstance;
  Object.defineProperties(VueInstance.prototype, {
    $keycloak: {
      get() {
        return keycloakInstance;
      },
    },
  });
};

export default Plugin;
