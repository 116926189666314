import axios from 'axios';
import qs from 'qs';
import * as types from './types';

const actions = {

  fetchBalanceSheet({ rootState, commit }, {
    abortController,
    endDate,
    showNoBookings,
    showNumber,
    showPercentage,
    showPrevious,
    showInactive,
    showZero,
    startDate,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_BALANCE_SHEET);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/balance_sheet/${yearId}`, {
      signal: controller.signal,
      params: {
        start_date: startDate,
        end_date: endDate,
        show_zero: showZero,
        show_no_bookings: showNoBookings,
        show_previous: showPrevious,
        show_percentage: showPercentage,
        show_number: showNumber,
        show_inactive: showInactive,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_BALANCE_SHEET_SUCCESS, response);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_BALANCE_SHEET_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_BALANCE_SHEET_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchEBalanceSheet({ rootState, commit }, {
    abortController,
    yearId,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_E_BALANCE_SHEET);

    return axios.get(
      `/accounts/${rootState.definitions.account.account.uuid}/accounting_xbrl_report?yearId=${yearId}`,
      {
        signal: controller.signal,
        responseType: 'blob',
      },
    ).then((response) => {
      commit(types.FETCH_E_BALANCE_SHEET_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_E_BALANCE_SHEET_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_E_BALANCE_SHEET_FAILURE, error);
      return Promise.reject(error);
    });
  },

  postEBalanceDeepCloud({ rootState, commit }, { yearId }) {
    commit(types.POST_E_BALANCE_SHEET_DEEP_CLOUD);
    return axios.post(
      `/accounts/${rootState.definitions.account.account.uuid}/accounting_xbrl_report/deepcloud`,
      { yearId },
    ).then((response) => {
      commit(types.POST_E_BALANCE_SHEET_DEEP_CLOUD_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.POST_E_BALANCE_SHEET_DEEP_CLOUD_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchBalanceSheetPdfExport({ rootState, commit }, {
    abortController,
    endDate,
    showNoBookings,
    showInactive,
    showNumber,
    showPercentage,
    showPrevious,
    showZero,
    startDate,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_BALANCE_SHEET_PDF_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/balance_sheet/${yearId}`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
      params: {
        export: 'pdf',
        per_page: 'all',
        start_date: startDate,
        end_date: endDate,
        show_zero: showZero,
        show_no_bookings: showNoBookings,
        show_previous: showPrevious,
        show_percentage: showPercentage,
        show_number: showNumber,
        hide_levels: hideLevels,
        show_exchange: showExchange,
        show_inactive: showInactive,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_BALANCE_SHEET_PDF_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_BALANCE_SHEET_PDF_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_BALANCE_SHEET_PDF_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchBalanceSheetXlsExport({ rootState, commit }, {
    abortController,
    endDate,
    showNoBookings,
    showInactive,
    showNumber,
    showPercentage,
    showPrevious,
    showZero,
    startDate,
    yearId,
    hideLevels,
    showExchange,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_BALANCE_SHEET_XLS_EXPORT);

    return axios({
      url: `/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/balance_sheet/${yearId}`,
      method: 'GET',
      responseType: 'blob',
      signal: controller.signal,
      params: {
        export: 'xls',
        per_page: 'all',
        start_date: startDate,
        end_date: endDate,
        show_zero: showZero,
        show_no_bookings: showNoBookings,
        show_inactive: showInactive,
        show_previous: showPrevious,
        show_percentage: showPercentage,
        show_number: showNumber,
        hide_levels: hideLevels,
        show_exchange: showExchange,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_BALANCE_SHEET_XLS_EXPORT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_BALANCE_SHEET_XLS_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_BALANCE_SHEET_XLS_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

};

export default actions;
