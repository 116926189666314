import axios from 'axios';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_PROVIDERS_PENDING = 'FETCH_PROVIDERS_PENDING';
const FETCH_PROVIDERS_FULFILLED = 'FETCH_PROVIDERS_FULFILLED';
const FETCH_PROVIDERS_ERROR = 'FETCH_PROVIDERS_ERROR';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

const FETCH_SYSTEM_PENDING = 'FETCH_SYSTEM_PENDING';
const FETCH_SYSTEM_FULFILLED = 'FETCH_SYSTEM_FULFILLED';
const FETCH_SYSTEM_ERROR = 'FETCH_SYSTEM_ERROR';

const STORE_SYSTEM_PENDING = 'STORE_SYSTEM_PENDING';
const STORE_SYSTEM_FULFILLED = 'STORE_SYSTEM_FULFILLED';
const STORE_SYSTEM_ERROR = 'STORE_SYSTEM_ERROR';

const DELETE_SYSTEM_PENDING = 'DELETE_SYSTEM_PENDING';
const DELETE_SYSTEM_FULFILLED = 'DELETE_SYSTEM_FULFILLED';
const DELETE_SYSTEM_ERROR = 'DELETE_SYSTEM_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    fetchProvidersPending: false,
    providers: [],
    listPending: false,
    list: [],
    fetchSystemPending: false,
    system: {},
    storeSystemPending: false,
    deleteSystemPending: false,
  },
  mutations: {
    // FETCH INITIAL
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    // FETCH PROVIDERS
    [FETCH_PROVIDERS_PENDING](state) {
      state.fetchProvidersPending = true;
    },
    [FETCH_PROVIDERS_FULFILLED](state, { data }) {
      state.providers = data;
      state.fetchProvidersPending = false;
    },
    [FETCH_PROVIDERS_ERROR](state) {
      state.fetchProvidersPending = false;
    },
    // FETCH LIST
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    // FETCH SYSTEM
    [FETCH_SYSTEM_PENDING](state) {
      state.fetchSystemPending = true;
    },
    [FETCH_SYSTEM_FULFILLED](state, { data }) {
      state.system = data;
      state.fetchSystemPending = false;
    },
    [FETCH_SYSTEM_ERROR](state) {
      state.fetchSystemPending = false;
    },
    // STORE SYSTEM
    [STORE_SYSTEM_PENDING](state) {
      state.storeSystemPending = true;
    },
    [STORE_SYSTEM_FULFILLED](state) {
      state.storeSystemPending = false;
    },
    [STORE_SYSTEM_ERROR](state) {
      state.storeSystemPending = false;
    },
    // DELETE SYSTEM
    [DELETE_SYSTEM_PENDING](state) {
      state.deleteSystemPending = true;
    },
    [DELETE_SYSTEM_FULFILLED](state) {
      state.deleteSystemPending = false;
    },
    [DELETE_SYSTEM_ERROR](state) {
      state.deleteSystemPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await Promise.all([
          dispatch('fetchProviders'),
        ]);
        commit(FETCH_INITIAL_FULFILLED);
        return true;
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchProviders({ rootState, commit }) {
      commit(FETCH_PROVIDERS_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting/creditcards/providers`;
        const response = await axios.get(url); // TODO: data in response
        commit(FETCH_PROVIDERS_FULFILLED, response);
        return true;
      } catch (error) {
        commit(FETCH_PROVIDERS_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, commit }) {
      commit(FETCH_LIST_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting/creditcards/cards`;
        const response = await axios.get(url); // TODO: data in response
        commit(FETCH_LIST_FULFILLED, response);
        return true;
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchSystem({ rootState, commit }, id) {
      commit(FETCH_SYSTEM_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting/creditcards/cards/${id}`;
        const response = await axios.get(url);
        commit(FETCH_SYSTEM_FULFILLED, response);
        return true;
      } catch (error) {
        commit(FETCH_SYSTEM_ERROR, error);
        return Promise.reject(error);
      }
    },
    async storeSystem({ rootState, commit, dispatch }, form) {
      commit(STORE_SYSTEM_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting/creditcards/cards`;
        commit(STORE_SYSTEM_FULFILLED, await axios.post(url, form)); // TODO: POST / PATCH
        dispatch('messages/showMessage', { key: 'creditcard_store_successful', color: 'success' }, { root: true });
        return true;
      } catch (error) {
        commit(STORE_SYSTEM_ERROR, error);
        dispatch('messages/showMessage', { key: 'creditcard_store_error', color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async deleteSystem({ rootState, commit, dispatch }, id) {
      commit(DELETE_SYSTEM_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting/creditcards/cards/${id}`;
        commit(DELETE_SYSTEM_FULFILLED, await axios.delete(url));
        dispatch('messages/showMessage', { key: 'creditcard_delete_successful', color: 'success' }, { root: true });
        return true;
      } catch (error) {
        commit(DELETE_SYSTEM_ERROR);
        dispatch('messages/showMessage', { key: 'creditcard_delete_error', color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
  },
};
