import * as types from './types';

export default {
  // GET base groups
  [types.FETCH_BASE_GROUPS](state) {
    state.isLoadingBaseGroups = true;
    state.hasLoadedBaseGroups = false;
    state.baseGroupsError = null;
  },
  [types.FETCH_BASE_GROUPS_SUCCESS](state, baseGroups) {
    state.baseGroups = baseGroups;

    state.isLoadingBaseGroups = false;
    state.hasLoadedBaseGroups = true;
    state.baseGroupsError = null;
  },
  [types.FETCH_BASE_GROUPS_CANCEL](state, error) {
    state.isLoadingBaseGroups = false;
    state.hasLoadedBaseGroups = false;
    state.baseGroupsError = error;
  },
  [types.FETCH_BASE_GROUPS_FAILURE](state) {
    state.isLoadingBaseGroups = false;
    state.baseGroupsError = null;
  },
};
