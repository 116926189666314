import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');
const DeliveryNotesList = () => import('@/pages/customers/delivery-notes/DeliveryNotesList.vue');

const deliveryNotesRoutes: Array<RouteConfig> = [
  {
    path: 'delivery-notes',
    component: PassThrough,
    alias: ['delivery_note'],
    children: [
      {
        path: ':group?',
        name: 'delivery-notes-list',
        meta: {
          title: 'delivery_notes',
        },
        component: DeliveryNotesList,
        beforeEnter(to, _, next) {
          if (!to.params.group) {
            next({ name: 'delivery-notes-list', params: { group: 'open' } });
          } else {
            next();
          }
        },
      },
    ],
  },
];

export default deliveryNotesRoutes;
