// initial data load
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_BANK_ACCOUNT_PENDING = 'FETCH_BANK_ACCOUNT_PENDING';
export const FETCH_BANK_ACCOUNT_FULFILLED = 'FETCH_BANK_ACCOUNT_FULFILLED';
export const FETCH_BANK_ACCOUNT_ERROR = 'FETCH_BANK_ACCOUNT_ERROR';

export const STORE_BANK_ACCOUNT_PENDING = 'STORE_BANK_ACCOUNT_PENDING';
export const STORE_BANK_ACCOUNT_FULFILLED = 'STORE_BANK_ACCOUNT_FULFILLED';
export const STORE_BANK_ACCOUNT_ERROR = 'STORE_BANK_ACCOUNT_ERROR';

export const DELETE_BANK_ACCOUNT_PENDING = 'DELETE_BANK_ACCOUNT_PENDING';
export const DELETE_BANK_ACCOUNT_FULFILLED = 'DELETE_BANK_ACCOUNT_FULFILLED';
export const DELETE_BANK_ACCOUNT_ERROR = 'DELETE_BANK_ACCOUNT_ERROR';

export const FETCH_ABABANK_BANKS_PENDING = 'FETCH_ABABANK_BANKS_PENDING';
export const FETCH_ABABANK_BANKS_FULFILLED = 'FETCH_ABABANK_BANKS_FULFILLED';
export const FETCH_ABABANK_BANKS_ERROR = 'FETCH_ABABANK_BANKS_ERROR';

export const FETCH_BANK_INFORMATION_PENDING = 'FETCH_BANK_INFORMATION_PENDING';
export const FETCH_BANK_INFORMATION_FULFILLED = 'FETCH_BANK_INFORMATION_FULFILLED';
export const FETCH_BANK_INFORMATION_ERROR = 'FETCH_BANK_INFORMATION_ERROR';

export const LOGIN_EBANKING_PENDING = 'LOGIN_EBANKING_PENDING';
export const LOGIN_EBANKING_FULFILLED = 'LOGIN_EBANKING_FULFILLED';
export const LOGIN_EBANKING_ERROR = 'LOGIN_EBANKING_ERROR';

export const BLINK_LOGIN_PENDING = 'BLINK_LOGIN_PENDING';
export const BLINK_LOGIN_FULFILLED = 'BLINK_LOGIN_FULFILLED';
export const BLINK_LOGIN_ERROR = 'BLINK_LOGIN_ERROR';

export const OAUTH_LOGIN_PENDING = 'OAUTH_LOGIN_PENDING';
export const OAUTH_LOGIN_FULFILLED = 'OAUTH_LOGIN_FULFILLED';
export const OAUTH_LOGIN_ERROR = 'OAUTH_LOGIN_ERROR';

export const OAUTH_LOGOUT_PENDING = 'OAUTH_LOGOUT_PENDING';
export const OAUTH_LOGOUT_FULFILLED = 'OAUTH_LOGOUT_FULFILLED';
export const OAUTH_LOGOUT_ERROR = 'OAUTH_LOGOUT_ERROR';

export const STORE_OAUTH_AUTHORIZATION_PENDING = 'STORE_OAUTH_AUTHORIZATION_PENDING';
export const STORE_OAUTH_AUTHORIZATION_FULFILLED = 'STORE_OAUTH_AUTHORIZATION_FULFILLED';
export const STORE_OAUTH_AUTHORIZATION_ERROR = 'STORE_OAUTH_AUTHORIZATION_ERROR';

export const STORE_OAUTH_CALLBACK_BLINK_PENDING = 'STORE_OAUTH_AUTHORIZATION_PENDING';
export const STORE_OAUTH_CALLBACK_BLINK_FULFILLED = 'STORE_OAUTH_AUTHORIZATION_FULFILLED';
export const STORE_OAUTH_CALLBACK_BLINK_ERROR = 'STORE_OAUTH_AUTHORIZATION_ERROR';

export const FETCH_EBANKING_BANK_ACCOUNTS_PENDING = 'FETCH_EBANKING_BANK_ACCOUNTS_PENDING';
export const FETCH_EBANKING_BANK_ACCOUNTS_FULFILLED = 'FETCH_EBANKING_BANK_ACCOUNTS_FULFILLED';
export const FETCH_EBANKING_BANK_ACCOUNTS_ERROR = 'FETCH_EBANKING_BANK_ACCOUNTS_ERROR';

export const PUSH_EBANKING_PAYMENTS_PENDING = 'PUSH_EBANKING_PAYMENTS_PENDING';
export const PUSH_EBANKING_PAYMENTS_FULFILLED = 'PUSH_EBANKING_PAYMENTS_FULFILLED';
export const PUSH_EBANKING_PAYMENTS_ERROR = 'PUSH_EBANKING_PAYMENTS_ERROR';

export const FETCH_EBANKING_TRANSACTIONS_PENDING = 'FETCH_EBANKING_TRANSACTIONS_PENDING';
export const FETCH_EBANKING_TRANSACTIONS_FULFILLED = 'FETCH_EBANKING_TRANSACTIONS_FULFILLED';
export const FETCH_EBANKING_TRANSACTIONS_ERROR = 'FETCH_EBANKING_TRANSACTIONS_ERROR';

export const SYNC_EBANKING_PENDING = 'SYNC_EBANKING_PENDING';
export const SYNC_EBANKING_FULFILLED = 'SYNC_EBANKING_FULFILLED';
export const SYNC_EBANKING_ERROR = 'SYNC_EBANKING_ERROR';

export const CONFIRM_EBANKING_PAYMENTS_PENDING = 'CONFIRM_EBANKING_PAYMENTS_PENDING';
export const CONFIRM_EBANKING_PAYMENTS_FULFILLED = 'CONFIRM_EBANKING_PAYMENTS_FULFILLED';
export const CONFIRM_EBANKING_PAYMENTS_ERROR = 'CONFIRM_EBANKING_PAYMENTS_ERROR';

export const LOGOUT_EBANKING_PENDING = 'LOGOUT_EBANKING_PENDING';
export const LOGOUT_EBANKING_FULFILLED = 'LOGOUT_EBANKING_FULFILLED';
export const LOGOUT_EBANKING_ERROR = 'LOGOUT_EBANKING_ERROR';

export const RESET_EBANKING = 'RESET_EBANKING';

export const CHECK_ACCOUNT_NUMBER_PENDING = 'CHECK_ACCOUNT_NUMBER_PENDING';
export const CHECK_ACCOUNT_NUMBER_FULFILLED = 'CHECK_ACCOUNT_NUMBER_FULFILLED';
export const CHECK_ACCOUNT_NUMBER_ERROR = 'CHECK_ACCOUNT_NUMBER_ERROR';

export const VALIDATE_BIC_NUMBER_PENDING = 'VALIDATE_BIC_NUMBER_PENDING';
export const VALIDATE_BIC_NUMBER_FULFILLED = 'VALIDATE_BIC_NUMBER_FULFILLED';

export const VALIDATE_ACCOUNT_NUMBER_PENDING = 'VALIDATE_ACCOUNT_NUMBER_PENDING';
export const VALIDATE_ACCOUNT_NUMBER_FULFILLED = 'VALIDATE_ACCOUNT_NUMBER_FULFILLED';

export const VALIDATE_QR_ACCOUNT_NUMBER_PENDING = 'VALIDATE_QR_ACCOUNT_NUMBER_PENDING';
export const VALIDATE_QR_ACCOUNT_NUMBER_FULFILLED = 'VALIDATE_QR_ACCOUNT_NUMBER_FULFILLED';

export const VALIDATE_QR_BESR_ID_PENDING = 'VALIDATE_QR_BESR_ID_PENDING';
export const VALIDATE_QR_BESR_ID_FULFILLED = 'VALIDATE_QR_BESR_ID_FULFILLED';

export const REMOVE_ABABANK_PENDING = 'REMOVE_ABABANK_PENDING';
export const REMOVE_ABABANK_FULFILLED = 'REMOVE_ABABANK_FULFILLED';
export const REMOVE_ABABANK_ERROR = 'REMOVE_ABABANK_ERROR';
