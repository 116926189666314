import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_USERS
  [types.FETCH_SIGNING_USERS_FULFILLED](state, { data }) {
    state.signingUsers = data;
  },
  // FETCH_PROJECT_ADDRESS
  [types.FETCH_PROJECT_ADDRESS_FULFILLED](state, projectAddress) {
    state.projectAddress = projectAddress;
  },
  // FETCH_DUPLICATE_ADDRESSES
  [types.FETCH_DUPLICATE_ADDRESSES_FULFILLED](state, address) {
    state.duplicateAddresses = address;
  },
  // FETCH_ADDRESS
  [types.FETCH_ADDRESS_PENDING](state) {
    state.fetchAddressPending = true;
  },
  [types.FETCH_ADDRESS_FULFILLED](state, { data }) {
    state.address = data;
    state.fetchAddressPending = false;
  },
  [types.FETCH_ADDRESS_ERROR](state) {
    state.fetchAddressPending = false;
  },
  // IMPORT_RECEIPT
  [types.IMPORT_RECEIPT_PENDING](state) {
    state.addresses = [];
    state.duplicateAddresses = [
      { id: null },
    ];
    state.receipt = {};
    state.address = {};
    state.projectAddress = {};
    state.importReceiptPending = true;
  },
  [types.IMPORT_RECEIPT_FULFILLED](state, invoice) {
    state.receipt = invoice;
    state.importReceiptPending = false;
  },
  [types.IMPORT_RECEIPT_ERROR](state) {
    state.importReceiptPending = false;
  },
  [types.FETCH_IMPORT_RECEIPT_QUEUE](state, { data }) {
    state.receiptQueue = data;
  },
  // FETCH_RECEIPT
  [types.FETCH_RECEIPT_PENDING](state) {
    state.fetchReceiptPending = true;
    state.addresses = [];
    state.duplicateAddresses = [
      { id: null },
    ];
    state.receipt = {};
    state.address = {};
    state.projectAddress = {};
  },
  [types.FETCH_RECEIPT_FULFILLED](state, receipt) {
    state.receipt = receipt;
    state.fetchReceiptPending = false;
  },
  [types.FETCH_RECEIPT_ERROR](state) {
    state.fetchReceiptPending = false;
  },
  // STORE_RECEIPT
  [types.STORE_RECEIPT_PENDING](state) {
    state.storeReceiptPending = true;
  },
  [types.STORE_RECEIPT_FULFILLED](state) {
    state.storeReceiptPending = false;
  },
  [types.STORE_RECEIPT_ERROR](state) {
    state.storeReceiptPending = false;
  },
  [types.RESET_RECEIPT](state) {
    state.fetchReceiptPending = false;
    state.addresses = [];
    state.duplicateAddresses = [
      { id: null },
    ];
    state.receipt = {};
    state.address = {};
    state.projectAddress = {};
  },
  // STORE_ACTION
  [types.STORE_ACTION_PENDING](state) {
    state.storeActionPending = true;
  },
  [types.STORE_ACTION_FULFILLED](state) {
    state.storeActionPending = false;
  },
  [types.STORE_ACTION_ERROR](state) {
    state.storeActionPending = false;
  },
  // FETCH_PRODUCT_RECOGNITION
  [types.FETCH_PRODUCT_RECOGNITION_PENDING](state) {
    state.fetchProductRecognitionPending = true;
  },
  [types.FETCH_PRODUCT_RECOGNITION_FULFILLED](state) {
    state.fetchProductRecognitionPending = false;
  },
  [types.FETCH_PRODUCT_RECOGNITION_ERROR](state) {
    state.fetchProductRecognitionPending = false;
  },
};

export default mutations;
