import type { RouteConfig } from 'vue-router/types/router';

const ApiTokensIndex = () => import('@/pages/settings/api-tokens/ApiTokensIndex.vue');
const ApiTokensForm = () => import('@/pages/settings/api-tokens/ApiTokensForm.vue');
const PassThrough = () => import('@/views/PassThrough.vue');

export const apiTokensRoutes: Array<RouteConfig> = [{
  path: 'api-tokens',
  component: PassThrough,
  children: [
    {
      path: '',
      name: 'api-tokens',
      component: ApiTokensIndex,
      meta: {
        title: 'api_tokens',
      },
    },
    {
      path: 'create',
      name: 'api-token-create',
      component: ApiTokensForm,
      meta: {
        title: 'api_token_create',
      },
    },
    {
      path: 'edit/:uuid',
      name: 'api-token-edit',
      component: ApiTokensForm,
      meta: {
        title: 'api_token_edit',
      },
    },
  ],
}];
