import { get } from '@vueuse/core';
import type { Ref } from 'vue';
import { ref, unref, watchEffect } from 'vue';

function clone<T>(obj: T): T {
  return obj ? JSON.parse(JSON.stringify(obj)) : undefined;
}

export const editorRef = <T extends object, K extends keyof T>(obj: T, name: K, defaultValue?: T[K]) => {
  const data = ref(defaultValue) as Ref<T[K] | undefined>;

  watchEffect(() => {
    data.value = clone(unref(get(obj, name))) ?? unref(defaultValue);
  });

  return data;
};
