// initial data load
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_ADDRESS_PENDING = 'FETCH_ADDRESS_PENDING';
export const FETCH_ADDRESS_FULFILLED = 'FETCH_ADDRESS_FULFILLED';
export const FETCH_ADDRESS_ERROR = 'FETCH_ADDRESS_ERROR';

export const FETCH_ADDRESS_DETAIL_PENDING = 'FETCH_ADDRESS_DETAIL_PENDING';
export const FETCH_ADDRESS_DETAIL_FULFILLED = 'FETCH_ADDRESS_DETAIL_FULFILLED';
export const FETCH_ADDRESS_DETAIL_ERROR = 'FETCH_ADDRESS_DETAIL_ERROR';

export const FETCH_ADDRESS_SINGLE_ACTIONS_FULFILLED = 'FETCH_ADDRESS_SINGLE_ACTIONS_FULFILLED';

export const FETCH_ADDRESS_OP_RECEIVABLES_FULFILLED = 'FETCH_ADDRESS_OP_RECEIVABLES_FULFILLED';
export const FETCH_ADDRESS_OP_PAYABLES_FULFILLED = 'FETCH_ADDRESS_OP_PAYABLES_FULFILLED';
export const FETCH_ADDRESS_OP_PAYABLES_PAID_FULFILLED = 'FETCH_ADDRESS_OP_PAYABLES_PAID_FULFILLED';
export const FETCH_ADDRESS_OP_RECEIVABLES_PAID_FULFILLED = 'FETCH_ADDRESS_OP_RECEIVABLES_PAID_FULFILLED';
export const FETCH_ADDRESS_OP_CREDITNOTES_FULFILLED = 'FETCH_ADDRESS_OP_CREDITNOTES_FULFILLED';

export const FETCH_ADDRESS_ACTIVITIES_PENDING = 'FETCH_ADDRESS_ACTIVITIES_PENDING';
export const FETCH_ADDRESS_ACTIVITIES_SUCCESSFUL = 'FETCH_ADDRESS_ACTIVITIES_SUCCESSFUL';
export const FETCH_ADDRESS_ACTIVITIES_ERROR = 'FETCH_ADDRESS_ACTIVITIES_ERROR';

export const FETCH_ADDRESS_PAYMENTS_PENDING = 'FETCH_ADDRESS_PAYMENTS_PENDING';
export const FETCH_ADDRESS_PAYMENTS_SUCCESSFUL = 'FETCH_ADDRESS_PAYMENTS_SUCCESSFUL';
export const FETCH_ADDRESS_PAYMENTS_ERROR = 'FETCH_ADDRESS_PAYMENTS_ERROR';

export const STORE_ADDRESS_PENDING = 'STORE_ADDRESS_PENDING';
export const STORE_ADDRESS_FULFILLED = 'STORE_AADRESS_FULFILLED';
export const STORE_ADDRESS_ERROR = 'STORE_ADDRESS_ERROR';

export const UPDATE_ADDRESS_PENDING = 'UPDATE_ADDRESS_PENDING';
export const UPDATE_ADDRESS_FULFILLED = 'UPDATE_ADDRESS_FULFILLED';
export const UPDATE_ADDRESS_ERROR = 'UPDATE_ADDRESS_ERROR';

export const FETCH_ZIP_PLACE_AUTO_COMPLETION_PENDING = 'FETCH_ZIP_PLACE_AUTO_COMPLETION_PENDING';
export const FETCH_ZIP_PLACE_AUTO_COMPLETION_FULFILLED = 'FETCH_ZIP_PLACE_AUTO_COMPLETION_FULFILLED';
export const FETCH_ZIP_PLACE_AUTO_COMPLETION_ERROR = 'FETCH_ZIP_PLACE_AUTO_COMPLETION_ERROR';

export const FETCH_CUSTOMER_NUMBER_PREVIEW_PENDING = 'FETCH_CUSTOMER_NUMBER_PREVIEW_PENDING';
export const FETCH_CUSTOMER_NUMBER_PREVIEW_FULFILLED = 'FETCH_CUSTOMER_NUMBER_PREVIEW_FULFILLED';
export const FETCH_CUSTOMER_NUMBER_PREVIEW_ERROR = 'FETCH_CUSTOMER_NUMBER_PREVIEW_ERROR';

export const VALIDATE_CUSTOMER_NUMBER_PENDING = 'VALIDATE_CUSTOMER_NUMBER_PENDING';
export const VALIDATE_CUSTOMER_NUMBER_FULFILLED = 'VALIDATE_CUSTOMER_NUMBER_FULFILLED';

export const VALIDATE_BIC_NUMBER_PENDING = 'VALIDATE_BIC_NUMBER_PENDING';
export const VALIDATE_BIC_NUMBER_FULFILLED = 'VALIDATE_BIC_NUMBER_FULFILLED';

export const VALIDATE_ACCOUNT_NUMBER_PENDING = 'VALIDATE_ACCOUNT_NUMBER_PENDING';
export const VALIDATE_ACCOUNT_NUMBER_FULFILLED = 'VALIDATE_ACCOUNT_NUMBER_FULFILLED';

export const FETCH_STREET_AUTO_COMPLETION_PENDING = 'FETCH_STREET_AUTO_COMPLETION_PENDING';
export const FETCH_STREET_AUTO_COMPLETION_FULFILLED = 'FETCH_STREET_AUTO_COMPLETION_FULFILLED';
export const FETCH_STREET_AUTO_COMPLETION_ERROR = 'FETCH_STREET_AUTO_COMPLETION_ERROR';

export const CHECK_ABANET_AVAILABLE_PENDING = 'CHECK_ABANET_AVAILABLE_PENDING';
export const CHECK_ABANET_AVAILABLE_FULFILLED = 'CHECK_ABANET_AVAILABLE_FULFILLED';
export const CHECK_ABANET_AVAILABLE_ERROR = 'CHECK_ABANET_AVAILABLE_ERROR';

export const RESET_ADDRESS = 'RESET_ADDRESS';
