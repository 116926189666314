import { useTheme } from '@/composables/theme';
import dark from '@/plugins/themes/dark';
import light from '@/plugins/themes/light';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import de from 'vuetify/lib/locale/de';
import en from 'vuetify/lib/locale/en';
import fr from 'vuetify/lib/locale/fr';
import it from 'vuetify/lib/locale/it';

const { isDarkTheme } = useTheme();

const opts = {
  theme: {
    dark: isDarkTheme.value,
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light,
      dark,
    },
  },
  lang: {
    locales: {
      de,
      en,
      fr,
      it,
    },
    current: 'de',
  },
  icons: {
    iconfont: 'mdi',
  },
};

Vue.use(Vuetify);
export default new Vuetify(opts);
