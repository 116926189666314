import * as types from './types';

const mutations = {
  [types.FETCH_VALUE_PENDING](state) {
    state.fetchValuePending = true;
  },
  [types.FETCH_VALUE](state, { data, id }) {
    state[id] = data || {};
  },

  [types.STORE_VALUE_PENDING](state) {
    state.storeValuePending = true;
  },
  [types.STORE_VALUE](state, { data, id }) {
    state[id] = data || {};
  },
};

export default mutations;
