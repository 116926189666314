import type { AxiosPromise } from 'axios';
import type { Payload } from '@/api/interfaces/payload';
import httpClient from '@/helpers/client/http-client';

interface IsLeanSyncActive {
  isLeanSyncActive: boolean;
}

export function getIsLeanSyncActive(
  abortController: AbortController = new AbortController(),
): AxiosPromise<Payload<IsLeanSyncActive['isLeanSyncActive']>> {
  return httpClient.get('/definitions/is-lean-sync-active', {
    signal: abortController.signal,
  }).then((response) => ({
    ...response,
    data: {
      ...response.data,
      data: response.data.data.isLeanSyncActive,
    },
  }));
}
