import { OUT_OF_SCOPE } from '@/helpers/utils';
import { getCurrentInstance } from 'vue-demi';

export function useVuexStore() {
  const instance = getCurrentInstance();
  if (instance) {
    return instance.proxy.$store;
  }
  console.warn(`[vuex] out of ${OUT_OF_SCOPE}`);

  return undefined as never;
}
