<template>
  <div class="center">
    <loader />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Loader,
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
}
</style>
