import { getTimeActivityTypes } from '@/api/time/settings/activity-types';
import { useLanguagesStore } from '@/stores/basics/languages';
import { useRequireEntitiesV2 } from '@/stores/composables/use-require-entities-v2';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed } from 'vue';

export const useTimeActivityTypesStore = defineStore('timeActivityTypes', () => {
  const languageStore = useLanguagesStore();
  const requireEntities = useRequireEntitiesV2(getTimeActivityTypes);

  const formattedEntities = computed(() => requireEntities.entities.value
    .map((activity) => ({
      ...activity,
      formattedName: `${
        activity.translations[languageStore.currentLocale].description
      } (${activity.activityNumber})`,
    }))
    .sort((a, b) => (b.isFavorite ? 1 : 0) - (a.isFavorite ? 1 : 0)));

  return {
    ...requireEntities,
    formattedEntities,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTimeActivityTypesStore, import.meta.hot));
}
