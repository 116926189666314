// initial data load
export const FETCH_BALANCE_SHEET = 'FETCH_BALANCE_SHEET';
export const FETCH_BALANCE_SHEET_SUCCESS = 'FETCH_BALANCE_SHEET_SUCCESS';
export const FETCH_BALANCE_SHEET_FAILURE = 'FETCH_BALANCE_SHEET_FAILURE';
export const FETCH_BALANCE_SHEET_CANCEL = 'FETCH_BALANCE_SHEET_CANCEL';

// fetch balance sheet e-bilance
export const FETCH_E_BALANCE_SHEET = 'FETCH_E_BALANCE_SHEET';
export const FETCH_E_BALANCE_SHEET_SUCCESS = 'FETCH_E_BALANCE_SHEET_SUCCESS';
export const FETCH_E_BALANCE_SHEET_FAILURE = 'FETCH_E_BALANCE_SHEET_FAILURE';
export const FETCH_E_BALANCE_SHEET_CANCEL = 'FETCH_E_BALANCE_SHEET_CANCEL';

// post e-balance to deep cloud
export const POST_E_BALANCE_SHEET_DEEP_CLOUD = 'POST_E_BALANCE_SHEET_DEEP_CLOUD';
export const POST_E_BALANCE_SHEET_DEEP_CLOUD_SUCCESS = 'POST_E_BALANCE_SHEET_DEEP_CLOUD_SUCCESS';
export const POST_E_BALANCE_SHEET_DEEP_CLOUD_FAILURE = 'POST_E_BALANCE_SHEET_DEEP_CLOUD_FAILURE';

// fetch balance sheet PDF export
export const FETCH_BALANCE_SHEET_PDF_EXPORT = 'FETCH_BALANCE_SHEET_PDF_EXPORT';
export const FETCH_BALANCE_SHEET_PDF_EXPORT_SUCCESS = 'FETCH_BALANCE_SHEET_PDF_EXPORT_SUCCESS';
export const FETCH_BALANCE_SHEET_PDF_EXPORT_FAILURE = 'FETCH_BALANCE_SHEET_PDF_EXPORT_FAILURE';
export const FETCH_BALANCE_SHEET_PDF_EXPORT_CANCEL = 'FETCH_BALANCE_SHEET_PDF_EXPORT_CANCEL';

// fetch balance sheet XLS export
export const FETCH_BALANCE_SHEET_XLS_EXPORT = 'FETCH_BALANCE_SHEET_XLS_EXPORT_PENDING';
export const FETCH_BALANCE_SHEET_XLS_EXPORT_SUCCESS = 'FETCH_BALANCE_SHEET_XLS_EXPORT_SUCCESS';
export const FETCH_BALANCE_SHEET_XLS_EXPORT_FAILURE = 'FETCH_BALANCE_SHEET_XLS_EXPORT_FAILURE';
export const FETCH_BALANCE_SHEET_XLS_EXPORT_CANCEL = 'FETCH_BALANCE_SHEET_XLS_EXPORT_CANCEL';
