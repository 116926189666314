export enum DocumentType {
  QUOTE = 'quote',
  INVOICE = 'invoice',
  RECURRING_INVOICE = 'recurring_invoice',
  CREDIT_NOTE = 'credit_note',
  DELIVERY_NOTE = 'delivery_note',
  CONTRACT_NOTE = 'contract_note',
  RECEIPT = 'receipt',
  SUPPLIER_CREDIT_NOTE = 'supplier_credit_note',
  TEMPLATE = 'template',
}

export const DocumentTypePlural = {
  [DocumentType.QUOTE]: 'quotes',
  [DocumentType.INVOICE]: 'invoices',
  [DocumentType.RECURRING_INVOICE]: 'recurring-invoices',
  [DocumentType.CREDIT_NOTE]: 'credit-notes',
  [DocumentType.CONTRACT_NOTE]: 'contract-notes',
  [DocumentType.DELIVERY_NOTE]: 'delivery-notes',
  [DocumentType.TEMPLATE]: 'templates',
  [DocumentType.RECEIPT]: 'receipts',
  [DocumentType.SUPPLIER_CREDIT_NOTE]: 'supplier-credit-notes',
};
