// fetch op list
export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_OP_LIST_PENDING = 'FETCH_OP_LIST_PENDING';
export const FETCH_OP_LIST_FULFILLED = 'FETCH_OP_LIST_FULFILLED';
export const FETCH_OP_LIST_ERROR = 'FETCH_OP_LIST_ERROR';
export const FETCH_TABLE_META = 'FETCH_TABLE_META';

// fetch op list export
export const FETCH_OP_LIST_PDF_EXPORT = 'FETCH_OP_LIST_PDF_EXPORT';
export const FETCH_OP_LIST_PDF_EXPORT_SUCCESS = 'FETCH_OP_LIST_PDF_EXPORT_SUCCESS';
export const FETCH_OP_LIST_PDF_EXPORT_FAILURE = 'FETCH_OP_LIST_PDF_EXPORT_FAILURE';

export const FETCH_OP_LIST_XLS_EXPORT = 'FETCH_OP_LIST_XLS_EXPORT';
export const FETCH_OP_LIST_XLS_EXPORT_SUCCESS = 'FETCH_OP_LIST_XLS_EXPORT_SUCCESS';
export const FETCH_OP_LIST_XLS_EXPORT_FAILURE = 'FETCH_OP_LIST_XLS_EXPORT_FAILURE';

export const FETCH_ADDRESS_OP_LIST_PDF_EXPORT = 'FETCH_ADDRESS_OP_LIST_PDF_EXPORT';
export const FETCH_ADDRESS_OP_LIST_PDF_EXPORT_SUCCESS = 'FETCH_ADDRESS_OP_LIST_PDF_EXPORT_SUCCESS';
export const FETCH_ADDRESS_OP_LIST_PDF_EXPORT_FAILURE = 'FETCH_ADDRESS_OP_LIST_PDF_EXPORT_FAILURE';

export const POST_OP_LIST_DEEP_BOX = 'POST_OP_LIST_DEEP_BOX';
export const POST_OP_LIST_DEEP_BOX_SUCCESS = 'POST_OP_LIST_DEEP_BOX_SUCCESS';
export const POST_OP_LIST_DEEP_BOX_FAILURE = 'POST_OP_LIST_DEEP_BOX_FAILURE';
