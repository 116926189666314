<template>
  <aba-dialog
    :closable="true"
    :dialog="dialog"
    :options="{ width: 600 }"
    :subtitle="$tc('texts.news_in_abaninja')"
    :title="$tc('texts.news')"
  >
    <template #content>
      <v-expansion-panels
        v-model="notificationsPanel"
      >
        <v-expansion-panel
          v-for="(item, index) in notifications"
          :key="index"
        >
          <v-expansion-panel-header>
            {{ item.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <span v-html="item.text"></span>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <template #buttons>
      <v-btn color="primary" depressed @click="read()">
        {{ $t('texts.news_read') }}
      </v-btn>
    </template>
  </aba-dialog>
</template>
<script>
import AbaDialog from '@/layouts/AbaDialog.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('user');

export default {
  components: {
    AbaDialog,
  },
  data() {
    return {
      dialog: { open: false },
      notificationsPanel: 0,
    };
  },
  computed: {
    ...mapState(['notifications']),
  },
  created() {
    this.fetchNotifications().then(() => {
      this.dialog.open = this.notifications.length > 0;
    });
  },
  methods: {
    ...mapActions([
      'fetchNotifications',
      'notificationRead',
    ]),
    read() {
      this.dialog.open = false;
      this.notificationRead();
    },
  },
};
</script>
