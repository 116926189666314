export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const FETCH_PAYMENT_PENDING = 'FETCH_PAYMENT_PENDING';
export const FETCH_PAYMENT_FULFILLED = 'FETCH_PAYMENT_FULFILLED';
export const FETCH_PAYMENT_ERROR = 'FETCH_PAYMENT_ERROR';

export const FETCH_ACTIONS_PENDING = 'FETCH_ACTIONS_PENDING';
export const FETCH_ACTIONS_FULFILLED = 'FETCH_ACTIONS_FULFILLED';
export const FETCH_ACTIONS_ERROR = 'FETCH_ACTIONS_ERROR';

export const STORE_PAYMENT_PENDING = 'STORE_PAYMENT_PENDING';
export const STORE_PAYMENT_FULFILLED = 'STORE_PAYMENT_FULFILLED';
export const STORE_PAYMENT_ERROR = 'STORE_PAYMENT_ERROR';

export const STORE_ACTION_PENDING = 'STORE_ACTION_PENDING';
export const STORE_ACTION_FULFILLED = 'STORE_ACTION_FULFILLED';
export const STORE_ACTION_ERROR = 'STORE_ACTION_ERROR';
