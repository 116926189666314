import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_BANK_ACCOUNT
  [types.FETCH_BANK_ACCOUNT_PENDING](state) {
    state.fetchBankAccountPending = true;
  },
  [types.FETCH_BANK_ACCOUNT_FULFILLED](state, { data }) {
    state.bankAccount = data;
    state.fetchBankAccountPending = false;
  },
  [types.FETCH_BANK_ACCOUNT_ERROR](state) {
    state.fetchBankAccountPending = false;
  },
  // STORE_BANK_ACCOUNT
  [types.STORE_BANK_ACCOUNT_PENDING](state) {
    state.storeBankAccountPending = true;
  },
  [types.STORE_BANK_ACCOUNT_FULFILLED](state, { data }) {
    state.bankAccount = data;
    state.storeBankAccountPending = false;
  },
  [types.STORE_BANK_ACCOUNT_ERROR](state) {
    state.storeBankAccountPending = false;
  },
  // DELETE_BANK_ACCOUNT
  [types.DELETE_BANK_ACCOUNT_PENDING](state) {
    state.deleteBankAccountPending = true;
  },
  [types.DELETE_BANK_ACCOUNT_FULFILLED](state) {
    state.deleteBankAccountPending = false;
  },
  [types.DELETE_BANK_ACCOUNT_ERROR](state) {
    state.deleteBankAccountPending = false;
  },
  // FETCH_BANK_INFORMATION
  [types.FETCH_BANK_INFORMATION_PENDING](state) {
    state.fetchBankInformationPending = true;
  },
  [types.FETCH_BANK_INFORMATION_FULFILLED](state, bankInformation) {
    state.bankInformation = bankInformation;
    state.fetchBankInformationPending = false;
  },
  [types.FETCH_BANK_INFORMATION_ERROR](state) {
    state.bankInformation = false;
    state.fetchBankInformationPending = false;
  },
  // LOGIN
  [types.LOGIN_EBANKING_PENDING](state) {
    state.loginEbankingPending = true;
  },
  [types.LOGIN_EBANKING_FULFILLED](state, { data }) {
    state.loginEbankingChangePassword = false;
    state.loginEbankingChoose = false;

    // login step contract successful
    if (!state.loginEbankingSuccessful) {
      state.loginEbankingSuccessful = true;
    }
    // login needs new password
    if (data.state === 'CHANGE_PASSWORD') {
      state.loginEbankingChangePassword = true;
    }
    // login needs choose of challenge
    if (data.state === 'CHOOSE') {
      state.loginEbankingChoose = true;
    }
    // login completed successfully
    if (state.loginEbankingSuccessful && data.state === 'SUCCESS') {
      state.loginEbanking = data;
      state.loginEbankingConfirmationSuccessful = true;
    }

    state.ebankingLogin = data;
    state.loginEbankingPending = false;
  },
  [types.LOGIN_EBANKING_ERROR](state) {
    state.loginEbankingPending = false;
    state.ebankingLogin.state = 'ERROR';
  },
  // BLINK_LOGIN
  [types.BLINK_LOGIN_PENDING](state) {
    state.blinkLoginPending = true;
  },
  [types.BLINK_LOGIN_FULFILLED](state) {
    state.blinkLoginPending = false;
  },
  [types.BLINK_LOGIN_ERROR](state) {
    state.blinkLoginPending = false;
  },
  // OAUTH_LOGIN
  [types.OAUTH_LOGIN_PENDING](state) {
    state.oauthLoginPending = true;
  },
  [types.OAUTH_LOGIN_FULFILLED](state) {
    state.oauthLoginPending = false;
  },
  [types.OAUTH_LOGIN_ERROR](state) {
    state.oauthLoginPending = false;
  },
  // OAUTH_LOGOUT
  [types.OAUTH_LOGOUT_PENDING](state) {
    state.oauthLogoutPending = true;
  },
  [types.OAUTH_LOGOUT_FULFILLED](state) {
    state.oauthLogoutPending = false;
  },
  [types.OAUTH_LOGOUT_ERROR](state) {
    state.oauthLogoutPending = false;
  },
  // STORE_OAUTH_AUTHORIZATION
  [types.STORE_OAUTH_AUTHORIZATION_PENDING](state) {
    state.storeOauthAuthorizationPending = true;
  },
  [types.STORE_OAUTH_AUTHORIZATION_FULFILLED](state) {
    state.storeOauthAuthorizationPending = false;
  },
  [types.STORE_OAUTH_AUTHORIZATION_ERROR](state) {
    state.storeOauthAuthorizationPending = false;
  },
  [types.STORE_OAUTH_CALLBACK_BLINK_PENDING](state) {
    state.storeOauthCallbackBlinkPending = true;
  },
  [types.STORE_OAUTH_CALLBACK_BLINK_FULFILLED](state) {
    state.storeOauthCallbackBlinkPending = false;
  },
  [types.STORE_OAUTH_CALLBACK_BLINK_ERROR](state) {
    state.storeOauthCallbackBlinkPending = false;
  },
  // PUSH_EBANKING
  [types.PUSH_EBANKING_PAYMENTS_PENDING](state) {
    state.pushbankingPaymentsPending = true;
  },
  [types.PUSH_EBANKING_PAYMENTS_FULFILLED](state, data) {
    state.ebankingPayments = data;
    state.pushbankingPaymentsPending = false;
  },
  [types.PUSH_EBANKING_PAYMENTS_ERROR](state) {
    state.pushbankingPaymentsPending = false;
  },
  // FETCH_TRANSACTIONS
  [types.FETCH_EBANKING_TRANSACTIONS_PENDING](state) {
    state.ebankingTransactionsError = false;
    state.fetchEbankingTransactionsPending = true;
  },
  [types.FETCH_EBANKING_TRANSACTIONS_FULFILLED](state, data) {
    state.ebankingTransactions = data;
    state.fetchEbankingTransactionsPending = false;
  },
  [types.FETCH_EBANKING_TRANSACTIONS_ERROR](state) {
    state.ebankingTransactionsError = true;
    state.fetchEbankingTransactionsPending = false;
  },
  // SYNC_EBANKING
  [types.SYNC_EBANKING_PENDING](state, id) {
    state.syncEbankingPending = true;
    state.syncEbankingPendingIds.push(id);
  },
  [types.SYNC_EBANKING_FULFILLED](state, id) {
    state.syncEbankingPending = false;
    state.syncEbankingPendingIds = state.syncEbankingPendingIds.filter((item) => item !== id);
  },
  [types.SYNC_EBANKING_ERROR](state, id) {
    state.syncEbankingPending = false;
    state.syncEbankingPendingIds = state.syncEbankingPendingIds.filter((item) => item !== id);
  },
  // CONFIRM_EBANKING_PAYMENTS
  [types.CONFIRM_EBANKING_PAYMENTS_PENDING](state) {
    state.confirmEbankingPaymentsPending = true;
  },
  [types.CONFIRM_EBANKING_PAYMENTS_FULFILLED](state, { data }) {
    state.ebankingPayments = data;
    state.confirmEbankingPaymentsPending = false;
  },
  [types.CONFIRM_EBANKING_PAYMENTS_ERROR](state) {
    state.confirmEbankingPaymentsPending = false;
  },
  // LOGOUT
  [types.LOGOUT_EBANKING_PENDING](state) {
    state.logoutEbankingPending = true;
  },
  [types.LOGOUT_EBANKING_FULFILLED](state) {
    state.logoutEbankingPending = false;
  },
  [types.LOGOUT_EBANKING_ERROR](state) {
    state.logoutEbankingPending = false;
  },
  [types.RESET_EBANKING](state) {
    state.loginEbankingPending = false;
    state.loginEbankingChangePassword = false;
    state.loginEbankingChoose = false;
    state.loginEbankingSuccessful = false;
    state.loginEbankingConfirmationSuccessful = false;
    state.ebankingLogin = {};
    state.ebankingPayments = {};
    state.ebankingTransactions = {};
    state.ebankingTransactionsError = false;
  },
  // CHECK_ACCOUNT_NUMBER
  [types.CHECK_ACCOUNT_NUMBER_PENDING](state) {
    state.checkAccountNumberPending = true;
  },
  [types.CHECK_ACCOUNT_NUMBER_FULFILLED](state) {
    state.checkAccountNumberPending = false;
  },
  [types.CHECK_ACCOUNT_NUMBER_ERROR](state) {
    state.checkAccountNumberPending = false;
  },
  // VALIDATE_BIC_NUMBER
  [types.VALIDATE_ACCOUNT_NUMBER_PENDING](state) {
    state.accountNumberValidationPending = true;
  },
  [types.VALIDATE_ACCOUNT_NUMBER_FULFILLED](state) {
    state.accountNumberValidationPending = false;
  },
  // VALIDATE_BIC_NUMBER
  [types.VALIDATE_BIC_NUMBER_PENDING](state) {
    state.bicNumberValidationPending = true;
  },
  [types.VALIDATE_BIC_NUMBER_FULFILLED](state) {
    state.bicNumberValidationPending = false;
  },
  // VALIDATE_QR_ACCOUNT_NUMBER
  [types.VALIDATE_QR_ACCOUNT_NUMBER_PENDING](state) {
    state.validateQrAccountNumberPending = true;
  },
  [types.VALIDATE_QR_ACCOUNT_NUMBER_FULFILLED](state) {
    state.validateQrAccountNumberPending = false;
  },
  // VALIDATE_QR_ACCOUNT_NUMBER
  [types.VALIDATE_QR_BESR_ID_PENDING](state) {
    state.validateQRBesrIdPending = true;
  },
  [types.VALIDATE_QR_BESR_ID_FULFILLED](state) {
    state.validateQRBesrIdPending = false;
  },
};

export default mutations;
