import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialPending = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialPending = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialPending = false;
  },
  [types.FETCH_TOP_CLIENTS_PENDING](state, isPending) {
    state.topClientsPending = isPending;
  },
  [types.FETCH_TOP_CLIENTS](state, { data }) {
    state.topClients = data;
  },
  [types.FETCH_TOP_PRODUCTS_PENDING](state, isPending) {
    state.topProductsPending = isPending;
  },
  [types.FETCH_TOP_PRODUCTS](state, { data }) {
    state.topProducts = data;
  },
  [types.FETCH_CUSTOMER_BALANCE_PENDING](state, isPending) {
    state.customerBalancePending = isPending;
  },
  [types.FETCH_CUSTOMER_BALANCE](state, { data, meta }) {
    // Check Standard Response
    state.customerBalance = { ...data, totals: meta.totals };
  },
  [types.FETCH_SUPPLIER_BALANCE_PENDING](state, isPending) {
    state.supplierBalancePending = isPending;
  },
  [types.FETCH_SUPPLIER_BALANCE](state, { data, meta }) {
    // Check Standard Response
    state.supplierBalance = { ...data, totals: meta.totals };
  },
  [types.FETCH_BALANCE_PENDING](state, isPending) {
    state.balancePending = isPending;
  },
  [types.FETCH_TOTALS_PENDING](state, { type, isPending }) {
    state.totalsPending[type] = isPending;
  },
  [types.FETCH_TOTALS](state, { data }) {
    state.totals = { ...state.totals, ...data };
  },
  [types.FETCH_FORM](state, { data }) {
    state.form.currencies = data.currencies;
  },
  [types.FETCH_DATA_PENDING](state, isPending) {
    state.dataPending = isPending;
  },
  [types.FETCH_DATA](state, { data }) {
    state.cashpointRevenues = data.cashpointRevenues;
    state.balances = data.balances;
    state.totalInvoice = data.totalInvoice;
    state.oldActivities = data.oldActivities;
    state.lastpayments = data.lastpayments;
  },
  [types.FETCH_ACTIVITIES_PENDING](state, isPending) {
    state.activitiesPending = isPending;
  },
  [types.FETCH_ACTIVITIES](state, { data }) {
    state.activities = data;
  },
  [types.FETCH_FIRST_STEPS_PENDING](state, isPending) {
    state.firstStepsPending = isPending;
  },
  [types.FETCH_FIRST_STEPS](state, { data }) {
    state.firstSteps = data;
  },
  [types.FETCH_DASHBOARD_SETTINGS_PENDING](state, isPending) {
    state.fetchDashboardSettingsPending = isPending;
  },
  [types.FETCH_DASHBOARD_SETTINGS](state, { data }) {
    const jsonData = data ? JSON.parse(data.value) : null;
    state.dashboardWidgets = jsonData ? jsonData.widgets : state.defaultDashboardWidgetsLayout;
  },
  [types.FETCH_DASHBOARD_STATUS_TOTAL_PENDING](state, isPending) {
    state.statusTotalPending = isPending;
  },
  [types.FETCH_DASHBOARD_STATUS_TOTAL](state, { data }) {
    state.statusTotal = data;
  },
  [types.FETCH_DASHBOARD_POS_DATA_PENDING](state, isPending) {
    state.posDataPending = isPending;
  },
  [types.FETCH_DASHBOARD_POS_DATA](state, { data, meta }) {
    state.posData = { data, ...meta };
  },
  [types.FETCH_DASHBOARD_SALES_PENDING](state, isPending) {
    state.salesPending = isPending;
  },
  [types.FETCH_DASHBOARD_SALES](state, { data, meta }) {
    state.sales = { data, ...meta };
  },
  [types.FETCH_LIQUIDITY_SALES_PENDING](state, isPending) {
    state.liquidityPending = isPending;
  },
  [types.FETCH_LIQUIDITY_SALES](state, { data, meta }) {
    const dataArray = Object.entries(data).map(([date, value]) => ({ date, ...value }));
    state.liquidity = { data: dataArray, ...meta };
  },
};

export default mutations;
