import statusList from '@/components/status/StatusList';
import contractNotes from '@/store/modules/customers/contract-notes';
import axios from 'axios';
import invoice from './invoice';
import invoiceImport from './invoice-import';
import jsonImport from './json-import';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';
const FETCH_TABLE_META = 'FETCH_TABLE_META';

const FETCH_BULK_ACTIONS_FULFILLED = 'FETCH_BULK_ACTIONS_FULFILLED';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

const FETCH_ADDRESS_CREDITNOTES_PENDING = 'FETCH_ADDRESS_CREDITNOTES_PENDING';
const FETCH_ADDRESS_CREDITNOTES_FULFILLED = 'FETCH_ADDRESS_CREDITNOTES_FULFILLED';
const FETCH_ADDRESS_CREDITNOTES_ERROR = 'FETCH_ADDRESS_CREDITNOTES_ERROR';

const FETCH_ADDRESS_INVOICES_PENDING = 'FETCH_ADDRESS_INVOICES_PENDING';
const FETCH_ADDRESS_INVOICES_FULFILLED = 'FETCH_ADDRESS_INVOICES_FULFILLED';
const FETCH_ADDRESS_INVOICES_ERROR = 'FETCH_ADDRESS_INVOICES_ERROR';

const FETCH_ACTION_PENDING = 'FETCH_ACTION_PENDING';
const FETCH_ACTION_ERROR = 'FETCH_ACTION_ERROR';

const SEARCH_INVOICES_PENDING = 'SEARCH_INVOICES_PENDING';
const SEARCH_INVOICES_FULFILLED = 'SEARCH_INVOICES_FULFILLED';
const SEARCH_INVOICES_ERROR = 'SEARCH_INVOICES_ERROR';

const VALIDATE_INVOICE_NUMBER_PENDING = 'VALIDATE_INVOICE_NUMBER_PENDING';
const VALIDATE_INVOICE_NUMBER_FULFILLED = 'VALIDATE_INVOICE_NUMBER_FULFILLED';
const VALIDATE_INVOICE_NUMBER_ERROR = 'VALIDATE_INVOICE_NUMBER_ERROR';

export default {
  namespaced: true,
  state: {
    bulkActions: [],
    initialPending: false,
    listPending: false,
    fetchAddressCreditnotesPending: false,
    fetchAddressInvoicesPending: false,
    list: [],
    meta: {},
    searchPending: false,
    searchList: [],
    validateInvoiceNumberPending: false,
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
      state.list = [];
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    [FETCH_ADDRESS_CREDITNOTES_PENDING](state) {
      state.fetchAddressCreditnotesPending = true;
    },
    [FETCH_ADDRESS_CREDITNOTES_FULFILLED](state) {
      state.fetchAddressCreditnotesPending = false;
    },
    [FETCH_ADDRESS_CREDITNOTES_ERROR](state) {
      state.fetchAddressCreditnotesPending = false;
    },
    [FETCH_ADDRESS_INVOICES_PENDING](state) {
      state.fetchAddressInvoicesPending = true;
    },
    [FETCH_ADDRESS_INVOICES_FULFILLED](state) {
      state.fetchAddressInvoicesPending = false;
    },
    [FETCH_ADDRESS_INVOICES_ERROR](state) {
      state.fetchAddressInvoicesPending = false;
    },
    [FETCH_TABLE_META](state, { meta, data, key }) {
      if (meta) {
        state.meta[key] = {
          sortDesc: [meta.direction === 'DESC'],
          itemsPerPage: parseInt(meta.per_page, 10),
          sortBy: [meta.sort],
          page: parseInt(meta.page, 10),
          search: meta.search,
          extendSearch: meta.extend_search,
          maxPage: parseInt(meta.max_page, 10),
          totalItems: data.length ? meta.max_page * meta.per_page : 0,
        };
      }
    },
    [FETCH_BULK_ACTIONS_FULFILLED](state, { data }) {
      state.bulkActions = Object.keys(data).map((key) => ({ id: key, text: data[key] }));
    },
    [FETCH_ACTION_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_ACTION_ERROR](state) {
      state.listPending = false;
    },
    [SEARCH_INVOICES_PENDING](state) {
      state.searchPending = true;
    },
    [SEARCH_INVOICES_FULFILLED](state, { data }) {
      state.searchList = data;
      state.searchPending = false;
    },
    [SEARCH_INVOICES_ERROR](state) {
      state.searchPending = false;
    },
    [VALIDATE_INVOICE_NUMBER_PENDING](state) {
      state.validateInvoiceNumberPending = true;
    },
    [VALIDATE_INVOICE_NUMBER_FULFILLED](state) {
      state.validateInvoiceNumberPending = false;
    },
    [VALIDATE_INVOICE_NUMBER_ERROR](state) {
      state.validateInvoiceNumberPending = false;
    },
  },
  actions: {
    async fetchInitial({ rootState, commit, dispatch }, params) {
      commit(FETCH_INITIAL_PENDING);
      try {
        const [bulkActions] = await Promise.all([
          axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/bulkactions/${params.typeSingle}`),
          dispatch('fetchList', params),
        ]);
        commit(FETCH_BULK_ACTIONS_FULFILLED, bulkActions);
        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve(bulkActions);
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },

    async fetchList({ rootState, state, commit }, {
      type, list, addressId, relatedInvoiceId, meta,
    }) {
      commit(FETCH_LIST_PENDING);
      try {
        let params = {};
        const metaKey = `${type}-${list}-${addressId || relatedInvoiceId || null}`;
        const metaValue = meta || state.meta[metaKey];
        const extendedSearch = {};
        const search = [];
        if (metaValue) {
          if (metaValue.search) {
            extendedSearch.status_name_OR = metaValue.search.filter(
              (filterValue) => {
                const hasFilter = statusList.allStatus().find(
                  (status) => status.text === filterValue,
                );
                if (!hasFilter) {
                  search.push(filterValue);
                }
                return hasFilter;
              },
            );
          }

          params = {
            direction: metaValue.sortDesc && !metaValue.sortDesc[0] ? 'ASC' : 'DESC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy && metaValue.sortBy[0] ? metaValue.sortBy[0] : '',
            page: metaValue.page,
            search,
          };
        }
        // prepare extended search parameter
        const extendedSearchParameter = Object.keys(extendedSearch).map(
          (extendedSearchKey) => {
            if (!extendedSearch[extendedSearchKey]) {
              return undefined;
            }
            return `extend_search[${extendedSearchKey}]=${extendedSearch[extendedSearchKey].join()}`;
          },
        ).filter((value) => value !== undefined).join('&');

        let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/customers`;
        url = list === 'address' ? `${url}/${addressId}` : url;
        url = `${url}/${type}`;
        url = list === 'related' ? `${url}/${relatedInvoiceId}` : url;
        url = list !== 'address' ? `${url}/${list}` : url;

        url = extendedSearchParameter ? `${url}?${extendedSearchParameter}` : url;
        const data = await axios.get(url, { params });
        if (data.meta) {
          if (metaValue) {
            data.meta.search = metaValue.search; // keep current search params
          }
          commit(FETCH_TABLE_META, { meta: data.meta, data: data.data, key: metaKey });
        }
        commit(FETCH_LIST_FULFILLED, data);
        return Promise.resolve(data);
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },

    async fetchAddressCreditnotes({ rootState, commit }, { addressId, currencyCode }) {
      commit(FETCH_ADDRESS_CREDITNOTES_PENDING);
      try {
        const baseUrl = `accounts/${rootState.definitions.account.account.uuid}`;
        const path = `pagination/customers/${addressId}/credit_notes/open`;
        const params = `extend_search[currency_code]=${currencyCode}`;
        const response = await axios.get(`/${baseUrl}/${path}?${params}`);
        commit(FETCH_ADDRESS_CREDITNOTES_FULFILLED);
        return response.data;
      } catch (error) {
        commit(FETCH_ADDRESS_CREDITNOTES_ERROR);
        return Promise.reject(error);
      }
    },
    async fetchAddressInvoices({ rootState, commit }, { addressId, currencyCode }) {
      commit(FETCH_ADDRESS_INVOICES_PENDING);
      try {
        const baseUrl = `accounts/${rootState.definitions.account.account.uuid}`;
        const path = `pagination/customers/${addressId}/invoices/open`;
        const params = `extend_search[currency_code]=${currencyCode}`;
        const response = await axios.get(`/${baseUrl}/${path}?${params}`);
        commit(FETCH_ADDRESS_INVOICES_FULFILLED);
        return response.data;
      } catch (error) {
        commit(FETCH_ADDRESS_INVOICES_ERROR);
        return Promise.reject(error);
      }
    },
    async fetchBulk({ rootState, commit, dispatch }, { list, params }) {
      commit(FETCH_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/invoices/${list.typeSingular}/bulk`, params);
        dispatch('fetchList', list);
        dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
        return Promise.resolve(response.data);
      } catch ({ data }) {
        commit(FETCH_ACTION_ERROR, data);
        // TODO: harmonize error message object key
        const message = data.data || data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(data);
      }
    },
    async fetchSingle({ rootState, commit, dispatch }, { list, params }) {
      commit(FETCH_ACTION_PENDING);
      try {
        const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/invoices/${list.typeSingular}/action`, params);
        dispatch('fetchList', list);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_ACTION_ERROR, error);
        // TODO: harmonize error message object key
        const message = error.data.data || error.data.message;
        dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async searchInvoices({ rootState, commit }, { types, search, abortController }) {
      const controller = abortController || new AbortController();
      commit(SEARCH_INVOICES_PENDING);
      try {
        commit(SEARCH_INVOICES_FULFILLED, await axios.get(
          `/accounts/${rootState.definitions.account.account.uuid}/invoices/search?entity_types=${types}&q=${search}`,
          {
            signal: controller.signal,
          },
        ));
      } catch (error) {
        commit(SEARCH_INVOICES_ERROR);
      }
    },
    async validateInvoiceNumber({ rootState, commit }, {
      invoiceNumber, invoiceId, type, isImport,
    }) {
      commit(VALIDATE_INVOICE_NUMBER_PENDING);
      try {
        const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/invoices/checknumber/${type}`, {
          params: {
            search: invoiceNumber,
            id: invoiceId,
            isImport,
          },
        });
        commit(VALIDATE_INVOICE_NUMBER_FULFILLED, response);
        return Promise.resolve({ valid: true });
      } catch (error) {
        commit(VALIDATE_INVOICE_NUMBER_ERROR, error);
        return Promise.resolve({ valid: false });
      }
    },
  },
  modules: {
    invoice,
    invoiceImport,
    contractNotes,
    jsonImport,
  },
};
