const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const AbaNetCallback = () => import('@/pages/abanet/Callback.vue');

export default {
  path: '/services',
  component: PassThroughKeyed,
  name: 'abanet',
  redirect: { name: 'error' },
  children: [
    {
      path: 'abanet/cb',
      name: 'abanet-callback',
      component: AbaNetCallback,
    },
  ],
};
