import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialLoading: false,
    initialError: false,
    precalculatePending: false,
    generateInvoicePending: false,
    generateDownloadPending: false,
    storeInvoicePending: false,
    printInvoicePending: false,
    sendInvoicePending: false,
    moreLoading: false,
    fetchSingleInvoicePending: false,
    fetchActionsPending: false,
    fetchInvoicePending: false,
    fetchRecurringPending: false,
    invoice: null,
    calculation: {
      position_total: '0.00',
      discount: '',
      taxes: [],
      rounding_difference: '',
      total: '0.00',
    },
    nextNumber: '',
    pdfPreviewUrl: '',
    pdfDownloadUrl: '',
    addresses: [],
    products: [],
    tax_rates: [],
    singleActions: {},
    activities: [],
    recurring: [],
    meta: {},
  },
  actions,
  mutations,
};
