import statusList from '@/components/status/StatusList';
import axios from 'axios';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_TABLE_META = 'FETCH_TABLE_META';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    listPending: false,
    list: [],
    meta: {},
  },
  mutations: {
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
      state.list = [];
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_TABLE_META](state, { meta, key }) {
      if (meta) {
        state.meta[key] = {
          sortDesc: [meta.direction === 'DESC'],
          itemsPerPage: parseInt(meta.per_page, 10),
          sortBy: [meta.sort],
          page: parseInt(meta.page, 10),
          search: meta.search,
          extendSearch: meta.extend_search,
          maxPage: parseInt(meta.max_page, 10),
          totalItems: meta.max_page * meta.per_page,
        };
      }
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await dispatch('fetchList', {});
        commit(FETCH_INITIAL_FULFILLED);
        return Promise.resolve();
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, commit, state }, { meta }) {
      commit(FETCH_LIST_PENDING);
      try {
        let params = {};
        const metaKey = 'bankFiles';
        const metaValue = meta || state.meta[metaKey];
        const extendedSearch = {};
        const search = [];
        if (metaValue) {
          extendedSearch.status_name_OR = metaValue.search.filter(
            (filterValue) => {
              const hasFilter = statusList.allStatus.find(
                (status) => status.text === filterValue,
              );
              if (!hasFilter) {
                search.push(filterValue);
              }
              return hasFilter;
            },
          );
          params = {
            direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
            per_page: metaValue.itemsPerPage,
            sort: metaValue.sortBy[0],
            page: metaValue.page,
            search,
          };
        }
        // prepare extended search parameter
        const extendedSearchParameter = Object.keys(extendedSearch).map(
          (extendedSearchKey) => {
            if (!extendedSearch[extendedSearchKey]) {
              return undefined;
            }
            return `extend_search[${extendedSearchKey}]=${extendedSearch[extendedSearchKey].join()}`;
          },
        ).filter((value) => value !== undefined).join('&');

        let url = `/accounts/${rootState.definitions.account.account.uuid}/pagination/bankfiles`;
        url = extendedSearchParameter ? `${url}?${extendedSearchParameter}` : url;
        const response = await axios.get(url, { params });
        if (response.meta) {
          if (metaValue) {
            response.meta.search = metaValue.search; // keep current search params
          }
          commit(FETCH_TABLE_META, { meta: response.meta, key: metaKey });
        }
        commit(FETCH_LIST_FULFILLED, response);
        return Promise.resolve(response);
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
  },
};
