import accountStatement from '@/router/accounting/account-statement';
import journal from '@/router/accounting/journal';
import { accountingInboxRoutes } from '@/router/accounting/inbox';
import report from './accounting/report';

const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const Accounting = () => import('@/pages/accounting/Accounting.vue');
const Preset = () => import('@/pages/accounting/preset/Preset.vue');

export default {
  path: '/accounting',
  name: 'accounting',
  component: Accounting,
  children: [
    ...accountStatement,
    ...journal,
    ...report,
    ...accountingInboxRoutes,
    {
      path: 'vat-report',
      name: 'accounting-vat-report',
      component: PassThroughKeyed,
      children: [
        {
          path: 'vat-journal',
          name: 'vat-journal',
          redirect: '/v3/accounting/vat/journal',
          meta: {
            title: 'vat_journal',
          },
        },
        {
          path: 'vat-control-report',
          name: 'vat-control-report',
          redirect: '/v3/accounting/vat/control-report',
          meta: {
            title: 'vat_control_report',
          },
        },
        {
          path: 'vat-adjustment',
          name: 'vat-adjustment',
          redirect: '/v3/accounting/vat/reconciliation',
          meta: {
            title: 'vat_adjustment',
          },
        },
      ],
    },
    {
      path: ':id/preset',
      name: 'preset',
      component: Preset,
      meta: {
        title: 'accounting_preset',
      },
    },
  ],
};
