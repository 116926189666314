import actions from './actions';
import group from './group';
import mutations from './mutations';
import product from './product';
import initialState from './state';

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations,
  actions,
  modules: {
    product,
    group,
  },
};
