export const UPDATE_TABLE_SETTING = 'UPDATE_TABLE_SETTING';

export const SET_INVOICE_ITEMS = 'SET_INVOICE_ITEMS';
export const ADD_INVOICE_ITEM = 'ADD_INVOICE_ITEM';

export const ADD_GHOST_ITEM = 'ADD_GHOST_ITEM';
export const RESET_GHOST_ITEMS = 'RESET_GHOST_ITEMS';

export const REMOVE_INVOICE_ITEM = 'REMOVE_INVOICE_ITEM';
export const REMOVE_NESTED_INVOICE_ITEM = 'REMOVE_NESTED_INVOICE_ITEM';
export const REMOVE_GHOST_ITEM = 'REMOVE_GHOST_ITEM';

export const UPDATE_INVOICE_ITEM_VALUE = 'UPDATE_INVOICE_ITEM_VALUE';
export const UPDATE_INVOICE_ITEM_VALUES = 'UPDATE_INVOICE_ITEM_VALUES';
export const UPDATE_GHOST_ITEM_VALUE = 'UPDATE_GHOST_ITEM_VALUE';
export const UPDATE_GHOST_ITEM_VALUES = 'UPDATE_GHOST_ITEM_VALUES';

export const UPDATE_GROUP_INVOICE_ITEM_VALUE = 'UPDATE_GROUP_INVOICE_ITEM_VALUE';
export const UPDATE_GROUP_INVOICE_ITEM_VALUES = 'UPDATE_GROUP_INVOICE_ITEM_VALUES';

export const UNGROUP_GROUP = 'UNGROUP_GROUP';
