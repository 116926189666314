import { useKeycloak } from '@/helpers/keycloak';
import type { InternalAxiosRequestConfig } from 'axios';

export const requestBearerTokenInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (config.baseURL !== import.meta.env.VITE_URL) {
    return config;
  }

  const newConfig = config;

  newConfig.headers['Content-Type'] = 'application/json';

  const keycloak = useKeycloak();
  if (keycloak.token) {
    newConfig.headers.Authorization = `Bearer ${keycloak.token}`;
  }

  return newConfig;
};
