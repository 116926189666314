import axios from 'axios';
import * as types from './types';

export default {
  fetchAccountProposal({ rootState, commit }, { accountProposalId, abortController }) {
    commit(types.FETCH_ACCOUNT_PROPOSAL);
    const controller = abortController || new AbortController();

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-accounts/${accountProposalId}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_PROPOSAL_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_PROPOSAL_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_ACCOUNT_PROPOSAL_FAILURE, error);
      return Promise.reject(error);
    });
  },

  postAccountProposal({ rootState, commit }, accountProposal) {
    commit(types.POST_ACCOUNT_PROPOSAL);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-accounts`, accountProposal).then((response) => {
      commit(types.POST_ACCOUNT_PROPOSAL_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.POST_ACCOUNT_PROPOSAL_FAILURE, error);
      return Promise.reject(error);
    });
  },

  patchAccountProposal({ rootState, commit }, { accountProposal, id }) {
    commit(types.PATCH_ACCOUNT_PROPOSAL);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-accounts/${id}`, accountProposal)
      .then((response) => {
        commit(types.PATCH_ACCOUNT_PROPOSAL_SUCCESS, response.data);
        return Promise.resolve(response.data);
      }).catch((error) => {
        commit(types.PATCH_ACCOUNT_PROPOSAL_FAILURE, error);
        return Promise.reject(error);
      });
  },

  deleteAccountProposal({ rootState, commit }, accountProposalId) {
    commit(types.DELETE_ACCOUNT_PROPOSAL);

    return axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/accounting/base-accounts/${accountProposalId}`)
      .then((response) => {
        commit(types.DELETE_ACCOUNT_PROPOSAL_SUCCESS, response);
        return Promise.resolve(response);
      }).catch((error) => {
        commit(types.DELETE_ACCOUNT_PROPOSAL_FAILURE, error);
        return Promise.reject(error);
      });
  },

};
