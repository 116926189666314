<template>
  <v-slide-y-transition>
    <v-snackbar
      v-if="currentNotification"
      :color="currentNotification.type"
      :timeout="-1"
      :value="true"
      centered
      top
    >
      <span style="display:block" v-html="filters.nl2br(currentNotification.message)"></span>
    </v-snackbar>
  </v-slide-y-transition>
</template>
<script lang="ts" setup>

import type { Notification } from '@/interfaces/notification';
import { useNotificationStore } from '@/stores/user-interface/notification';
import { ref, watch } from 'vue';
import { useFilters } from '@/helpers/use-filters';

const notificationStore = useNotificationStore();
const currentNotification = ref<Notification | null>(null);
const filters = useFilters();

function getNextNotification() {
  currentNotification.value = notificationStore.entities.shift() || null;

  if (currentNotification.value) {
    setTimeout(() => {
      currentNotification.value = null;
      setTimeout(() => {
        getNextNotification();
      }, 250);
    }, currentNotification.value.timeout);
  }
}

watch(
  () => notificationStore.entities.length,
  () => {
    if (!currentNotification.value) {
      getNextNotification();
    }
  },
  {
    immediate: true,
  },
);

</script>
