import type { RouteConfig } from 'vue-router/types/router';
import { DocumentType } from '@/components/Document/types/document-type';

const PassThrough = () => import('@/views/PassThrough.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const InvoicesList = () => import('@/pages/customers/invoices/InvoicesList.vue');
const DocumentImport = () => import('@/pages/document-import/DocumentImport.vue');
const InvoiceImportQueue = () => import('@/pages/customers/invoices/InvoiceImportQueue.vue');
const Invoice = () => import('@/pages/customers/invoices/InvoiceImported.vue');

const invoicesRoutes: Array<RouteConfig> = [
  {
    path: 'invoices',
    alias: ['invoice'],
    component: PassThroughKeyed,
    children: [
      {
        path: 'import',
        name: 'invoice-import',
        component: DocumentImport,
        props: { type: DocumentType.INVOICE },
      },
      {
        path: 'import/:id?',
        name: 'invoice',
        component: Invoice,
        meta: {
          paymentListType: 'invoice',
          title: 'invoice',
        },
      },
      {
        path: 'import/queue/:id',
        name: 'invoice-import-queue',
        component: InvoiceImportQueue,
        props: { type: DocumentType.INVOICE },
        meta: {
          paymentListType: 'invoice',
          title: 'invoices',
        },
      },
    ],
  },
  {
    path: 'invoices',
    component: PassThrough,
    children: [
      {
        path: ':group?',
        name: 'invoices-list',
        component: InvoicesList,
        meta: {
          title: 'invoices',
        },
        beforeEnter(to, _, next) {
          if (!to.params.group) {
            next({ name: 'invoices-list', params: { group: 'open' } });
          } else {
            next();
          }
        },
      },
    ],
  },
];

export default invoicesRoutes;
