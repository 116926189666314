export default {
  appendix: {
    esrBackground: true,
    appendDocuments: true,
  },
  design: {
    fontPrimary: 'Arial',
    sideDistanceTop: 10,
    sideDistanceBottom: 14,
    sideDistanceLeft: 20,
    sideDistanceRight: 12,
    fontSize: 9,
    fontColor: '#000000',
    stripe: false,
    stripeWidth: 10,
    stripeBackgroundColor: '#4290d5',
    h1FontSize: 17,
    h1FontColor: '#000000',
    h1FontWeight: 'normal',
    h2FontSize: 11,
    h2FontColor: '#000000',
    h2FontWeight: 'normal',
    colorPrimary: '#000000',
    colorSecondary: '#000000',
  },
  details: {
    show: true,
    positionTop: 0,
    positionLeft: 100,
    mode: 'cols',
    fontSize: 9,
    fontColor: '#000000',
    showNumber: true,
    showReference: true,
    showDate: true,
    showDueDate: true,
    showDeliveryDate: true,
    showCustomerNumber: false,
    showCustomField1: true,
    showCustomField2: true,
    showCustomField3: true,
    showCustomField4: true,
  },
  footer: {
    show: true,
    background: false,
    showPaging: false,
    pagingColor: '#000000',
    heightFooter: 16,
    backgroundColor: '#dddddd',
    childPosition: 'absolute',
  },
  footerSenderAddress: {
    show: true,
    display: 'cols',
    fontSize: 6,
    fontColor: '#000000',
    positionTop: 5,
    positionLeft: 57,
    showCompanyName: true,
    showAddress: true,
    showPhone: true,
    showEmail: true,
    showWebsite: true,
    showUid: true,
    showVat: true,
  },
  footerText: {
    show: false,
    fontSize: 9,
    fontColor: '#454142',
    positionTop: 0,
    positionLeft: 0,
  },
  footerBankDetails: {
    show: false,
    fontSize: 6,
    fontColor: '#000000',
    positionTop: 0,
    positionLeft: 0,
    showBank: true,
    showIban: true,
    showSwiftBic: true,
    formatIban: true,
  },
  header: {
    heightHeaderPage1: 92,
    heightHeaderPages: 30,
  },
  logo: {
    show: true,
    width: 46,
    height: 34,
    positionTop: 47,
    positionLeft: 0,
  },
  notes: {
    show: true,
    fontSize: 9,
    position: 'after',
    fontColor: '#454142',
  },
  recipientAddress: {
    fontSize: 9,
    positionTop: 48,
    positionLeft: 101,
    showUid: false,
    showVat: false,
  },
  senderAddress: {
    show: true,
    fontSize: 12,
    fontColor: '#000000',
    display: 'one--block',
    positionTop: 7,
    positionLeft: 0,
    showCompanyName: true,
    showAddress: false,
    showPhone: false,
    showEmail: false,
    showWebsite: false,
    showUid: false,
    showVat: false,
  },
  tableBasic: {
    break: false,
    space: true,
    borderColor: '#000000',
  },
  tableHeader: {
    show: true,
    fontSize: 9,
    borderTop: false,
    fontColor: '#000000',
    background: true,
    fontWeight: 'bold',
    borderBottom: true,
    backgroundColor: '#dddddd',
  },
  tableBody: {
    lines: true,
    stripes: false,
    stripesColor: '#000000',
  },
  tableColumns: {
    showProductKey: true,
    swapPriceAndQty: false,
    // forceShowQuantity: true,
    // forceShowUnitPrice: true,
  },
  tableFooter: {
    sumLine: false,
    resultUnterline: false,
    resultUnterlineSize: 1,
  },
  terms: {
    show: true,
    showTitle: true,
    fontSize: 9,
    fontColor: '#454142',
  },
  title: {
    show: false,
    showNumber: true,
  },
};
