export const FETCH_ACCOUNT_STATEMENT = 'FETCH_ACCOUNT_STATEMENT';
export const FETCH_ACCOUNT_STATEMENT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_FAILURE = 'FETCH_ACCOUNT_STATEMENT_FAILURE';
export const FETCH_ACCOUNT_STATEMENT_CANCEL = 'FETCH_ACCOUNT_STATEMENT_CANCEL';

export const FETCH_MORE_ACCOUNT_STATEMENT = 'FETCH_MORE_ACCOUNT_STATEMENT';
export const FETCH_MORE_ACCOUNT_STATEMENT_SUCCESS = 'FETCH_MORE_ACCOUNT_STATEMENT_SUCCESS';
export const FETCH_MORE_ACCOUNT_STATEMENT_FAILURE = 'FETCH_MORE_ACCOUNT_STATEMENT_FAILURE';
export const FETCH_MORE_ACCOUNT_STATEMENT_CANCEL = 'FETCH_MORE_ACCOUNT_STATEMENT_CANCEL';

export const FETCH_ACCOUNT_STATEMENT_PDF_EXPORT = 'FETCH_ACCOUNT_STATEMENT_PDF_EXPORT';
export const FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_FAILURE = 'FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_FAILURE';
export const FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_CANCEL = 'FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_CANCEL';

export const FETCH_ACCOUNT_STATEMENT_XLS_EXPORT = 'FETCH_ACCOUNT_STATEMENT_XLS_EXPORT';
export const FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_FAILURE = 'FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_FAILURE';
export const FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_CANCEL = 'FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_CANCEL';

export const POST_ACCOUNT_STATEMENT = 'POST_ACCOUNT_STATEMENT';
export const POST_ACCOUNT_STATEMENT_SUCCESS = 'POST_ACCOUNT_STATEMENT_SUCCESS';
export const POST_ACCOUNT_STATEMENT_FAILURE = 'POST_ACCOUNT_STATEMENT_FAILURE';

// fetch account export
export const FETCH_ACCOUNT_STATEMENT_EXPORT = 'FETCH_ACCOUNT_STATEMENT_EXPORT';
export const FETCH_ACCOUNT_STATEMENT_EXPORT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_EXPORT_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_EXPORT_FAILURE = 'FETCH_ACCOUNT_STATEMENT_EXPORT_FAILURE';
export const FETCH_ACCOUNT_STATEMENT_EXPORT_CANCEL = 'FETCH_ACCOUNT_STATEMENT_EXPORT_CANCEL';

export const BOOK_EXCHANGE_DIFF = 'BOOK_EXCHANGE_DIFF';
export const BOOK_EXCHANGE_DIFF_SUCCESS = 'BOOK_EXCHANGE_DIFF_SUCCESS';
export const BOOK_EXCHANGE_DIFF_FAILURE = 'BOOK_EXCHANGE_DIFF_FAILURE';

export const SET_SEARCH = 'SET_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
