import axios from 'axios';

const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_FULFILLED';

const FETCH_PROVIDERS_PENDING = 'FETCH_PROVIDERS_PENDING';
const FETCH_PROVIDERS_FULFILLED = 'FETCH_PROVIDERS_FULFILLED';
const FETCH_PROVIDERS_ERROR = 'FETCH_PROVIDERS_ERROR';

const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';

const FETCH_SYSTEM_PENDING = 'FETCH_SYSTEM_PENDING';
const FETCH_SYSTEM_FULFILLED = 'FETCH_SYSTEM_FULFILLED';
const FETCH_SYSTEM_ERROR = 'FETCH_SYSTEM_ERROR';

const STORE_SYSTEM_PENDING = 'STORE_SYSTEM_PENDING';
const STORE_SYSTEM_FULFILLED = 'STORE_SYSTEM_FULFILLED';
const STORE_SYSTEM_ERROR = 'STORE_SYSTEM_ERROR';
const STORE_OAUTH_AUTHORIZATION_PENDING = 'STORE_OAUTH_AUTHORIZATION_PENDING';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    fetchProvidersPending: false,
    providers: [],
    listPending: false,
    list: [],
    fetchSystemPending: false,
    system: {},
    storeSystemPending: false,
    storeOauthAuthorizationPending: false,
  },
  mutations: {
    // FETCH INITIAL
    [FETCH_INITIAL_PENDING](state) {
      state.initialPending = true;
    },
    [FETCH_INITIAL_FULFILLED](state) {
      state.initialPending = false;
    },
    [FETCH_INITIAL_ERROR](state) {
      state.initialPending = false;
    },
    // FETCH PROVIDERS
    [FETCH_PROVIDERS_PENDING](state) {
      state.fetchProvidersPending = true;
    },
    [FETCH_PROVIDERS_FULFILLED](state, { data }) {
      state.providers = data;
      state.fetchProvidersPending = false;
    },
    [FETCH_PROVIDERS_ERROR](state) {
      state.fetchProvidersPending = false;
    },
    // FETCH LIST
    [FETCH_LIST_PENDING](state) {
      state.listPending = true;
    },
    [FETCH_LIST_FULFILLED](state, { data }) {
      state.list = data;
      state.listPending = false;
    },
    [FETCH_LIST_ERROR](state) {
      state.listPending = false;
    },
    // FETCH SYSTEM
    [FETCH_SYSTEM_PENDING](state) {
      state.fetchSystemPending = true;
    },
    [FETCH_SYSTEM_FULFILLED](state, { data }) {
      [state.system] = data;
      state.fetchSystemPending = false;
    },
    [FETCH_SYSTEM_ERROR](state) {
      state.fetchSystemPending = false;
    },
    // STORE SYSTEM
    [STORE_SYSTEM_PENDING](state) {
      state.storeSystemPending = true;
    },
    [STORE_SYSTEM_FULFILLED](state) {
      state.storeSystemPending = false;
    },
    [STORE_SYSTEM_ERROR](state) {
      state.storeSystemPending = false;
    },
    [STORE_OAUTH_AUTHORIZATION_PENDING](state, isPending) {
      state.storeOauthAuthorizationPending = isPending;
    },
  },
  actions: {
    async fetchInitial({ commit, dispatch }) {
      commit(FETCH_INITIAL_PENDING);
      try {
        await Promise.all([
          dispatch('fetchProviders'),
        ]);
        commit(FETCH_INITIAL_FULFILLED);
        return true;
      } catch (error) {
        commit(FETCH_INITIAL_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchProviders({ rootState, commit }) {
      commit(FETCH_PROVIDERS_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting_get_api_providers`;
        const response = await axios.get(url);
        commit(FETCH_PROVIDERS_FULFILLED, response);
        return true;
      } catch (error) {
        commit(FETCH_PROVIDERS_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchPaymentSystemDetail({ rootState }, id) {
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/payment-systems/transactions/${id}`;
        return await axios.get(url);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchList({ rootState, commit }) {
      commit(FETCH_LIST_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/payment-systems/accounts`;
        const response = await axios.get(url);
        commit(FETCH_LIST_FULFILLED, response);
        return response;
      } catch (error) {
        commit(FETCH_LIST_ERROR, error);
        return Promise.reject(error);
      }
    },
    async fetchSystem({ rootState, commit }, id) {
      commit(FETCH_SYSTEM_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting_get_api_single_key`;
        const params = { id };
        const response = await axios.get(url, { params }); // TODO: data in response, Why Array?
        commit(FETCH_SYSTEM_FULFILLED, response);
        return true;
      } catch (error) {
        commit(FETCH_SYSTEM_ERROR, error);
        return Promise.reject(error);
      }
    },
    async deletePaymentSystem({ rootState, dispatch }, id) {
      try {
        await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/finances/payment-systems/accounts/${id}`);
        dispatch('fetchList');
        return true;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async storeSystem({ rootState, commit, dispatch }, form) {
      commit(STORE_SYSTEM_PENDING);
      try {
        const url = `/accounts/${rootState.definitions.account.account.uuid}/accounting_store_api_single_key`;
        const response = await axios.post(url, form); // TODO: POST / PATCH
        commit(STORE_SYSTEM_FULFILLED, response);
        dispatch('messages/showMessage', { key: 'payment_system_store_successful', color: 'success' }, { root: true });
        return true;
      } catch (error) {
        commit(STORE_SYSTEM_ERROR, error);
        dispatch('messages/showMessage', { key: 'payment_system_store_error', color: 'error' }, { root: true });
        return Promise.reject(error);
      }
    },
    async storeOauthAuthorization({ rootState, commit, dispatch }, payload) {
      commit(STORE_OAUTH_AUTHORIZATION_PENDING, true);
      try {
        await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
        const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/payment-systems/payment-system/${payload.id}/authorization`;
        const response = await axios.patch(url, payload);
        commit(STORE_OAUTH_AUTHORIZATION_PENDING, false);
        return response;
      } catch (error) {
        commit(STORE_OAUTH_AUTHORIZATION_PENDING, false);
        dispatch(
          'messages/showMessage',
          {
            text: 'OAuth Authorization fehlgeschlagen',
            color: 'error',
          },
          { root: true },
        );
        return Promise.reject(error);
      }
    },
  },
};
