import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      await dispatch('fetchList');
      commit(types.FETCH_INITIAL_FULFILLED);
      return true;
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchList({ rootState, commit }, params = null) {
    commit(types.FETCH_LIST_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/banking/accounts`;
      commit(types.FETCH_LIST_FULFILLED, await axios.get(url, { params }));
      return true;
    } catch (error) {
      commit(types.FETCH_LIST_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchGroupedList({ rootState, commit }, params = null) {
    commit(types.FETCH_GROUPED_LIST_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/finances/banking/accounts/groups`;
      commit(types.FETCH_GROUPED_LIST_FULFILLED, await axios.get(url, { params }));
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_GROUPED_LIST_ERROR, error);
      return Promise.reject(error);
    }
  },
};

export default actions;
