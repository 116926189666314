import { acceptHMRUpdate, defineStore } from 'pinia';
import { useRequireEntity } from '@/stores/composables/use-require-entitity';
import { getAccountUser } from '@/api/users/user';

export const useUserStore = defineStore('user', () => ({
  ...useRequireEntity(getAccountUser),
}));

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
