import axios from 'axios';
import qs from 'qs';
import * as types from './types';

const actions = {
  async fetchReportInitial({ rootState, commit }) {
    try {
      commit(types.FETCH_REPORT_INITIAL_PENDING, true);
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/report/charts_and_reports`);
      commit(types.FETCH_REPORT_INITIAL, data);
      commit(types.FETCH_REPORT_INITIAL_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_REPORT_INITIAL_PENDING, false);
      return Promise.reject(error);
    }
  },
  async getReport({ rootState, commit }, payload) {
    try {
      commit(types.FETCH_REPORT_PENDING, true);
      payload.action = null;
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/report/charts_and_reports`, payload);
      commit(types.FETCH_REPORT_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_REPORT_PENDING, false);
      return Promise.reject(error);
    }
  },
  async fetchListInitial({ dispatch, commit }, {
    list,
    id,
    date_range_from: dateRangeFrom,
    date_range_to: dateRangeTo,
    metaKey,
    meta,
    status,
    status_name: statusName,
  }) {
    try {
      commit(types.FETCH_LIST_INITIAL_PENDING, true);
      await dispatch('fetchList', {
        list,
        id,
        date_range_from: dateRangeFrom,
        date_range_to: dateRangeTo,
        metaKey,
        meta,
        status,
        status_name: statusName,
      });
      commit(types.FETCH_LIST_INITIAL_PENDING, false);
    } catch (error) {
      commit(types.FETCH_LIST_INITIAL_PENDING, false);
    }
  },
  async fetchList({ rootState, state, commit }, {
    list,
    date_range_from: dateRangeFrom,
    date_range_to: dateRangeTo,
    metaKey,
    meta,
    status,
    status_name: statusName,
  }) {
    commit(types.FETCH_LIST_PENDING);

    try {
      let parameter = {};
      const metaValue = meta || state.meta[metaKey] || { sortDesc: [true], sortBy: ['id'] };
      const { search } = metaValue;

      if (metaValue) {
        parameter = {
          date_range_from: dateRangeFrom,
          date_range_to: dateRangeTo,
          status,
          direction: metaValue.sortDesc && metaValue.sortDesc[0] ? 'DESC' : 'ASC',
          per_page: metaValue.itemsPerPage,
          sort: metaValue.sortBy ? metaValue.sortBy[0] : undefined,
          page: metaValue.page,
          extend_search: {},
          search,
        };
      }
      if (statusName) {
        parameter.extend_search.status_name_OR = statusName.join();
      }

      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/pagination/reports`;
      const url = `${baseUrl}/${list}`;

      const response = await axios.get(
        url,
        {
          params: parameter,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
        },
      );
      if (response.meta) {
        if (metaValue) {
          response.meta.search = metaValue.search; // keep current search params
        }
        commit(types.FETCH_TABLE_META, { meta: response.meta, key: metaKey });
        commit(types.FETCH_TABLE_TOTALS, response.meta.totals);
      }
      commit(types.FETCH_LIST_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_LIST_ERROR, error);
      return Promise.reject(error);
    }
  },
  async paginationExport({ rootState, state, commit }, {
    report_type: reportType,
    start_date: startDate,
    end_date: endDate,
    exportFormat,
    invoice_status: invoiceStatus,
    status_name: statusName,
    metaKey,
    meta,
  }) {
    const pendingType = exportFormat === 'pdf'
      ? 'FETCH_REPORT_EXPORT_PDF_PENDING'
      : 'FETCH_REPORT_EXPORT_EXCEL_PENDING';

    commit(types[pendingType], true);
    try {
      let parameter = {};
      const metaKeyValue = metaKey || reportType;
      const metaValue = meta || state.meta[metaKeyValue] || { sortDesc: [true], sortBy: ['id'] };
      const { search } = metaValue;
      parameter = {
        date_range_from: startDate,
        date_range_to: endDate,
        per_page: 'all',
        sort: metaValue.sortBy[0],
        export: exportFormat || 'csv',
        direction: metaValue.sortDesc[0] ? 'DESC' : 'ASC',
        status: reportType === 'invoices' ? invoiceStatus : undefined,
        search,
        extend_search: {},
      };
      // prepare extended search parameter
      if (statusName) {
        parameter.extend_search.status_name_OR = statusName.join();
      }
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/pagination/reports`;
      const url = `${baseUrl}/${reportType}`;

      const response = await axios.get(
        url,
        {
          params: parameter,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
          responseType: 'blob',
        },
      );
      commit(types[pendingType], false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types[pendingType], false);
      return Promise.reject(error);
    }
  },
  async exportReport({ rootState, commit }, payload) {
    try {
      commit(types.FETCH_REPORT_EXPORT_PENDING, true);
      payload.action = 'export';
      const url = `/accounts/${rootState.definitions.account.account.uuid}/report/charts_and_reports`;
      const data = await axios.post(url, payload, { responseType: 'blob' });
      commit(types.FETCH_REPORT_EXPORT_PENDING, false);
      return Promise.resolve(data);
    } catch (error) {
      commit(types.FETCH_REPORT_EXPORT_PENDING, false);
      return Promise.reject(error);
    }
  },
};

export default actions;
