import * as types from './types';

export default {
  // GET base kinds
  [types.FETCH_BASE_KINDS](state) {
    state.isLoadingBaseKinds = true;
    state.hasLoadedBaseKinds = false;
    state.baseKindsError = null;
  },
  [types.FETCH_BASE_KINDS_SUCCESS](state, baseKinds) {
    state.baseKinds = baseKinds;

    state.isLoadingBaseKinds = false;
    state.hasLoadedBaseKinds = true;
    state.baseKindsError = null;
  },
  [types.FETCH_BASE_KINDS_CANCEL](state, error) {
    state.isLoadingBaseKinds = false;
    state.hasLoadedBaseKinds = false;
    state.baseKindsError = error;
  },
  [types.FETCH_BASE_KINDS_FAILURE](state) {
    state.isLoadingBaseKinds = false;
    state.baseKindsError = null;
  },
};
