import * as types from './types';

const mutations = {
  [types.FETCH_PRODUCT_PENDING](state, isPending) {
    state.productPending = isPending;
  },
  [types.FETCH_PRODUCT_FULFILLED](state, { data }) {
    state.product = data;
  },
  [types.FETCH_PRODUCT_ERROR](state) {
    state.productPending = false;
  },
  [types.STORE_PRODUCT_PENDING](state) {
    state.productStorePending = true;
  },
  [types.STORE_PRODUCT_FULFILLED](state) {
    state.productStorePending = false;
  },
  [types.STORE_PRODUCT_ERROR](state) {
    state.productStorePending = false;
  },
  [types.VALIDATE_PRODUCT_KEY_PENDING](state) {
    state.productKeyValidationPending = true;
  },
  [types.VALIDATE_PRODUCT_KEY_FULFILLED](state) {
    state.productKeyValidationPending = false;
  },
  [types.RESET_PRODUCT](state) {
    state.product = {};
  },
  [types.ARCHIVE_PRODUCT_PENDING](state) {
    state.productArchivePending = true;
  },
  [types.ARCHIVE_PRODUCT_FULFILLED](state) {
    state.productArchivePending = false;
  },
  [types.ARCHIVE_PRODUCT_ERROR](state) {
    state.productArchivePending = false;
  },
  [types.RESTORE_PRODUCT_PENDING](state) {
    state.productRestorePending = true;
  },
  [types.RESTORE_PRODUCT_FULFILLED](state) {
    state.productRestorePending = false;
  },
  [types.RESTORE_PRODUCT_ERROR](state) {
    state.productRestorePending = false;
  },
  [types.DELETE_PRODUCT_PENDING](state) {
    state.productDeletePending = true;
  },
  [types.DELETE_PRODUCT_FULFILLED](state) {
    state.productDeletePending = false;
  },
  [types.DELETE_PRODUCT_ERROR](state) {
    state.productDeletePending = false;
  },
};

export default mutations;
