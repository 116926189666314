import type { InternalAxiosRequestConfig } from 'axios';

export const requestWebapiBaseInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (config.baseURL !== import.meta.env.VITE_URL) {
    return config;
  }

  return {
    ...config,
    url: `/webapi${config.url}`,
  };
};
