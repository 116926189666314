export const FETCH_REPORT_INITIAL_PENDING = 'FETCH_REPORT_INITIAL_PENDING';
export const FETCH_REPORT_INITIAL = 'FETCH_REPORT_INITIAL';

export const FETCH_REPORT_PENDING = 'FETCH_REPORT_PENDING';
export const FETCH_REPORT_EXPORT_PENDING = 'FETCH_REPORT_EXPORT_PENDING';
export const FETCH_REPORT_EXPORT_EXCEL_PENDING = 'FETCH_REPORT_EXPORT_EXCEL_PENDING';
export const FETCH_REPORT_EXPORT_PDF_PENDING = 'FETCH_REPORT_EXPORT_PDF_PENDING';

export const FETCH_LIST_INITIAL_PENDING = 'FETCH_LIST_INITIAL_PENDING';
export const FETCH_LIST_PENDING = 'FETCH_LIST_PENDING';
export const FETCH_LIST_FULFILLED = 'FETCH_LIST_FULFILLED';
export const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR';
export const FETCH_TABLE_META = 'FETCH_TABLE_META';
export const FETCH_TABLE_TOTALS = 'FETCH_TABLE_TOTALS';
