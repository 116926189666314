import * as types from './types';

export default {
  // GET base types
  [types.FETCH_BASE_TYPES](state) {
    state.isLoadingBaseTypes = true;
    state.hasLoadedBaseTypes = false;
    state.baseTypeError = null;
  },
  [types.FETCH_BASE_TYPES_SUCCESS](state, baseTypes) {
    state.baseTypes = baseTypes;

    state.isLoadingBaseTypes = false;
    state.hasLoadedBaseTypes = true;
    state.baseTypeError = null;
  },
  [types.FETCH_BASE_TYPES_CANCEL](state, error) {
    state.isLoadingBaseTypes = false;
    state.hasLoadedBaseTypes = false;
    state.baseTypeError = error;
  },
  [types.FETCH_BASE_TYPES_FAILURE](state) {
    state.isLoadingBaseTypes = false;
    state.baseTypeError = null;
  },
};
