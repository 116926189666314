import account from './account';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import permissions from './permissions';
import initialState from './state';

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
  getters,
  modules: {
    account,
    permissions,
  },
};
