import { OUT_OF_SCOPE } from '@/helpers/utils';

import { getCurrentInstance } from 'vue';

export function useVuetify() {
  const instance = getCurrentInstance();
  if (!instance) {
    const errorMessage = `[Vuetify] out of ${OUT_OF_SCOPE}`;
    console.warn(errorMessage);
    throw new Error(errorMessage);
  }
  return instance.proxy.$vuetify;
}
