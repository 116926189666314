import { useRequireEntities } from '@/stores/composables/use-require-entities';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { getJournalYears } from '@/api/definitions/journal-years';

export const useJournalYearsStore = defineStore('journalYears', () => ({
  ...useRequireEntities(getJournalYears),
}));

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJournalYearsStore, import.meta.hot));
}
