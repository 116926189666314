import * as types from './types';

const mutations = {
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  [types.FETCH_ACCOUNTING_YEAR_FULFILLED](state, { data }) {
    state.accountingYears = data;
  },
  [types.FETCH_KEYFIGURES_PENDING](state) {
    state.keyFiguresLoading = true;
  },
  [types.FETCH_KEYFIGURES_FULFILLED](state, { data }) {
    Object.keys(data.ranges).forEach((key) => {
      if (/.*_(from|to)/.test(key)) {
        const nomalizedKey = key.replace(/_from|_to/, '');
        if (!data.ranges[nomalizedKey]) {
          data.ranges[nomalizedKey] = [];
        }
        data.ranges[nomalizedKey].push(parseFloat(data.ranges[key] || 0));
      }
    });

    state.keyFigures = data;
    state.keyFiguresLoading = false;
  },
  [types.FETCH_KEYFIGURES_ERROR](state) {
    state.keyFiguresLoading = false;
  },
  [types.CHANGE_ACCOUNTING_YEAR](state, currentAccountingYear) {
    state.currentAccountingYear = currentAccountingYear;
  },
  [types.CHANGE_RANGE](state, { data, key }) {
    state.keyFigures.ranges[key] = [...data];
  },
};

export default mutations;
