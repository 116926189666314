import DeepCloudConnect from '@/pages/finances/banking/bankaccount/DeepCloudConnect.vue';
import { payments } from '@/router/finances/payments';

const Ababank = () => import('@/pages/finances/banking/ababank/Ababank.vue');
const AbabankPostfinance = () => import('@/pages/finances/banking/ababank/postfinance/Postfinance.vue');
const BankAccount = () => import('@/pages/finances/banking/bankaccount/BankAccount.vue');
const Bankfile = () => import('@/pages/finances/bankfiles/Bankfile.vue');
const BankfileImport = () => import('@/pages/finances/bankfiles/BankfileImport.vue');
const Banking = () => import('@/pages/finances/banking/Banking.vue');
const CreditCard = () => import('@/pages/finances/creditcards/creditcard/CreditCard.vue');
const CreditCards = () => import('@/pages/finances/creditcards/CreditCards.vue');
const CreditCardsList = () => import('@/pages/finances/creditcards/List.vue');
const DeepCloudSetup = () => import('@/pages/finances/banking/bankaccount/DeepCloudSetup.vue');
const Ebanking = () => import('@/pages/finances/banking/ebanking/Ebanking.vue');
const ExportPayments = () => import('@/pages/finances/payments/export/ExportPayments.vue');
const Finances = () => import('@/pages/finances/Finances.vue');
const List = () => import('@/pages/finances/banking/list/List.vue');
const OauthAuthorization = () => import('@/pages/finances/banking/bankaccount/OauthAuthorization.vue');
const OauthCallbackBlink = () => import('@/pages/finances/banking/bankaccount/OauthCallbackBlink.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const PaymentOauthAuthorization = () => import('@/pages/finances/payment-systems/PaymentOauthAuthorization.vue');
const PaymentSystem = () => import('@/pages/finances/payment-systems/payment-system/PaymentSystem.vue');
const PaymentSystems = () => import('@/pages/finances/payment-systems/PaymentSystems.vue');
const PaymentSystemsList = () => import('@/pages/finances/payment-systems/List.vue');

export default {
  path: '/finances',
  name: 'payments',
  component: Finances,
  redirect: { name: 'payments-list', params: { group: 'open' } },
  children: [
    ...payments,
    {
      path: 'bankfiles',
      name: 'payments-bankfiles',
      component: PassThroughKeyed,
      children: [
        {
          path: 'import',
          name: 'finances-bankfiles-import',
          component: BankfileImport,
        },
        {
          path: ':id',
          name: 'finances-bankfiles-edit',
          component: Bankfile,
        },
      ],
    },
    {
      path: 'export-payments',
      name: 'finances-export-payments',
      component: ExportPayments,
    },
    {
      path: 'banking',
      component: Banking,
      children: [
        {
          path: '',
          name: 'finances-banking',
          component: List,
          meta: {
            title: 'banking',
          },
        },
        {
          path: 'create',
          name: 'finances-bank-create',
          component: BankAccount,
          meta: {
            title: 'new_bank_account',
          },
        },
        {
          path: ':id?/deepcloud/setup',
          name: 'finances-bank-deepcloud-setup',
          component: DeepCloudSetup,
          meta: {
            title: 'deepcloud_connections',
          },
        },
        {
          path: ':id/deepcloud/connect',
          name: 'finances-bank-deepcloud-connect',
          component: DeepCloudConnect,
          meta: {
            title: 'deepcloud_connections',
          },
        },
        {
          path: 'blink/callback/:customerUuid',
          name: 'finance-blink-callback',
          component: OauthCallbackBlink,
        },
        {
          path: ':id/edit',
          name: 'finances-bank-edit',
          component: BankAccount,
          meta: {
            title: 'edit_bank_account',
          },
        },
        {
          path: ':id/ebanking',
          name: 'finances-bank-ebanking',
          component: Ebanking,
        },
        {
          path: ':id/authorization',
          name: 'finances-bank-authorization',
          component: OauthAuthorization,
        },
        {
          path: 'ababank',
          name: 'finances-ababank',
          component: Ababank,
          children: [
            {
              path: 'postfinance/:id?',
              name: 'finances-ababank-postfinance',
              component: AbabankPostfinance,
              meta: {
                title: 'add_postfinance_account',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'payment-systems',
      component: PaymentSystems,
      children: [
        {
          path: '',
          name: 'payment-systems',
          component: PaymentSystemsList,
          meta: {
            title: 'payment_systems',
          },
        },
        {
          path: 'create',
          name: 'payment-system-create',
          component: PaymentSystem,
          meta: {
            title: 'payment_system_add',
          },
        },
        {
          path: ':id/edit',
          name: 'payment-system-edit',
          component: PaymentSystem,
          meta: {
            title: 'payment_system_edit',
          },
        },
        {
          path: ':id/authorization',
          name: 'payment-system-authorization',
          component: PaymentOauthAuthorization,
        },
      ],
    },
    {
      path: 'creditcards',
      component: CreditCards,
      children: [
        {
          path: '',
          name: 'creditcard',
          component: CreditCardsList,
          meta: {
            title: 'creditcards',
          },
        },
        {
          path: 'create',
          name: 'credit-card-create',
          component: CreditCard,
          meta: {
            title: 'credit_card_add',
          },
        },
        {
          path: ':id/edit',
          name: 'credit-card-edit',
          component: CreditCard,
          meta: {
            title: 'credit_card_edit',
          },
        },
      ],
    },
  ],
};
