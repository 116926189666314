import actions from '@/store/modules/accounting/classification/actions';
import mutations from '@/store/modules/accounting/classification/mutations';
import initialState from '@/store/modules/accounting/classification/state';

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
};
