import * as types from './types';

export default {
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialPending = true;
    state.paginationProducts = [];
    state.productGroups = [];
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialPending = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialPending = false;
  },
  [types.FETCH_PRODUCTS_PENDING](state) {
    state.productsPending = true;
  },
  [types.FETCH_PRODUCTS_FULFILLED](state, { data }) {
    state.products = data;
    state.productsPending = false;
  },
  [types.FETCH_PAGINATION_PRODUCTS_FULFILLED](state, { data }) {
    state.paginationProducts = data;
    state.productsPending = false;
  },
  [types.FETCH_TABLE_META](state, { meta, key }) {
    if (!meta || !key) {
      return;
    }
    state.meta[key] = {
      sortDesc: [meta.direction === 'DESC'],
      itemsPerPage: parseInt(meta.per_page, 10),
      sortBy: [meta.sort],
      page: parseInt(meta.page, 10),
      search: meta.search,
      maxPage: parseInt(meta.max_page, 10),
      totalItems: meta.max_page * meta.per_page,
    };
  },
  [types.FETCH_PRODUCTS_ERROR](state) {
    state.productsPending = false;
  },
  [types.FETCH_PRODUCT_GROUPS_PENDING](state) {
    state.productGroupsPending = true;
  },
  [types.FETCH_PRODUCT_GROUPS_FULFILLED](state, { data }) {
    state.productGroups = data;
    state.productGroupsPending = false;
  },
  [types.FETCH_PRODUCT_GROUPS_ERROR](state) {
    state.productGroupsPending = false;
  },
  [types.FETCH_ARCHIVE_PRODUCTS_PENDING](state) {
    state.archiveProductsPending = true;
  },
  [types.FETCH_ARCHIVE_PRODUCTS_FULFILLED](state, { data }) {
    state.archiveProducts = data;
    state.archiveProductsPending = false;
  },
  [types.FETCH_ARCHIVE_PRODUCTS_ERROR](state) {
    state.archiveProductsPending = false;
  },
  [types.FETCH_ARCHIVE_PRODUCT_GROUPS_PENDING](state) {
    state.archiveProductGroupsPending = true;
  },
  [types.FETCH_ARCHIVE_PRODUCT_GROUPS_FULFILLED](state, { data }) {
    state.archiveProductGroups = data;
    state.archiveProductGroupsPending = false;
  },
  [types.FETCH_ARCHIVE_PRODUCT_GROUPS_ERROR](state) {
    state.archiveProductGroupsPending = false;
  },
  [types.FETCH_TAG_PENDING](state, isPending) {
    state.tagsPending = isPending;
  },
  [types.FETCH_TAG](state, { data }) {
    state.tags = data;
  },
  [types.RESET_PRODUCTS_PAGINATION_LIST](state) {
    state.paginationProducts = [];
    state.productsPending = false;

    if (state.meta.productslist) {
      delete state.meta.productslist;
    }
  },

  [types.SEARCH_PRODUCT](state) {
    state.isSearching = true;
    state.searchProducts = [];
    state.searchError = null;
  },
  [types.SEARCH_PRODUCT_SUCCESS](state, { data }) {
    state.isSearching = false;
    state.searchProducts = data;
    state.searchError = null;
  },
  [types.SEARCH_PRODUCT_FAILURE](state, error) {
    state.isSearching = false;
    state.searchProducts = [];
    state.searchError = error;
  },
  [types.SEARCH_PRODUCT_CANCEL](state) {
    state.isSearching = false;
  },

  [types.FETCH_EMPTY_SEARCH_PRODUCTS](state) {
    state.isFetchingEmptySearchProducts = true;
    state.emptySearchProducts = [];
    state.emptySearchProductsError = null;
  },
  [types.FETCH_EMPTY_SEARCH_PRODUCTS_SUCCESS](state, { data }) {
    state.isFetchingEmptySearchProducts = false;
    state.emptySearchProducts = data;
    state.emptySearchProductsError = null;
  },
  [types.FETCH_EMPTY_SEARCH_PRODUCTS_FAILURE](state, error) {
    state.isFetchingEmptySearchProducts = false;
    state.emptySearchProducts = [];
    state.emptySearchProductsError = error;
  },
  [types.FETCH_EMPTY_SEARCH_PRODUCTS_CANCEL](state) {
    state.isFetchingEmptySearchProducts = false;
  },
};
