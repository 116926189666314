import type { AxiosPromise } from 'axios';
import type { Payload } from '@/api/interfaces/payload';
import type { Module } from '@/api/interfaces/definitions/module';
import httpClient from '@/helpers/client/http-client';

export function getModules(
  abortController: AbortController = new AbortController(),
): AxiosPromise<Payload<Array<Module>>> {
  return httpClient.get('/definitions/modules', {
    signal: abortController.signal,
  });
}
