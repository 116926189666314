export default {
  isLoading: false,
  hasLoaded: false,
  error: null,

  balanceSheet: [],

  isLoadingEBalance: false,

  isPostingEBalance: false,
  isLoadingBalanceSheetPdfExport: false,
  isLoadingBalanceSheetXlsExport: false,

  meta: {
    availableLevels: [],
  },
};
