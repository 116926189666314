import type { ErrorPayload, Payload } from '@/api/interfaces/payload';
import { useI18n } from '@/helpers/i18n';
import type { Notification } from '@/interfaces/notification';
import { useEntities } from '@/stores/composables/use-entities';
import { useModulesStore } from '@/stores/definitions/modules';
import type { AxiosResponse } from 'axios';
import { isAxiosError, isCancel } from 'axios';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useNotificationStore = defineStore('notification', () => {
  const { entities } = useEntities<Notification>();
  const i18n = useI18n();
  const paidSubscription = ref(false);

  function showNotification(notification: Partial<Notification>) {
    entities.value.push({
      message: notification.message || '',
      type: notification.type || 'success',
      timeout: notification.timeout || 5000,
    });
  }

  function fromAxiosResponse(axiosResponse: AxiosResponse<Payload<unknown>>) {
    if (axiosResponse.data.message) {
      showNotification({
        message: axiosResponse.data.message,
      });
    }
  }

  function fromAxiosError(axiosError: unknown, defaultMessage = '') {
    if (isCancel(axiosError) || !isAxiosError<Payload<ErrorPayload>>(axiosError)) {
      return;
    }

    showNotification({
      message: axiosError.response?.data.message || axiosError.message || defaultMessage,
      type: 'error',
    });
  }

  function showNeedsPaidSubscription() {
    paidSubscription.value = true;
  }

  function showMessageAdvancedAmidRequired() {
    const advancedAmidText = `
      ${i18n.tc('texts.swiss21_needs_one_following_module')}
      <ul>
        <li>${i18n.tc('texts.advanced_accounting')}</li>
        <li>${i18n.tc('texts.amid_account')}</li>
      </ul>
    `;
    const paidSubscriptionAmidText = i18n.tc('texts.swiss21_needs_paid_subscription_or_amid');
    showNotification({
      message: useModulesStore().isNewSwiss21Subscription ? paidSubscriptionAmidText : advancedAmidText,
      type: 'info',
    });
  }

  return {
    entities,
    paidSubscription,
    showNotification,
    showNeedsPaidSubscription,
    fromAxiosResponse,
    fromAxiosError,
    showMessageAdvancedAmidRequired,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
