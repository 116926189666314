import * as types from './types';

export default {
  [types.FETCH_CONTRACT_NOTES](state) {
    state.isLoading = true;
    state.hasLoaded = false;
    state.error = false;

    state.contractNotes = [];
  },
  [types.FETCH_CONTRACT_NOTES_SUCCESS](state, data) {
    state.isLoading = false;
    state.hasLoaded = true;

    state.contractNotes = data;
  },
  [types.FETCH_CONTRACT_NOTES_FAILURE](state, error) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = error;

    state.contractNotes = [];
  },
  [types.FETCH_CONTRACT_NOTES_CANCEL](state) {
    state.isLoading = false;
  },

  [types.RESET_CONTRACT_NOTES](state) {
    state.isLoading = false;
    state.hasLoaded = false;
    state.error = false;

    state.contractNotes = [];
  },
};
