import type { RouteConfig } from 'vue-router/types/router';

const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');

export const settingsTimeTrackingRoutes: Array<RouteConfig> = [{
  path: 'time-tracking',
  component: PassThroughKeyed,
  children: [
    // Activity Texts
    {
      path: 'activities',
      name: 'settings-activities',
      redirect: '/v3/settings/time-tracking/activities',
    },
    // activity types
    {
      path: 'activity-types',
      component: PassThroughKeyed,
      children: [
        {
          path: '',
          name: 'settings-activity-types',
          redirect: '/v3/settings/time-tracking/activity-types',
        },
        {
          path: 'activity-type',
          name: 'settings-activity-type-create',
          redirect: '/v3/settings/time-tracking/activity-types/activity-type',
        },
        {
          path: 'activity-type/:activityTypeUuid',
          name: 'settings-activity-type-edit',
          redirect: '/v3/settings/time-tracking/activity-types/activity-type/:activityTypeUuid',
        },
      ],
    },
    // work models
    {
      path: 'work-models',
      name: 'SettingsTimeTrackingWorkModels',
      component: PassThroughKeyed,
      redirect: { name: 'settings-work-models' },
      children: [
        {
          path: '',
          name: 'settings-work-models',
          redirect: '/v3/settings/time-tracking/work-models',
        },
        {
          path: 'work-model',
          name: 'settings-work-model-create',
          redirect: '/v3/settings/time-tracking/work-models/work-model',
        },
        {
          path: 'work-model/:workModelUuid',
          name: 'settings-work-model-edit',
          redirect: '/v3/settings/time-tracking/work-models/work-model/:workModelUuid',
        },
      ],
    },
    // Holidays
    {
      path: 'holidays',
      name: 'SettingsTimeTrackingHolidayCalendars',
      component: PassThroughKeyed,
      redirect: { name: 'settings-holidays' },
      children: [
        {
          path: '',
          name: 'settings-holidays',
          redirect: '/v3/settings/time-tracking/holidays',
        },
        {
          path: 'holiday',
          name: 'settings-holiday-create',
          redirect: '/v3/settings/time-tracking/holidays/holiday',
        },
        {
          path: 'holiday/:holidayUuid',
          name: 'settings-holiday-edit',
          redirect: '/v3/settings/time-tracking/holidays/holiday/:holidayUuid',
        },
      ],
    },
  ],
}];
