import axios from 'axios';
import qs from 'qs';
import * as types from './types';

export default {
  fetchContractNotes({ rootState, commit }, { addressId, abortController }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_CONTRACT_NOTES);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/customers/${addressId}/contract_notes`, {
      params: {
        extend_search: {
          status_id_EXACT: 1,
        },
        direction: 'ASC',
        per_page: 'all',
        sort: 'invoice_number',
        page: 1,
      },
      signal: controller.signal,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_CONTRACT_NOTES_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_CONTRACT_NOTES_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_CONTRACT_NOTES_FAILURE, error);
      return Promise.reject(error);
    });
  },

  resetContractNotes({ commit }) {
    commit(types.RESET_CONTRACT_NOTES);
  },
};
