import * as types from './types';

const mutations = {
  [types.FETCH_REPORT_INITIAL_PENDING](state, isPending) {
    state.initialReportPending = isPending;
  },
  [types.FETCH_REPORT_INITIAL](state, { data }) {
    state.reportTypes = data.reportTypes;
  },
  [types.FETCH_REPORT_PENDING](state, isPending) {
    state.fetchReportPending = isPending;
  },
  [types.FETCH_REPORT_EXPORT_PENDING](state, isPending) {
    state.fetchReportExportPending = isPending;
  },
  [types.FETCH_REPORT_EXPORT_EXCEL_PENDING](state, isPending) {
    state.fetchReportExportExcelPending = isPending;
  },
  [types.FETCH_REPORT_EXPORT_PDF_PENDING](state, isPending) {
    state.fetchReportExportPdfPending = isPending;
  },
  [types.FETCH_LIST_PENDING](state) {
    state.listPending = true;
  },
  [types.FETCH_LIST_FULFILLED](state, { data }) {
    state.list = data;
    state.listPending = false;
  },
  [types.FETCH_LIST_INITIAL_PENDING](state, isPending) {
    state.listInitialPending = isPending;
    if (isPending) {
      state.list = [];
      state.totals = {};
    }
  },
  [types.FETCH_TABLE_META](state, { meta, key }) {
    if (meta) {
      state.meta[key] = {
        sortDesc: [meta.direction === 'DESC'],
        itemsPerPage: parseInt(meta.per_page, 10),
        sortBy: [meta.sort],
        page: parseInt(meta.page, 10),
        search: meta.search,
        extendSearch: meta.extend_search,
        maxPage: parseInt(meta.max_page, 10),
        totalItems: meta.max_page * meta.per_page,
      };
    }
  },
  [types.FETCH_TABLE_TOTALS](state, totals) {
    let normalizedTotals = totals;
    if (Array.isArray(normalizedTotals)) {
      normalizedTotals = {};
    }
    state.totals = normalizedTotals || {};
  },
  [types.FETCH_LIST_ERROR](state) {
    state.listPending = false;
  },
};

export default mutations;
