export const FETCH_BOOKING_ACCOUNT = 'FETCH_BOOKING_ACCOUNT';
export const FETCH_BOOKING_ACCOUNT_SUCCESS = 'FETCH_BOOKING_ACCOUNT_SUCCESS';
export const FETCH_BOOKING_ACCOUNT_FAILURE = 'FETCH_BOOKING_ACCOUNT_FAILURE';
export const FETCH_BOOKING_ACCOUNT_CANCEL = 'FETCH_BOOKING_ACCOUNT_CANCEL';

export const POST_BOOKING_ACCOUNT = 'POST_BOOKING_ACCOUNT';
export const POST_BOOKING_ACCOUNT_SUCCESS = 'POST_BOOKING_ACCOUNT_SUCCESS';
export const POST_BOOKING_ACCOUNT_FAILURE = 'POST_BOOKING_ACCOUNT_FAILURE';

export const PATCH_BOOKING_ACCOUNT = 'PATCH_BOOKING_ACCOUNT';
export const PATCH_BOOKING_ACCOUNT_SUCCESS = 'PATCH_BOOKING_ACCOUNT_SUCCESS';
export const PATCH_BOOKING_ACCOUNT_FAILURE = 'PATCH_BOOKING_ACCOUNT_FAILURE';

export const DELETE_BOOKING_ACCOUNT = 'DELETE_BOOKING_ACCOUNT';
export const DELETE_BOOKING_ACCOUNT_SUCCESS = 'DELETE_BOOKING_ACCOUNT_SUCCESS';
export const DELETE_BOOKING_ACCOUNT_FAILURE = 'DELETE_BOOKING_ACCOUNT_FAILURE';

export const FAVORITE_BOOKING_ACCOUNT = 'FAVORITE_BOOKING_ACCOUNT';
export const FAVORITE_BOOKING_ACCOUNT_SUCCESS = 'FAVORITE_BOOKING_ACCOUNT_SUCCESS';
export const FAVORITE_BOOKING_ACCOUNT_FAILURE = 'FAVORITE_BOOKING_ACCOUNT_FAILURE';
