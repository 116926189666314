<template>
  <v-slide-y-transition>
    <v-alert
      v-show="!hasEmailVerified"
      dense
      color="warning"
      class="verify-email-alert"
    >
      {{ $t('texts.verify_email') }}
      <v-btn small outlined :href="verifyEmailUrl">{{ $t('texts.confirm') }}</v-btn>
    </v-alert>
  </v-slide-y-transition>
</template>

<script lang="ts" setup>
import { useKeycloak } from '@/helpers/keycloak';
import { useI18n } from '@/helpers/i18n';

const keycloak = useKeycloak();
const i18n = useI18n();

const hasEmailVerified = keycloak?.tokenParsed?.email_verified;

const keycloakLoginUrl = keycloak.createLoginUrl({
  redirectUri: import.meta.env.VITE_ABASKYIDP_LOGIN_REDIRECT,
  scope: import.meta.env.VITE_ABASKYIDP_SCOPE.replaceAll(',', ' '),
});

const verifyEmailUrl = `${keycloakLoginUrl}&kc_action=VERIFY_EMAIL_APP_TRIGGERED&kc_locale=${i18n.locale}`;

</script>

<style lang="scss" scoped>
.verify-email-alert {
  z-index: 7;
  width: 100%;
  position: fixed;
  top: 55px;
  padding: 2px;
  text-align: center;
}
</style>
