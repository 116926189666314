import type { RouteConfig } from 'vue-router/types/router';
import { DocumentType } from '@/components/Document/types/document-type';

const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const InvoiceImportQueue = () => import('@/pages/customers/invoices/InvoiceImportQueue.vue');
const Receipt = () => import('@/pages/suppliers/receipt/Receipt.vue');
const DocumentImport = () => import('@/pages/document-import/DocumentImport.vue');
const ReceiptsList = () => import('@/pages/suppliers/receipts/ReceiptsList.vue');

const receiptsRoutes: Array<RouteConfig> = [{
  path: 'receipts',
  component: PassThroughKeyed,
  children: [
    {
      path: 'create',
      name: 'suppliers-receipt-create',
      component: DocumentImport,
      props: { type: DocumentType.RECEIPT },
      meta: {
        title: 'import_receipt',
      },
    },
    {
      path: 'import/queue/:id',
      name: 'receipt-import-queue',
      component: InvoiceImportQueue,
      props: { type: DocumentType.RECEIPT },
      meta: {
        title: 'receipts',
      },
    },
    {
      path: 'edit/:id?',
      name: 'suppliers-receipt-edit',
      component: Receipt,
      meta: {
        paymentListType: 'invoice',
        title: 'receipt',
      },
    },
    {
      path: ':group?',
      name: 'receipts-list',
      meta: {
        title: 'receipts',
      },
      component: ReceiptsList,
      beforeEnter(to, _, next) {
        if (!to.params.group) {
          next({ name: 'receipts-list', params: { group: 'open' } });
        } else {
          next();
        }
      },
    },
  ],
}];

export default receiptsRoutes;
