export enum CurrencyCode {
  CHF = 'CHF',
  EUR = 'EUR',
  USD = 'USD',
}

export interface CurrencyV2 {
  currencyCode: CurrencyCode;
  name: string;
  roundAmounts: boolean;
}
