import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialPending = true;
    state.list = [];
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialPending = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialPending = false;
  },
  // FETCH_LIST
  [types.FETCH_LIST_PENDING](state) {
    state.fetchListPending = true;
  },
  [types.FETCH_LIST_FULFILLED](state, { data }) {
    state.list = data;
    state.fetchListPending = false;
  },
  [types.FETCH_LIST_ERROR](state) {
    state.fetchListPending = false;
  },
  [types.FETCH_TABLE_META](state, { meta, key }) {
    if (!meta || !key) {
      return;
    }
    state.meta[key] = {
      sortDesc: [meta.direction === 'DESC'],
      itemsPerPage: parseInt(meta.per_page, 10),
      sortBy: [meta.sort],
      page: parseInt(meta.page, 10),
      search: meta.search,
      extendSearch: meta.extend_search,
      maxPage: parseInt(meta.max_page, 10),
      totalItems: meta.max_page * meta.per_page,
    };
  },
  // STORE
  [types.STORE_ACTION_PENDING](state) {
    state.storeActionPending = true;
  },
  [types.STORE_ACTION_FULFILLED](state) {
    state.storeActionPending = false;
  },
  [types.STORE_ACTION_ERROR](state) {
    state.storeActionPending = false;
  },
  // FETCH_BULK_ACTIONS
  [types.FETCH_BULK_ACTIONS_PENDING](state) {
    state.fetchBulkActionsPending = true;
  },
  [types.FETCH_BULK_ACTIONS_FULFILLED](state, { data }) {
    state.bulkActions = data;
    state.fetchBulkActionsPending = false;
  },
  [types.FETCH_BULK_ACTIONS_ERROR](state) {
    state.fetchBulkActionsPending = false;
  },
  // STORE_BULK
  [types.STORE_BULK_PENDING](state) {
    state.storeBulkPending = true;
  },
  [types.STORE_BULK_FULFILLED](state) {
    state.storeBulkPending = false;
  },
  [types.STORE_BULK_ERROR](state) {
    state.storeBulkPending = false;
  },
  // STORE_SIGNING
  [types.STORE_SIGNING_PENDING](state) {
    state.storeSigningPending = true;
  },
  [types.STORE_SIGNING_FULFILLED](state) {
    state.storeSigningPending = false;
  },
  [types.STORE_SIGNING_ERROR](state) {
    state.storeSigningPending = false;
  },
  // FETCH_SIGNING_USERS
  [types.FETCH_SIGNING_USERS_PENDING](state) {
    state.signingUsers = [];
    state.fetchSigningUsersPending = true;
  },
  [types.FETCH_SIGNING_USERS_FULFILLED](state, { data }) {
    state.signingUsers = data;
    state.fetchSigningUsersPending = false;
  },
  [types.FETCH_SIGNING_USERS_ERROR](state) {
    state.fetchSigningUsersPending = false;
  },
  [types.FETCH_INBOX_COUNT](state, { data }) {
    state.inboxCount = data.unread;
  },
  [types.MARK_AS_READ](state) {
    state.inboxCount -= 1;
  },
};

export default mutations;
