import axios from 'axios';
import * as types from './types';

export default {
  fetchBookingAccount({ rootState, commit }, { bookingAccountId, abortController }) {
    commit(types.FETCH_BOOKING_ACCOUNT);
    const controller = abortController || new AbortController();

    return axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts/${bookingAccountId}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_BOOKING_ACCOUNT_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_BOOKING_ACCOUNT_CANCEL);
        return Promise.reject(error);
      }
      commit(types.FETCH_BOOKING_ACCOUNT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  postBookingAccount({ rootState, commit }, account) {
    commit(types.POST_BOOKING_ACCOUNT);

    return axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts`, account).then((response) => {
      commit(types.POST_BOOKING_ACCOUNT_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.POST_BOOKING_ACCOUNT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  patchBookingAccount({ rootState, commit }, { bookingAccount, id }) {
    commit(types.PATCH_BOOKING_ACCOUNT);

    return axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts/${id}`, bookingAccount).then((response) => {
      commit(types.PATCH_BOOKING_ACCOUNT_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.PATCH_BOOKING_ACCOUNT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  deleteBookingAccount({ rootState, commit }, bookingAccountId) {
    commit(types.DELETE_BOOKING_ACCOUNT);

    return axios.delete(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_accounts/${bookingAccountId}`)
      .then((response) => {
        commit(types.DELETE_BOOKING_ACCOUNT_SUCCESS, response);
        return Promise.resolve(response);
      }).catch((error) => {
        commit(types.DELETE_BOOKING_ACCOUNT_FAILURE, error);
        return Promise.reject(error);
      });
  },
};
