import type { ElasticPayload, Payload } from '@/api/interfaces/payload';
import type { AddressSearchIndexStatus, AddressTag, ElasticsearchAddress } from '@/components/Address/types';
import apiClient from '@/helpers/client/api-client';
import { useCancellablePromise } from '@/helpers/useCancellablePromise';
import type { ElasticsearchBaseRequest } from '@/interfaces/elasticsearch';

export function getElasticsearchAddresses(
  data?: ElasticsearchBaseRequest,
) {
  return useCancellablePromise((abortController) => apiClient.post<ElasticPayload<ElasticsearchAddress>>(
    '/addresses/v2/search',
    data,
    {
      signal: abortController.signal,
    },
  ));
}

export function getAddressTags() {
  return useCancellablePromise((abortController) => apiClient.get<Payload<Array<AddressTag>>>('/addresses/v2/tags', {
    signal: abortController.signal,
  }));
}

export function getAddressSearchIndexStatus() {
  return useCancellablePromise(
    (abortController) => apiClient.get<Payload<AddressSearchIndexStatus>>(
      '/addresses/v2/search/index-status',
      {
        signal: abortController.signal,
      },
    ),
  );
}

export function initAddressSearchIndex() {
  return apiClient.post('/addresses/v2/search/index-init');
}
