import * as types from './types';

const mutations = {
  [types.FETCH_NOTIFICATIONS_PENDING](state) {
    state.notificationsPending = true;
  },
  [types.FETCH_NOTIFICATIONS](state, { data }) {
    state.notifications = data;
    state.notificationsPending = false;
  },
  [types.FETCH_NOTIFICATION_PENDING](state) {
    state.notificationPending = true;
  },
  [types.FETCH_NOTIFICATION](state, { data }) {
    state.notification = data;
    state.notificationPending = false;
  },
  [types.FETCH_NOTIFICATION_TYPES_PENDING](state) {
    state.notificationTypesPending = true;
  },
  [types.FETCH_NOTIFICATION_TYPES](state, { data }) {
    state.notificationTypes = data;
    state.notificationTypesPending = false;
  },
  [types.FETCH_NOTIFICATION_USER_GROUPS_PENDING](state) {
    state.notificationUserGroupsPending = true;
  },
  [types.FETCH_NOTIFICATION_USER_GROUPS](state, { data }) {
    state.notificationUserGroups = data;
    state.notificationUserGroupsPending = false;
  },
};

export default mutations;
