import axios from 'axios';
import qs from 'qs';
import * as types from './types';

export default {

  fetchAccountStatement({ rootState, commit, state }, {
    abortController, yearId, accountNumber, extendSearch, showYear, canceledBookings,
  }) {
    const controller = abortController || new AbortController();
    commit(types.FETCH_ACCOUNT_STATEMENT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/account_statements/${yearId}/${accountNumber}`, {
      signal: controller.signal,
      params: {
        page: state.meta.page,
        per_page: state.meta.per_page,
        extend_search: extendSearch,
        show_year: showYear ? 1 : 0,
        canceled_bookings: canceledBookings ? 1 : 0,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_ACCOUNT_STATEMENT_SUCCESS, response);

      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_STATEMENT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_ACCOUNT_STATEMENT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchMoreAccountStatement({ rootState, commit, state }, {
    abortController, yearId, accountNumber, showYear, canceledBookings,
  }) {
    commit(types.FETCH_MORE_ACCOUNT_STATEMENT);

    const controller = abortController || new AbortController();

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/account_statements/${yearId}/${accountNumber}`, {
      signal: controller.signal,
      params: {
        page: parseInt(state.meta.page, 10) + 1,
        per_page: state.meta.per_page,
        extend_search: state.meta.extend_search,
        show_year: showYear ? 1 : 0,
        canceled_bookings: canceledBookings ? 1 : 0,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((response) => {
      commit(types.FETCH_MORE_ACCOUNT_STATEMENT_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_MORE_ACCOUNT_STATEMENT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_MORE_ACCOUNT_STATEMENT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchAccountStatementPdfExport({ commit, rootState, state }, {
    abortController, journalYearId, accountNumber, canceledBookings, props,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT);
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/account_statements/${journalYearId}/${accountNumber}`, {
      signal: controller.signal,
      responseType: 'blob',
      params: {
        export: 'pdf',
        per_page: 'all',
        extend_search: state.meta.extend_search,
        show_year: 1,
        canceled_bookings: canceledBookings ? 1 : 0,
        ...props,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_SUCCESS, payload);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_ACCOUNT_STATEMENT_PDF_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchAccountStatementXlsExport({ commit, rootState, state }, {
    abortController, journalYearId, accountNumber, canceledBookings, props,
  }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT);
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/account_statements/${journalYearId}/${accountNumber}`, {
      signal: controller.signal,
      responseType: 'blob',
      params: {
        export: 'xls',
        per_page: 'all',
        extend_search: state.meta.extend_search,
        show_year: 1,
        canceled_bookings: canceledBookings ? 1 : 0,
        ...props,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    }).then((payload) => {
      commit(types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_SUCCESS, payload);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_ACCOUNT_STATEMENT_XLS_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  fetchAccountStatementAllExport({ commit, rootState }, { abortController, yearId, params }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNT_STATEMENT_EXPORT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/export/acc_accounts/all/${yearId}`, {
      params,
      signal: controller.signal,
    }).then((payload) => {
      commit(types.FETCH_ACCOUNT_STATEMENT_EXPORT_SUCCESS, payload);
      return Promise.resolve(payload);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNT_STATEMENT_EXPORT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_ACCOUNT_STATEMENT_EXPORT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  setSearch({ commit }, search) {
    commit(types.SET_SEARCH, search);
  },

  resetSearch({ commit }) {
    commit(types.RESET_SEARCH);
  },

  postAccountStatement({ rootState, commit }, data) {
    commit(types.POST_ACCOUNT_STATEMENT);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry`, {
      data,
    }).then((payload) => {
      commit(types.POST_ACCOUNT_STATEMENT_SUCCESS);
      return Promise.resolve(payload);
    }).catch((error) => {
      commit(types.POST_ACCOUNT_STATEMENT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  bookExchangeDiff({ rootState, commit }, { yearId, accountNumber }) {
    commit(types.BOOK_EXCHANGE_DIFF);

    return axios.post(`/accounts/${rootState.definitions.account.account.uuid}/exchange_differences`, {
      journal_year_id: yearId,
      account_number: accountNumber,
    }).then((response) => {
      commit(types.BOOK_EXCHANGE_DIFF_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.BOOK_EXCHANGE_DIFF_FAILURE, error);
      return Promise.reject(error);
    });
  },
};
