import { acceptHMRUpdate, defineStore } from 'pinia';
import { useRequireEntities } from '@/stores/composables/use-require-entities';
import { getBetaFeatures } from '@/api/settings/tags';
import type { Tag } from '@/api/interfaces/tag';

export const useBetaFeaturesStore = defineStore('betaFeatures', () => {
  const requireEntities = useRequireEntities(getBetaFeatures);

  function hasAddressCriteria(addressCriteria: Tag['tag']) {
    if (requireEntities.entities.value.length === 0) {
      return false;
    }
    return requireEntities.entities.value.find((betaFeature) => betaFeature.tag === `[${addressCriteria}]`);
  }

  return {
    ...requireEntities,
    hasAddressCriteria,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBetaFeaturesStore, import.meta.hot));
}
