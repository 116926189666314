const InboxList = () => import('@/pages/inbox/InboxList.vue');

export default {
  path: '/inbox',
  name: 'inbox',
  component: InboxList,
  meta: {
    title: 'inbox',
  },
};
