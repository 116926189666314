import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.fetchInitialPending = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.fetchInitialPending = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.fetchInitialPending = false;
  },
  // FETCH_LIST
  [types.FETCH_LIST_PENDING](state) {
    state.fetchListPending = true;
  },
  [types.FETCH_LIST_FULFILLED](state, { data }) {
    state.bankAccounts = data;
    state.fetchListPending = false;
  },
  [types.FETCH_LIST_ERROR](state) {
    state.fetchListPending = false;
  },
  // FETCH_GROUPED
  [types.FETCH_GROUPED_LIST_PENDING](state) {
    state.fetchGroupedListPending = true;
  },
  [types.FETCH_GROUPED_LIST_FULFILLED](state, { data }) {
    state.bankAccountsByGroup = data;
    state.fetchGroupedListPending = false;
  },
  [types.FETCH_GROUPED_LIST_ERROR](state) {
    state.fetchGroupedListPending = false;
  },
};

export default mutations;
