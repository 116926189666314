import { ref } from 'vue';

export const useLoadable = () => {
  const isLoading = ref(false);
  const hasLoaded = ref(false);
  const abortController = ref<AbortController | null>(null);

  function cancel() {
    if (abortController.value) {
      isLoading.value = false;
      abortController.value.abort();
      abortController.value = null;
    }
  }

  return {
    isLoading,
    hasLoaded,
    abortController,
    cancel,
  };
};
