import type { Payload } from '@/api/interfaces/payload';
import type { TaxTimeline } from '@/api/interfaces/tax/tax-timeline';
import { useCancellablePromise } from '@/helpers/useCancellablePromise';
import apiClient from '@/helpers/client/api-client';

export function getTaxTimelines() {
  return useCancellablePromise((abortController) => apiClient.get<Payload<Array<TaxTimeline>>>(
    '/accounting/v2/tax-timelines',
    { signal: abortController.signal },
  ));
}
