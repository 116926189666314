import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_ADDRESS
  [types.FETCH_ADDRESS_PENDING](state) {
    state.addressPending = true;
  },
  [types.FETCH_ADDRESS_FULFILLED](state, { data }) {
    state.address = data;
    state.addressPending = false;
  },
  [types.FETCH_ADDRESS_ERROR](state) {
    state.addressPending = false;
  },
  // FETCH_ADDRESS_DETAIL
  [types.FETCH_ADDRESS_DETAIL_PENDING](state) {
    state.addressDetailPending = true;
  },
  [types.FETCH_ADDRESS_DETAIL_FULFILLED](state, { data }) {
    state.address = data;
    state.addressDetailPending = false;
  },
  [types.FETCH_ADDRESS_DETAIL_ERROR](state) {
    state.addressDetailPending = false;
  },
  // FETCH_ADDRESS_SINGLE_ACTIONS
  [types.FETCH_ADDRESS_SINGLE_ACTIONS_FULFILLED](state, { data }) {
    state.singleActions = data;
  },
  // FETCH_ADDRESS_OP_RECEIVABLES
  [types.FETCH_ADDRESS_OP_RECEIVABLES_FULFILLED](state, { data }) {
    state.opReceivablesAmount = data.data;
  },
  // FETCH_ADDRESS_OP_RECEIVABLES
  [types.FETCH_ADDRESS_OP_PAYABLES_FULFILLED](state, { data }) {
    state.opPayablesAmount = data.data;
  },
  // FETCH_ADDRESS_OP_PAYABLES_PAID
  [types.FETCH_ADDRESS_OP_PAYABLES_PAID_FULFILLED](state, { data }) {
    state.opPayablesPaidAmount = data.data;
  },
  // FETCH_ADDRESS_OP_RECEIVABLES_PAID
  [types.FETCH_ADDRESS_OP_RECEIVABLES_PAID_FULFILLED](state, { data }) {
    state.opReceivablesPaidAmount = data.data;
  },
  // FETCH_ADDRESS_OP_CREDITNOTES
  [types.FETCH_ADDRESS_OP_CREDITNOTES_FULFILLED](state, { data }) {
    state.opCreditnotesAmount = data.data;
  },
  // FETCH_ADDRESS_ACTIVITIES
  [types.FETCH_ADDRESS_ACTIVITIES_PENDING](state) {
    state.fetchActivitiesPending = true;
  },
  [types.FETCH_ADDRESS_ACTIVITIES_SUCCESSFUL](state, { data }) {
    state.activities = data;
    state.fetchActivitiesPending = false;
  },
  [types.FETCH_ADDRESS_ACTIVITIES_ERROR](state) {
    state.fetchActivitiesPending = false;
  },
  // STORE_ADDRESS
  [types.STORE_ADDRESS_PENDING](state) {
    state.storeAddressPending = true;
  },
  [types.STORE_ADDRESS_FULFILLED](state, { data }) {
    state.newAddress = data;
    state.storeAddressPending = false;
  },
  [types.STORE_ADDRESS_ERROR](state) {
    state.storeAddressPending = false;
  },
  // FETCH_ZIP_PLACE_AUTO_COMPLETION
  [types.FETCH_ZIP_PLACE_AUTO_COMPLETION_PENDING](state) {
    state.fetchZipPlaceAutoCompletionPending = true;
  },
  [types.FETCH_ZIP_PLACE_AUTO_COMPLETION_FULFILLED](state) {
    state.fetchZipPlaceAutoCompletionPending = false;
  },
  [types.FETCH_ZIP_PLACE_AUTO_COMPLETION_ERROR](state) {
    state.fetchZipPlaceAutoCompletionPending = false;
  },
  // FETCH_CUSTOMER_NUMBER_PREVIEW
  [types.FETCH_CUSTOMER_NUMBER_PREVIEW_PENDING](state) {
    state.fetchCustomerNumberPreviewPending = true;
  },
  [types.FETCH_CUSTOMER_NUMBER_PREVIEW_FULFILLED](state) {
    state.fetchCustomerNumberPreviewPending = false;
  },
  [types.FETCH_CUSTOMER_NUMBER_PREVIEW_ERROR](state) {
    state.fetchCustomerNumberPreviewPending = false;
  },
  // VALIDATE_CUSTOMER_NUMBER
  [types.VALIDATE_CUSTOMER_NUMBER_PENDING](state) {
    state.customerNumberValidationPending = true;
  },
  [types.VALIDATE_CUSTOMER_NUMBER_FULFILLED](state) {
    state.customerNumberValidationPending = false;
  },
  // VALIDATE_CUSTOMER_NUMBER
  [types.VALIDATE_BIC_NUMBER_PENDING](state) {
    state.bicNumberValidationPending = true;
  },
  [types.VALIDATE_BIC_NUMBER_FULFILLED](state) {
    state.bicNumberValidationPending = false;
  },
  // VALIDATE_BIC_NUMBER
  [types.VALIDATE_ACCOUNT_NUMBER_PENDING](state) {
    state.accountNumberValidationPending = true;
  },
  [types.VALIDATE_ACCOUNT_NUMBER_FULFILLED](state) {
    state.accountNumberValidationPending = false;
  },
  // FETCH_STREET_AUTO_COMPLETION
  [types.FETCH_STREET_AUTO_COMPLETION_PENDING](state) {
    state.fetchStreetAutoCompletionPending = true;
  },
  [types.FETCH_STREET_AUTO_COMPLETION_FULFILLED](state) {
    state.fetchStreetAutoCompletionPending = false;
  },
  [types.FETCH_STREET_AUTO_COMPLETION_ERROR](state) {
    state.fetchStreetAutoCompletionPending = false;
  },
  // CHECK ABANET AVAILABLE
  [types.CHECK_ABANET_AVAILABLE_PENDING](state) {
    state.checkAbaNetAvailablePending = true;
  },
  [types.CHECK_ABANET_AVAILABLE_FULFILLED](state) {
    state.checkAbaNetAvailablePending = false;
  },
  [types.CHECK_ABANET_AVAILABLE_ERROR](state) {
    state.checkAbaNetAvailablePending = false;
  },
  [types.RESET_ADDRESS](state) {
    state.address = null;
  },
};

export default mutations;
