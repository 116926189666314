import * as types from './types';

const mutations = {
  // FETCH_INITIAL
  [types.FETCH_INITIAL_PENDING](state) {
    state.initialLoading = true;
  },
  [types.FETCH_INITIAL_FULFILLED](state) {
    state.initialLoading = false;
  },
  [types.FETCH_INITIAL_ERROR](state) {
    state.initialLoading = false;
    state.initialError = true;
  },
  // FETCH_NEXTNUMBER
  [types.FETCH_NEXTNUMBER_FULFILLED](state, { data }) {
    state.nextNumber = data.invoice_number;
  },

  // FETCH_INVOICE
  [types.FETCH_INVOICE_PENDING](state) {
    state.fetchInvoicePending = true;
  },
  [types.FETCH_INVOICE_FULFILLED](state) {
    state.fetchInvoicePending = false;
  },
  [types.FETCH_INVOICE_ERROR](state) {
    state.fetchInvoicePending = false;
  },
  // FETCH_SINGLE_INVOICE
  [types.FETCH_SINGLE_INVOICE_PENDING](state) {
    state.fetchSingleInvoicePending = true;
  },
  [types.FETCH_SINGLE_INVOICE_FULFILLED](state, { data }) {
    state.fetchSingleInvoicePending = false;
    state.invoice = data;
  },
  [types.FETCH_SINGLE_INVOICE_ERROR](state) {
    state.fetchSingleInvoicePending = false;
  },
  // FETCH_ACTIONS
  [types.FETCH_ACTIONS_PENDING](state) {
    state.fetchSingleActionsPending = true;
  },
  [types.FETCH_ACTIONS_FULFILLED](state, { data }) {
    state.singleActions = data;
    state.fetchSingleActionsPending = false;
  },
  [types.FETCH_ACTIONS_ERROR](state) {
    state.fetchSingleActionsPending = false;
  },
  // FETCH_RECURRING
  [types.FETCH_RECURRING_PENDING](state, isPending) {
    state.fetchRecurringPending = isPending;
  },
  [types.FETCH_RECURRING_FULFILLED](state, { data }) {
    state.recurring = data;
  },
  // PRECALTULATE_INVOICE
  [types.PRECALCULATE_INVOICE_PENDING](state) {
    state.precalculatePending = true;
  },
  [types.PRECALCULATE_INVOICE_FULFILLED](state, { data }) {
    state.calculation = {
      ...state.calculation,
      ...data,
    };
    state.precalculatePending = false;
  },
  [types.PRECALCULATE_INVOICE_ERROR](state) {
    state.precalculatePending = false;
  },
  // GENERATE_PREVIEW
  [types.GENERATE_PREVIEW_PENDING](state) {
    state.generateInvoicePending = true;
  },
  [types.GENERATE_PREVIEW_FULFILLED](state, { data }) {
    state.pdfPreviewUrl = data.previewUrl;
    state.generateInvoicePending = false;
  },
  [types.GENERATE_PREVIEW_ERROR](state) {
    state.generateInvoicePending = false;
  },
  // STORE_INVOICE
  [types.STORE_INVOICE_PENDING](state) {
    state.storeInvoicePending = true;
  },
  [types.STORE_INVOICE_FULFILLED](state) {
    state.storeInvoicePending = false;
  },
  [types.STORE_INVOICE_ERROR](state) {
    state.storeInvoicePending = false;
  },
  // META
  [types.FETCH_TABLE_META](state, { meta, key }) {
    if (meta) {
      state.meta[key] = {
        sortDesc: [meta.direction === 'DESC'],
        itemsPerPage: parseInt(meta.per_page, 10),
        sortBy: [meta.sort],
        page: parseInt(meta.page, 10),
        search: meta.search,
        extendSearch: meta.extend_search,
        maxPage: parseInt(meta.max_page, 10),
        totalItems: meta.max_page * meta.per_page,
      };
    }
  },
};

export default mutations;
