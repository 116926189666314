import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialPending: false,
    initialError: false,
    fetchPaymentPending: false,
    storePaymentPending: false,
    payment: {},
    fetchSingleActionsPending: false,
    singleActions: {},
    storeActionPending: false,
  },
  actions,
  mutations,
};
