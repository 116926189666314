export const FETCH_INITIAL_PENDING = 'FETCH_INITIAL_PENDING';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';
export const FETCH_INITIAL_ERROR = 'FETCH_INITIAL_ERROR';

export const IMPORT_BANKFILE_PENDING = 'IMPORT_BANKFILE_PENDING';
export const IMPORT_BANKFILE_FULFILLED = 'IMPORT_BANKFILE_FULFILLED';
export const IMPORT_BANKFILE_ERROR = 'IMPORT_BANKFILE_ERROR';

export const FETCH_BANKFILE_PENDING = 'FETCH_BANKFILE_PENDING';
export const FETCH_BANKFILE_FULFILLED = 'FETCH_BANKFILE_FULFILLED';
export const FETCH_BANKFILE_ERROR = 'FETCH_BANKFILE_ERROR';

export const STORE_BANKFILE_PENDING = 'STORE_BANKFILE_PENDING';
export const STORE_BANKFILE_FULFILLED = 'STORE_BANKFILE_FULFILLED';
export const STORE_BANKFILE_ERROR = 'STORE_BANKFILE_ERROR';

export const DELETE_BANKFILE_PENDING = 'DELETE_BANKFILE_PENDING';
export const DELETE_BANKFILE_FULFILLED = 'DELETE_BANKFILE_FULFILLED';
export const DELETE_BANKFILE_ERROR = 'DELETE_BANKFILE_ERROR';
