const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');

export default {
  path: '/products',
  name: 'products',
  component: PassThroughKeyed,
  redirect: '/v3/products/products',
  children: [
    {
      path: '',
      meta: {
        title: 'products',
      },
      children: [
        {
          name: 'product-list',
          path: 'list',
          redirect: '/v3/products/products',
          meta: {
            list: 'list',
          },
        },
        {
          name: 'product-list-archived',
          path: 'archived',
          redirect: '/v3/products/products/archived',
          meta: {
            list: 'archived',
          },
        },
      ],
    },
    {
      path: 'create',
      name: 'product-create',
      redirect: '/v3/products/products/product',
      meta: {
        title: 'create_product',
      },
    },
    {
      path: 'edit/:productUuid',
      name: 'product-edit',
      redirect: '/v3/products/products/:productUuid',
      meta: {
        title: 'edit_product',
      },
    },
    {
      path: 'group',
      name: 'product-group',
      component: PassThroughKeyed,
      redirect: { name: 'product-group-list' },
      children: [
        {
          path: '',
          component: PassThroughKeyed,
          meta: {
            title: 'product_groups',
          },
          children: [
            {
              path: 'list',
              name: 'product-group-list',
              redirect: '/v3/products/product-groups',
              meta: {
                list: 'list',
              },
            },
            {
              path: 'archived',
              name: 'product-group-archived',
              redirect: '/v3/products/product-groups/archived',
              meta: {
                list: 'archived',
              },
            },
          ],
        },
        {
          path: 'edit/:productGroupUuid',
          props: true,
          name: 'product-group-edit',
          redirect: '/v3/products/product-groups/:productGroupUuid/product-group',
          meta: {
            title: 'product_groups',
          },
        },
        {
          path: 'create',
          name: 'product-group-create',
          redirect: '/v3/products/product-groups/product-group',
          meta: {
            title: 'product_groups',
          },
        },
      ],
    },
    {
      path: ':productUuid',
      props: true,
      redirect: { name: 'product-edit' },
    },
  ],
};
