import { getLanguages } from '@/api/basics/languages';
import store from '@/store';
import { useRequireEntities } from '@/stores/composables/use-require-entities';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const
  useLanguagesStore = defineStore('languages', () => {
    const requireEntities = useRequireEntities(getLanguages);
    const receiverLanguageId = ref<number | null>(null);

    const currentMainLanguage = computed(() => {
      const mainLanguageIdx = requireEntities.entities.value.findIndex(
        (lang) => lang.id === store.state.definitions.account.account.language_id,
      );
      return requireEntities.entities.value[mainLanguageIdx];
    });

    const currentLocale = computed(() => currentMainLanguage.value.locale);

    const intlLocale = computed(() => (currentLocale.value === 'de' ? 'de-CH' : currentLocale.value));

    const languagesByMainLanguage = computed(() => {
      const languages = structuredClone(requireEntities.entities.value);

      const mainLanguageIdx = requireEntities.entities.value.findIndex(
        (lang) => lang.id === store.state.definitions.account.account.language_id,
      );

      if (mainLanguageIdx !== -1) {
        languages.unshift(languages.splice(mainLanguageIdx, 1)[0]);
      }
      return languages;
    });

    const addressLanguage = computed<Language>(() => {
      if (store.getters['addresses/address/addressUser']) {
        const correspondingAddressLanguage = requireEntities.entities.value.find(
          (lang) => lang.id === store.getters['addresses/address/addressUser'].language_id,
        );

        if (correspondingAddressLanguage) {
          return correspondingAddressLanguage;
        }
      } else if (receiverLanguageId.value) {
        const correspondingAddressLanguage = requireEntities.entities.value.find(
          (lang) => lang.id === receiverLanguageId.value,
        );

        if (correspondingAddressLanguage) {
          return correspondingAddressLanguage;
        }
      }
      return currentMainLanguage.value;
    });

    return {
      ...requireEntities,
      currentMainLanguage,
      languagesByMainLanguage,
      addressLanguage,
      currentLocale,
      intlLocale,
      receiverLanguageId,
    };
  });

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLanguagesStore, import.meta.hot));
}
