import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }) {
    commit(types.FETCH_INITIAL_PENDING);

    try {
      await dispatch('accounting/fetchVatPeriods', null, { root: true });
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchVatJournal({ rootState, commit }, { year, id }) {
    commit(types.FETCH_VAT_JOURNAL_PENDING);
    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/accounting/vat-reporting`;
      const response = await axios.get(`${baseUrl}/years/${year}/periods/${id}/form`);
      commit(types.FETCH_VAT_JOURNAL_FULFILLED, response);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.FETCH_VAT_JOURNAL_ERROR, error);
      return Promise.reject(error);
    }
  },
  addVatNumber({ commit }, key) {
    commit(types.ADD_VAT_NUMBER, key);
  },
  async closeJournal({ rootState, commit, dispatch }, { year, id }) {
    commit(types.CLOSE_VAT_JOURNAL_PENDING);
    try {
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/accounting/vat-reporting`;
      const response = await axios.patch(`${baseUrl}/years/${year}/periods/${id}/close`);
      commit(types.CLOSE_VAT_JOURNAL_FULFILLED, response);
      await Promise.all([
        dispatch('fetchVatJournal', { year, id }),
        dispatch('accounting/fetchVatPeriods', null, { root: true }),
      ]);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
    } catch (error) {
      commit(types.CLOSE_VAT_JOURNAL_ERROR, error);
      dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
    }
  },
};

export default actions;
